import { BrowserModule } from "@angular/platform-browser";
import { APP_INITIALIZER, Injector, NgModule } from "@angular/core";

import {
  TranslateLoader,
  TranslateModule,
  TranslatePipe,
} from "@ngx-translate/core";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CookieService } from "ngx-cookie-service";
import { DatePipe } from "@angular/common";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { SidebarMenuComponent } from "./sidebar-menu/sidebar-menu.component";
import { StylesheetPageComponent } from "./pages/stylesheet-page/stylesheet-page.component";
import { DashboardPageComponent } from "./pages/dashboard-page/dashboard-page.component";
import { AuctionsPageComponent } from "./pages/auctions-page/auctions-page.component";
import { StakingPageComponent } from "./pages/staking-page/staking-page.component";
import { StatisticsPageComponent } from "./pages/statistics-page/statistics-page.component";
import { RexInputComponent } from "./components/rex-input/rex-input.component";
import { RexOutputComponent } from "./components/rex-output/rex-output.component";
import { RexClaimableBoxComponent } from "./components/rex-claimable-box/rex-claimable-box.component";
import { RexClaimDoorAnimationComponent } from "./components/rex-claim-door-animation/rex-claim-door-animation.component";
import { RexPopupMessageComponent } from "./components/rex-popup-message/rex-popup-message.component";
import { RexCountdownTimerComponent } from "./components/rex-countdown-timer/rex-countdown-timer.component";
import { RexTableComponent } from "./components/rex-table/rex-table.component";
import { ContractService } from "./services/contract";
import {
  BigNumberDirective,
  BigNumberFormat,
  BigNumberMax,
  BigNumberMin,
} from "./directives/bignumber/bignumber";
import { GlobalControllerService } from "./services/global-controller.service";
import { MinMaxDirective } from "./directives/minmax/minmax";
import { ChartComponent } from "./components/rex-chart/chart.component";
import { GlobalComponent } from "./pages/statistics-page/SubPages/global/global.component";
import { AuctionsComponent } from "./pages/statistics-page/SubPages/auctions/auctions.component";
import { StakingComponent } from "./pages/statistics-page/SubPages/staking/staking.component";
import { PriceComponent } from "./pages/statistics-page/SubPages/price/price.component";
import { YourStatsComponent } from "./pages/statistics-page/SubPages/your-stats/your-stats.component";
import { HttpService } from "./services/http.service";
import { DexPageComponent } from "./pages/dex-page/dex-page.component";
import { TablePaginationComponent } from "./components/table-pagination/table-pagination.component";
import { TableSortingComponent } from "./components/table-sorting/table-sorting.component";
import { TableFilterComponent } from "./components/table-filter/table-filter.component";
import { LiquidderyPageComponent } from "./pages/liquiddery-page/liquiddery-page.component";

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

export function initializeApp(injector: Injector) {
  return () =>
    new Promise<any>((resolve: any) => {
      const contractService = injector.get(
        ContractService,
        Promise.resolve(null)
      );
      contractService
        .getStaticInfo(true)
        .then(() => {
          console.log("contractService initialized");
          resolve(null);
        })
        .catch((err) => {
          console.error("contractService init error: ", err);
        });
    });
}

@NgModule({
  entryComponents: [RexPopupMessageComponent],
  declarations: [
    AppComponent,
    SidebarMenuComponent,
    StylesheetPageComponent,
    DashboardPageComponent,
    AuctionsPageComponent,
    StakingPageComponent,
    StatisticsPageComponent,
    RexInputComponent,
    RexOutputComponent,
    RexClaimableBoxComponent,
    RexClaimDoorAnimationComponent,
    RexPopupMessageComponent,
    RexCountdownTimerComponent,
    RexTableComponent,
    BigNumberDirective,
    BigNumberFormat,
    BigNumberMax,
    BigNumberMin,
    MinMaxDirective,
    ChartComponent,
    GlobalComponent,
    AuctionsComponent,
    StakingComponent,
    PriceComponent,
    YourStatsComponent,
    DexPageComponent,
    TablePaginationComponent,
    TableSortingComponent,
    TableFilterComponent,
    LiquidderyPageComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      defaultLanguage: "en-v2",
    }),
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [Injector],
      multi: true,
    },
    TranslatePipe,
    BigNumberFormat,
    GlobalControllerService,
    HttpService,
    CookieService,
    DatePipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
