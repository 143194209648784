import { Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { TableData } from "src/app/models/data-models";
import { GlobalControllerService } from "src/app/services/global-controller.service";

@Component({
  selector: "app-stylesheet-page",
  templateUrl: "./stylesheet-page.component.html",
  styleUrls: ["./stylesheet-page.component.scss"],
})
export class StylesheetPageComponent implements OnInit {
  exampleTableData: TableData;

  exampleTableDataExtra: string[][];

  exampleTableHasExtraData: boolean = true;

  constructor(
    private translate: TranslateService,
    public glbC: GlobalControllerService
  ) {}

  ngOnInit() {
    this.initExampleTableData();
  }

  /**
   * Loads dummy example data for the rex-table-component
   */
  initExampleTableData() {
    this.translate
      .get([
        "AUCTIONS.th_day",
        "AUCTIONS.th_rex_pool",
        "AUCTIONS.th_busd_pool",
        "AUCTIONS.th_rex_busd",
        "AUCTIONS.th_participants",
        "AUCTIONS.th_my_sent_amount",
        "AUCTIONS.th_my_referred_amount",
      ])
      .toPromise()
      .then((translations) => {
        this.exampleTableData = {
          tableHeads: [
            translations["AUCTIONS.th_day"],
            translations["AUCTIONS.th_rex_pool"],
            translations["AUCTIONS.th_busd_pool"],
            translations["AUCTIONS.th_rex_busd"],
            translations["AUCTIONS.th_participants"],
            translations["AUCTIONS.th_my_sent_amount"],
            translations["AUCTIONS.th_my_referred_amount"],
          ],
          tableMatrix: [
            [
              "17.20.21 | Day 12",
              "12766060",
              "58.3",
              "218910.4",
              "56",
              "0",
              "0",
            ],
            [
              "16.20.21 | Day 11",
              "12766060",
              "58.3",
              "218910.4",
              "56",
              "0",
              "0",
            ],
            [
              "15.20.21 | Day 10",
              "12766060",
              "58.3",
              "218910.4",
              "56",
              "0",
              "0",
            ],
            [
              "14.20.21 | Day 9",
              "12766060",
              "58.3",
              "218910.4",
              "56",
              "0",
              "0",
            ],
            [
              "13.20.21 | Day 8",
              "12766060",
              "58.3",
              "218910.4",
              "56",
              "0",
              "0",
            ],
            [
              "12.20.21 | Day 7",
              "12766060",
              "58.3",
              "218910.4",
              "56",
              "0",
              "0",
            ],
          ],
        };
      });
  }

  loadExampleTableExtradata() {
    this.exampleTableDataExtra = [
      ["11.20.21 | Day 6", "12766060", "58.3", "218910.4", "56", "0", "0"],
      ["10.20.21 | Day 5", "12766060", "58.3", "218910.4", "56", "0", "0"],
      ["09.20.21 | Day 4", "12766060", "58.3", "218910.4", "56", "0", "0"],
      ["08.20.21 | Day 3", "12766060", "58.3", "218910.4", "56", "0", "0"],
      ["07.20.21 | Day 2", "12766060", "58.3", "218910.4", "56", "0", "0"],
      ["06.20.21 | Day 1", "12766060", "58.3", "218910.4", "56", "0", "0"],
    ];
    this.exampleTableData.tableMatrix =
      this.exampleTableData.tableMatrix.concat(this.exampleTableDataExtra);
    this.exampleTableHasExtraData = false;
  }

  inputChangeTest($event) {
    console.log("inputChangeTest: ", $event.target.value);
  }

  inputSelectValueChanged($event) {
    console.log(
      "inputSelectValueChanged: $event.target.value",
      $event.target.value
    );
  }

  buttonsContainerInfoClicked() {
    console.log("buttonsContainerInfoClicked");
  }

  claimBoxBPDClicked() {
    console.log("claimBoxBPDClicked");
  }

  claimBoxRefBNBClick() {
    console.log("claimBoxRefBNBClick");
  }

  claimBoxAuctionREXClick() {
    console.log("claimBoxAuctionREXClick");
  }

  toggleMessageBox() {
    this.glbC.showPopupMessage({
      title: "Popup Title",
      msg: "<p>Popup Message</p>",
      declineButtonText: "Close Button Text",
    });
    this.glbC.showPopupMessage({
      title: "Popup Title",
      msg: "<p>Popup Message</p>",
      inputFieldPlaceholder: "Input Placeholder",
      inputType: "number",
      declineButtonText: "Decline",
      acceptButtonText: "Accept",
      onAcceptCallback: (inputValue) => {
        console.log(`Popup accepted, inputValue: ${inputValue}`);
      },
    });
  }
}
