import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-rex-output",
  templateUrl: "./rex-output.component.html",
  styleUrls: ["./rex-output.component.scss"],
})
export class RexOutputComponent implements OnInit {
  @Input() textLeft: string;

  @Input() textLeft2: string;

  @Input() textLeft3: string;

  @Input() textRight: any;

  @Input() textRight2: any;

  @Input() outputType: string;

  @Input() outputVariant: string;

  @Input() currencyIconSrc: string;

  @Input() coursePerc: string;

  @Input() tooltipContent: string;

  @Input() customClasses: string[] = [];

  downwardTrend: boolean = false;

  courseArrowSrc: string;

  constructor() {}

  ngOnInit() {}

  addClass(newClass: string) {
    let alreadyIn = false;
    this.customClasses.forEach((cClass) => {
      if (cClass === newClass) {
        alreadyIn = true;
      }
    });
    if (!alreadyIn) {
      this.customClasses.push(newClass);
    }
  }

  ngOnChanges() {
    if (this.outputVariant === "isPercentage" && this.textRight) {
      if (parseFloat(this.textRight.slice(0, -1)) >= 0) {
        this.addClass("isPercentageGreen");
      } else {
        this.addClass("isPercentageRed");
      }
    }

    // determine customclasses
    if (this.outputType === "currentCourse") {
      this.addClass("rex-output-current-course");
      if (this.outputVariant === "bigChart") {
        this.addClass("rex-output-variant-big-chart");
      }
      /*       if (this.coursePerc) {
        const tmpCoursePerc = Number(
          this.coursePerc.toString().substring(0, this.coursePerc.length - 1)
        );
        if (tmpCoursePerc < 0) {
          this.downwardTrend = true;
          this.courseArrowSrc = "assets/img/icons/chart-down-arrow.svg";
        } else {
          this.courseArrowSrc = "assets/img/icons/chart-up-arrow.svg";
        }
      } */
    } else if (this.outputType === "stackingTexts") {
      this.addClass("rex-output-stacking-texts");
    } else {
      if (this.textLeft && this.textLeft2) {
        this.addClass("rex-output-left-small");
      }
      if (this.textRight && this.textRight2) {
        this.addClass("rex-output-data-2");
      }
    }
  }
}
