/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
// eslint-disable-next-line import/no-extraneous-dependencies
import { Contract } from "web3-eth-contract";
import { Observable } from "rxjs";
import { Injectable, NgZone } from "@angular/core";
import BigNumber from "bignumber.js";
import { HttpClient } from "@angular/common/http";
import { DepositInterface, DexInterface } from "src/app/models/data-models";
import { environment } from "src/environments/environment";
import { TranslateService } from "@ngx-translate/core";
import { MetamaskService } from "../web3/indexWeb3";
import { AppConfig } from "../app-config.service";
import { GlobalControllerService } from "../global-controller.service";

export const LAST_CLAIM_DAY = 250;
export const LAST_CONTRACT_DAY = 259;
export const TREASURY_CLAIM_DAY = 252;

@Injectable({
  providedIn: "root",
})
export class ContractService {
  public web3Service;

  private REXContract: Contract;

  private DEXContract: Contract;

  private AIRDROPContract: Contract;

  private TREXContract: Contract;

  private MREXContract: Contract;

  private RDAContract: Contract;

  private BUSDContract: Contract;

  private REXPAIRContract: Contract;
  4;
  private DEVFUNDContract: Contract;

  private BUSDPAIRContract: Contract;

  private ROUTERContract: Contract;

  private COMPContract: Contract;

  private LOTTERIEContract: Contract;

  private contractSettingsFolder: string = "classic";

  private isActive: boolean;

  private tokensDecimals: any = {
    ETH: 18,
    REX: 18,
    TREX: 0,
    MREX: 0,
    BUSD: 18,
    PAIR: 0,
  };

  public account;

  private allAccountSubscribers = [];

  private allTransactionSubscribers = [];

  public glbC: GlobalControllerService;

  public settingsApp = {
    settings: {
      checkerDays: 5000,
      checkerAuctionPool: 5000,
      checkerStakingInfo: 3600000,
      checkerBPD: 3600000,

      production: true,
      network: "SmartChain",
      chainsForButtonAddToMetamask: [56, 97],
      net: 97,

      time: {
        seconds: 3600,
        display: "days",
      },
    },
    minutes: {
      name: "Minutes",
      shortName: "Min",
      lowerName: "minutes",
    },
    days: {
      name: "Days",
      shortName: "Days",
      lowerName: "days",
    },
  };

  private secondsInDay: any;

  private CONTRACTS_PARAMS: any;

  constructor(
    private httpService: HttpClient,
    private config: AppConfig,
    private ngZone: NgZone,
    private translate: TranslateService
  ) {
    // Disables all console.logs
    if (window) {
      window.console.log = function () {};
      window.console.warn = function () {};
    }
  }

  public setGlbc(glbcRef) {
    this.glbC = glbcRef;
    this.web3Service.setGlbC(glbcRef);
  }

  public getMSecondsInDay() {
    return this.secondsInDay * 1000;
  }

  private initAll() {
    const promises = [
      this.httpService
        .get(`/assets/js/settings.json?v=${new Date().getTime()}`)
        .toPromise()
        .then((config) => {
          if (config) {
            this.settingsApp = config as any;
            this.config.setConfig(config);
            console.log(`Chain ID: ${this.settingsApp.settings.net}`);
          } else {
            this.config.setConfig(this.settingsApp);
          }
        })
        .catch((err) => {
          console.log("err settings", err);
          this.config.setConfig(this.settingsApp);
        }),
      this.httpService
        .get(`/assets/js/constants.json?v=${new Date().getTime()}`)
        .toPromise()
        .then((result) => {
          return result;
        })
        .catch((err) => {
          console.log("err constants", err);
        }),
    ];

    return Promise.all(promises).then((result) => {
      const IS_PRODUCTION = this.settingsApp.settings.production;
      this.CONTRACTS_PARAMS =
        result[1][
          IS_PRODUCTION ? "mainnet" : this.settingsApp.settings.network
        ];
      console.log(this.CONTRACTS_PARAMS);
      this.isActive = true;
      if (this.account) {
        this.callAllAccountsSubscribers();
      }
    });
  }

  public addTokenToMetamask(token: string) {
    console.log("add token", token);
    const tokenData = {
      address: "",
      decimals: 0,
      image: "",
      symbol: "",
    };
    if (token === "REX") {
      tokenData.address = this.CONTRACTS_PARAMS.REX.ADDRESS;
      tokenData.decimals = this.tokensDecimals.REX;
      tokenData.image = `${environment.appDomain}/assets/img/icons/token-icon-rex.jpg`;
      tokenData.symbol = "REX";
    } else if (token === "MREX") {
      tokenData.address = this.CONTRACTS_PARAMS.MREX.ADDRESS;
      tokenData.decimals = this.tokensDecimals.MREX;
      tokenData.image = `${environment.appDomain}/assets/img/icons/token-icon-mrex.jpg`;
      tokenData.symbol = "MREX";
    } else if (token === "TREX") {
      tokenData.address = this.CONTRACTS_PARAMS.TREX.ADDRESS;
      tokenData.decimals = this.tokensDecimals.TREX;
      tokenData.image = `${environment.appDomain}/assets/img/icons/token-icon-trex.jpg`;
      tokenData.symbol = "TREX";
    } else if (token === "BUSD") {
      tokenData.address = this.CONTRACTS_PARAMS.BUSD.ADDRESS;
      tokenData.decimals = this.tokensDecimals.BUSD;
      tokenData.image = `${environment.appDomain}/assets/img/icons/token-icon-busd.jpg`;
      tokenData.symbol = "BUSD";
      console.log(tokenData.image);
    } else if (token === "REX/BUSD PAIR") {
      tokenData.address = this.CONTRACTS_PARAMS.REXPAIR.ADDRESS;
      tokenData.decimals = this.tokensDecimals.PAIR;
      tokenData.image = "";
      tokenData.symbol = "REX PAIR";
    } else {
      return;
    }
    this.web3Service.addToken(tokenData);
  }

  public addToken() {
    this.web3Service.addToken({
      address: this.CONTRACTS_PARAMS.REX.ADDRESS,
      decimals: this.tokensDecimals.REX,
      image: "https://classic.rex-token.com/assets/images/REX_logo_dark.jpeg",
      symbol: "REX",
    });
  }

  public addTokenT() {
    this.web3Service.addToken({
      address: this.CONTRACTS_PARAMS.TREX.ADDRESS,
      decimals: 0,
      image: "https://classic.rex-token.com/assets/images/trex2.png",
      symbol: "TREX",
    });
  }

  public addTokenM() {
    this.web3Service.addToken({
      address: this.CONTRACTS_PARAMS.MREX.ADDRESS,
      decimals: 0,
      image: "https://classic.rex-token.com/assets/images/mrex2.png",
      symbol: "MREX",
    });
  }

  private getTokensInfo(noEnable?) {
    if (!noEnable) {
      this.initializeContracts();
    }
    const promises = [
      (this.tokensDecimals.REX = 18),
      (this.tokensDecimals.HEX = 18),
      (this.tokensDecimals.BUSD = 18),
    ];
    return Promise.all(promises);
  }

  // public getStaticInfo() {
  //   return this.initAll().then(() => {
  //     this.web3Service = new MetamaskService(this.config);
  //     this.web3Service.getAccounts().subscribe((account: any) => {
  //       if (account) {
  //         this.initializeContracts();
  //         const promises = [this.getTokensInfo(false)];
  //         return Promise.all(promises);
  //       }
  //       return null;
  //     });
  //   });
  // }

  public endWeb3Connection() {
    this.web3Service.endWeb3Connection();
  }

  public getStaticInfo(initial?: boolean) {
    console.log("getStaticInfo called initial: ", initial);
    return new Promise((resolve: any, reject: any) => {
      this.loadContractSettingsAndConstants().then(() => {
        this.web3Service = new MetamaskService(this.config);
        console.log("this.config: ", this.config);
        console.log("this.web3Service: ", this.web3Service);

        if (initial) {
          resolve();
        } else {
          console.log("before loadCurrentAccount");
          // TODO never gets executed
          this.loadCurrentAccount()
            .then((account: any) => {
              console.log("account: ", account);
              const promises = [];
              if (account) {
                console.log("call initializeContracts from getStaticInfo");
                this.initializeContracts();
                promises.concat(this.getTokensInfo(true));
              }
              Promise.all(promises).then(() => {
                console.log("getStaticInfo resolved");
                resolve();
              });
            })
            .catch((err) => {
              console.error("getAccountsOnce error: ", err);
              if (err && err.msg === "Not authorized") {
                console.log(
                  "getStaticInfo -> getAccounts wrong Chain error:",
                  err
                );
                if (this.glbC) {
                  this.ngZone.run(() => {
                    console.log("set isonWrongNetwork: true");
                    this.glbC.isOnWrongNetwork = true;
                  });
                }
                resolve();
              } else {
                console.error("getStaticInfo -> getAccounts error: ", err);
                reject(err);
              }
            });
        }
      });
    });
  }

  public loadCurrentAccount() {
    console.log("begin function loadCurrentAccount");
    console.log(this.glbC.account.address);
    return new Promise((resolve: any, reject: any) => {
      if (this.glbC.account.address === undefined) {
        this.web3Service.initWeb3Connection(this.glbC.walletType).then(() => {
          if (this.glbC.walletType === "metamask") {
            // Check if there is a metamask extension
            if (this.web3Service.metaMaskWeb3) {
              console.log("There is metamask extension");
              this.web3Service
                .getAccountsOnce()
                .then((response: any) => {
                  console.log(response);
                  console.log(this.glbC.showConnectBox);
                  // If Metamask is on wrong chain show message
                  if (this.glbC.isOnWrongNetwork && this.glbC.showConnectBox) {
                    console.log(
                      "is on wrong network: ",
                      this.glbC.isOnWrongNetwork
                    );

                    const popupMessage = `<p ">${this.translate.instant(
                      "POPUPS.metamask_wrong_chain",
                      {
                        currentChain: this.glbC.chainNetworkString,
                      }
                    )}</p>`;
                    this.glbC.showPopupMessage({
                      title: this.translate.instant(
                        "POPUPS.metamask_error_title"
                      ),
                      msg: popupMessage,
                      declineButtonText: this.translate.instant("BUTTONS.ok"),
                      removeOtherPopups: true,
                    });
                    this.glbC.globalLoading = false;
                    this.glbC.showConnectBox = false;
                  } else if (
                    // If Login cookie is set, but metamsk is not connected/on wrong network, don't connect
                    (this.glbC.isOnWrongNetwork && !this.glbC.showConnectBox) ||
                    (response.waitingForAuthorization &&
                      !this.glbC.showConnectBox)
                  ) {
                    this.glbC.globalLoading = false;
                    this.glbC.showConnectBox = false;
                  } else if (
                    response.waitingForAuthorization &&
                    this.glbC.showConnectBox
                  ) {
                    this.glbC.waitingForAuthorization = true;
                    console.warn(
                      "waiting for authorization response",
                      response
                    );
                    // waiting for response
                    response.promise
                      .then((ethAccounts) => {
                        console.warn("recieved ethAccounts: ", ethAccounts);
                        this.account =
                          this.web3Service.setProviderAndReturnAccount();
                        this.glbC.waitingForAuthorization = false;
                        if (this.account) {
                          resolve(this.account);
                        } else {
                          resolve(null);
                        }
                      })
                      .catch((err) => {
                        console.error("request_accounts promise error: ", err);
                        reject(err);
                      });
                  } else {
                    console.log("Logged in in MM and MM cookie set");
                    this.account = response;
                    this.glbC.waitingForAuthorization = false;
                    this.glbC.globalLoading = false;
                    this.glbC.showConnectBox = false;
                    resolve(this.account);
                  }
                })
                .catch((err) => {
                  console.error("getAccountsOnce error: ", err);
                  reject(err);
                });
            } else {
              console.log("There is no metamask extension");
              this.glbC.showPopupMessage({
                title: this.translate.instant("POPUPS.metamask_error_title"),
                msg: `<p>${this.translate.instant(
                  "POPUPS.metamask_error_template"
                )}</p>`,
                declineButtonText: this.translate.instant("BUTTONS.ok"),
                removeOtherPopups: true,
              });
              this.glbC.globalLoading = false;
              this.glbC.showConnectBox = false;
            }
          } else if (
            this.glbC.walletType === "walletConnect" &&
            !this.web3Service.userClosedWalletConnect
          ) {
            this.account = this.glbC.account;
            this.web3Service
              .getAccountOnceWalletConnect()
              .then(() => {
                console.log("walletType:", this.glbC.walletType);
                console.log("succes get accountonce walletConnect");
                this.glbC.globalLoading = false;
                this.glbC.showConnectBox = false;
                if (this.account) {
                  resolve(this.account);
                } else {
                  resolve(null);
                }
              })
              .catch((error) => {
                console.log("error in load current account", error);
                resolve();
              });
          } else if (this.web3Service.userClosedWalletConnect) {
            console.log("x was clicked");
            this.glbC.globalLoading = false;
            this.glbC.showConnectBox = false;
          } else {
            console.log("unknown case");
          }
        });
      } else {
        this.glbC.waitingForAuthorization = false;
        this.glbC.globalLoading = false;
        this.glbC.showConnectBox = false;
        resolve(this.account);
      }
    });
  }

  /**
   * Loading contractSettings and configurations
   * @returns
   */
  private loadContractSettingsAndConstants() {
    console.log("loadContractSettingsAndConstants called");
    return new Promise((resolve: any, reject: any) => {
      const promises = [];
      let contractConstants: any = null;
      promises.push(
        new Promise((res: any) => {
          this.httpService
            .get(
              `/assets/contractSettings/${
                this.contractSettingsFolder
              }/settings.json?v=${new Date().getTime()}`
            )
            .toPromise()
            .then((config) => {
              if (config) {
                this.settingsApp = config as any;
                this.config.setConfig(config);
                console.log(`Chain ID: ${this.settingsApp.settings.net}`);
              } else {
                this.config.setConfig(this.settingsApp);
              }
              res();
            })
            .catch((err) => {
              console.log("err settings", err);
              this.config.setConfig(this.settingsApp);
              res();
            });
        })
      );
      promises.push(
        new Promise((res: any) => {
          this.httpService
            .get(
              `/assets/contractSettings/${
                this.contractSettingsFolder
              }/constants.json?v=${new Date().getTime()}`
            )
            .toPromise()
            .then((result) => {
              contractConstants = result;
              res();
            })
            .catch((err) => {
              console.error("err constants", err);
              res();
            });
        })
      );
      Promise.all(promises).then(() => {
        if (!contractConstants) {
          reject(new Error("missing_contractConstants"));
        } else {
          const IS_PRODUCTION = this.settingsApp.settings.production;
          console.log("IS_PRODUCTION: ", IS_PRODUCTION);
          console.log("contractConstants: ", contractConstants);
          console.log("this.settingsApp: ", this.settingsApp);
          console.log(
            "this.settingsApp.settings.network: ",
            this.settingsApp.settings.network
          );
          // Why differentiate between mainnet/settings, when the settings could be set for production?
          this.CONTRACTS_PARAMS =
            contractConstants[
              IS_PRODUCTION ? "mainnet" : this.settingsApp.settings.network
            ];
          console.log("this.CONTRACTS_PARAMS: ", this.CONTRACTS_PARAMS);
          this.isActive = true;
          if (this.account) {
            this.callAllAccountsSubscribers();
          }
          resolve();
        }
      });
    });
  }

  private callAllAccountsSubscribers() {
    if (this.isActive) {
      this.allAccountSubscribers.forEach((observer) => {
        observer.next(this.account);
      });
    }
  }

  private callAllTransactionsSubscribers(transaction) {
    this.allTransactionSubscribers.forEach((observer) => {
      observer.next(transaction);
    });
  }

  public accountSubscribe() {
    const newObserver = new Observable((observer) => {
      observer.next(this.account);
      this.allAccountSubscribers.push(observer);
      return {
        unsubscribe: () => {
          this.allAccountSubscribers = this.allAccountSubscribers.filter(
            (a) => a !== newObserver
          );
        },
      };
    });
    return newObserver;
  }

  public transactionsSubscribe() {
    const newObserver = new Observable((observer) => {
      this.allTransactionSubscribers.push(observer);
    });
    return newObserver;
  }

  // public getAccount(noEnable?) {
  //   const finishIniAccount = () => {
  //     // if (!noEnable) {
  //     // }
  //     if (this.account) {
  //       this.initializeContracts();
  //     } else {
  //       this.callAllAccountsSubscribers();
  //     }
  //   };
  //   return new Promise((resolve: any, reject: any) => {
  //     this.web3Service.getAccounts(noEnable).subscribe(
  //       (account) => {
  //         if (!this.account || account.address !== this.account.address) {
  //           this.account = account;
  //           finishIniAccount();
  //         }
  //         resolve(this.account);
  //       },
  //       (err) => {
  //         this.account = false;
  //         finishIniAccount();
  //         reject(err);
  //       }
  //     );
  //   });
  // }

  /**
   * @param noEnable
   * @returns
   */
  public getAccount(noEnable?) {
    console.log("get account in index");
    const finishIniAccount = () => {
      // outcommented unused if block
      // if (!noEnable) {
      // }
      if (this.account) {
        this.initializeContracts();
      } else {
        this.callAllAccountsSubscribers();
      }
    };
    return new Promise((resolve: any, reject: any) => {
      // this.web3Service.getAccounts(noEnable).subscribe(
      //   (account) => {
      //     if (!this.account || account.address !== this.account.address) {
      //       this.account = account;
      //       finishIniAccount();
      //       this.glbC.initSubject.next(true);
      //     }
      //     resolve(this.account);
      //   },
      //   (err) => {
      //     this.account = false;
      //     if (err.code === 2) {
      //       this.ngZone.run(() => {
      //         this.glbC.isOnWrongNetwork = true;
      //         finishIniAccount();
      //         resolve();
      //       });
      //     } else {
      //       finishIniAccount();
      //       reject(err);
      //     }
      //   }
      // );
      console.log("before getacc");
      this.web3Service.initWeb3Connection("metamask").then(() => {
        console.log("metamask init complete");
        this.web3Service
          .getAccountsOnce(noEnable)
          .then((account) => {
            if (!this.account || account.address !== this.account.address) {
              this.account = account;
              finishIniAccount();
              console.log("finishIniAccount done, initSubject.next");
              this.glbC.initSubject.next({ doInit: true });
            }
            resolve(this.account);
          })
          .catch((err) => {
            this.account = false;
            console.warn("getAccountsOnce error: ", err);
            if (err && err.msg === "Not authorized") {
              this.ngZone.run(() => {
                // this.glbC.isOnWrongNetwork = true;
                // this.glbC.showPopupMessage({
                //   title: this.translate.instant("POPUPS.not_authorized"),
                //   msg: `<p>${this.translate.instant(
                //     "POPUPS.please_login_to_metamask"
                //   )}</p>`,
                //   declineButtonText: this.translate.instant("BUTTONS.ok"),
                // });
                finishIniAccount();
                resolve();
              });
            } else {
              finishIniAccount();
              reject(err);
            }
          });
      });
    });
  }

  public unstake(stakeID) {
    console.log("Close stake with stakeID", stakeID);
    return this.REXContract.methods
      .endStake(stakeID)
      .send({
        from: this.account.address,
      })
      .then((res) => {
        console.log("unstake success");
        this.glbC.showBSCscanPopup(res.transactionHash);
        return this.checkTransaction(res);
      })
      .catch((err) => {
        console.log("Error in close stake", err);
        return err;
      });
  }

  public movestake(stakeID, addr) {
    if (addr === "" || addr === undefined) {
      return null;
    }
    return this.REXContract.methods
      .transferStake(stakeID, addr)
      .send({
        from: this.account.address,
      })
      .then((res) => {
        this.glbC.showBSCscanPopup(res.transactionHash);
        return this.checkTransaction(res);
      });
  }

  public splitstake(stakeID, amount) {
    const amountHalf = new BigNumber(amount / 2);
    return this.REXContract.methods
      .splitStake(stakeID, amountHalf)
      .send({
        from: this.account.address,
      })
      .then((res) => {
        this.glbC.showBSCscanPopup(res.transactionHash);
        return this.checkTransaction(res);
      });
  }

  public renameStake(stakeID, name) {
    if (name === "" || name === undefined) {
      return null;
    }
    return this.REXContract.methods
      .renameStake(stakeID, name)
      .send({
        from: this.account.address,
      })
      .then((res) => {
        this.glbC.showBSCscanPopup(res.transactionHash);
        return this.checkTransaction(res);
      });
  }

  public scrapeRewards(stakeID, days) {
    return this.REXContract.methods
      .withdrawRewards(stakeID, days)
      .send({
        from: this.account.address,
      })
      .then((res) => {
        this.glbC.showBSCscanPopup(res.transactionHash);
        return this.checkTransaction(res);
      });
  }

  public offerStake(stakeID, price, days) {
    const amount = new BigNumber(price).times(new BigNumber(10).pow(18));
    return this.REXContract.methods
      .offerStake(stakeID, amount, days)
      .send({
        from: this.account.address,
      })
      .then((res) => {
        this.glbC.showBSCscanPopup(res.transactionHash);
        return this.checkTransaction(res);
      });
  }

  public revokeOffer(stakeID) {
    return this.DEXContract.methods
      .revokeOffer(stakeID)
      .send({
        from: this.account.address,
      })
      .then((res) => {
        this.glbC.showBSCscanPopup(res.transactionHash);
        return this.checkTransaction(res);
      });
  }

  public buyStake(amount, stakeID) {
    const depositTokens = (resolve: any, reject: any) => {
      return this.DEXContract.methods
        .buyStakeFromList(stakeID)
        .send({
          from: this.account.address,
        })
        .then((res) => {
          this.glbC.showBSCscanPopup(res.transactionHash);
          return this.checkTransaction(res);
        })
        .then(resolve, reject);
    };

    return new Promise((resolve: any, reject: any) => {
      this.checkBUSDApproval(amount, this.DEXContract.options.address).then(
        () => {
          depositTokens(resolve, reject);
        },
        () => {
          this.BUSDContract.methods
            .approve(this.DEXContract.options.address, amount)
            .send({
              from: this.account.address,
            })
            .then(() => {
              depositTokens(resolve, reject);
            }, reject);
        }
      );
    });
  }

  public getRexTotalSupply() {
    return this.REXContract.methods
      .totalSupply()
      .call({ from: this.account.address });
  }

  public getRexAllocatedSupply() {
    return this.REXContract.methods
      .allocatedSupply()
      .call({ from: this.account.address });
  }

  public getRexTotalClaimed() {
    return this.REXContract.methods
      .getTotalClaimed()
      .call({ from: this.account.address });
  }

  public getBusdInTrex() {
    return this.BUSDContract.methods
      .balanceOf(this.TREXContract.options.address)
      .call({ from: this.account.address });
  }

  public getBusdAmount() {
    return this.BUSDContract.methods
      .balanceOf(this.account.address)
      .call({ from: this.account.address });
  }

  public getBusdAmount2(address: string) {
    return this.BUSDContract.methods.balanceOf(address).call({ from: address });
  }

  public getLPAmount() {
    return this.REXPAIRContract.methods
      .balanceOf(this.account.address)
      .call({ from: this.account.address });
  }

  public getRexAmount() {
    return this.REXContract.methods
      .balanceOf(this.account.address)
      .call({ from: this.account.address });
  }

  public getRexAmountOfAddr(addr) {
    return this.REXContract.methods.balanceOf(addr).call({ addr });
  }

  public getCurrentRexDay() {
    console.log("this.account.address: ", this.account.address);
    return this.REXContract.methods
      .currentRxDay()
      .call({ from: this.account.address });
  }

  public getETHPOOL() {
    return this.RDAContract.methods
      .BUSDPOOL()
      .call({ from: this.account.address });
  }

  public getETHTREASURY() {
    return this.RDAContract.methods
      .BUSDTREASURY()
      .call({ from: this.account.address });
  }

  public getUniqueDonatorCount() {
    return this.RDAContract.methods
      .uniqueDonatorCount()
      .call({ from: this.account.address });
  }

  public getLastCheckedSupplyDay() {
    return this.RDAContract.methods
      .lastCheckedSupplyDay()
      .call({ from: this.account.address });
  }

  public getIsBPDeligibleAddr() {
    return this.RDAContract.methods
      .isBPDeligibleAddr(this.account.address)
      .call({ from: this.account.address });
  }

  public getIsBPDeligibleAddrAll() {
    return this.RDAContract.methods
      .getBPDCount()
      .call({ from: this.account.address });
  }

  public getAddressHitByRandomWho() {
    return this.RDAContract.methods
      .addressHitByRandom(this.account.address)
      .call({ from: this.account.address });
  }

  public getDonatorsToday(day) {
    return this.RDAContract.methods
      .donatorAccountCount(day)
      .call({ from: this.account.address });
  }

  public getTotalDonBalance() {
    return this.RDAContract.methods
      .donatorTotalBalance(this.account.address)
      .call({ from: this.account.address });
  }

  public getTotalRefBalance() {
    return this.RDAContract.methods
      .referrerTotalBalance(this.account.address)
      .call({ from: this.account.address });
  }

  public getMyDonationOnDay(day) {
    return this.RDAContract.methods
      .donatorBalances(this.account.address, day)
      .call({ from: this.account.address });
  }

  public getAddressesDonationOnDay(day, addr) {
    return this.RDAContract.methods
      .donatorBalances(addr, day)
      .call({ from: addr });
  }

  public getTotalSentBUSD() {
    return this.RDAContract.methods
      .originalDonation(this.account.address)
      .call({ from: this.account.address });
  }

  public getGlobals() {
    return this.RDAContract.methods.g().call({ from: this.account.address });
  }

  public getRexGlobals() {
    return this.REXContract.methods
      .globals()
      .call({ from: this.account.address });
  }

  public getUnhit() {
    return this.RDAContract.methods
      .getActualUnhitByRandom()
      .call({ from: this.account.address });
  }

  public getMyTotalStakedREX() {
    return this.REXContract.methods
      .totalREXinActiveStakes(this.account.address)
      .call({ from: this.account.address });
  }

  public isUltraRex() {
    return this.RDAContract.methods
      .ultraRexican(this.account.address)
      .call({ from: this.account.address });
  }

  public isAddressBPDExcluded() {
    return this.RDAContract.methods
      .addressBPDExcluded(this.account.address)
      .call({ from: this.account.address });
  }

  public getMyReferralOnDay(day) {
    return this.RDAContract.methods
      .referrerBalances(this.account.address, day)
      .call({ from: this.account.address });
  }

  public _getTodaysSupply(day) {
    return this.RDAContract.methods
      .auctionSupplyOnDay(day)
      .call({ from: this.account.address });
  }

  public getDailyTotalDonation(day) {
    return this.RDAContract.methods
      .dailyTotalDonation(day)
      .call({ from: this.account.address });
  }

  public getDailyTotalReferral(day) {
    return this.RDAContract.methods
      .dailyTotalReferral(day)
      .call({ from: this.account.address });
  }

  public sumOfDonationsOfUnHit() {
    return this.RDAContract.methods
      .sumOfDonationsOfUnHit()
      .call({ from: this.account.address });
  }

  public getStakeCount() {
    return this.REXContract.methods
      .stakeCount(this.account.address)
      .call({ from: this.account.address });
  }

  public getTrex() {
    return this.TREXContract.methods
      .balanceOf(this.account.address)
      .call({ from: this.account.address });
  }

  public getSoldTrex() {
    return this.TREXContract.methods
      .soldTREX()
      .call({ from: this.account.address });
  }

  public getTrexPrice() {
    return this.TREXContract.methods
      .price()
      .call({ from: this.account.address });
  }

  public getMrex() {
    return this.MREXContract.methods
      .balanceOf(this.account.address)
      .call({ from: this.account.address });
  }

  public getSoldMrex() {
    return this.MREXContract.methods
      ._getSoldMREX()
      .call({ from: this.account.address });
  }

  public getCanClaimTrex() {
    return this.TREXContract.methods
      .canClaimAirdropNow(this.account.address)
      .call({ from: this.account.address });
  }

  public getTrexClaimedAlready() {
    return this.TREXContract.methods
      ._airdropClaimed(this.account.address)
      .call({ from: this.account.address });
  }

  public getAirdropBalancesLeft() {
    return this.TREXContract.methods
      ._airdropBalancesLeft(this.account.address)
      .call({ from: this.account.address });
  }

  public getAirdropBalances() {
    return this.TREXContract.methods
      ._airdropBalances(this.account.address)
      .call({ from: this.account.address });
  }

  public getNoOfTREXSellable() {
    return this.TREXContract.methods
      .noOfTREXSellable(this.account.address)
      .call({ from: this.account.address });
  }

  public getPoolWasntEmpty() {
    return this.RDAContract.methods
      .poolWasntEmpty()
      .call({ from: this.account.address });
  }

  public createUserBPD() {
    return this.RDAContract.methods
      ._createUserBPD()
      .send({
        from: this.account.address,
      })
      .then((res) => {
        this.glbC.showBSCscanPopup(res.transactionHash);
        return this.checkTransaction(res);
      });
  }

  public claimTrex() {
    return this.TREXContract.methods
      .claimAirdrop()
      .send({
        from: this.account.address,
      })
      .then((res) => {
        this.glbC.showBSCscanPopup(res.transactionHash);
        return this.checkTransaction(res);
      });
  }

  public sellAirTrex() {
    return this.TREXContract.methods
      .sellAirdroppedTREX()
      .send({
        from: this.account.address,
      })
      .then((res) => {
        this.glbC.showBSCscanPopup(res.transactionHash);
        return this.checkTransaction(res);
      });
  }

  public getRexOut(amount) {
    console.log(`amount: ${amount}`);
    const addr1 = this.BUSDContract.options.address;
    const addr2 = this.REXContract.options.address;
    const path = [addr1, addr2];
    return this.ROUTERContract.methods
      .getAmountsOut(amount, path)
      .call({ from: this.account.address });
  }

  public getBusdOut(amount) {
    const amountIn = new BigNumber(amount).times(1e18);
    const addr2 = this.BUSDContract.options.address;
    const addr1 = this.REXContract.options.address;
    const path = [addr1, addr2];
    return this.ROUTERContract.methods
      .getAmountsOut(amountIn.toString(), path)
      .call({ from: this.account.address });
  }

  public swapForRex(amount) {
    console.log(amount);
    const amountIn = new BigNumber(amount).times(1e18);
    console.log(amountIn);
    const amountOutMin = new BigNumber(0);
    const addr1 = this.BUSDContract.options.address;
    const addr2 = this.REXContract.options.address;
    const path = [addr1, addr2];
    const toAddress = this.account.address;
    const deadline = Math.round(Date.now() / 1000) + 60 * 20;
    const depositTokens = (resolve: any, reject: any) => {
      return this.ROUTERContract.methods
        .swapExactTokensForTokens(
          amountIn.toString(),
          amountOutMin.toString(),
          path,
          toAddress,
          deadline
        )
        .send({
          from: this.account.address,
        })
        .then((res) => {
          console.log("function in first part works");
          this.glbC.showBSCscanPopup(res.transactionHash);
          return this.checkTransaction(res);
        })
        .catch((error) => {
          console.log("error 1028", error);
          return false;
        })
        .then(resolve, reject);
    };

    return new Promise((resolve: any, reject: any) => {
      console.log("In low return");
      console.log(amountIn);
      this.checkBUSDApproval(amountIn, this.ROUTERContract.options.address)
        .then(
          () => {
            console.log("checkBUSDApproval success");
            depositTokens(resolve, reject);
          },
          () => {
            this.BUSDContract.methods
              .approve(this.ROUTERContract.options.address, amountIn)
              .send({
                from: this.account.address,
              })
              .then(() => {
                console.log("BUSDContract success");
                depositTokens(resolve, reject);
              }, reject);
          }
        )
        .catch((error) => {
          console.log("here is the error");
          console.log(error);
          return error;
        });
    });
  }

  /*   public swapForRex(amount) {
    return new Promise((resolve, reject) => {
      console.log(amount);
      resolve("yes");
    });
  } */

  public swapForBusd(amount) {
    console.log(amount);
    const amountIn = new BigNumber(amount).times(1e18);
    const amountOutMin = new BigNumber(0);
    const addr2 = this.BUSDContract.options.address;
    const addr1 = this.REXContract.options.address;
    const path = [addr1, addr2];
    const toAddress = this.account.address;
    const deadline = Math.round(Date.now() / 1000) + 60 * 20;
    const depositTokens = (resolve: any, reject: any) => {
      console.log("in deposit tokens");
      return this.ROUTERContract.methods
        .swapExactTokensForTokens(
          amountIn.toString(),
          amountOutMin.toString(),
          path,
          toAddress,
          deadline
        )
        .send({
          from: this.account.address,
        })
        .then((res) => {
          this.glbC.showBSCscanPopup(res.transactionHash);
          return this.checkTransaction(res);
        })
        .catch((err) => {
          console.error("Error in swap: ", err);
        })
        .then(resolve, reject);
    };

    return new Promise((resolve: any, reject: any) => {
      this.checkREXApproval(amountIn, this.ROUTERContract.options.address)
        .then(
          /*           () => {
            console.log("checkREXApproval for swap success");
            depositTokens(resolve, reject);
          }, */
          () => {
            console.log("in second part");
            this.REXContract.methods
              .approve(this.ROUTERContract.options.address, amountIn)
              .send({
                from: this.account.address,
              })
              .then(() => {
                depositTokens(resolve, reject);
              }, reject)
              .catch((err) => {
                console.error("Error in approve: ", err);
              });
          }
        )
        .catch((error) => {
          console.error("Error in checkREXApproval", error);
        });
    });
  }

  public swapForBusdTest(amount) {
    console.log(amount);
    const amountIn = new BigNumber(amount).times(1e18);
    const amountOutMin = new BigNumber(0);
    const addr2 = this.BUSDContract.options.address;
    const addr1 = this.REXContract.options.address;
    const path = [addr1, addr2];
    const toAddress = this.account.address;
    const deadline = Math.round(Date.now() / 1000) + 60 * 20;
    const depositTokens = (resolve: any, reject: any) => {
      console.log("in deposit tokens");
      return this.ROUTERContract.methods
        .swapExactTokensForTokens(
          amountIn.toString(),
          amountOutMin.toString(),
          path,
          toAddress,
          deadline
        )
        .send({
          from: this.account.address,
        })
        .then((res) => {
          this.glbC.showBSCscanPopup(res.transactionHash);
          return this.checkTransaction(res);
        })
        .catch((err) => {
          console.error("Error in swap: ", err);
        })
        .then(resolve, reject);
    };

    return new Promise((resolve: any, reject: any) => {
      this.REXContract.methods
        .approve(this.ROUTERContract.options.address, amountIn)
        .send({
          from: this.account.address,
        })
        .then(() => {
          console.log("success REXContract approve");
          this.checkREXApproval(amountIn, this.ROUTERContract.options.address)
            .then(() => {
              console.log("success");
              depositTokens(resolve, reject);
            })
            .catch((error) => {
              console.error("Error in checkREXApproval", error);
            });
        }, reject)
        .catch((err) => {
          console.error("Error in approve: ", err);
        });
      /*
      this.checkREXApproval(amountIn, this.ROUTERContract.options.address)
        .then(
          /*           () => {
            console.log("checkREXApproval for swap success");
            depositTokens(resolve, reject);
          },
          () => {
            console.log("in second part");
            this.REXContract.methods
              .approve(this.ROUTERContract.options.address, amountIn)
              .send({
                from: this.account.address,
              })
              .then(() => {
                depositTokens(resolve, reject);
              }, reject)
              .catch((err) => {
                console.error("Error in approve: ", err);
              });
          }
        )
        .catch((error) => {
          console.error("Error in checkREXApproval", error);
        }); */
    });
  }

  public buyTrex(amount) {
    const amount10 = new BigNumber(amount);
    const depositTokens = (resolve: any, reject: any) => {
      return this.TREXContract.methods
        .buyOneTREX()
        .send({
          from: this.account.address,
        })
        .then((res) => {
          this.glbC.showBSCscanPopup(res.transactionHash);
          return this.checkTransaction(res);
        })
        .then(resolve, reject);
    };

    return new Promise((resolve: any, reject: any) => {
      this.checkBUSDApproval(amount, this.TREXContract.options.address).then(
        () => {
          depositTokens(resolve, reject);
        },
        () => {
          this.BUSDContract.methods
            .approve(this.TREXContract.options.address, amount10)
            .send({
              from: this.account.address,
            })
            .then(() => {
              depositTokens(resolve, reject);
            }, reject);
        }
      );
    });
  }

  public ___buyTrex(amount) {
    const price = amount * 10 ** 18;
    return this.TREXContract.methods
      .buyOneTREX(price)
      .send({
        from: this.account.address,
      })
      .then((res) => {
        this.glbC.showBSCscanPopup(res.transactionHash);
        return this.checkTransaction(res);
      });
  }

  public buyMrex() {
    return this.MREXContract.methods
      .mintMeMrex()
      .send({
        from: this.account.address,
      })
      .then((res) => {
        this.glbC.showBSCscanPopup(res.transactionHash);
        return this.checkTransaction(res);
      });
  }

  public buyBusd() {
    const amo = "10000000000000000000000";
    return this.BUSDContract.methods
      .mint(this.account.address, amo)
      .send({
        from: this.account.address,
      })
      .then((res) => {
        this.glbC.showBSCscanPopup(res.transactionHash);
        return this.checkTransaction(res);
      });
  }

  public getLaunch() {
    return this.REXContract.methods
      .LAUNCH_TIME()
      .call({ from: this.account.address });
  }

  public getAIRClaimCount() {
    return this.AIRDROPContract.methods
      .claimCount()
      .call({ from: this.account.address });
  }

  public getAIRStakeCount() {
    return this.AIRDROPContract.methods
      .stakeCount()
      .call({ from: this.account.address });
  }

  public getClaimableAirdropAmount() {
    return this.AIRDROPContract.methods
      .claimableAmount(this.account.address)
      .call({ from: this.account.address });
  }

  public addressHasClaimedAIR() {
    return this.AIRDROPContract.methods
      .addressHasClaimed(this.account.address)
      .call({ from: this.account.address });
  }

  public addressCanClaimNowAirdrop() {
    return this.AIRDROPContract.methods
      .addressCanClaimNow(this.account.address)
      .call({ from: this.account.address });
  }

  public addressCanStakeNowAirdrop() {
    return this.AIRDROPContract.methods
      .addressCanStakeNow(this.account.address)
      .call({ from: this.account.address });
  }

  public addressIsInAIRList() {
    return this.AIRDROPContract.methods
      .addressIsInAirdropList(this.account.address)
      .call({ from: this.account.address });
  }

  public claimAIRTokens() {
    return this.AIRDROPContract.methods
      .claimAirdropTokens()
      .send({
        from: this.account.address,
      })
      .then((res) => {
        this.glbC.showBSCscanPopup(res.transactionHash);
        return this.checkTransaction(res);
      });
  }

  public claimAIRTokensStake(days) {
    console.log("claim airdrop duration:", days);
    if (
      days === "" ||
      days === undefined ||
      days < 30 ||
      days > 3653 ||
      this.glbC.currRDay > this.glbC.lastAuctionDay
    ) {
      return null;
    }
    return this.AIRDROPContract.methods
      .claimAirdropStake(days)
      .send({
        from: this.account.address,
      })
      .then((res) => {
        console.log("success aridrop");
        this.glbC.showBSCscanPopup(res.transactionHash);
        return this.checkTransaction(res);
      })
      .catch((error) => {
        console.log("Error in airdrop stake", error);
      });
  }

  public withdrawFromAuctionRexAuc() {
    return this.RDAContract.methods
      .claimRexFromDonations()
      .send({
        from: this.account.address,
      })
      .then((res) => {
        this.glbC.showBSCscanPopup(res.transactionHash);
        return this.checkTransaction(res);
      });
  }

  public withdrawFromAuctionRexAucS(days) {
    console.warn("withdrawFromAuctionRexAucS days: ", days);
    return this.RDAContract.methods
      .claimStakeFromDonations(days)
      .send({
        from: this.account.address,
      })
      .then((res) => {
        this.glbC.resetBigNumberConfig();
        this.glbC.showBSCscanPopup(res.transactionHash);
        return this.checkTransaction(res);
      })
      .catch((err) => {
        console.error("claimStakeFromDonations error: ", err);
        this.glbC.resetBigNumberConfig();
      });
  }

  public withdrawFromAuctionRexRef() {
    return this.RDAContract.methods
      .claimRexFromReferrals()
      .send({
        from: this.account.address,
      })
      .then((res) => {
        this.glbC.showBSCscanPopup(res.transactionHash);
        return this.checkTransaction(res);
      });
  }

  public withdrawFromAuctionEthRef() {
    return this.RDAContract.methods
      .claimBusdFromReferrals()
      .send({
        from: this.account.address,
      })
      .then((res) => {
        this.glbC.showBSCscanPopup(res.transactionHash);
        return this.checkTransaction(res);
      });
  }

  public withdrawFromAuctionEthBpd() {
    return this.RDAContract.methods
      .claimBusdFromBPD()
      .send({
        from: this.account.address,
      })
      .then((res) => {
        this.glbC.showBSCscanPopup(res.transactionHash);
        return this.checkTransaction(res);
      });
  }

  public withdrawFromAuctionEthTre() {
    return this.RDAContract.methods
      .claimBusdFromTREASURY()
      .send({
        from: this.account.address,
      })
      .then((res) => {
        this.glbC.showBSCscanPopup(res.transactionHash);
        return this.checkTransaction(res);
      });
  }

  public withdrawLPTokensRDA() {
    console.log("send withdraw LP token request to contract");
    return this.RDAContract.methods
      .withdrawLPTokens()
      .send({
        from: this.account.address,
      })
      .then((res) => {
        this.glbC.showBSCscanPopup(res.transactionHash);
        return this.checkTransaction(res);
      })
      .catch((error) => {
        console.warn("Error in withdraw LP token:", error);
      });
  }

  public getClaimableRexAuctions() {
    return this.RDAContract.methods
      .myClaimableRexFromDonations(this.account.address)
      .call({ from: this.account.address });
  }

  public getClaimableRexAuctionsFrom(addr) {
    return this.RDAContract.methods
      .myClaimableRexFromDonations(this.account.address)
      .call({ from: addr });
  }

  public getClaimableRexReferrals() {
    return this.RDAContract.methods
      .myClaimableRexFromReferrals(this.account.address)
      .call({ from: this.account.address });
  }

  // public getClaimableEthReferrals() {
  //   return this.RDAContract.methods
  //     .myClaimableBUSDFromReferrals(this.account.address)
  //     .call({ from: this.account.address });
  // }

  public getClaimableBUSDReferrals() {
    return new Promise((resolve: any) => {
      this.RDAContract.methods
        .referralBUSD(this.account.address)
        .call({ from: this.account.address })
        .then((result) => {
          resolve(this.glbC.currRDay <= LAST_CLAIM_DAY ? Number(result) : 0);
        });
    });
  }

  // public getClaimableEthBPD() {
  //   return this.RDAContract.methods
  //     .myClaimableBUSDFromBPD(this.account.address)
  //     .call({ from: this.account.address });
  // }

  public getClaimableBUSDBPD(address: string = this.account.address) {
    return new Promise((resolve: any) => {
      this.RDAContract.methods
        .randomBUSD(address)
        .call({ from: this.account.address })
        .then((result) => {
          resolve(this.glbC.currRDay <= LAST_CLAIM_DAY ? Number(result) : 0);
        });
    });
  }

  // public getClaimableEthTre() {
  //   return this.RDAContract.methods
  //     .myClaimableBUSDFromTreasury(this.account.address)
  //     .call({ from: this.account.address });
  // }

  // public getClaimableBUSDTre() {
  //   return this.RDAContract.methods
  //     .myClaimableBUSDFromTreasury(this.account.address)
  //     .call({ from: this.account.address });
  // }

  public getClaimableBUSDTre() {
    return new Promise((resolve: any) => {
      // check if it is before LAST_CONTRACT_DAY and after TREASURY_CLAIM_DAY
      if (
        this.glbC.currRDay < TREASURY_CLAIM_DAY ||
        this.glbC.currRDay >= LAST_CONTRACT_DAY
      ) {
        resolve(0);
      } else {
        // check if address got hit by random BPD
        this.getAddressHitByRandomWho().then((gotHit) => {
          console.warn("getAddressHitByRandomWho gotHit: ", gotHit);
          if (gotHit) {
            resolve(0);
          } else {
            this.RDAContract.methods
              .treasuryRatio()
              .call({ from: this.account.address })
              .then((treasuryRatio) => {
                console.warn("treasuryRatio: ", treasuryRatio);
                this.RDAContract.methods
                  .originalDonation(this.account.address)
                  .call({ from: this.account.address })
                  .then((originalDonation) => {
                    console.warn("originalDonation: ", originalDonation);
                    // multiply by treasuryRatio and devide bei TEN_PRECISION
                    let result = Number(originalDonation);
                    result *= Number(treasuryRatio);
                    console.warn(
                      "Number(treasuryRatio): ",
                      Number(treasuryRatio)
                    );
                    result /= Number("1E10");
                    console.warn("Number('1E10'): ", Number("1E10"));
                    console.warn("result: ", result);
                    resolve(result);
                  });
              });
          }
        });
      }
    });
  }

  public getTotalLpTokens() {
    return this.RDAContract.methods
      .totalLpTokens()
      .call({ from: this.account.address });
  }

  public getINITIAL_LIQ_BUSD() {
    return this.RDAContract.methods
      .INITIAL_LIQ_BUSD()
      .call({ from: this.account.address });
  }

  public getLiquidityBalances() {
    return this.RDAContract.methods
      .liquidityBalances(this.account.address)
      .call({ from: this.account.address });
  }

  public getLiquidityDrawn() {
    return this.RDAContract.methods
      .liquidityBalancesDrawn(this.account.address)
      .call({ from: this.account.address });
  }

  public getWithdrawableLPTokens() {
    return this.RDAContract.methods
      .withdrawableLPTokens(this.account.address)
      .call({ from: this.account.address });
  }

  public getClaimableRexBool() {
    return this.REXContract.methods
      .addressHasClaimed(this.account.address)
      .call({ from: this.account.address });
  }

  public rdaPush() {
    return this.RDAContract.methods
      .triggerDailyRoutineOneDay()
      .send({ from: this.account.address });
  }

  public rexPush() {
    return this.REXContract.methods
      .manualSnapshotOneDay()
      .send({ from: this.account.address });
  }

  public getNoOfOffersDex() {
    return this.DEXContract.methods
      .noOfOffers()
      .call({ from: this.account.address });
  }

  public rexAddress() {
    return this.CONTRACTS_PARAMS.REX.ADDRESS;
  }

  public trexAddress() {
    return this.CONTRACTS_PARAMS.TREX.ADDRESS;
  }

  public mrexAddress() {
    return this.CONTRACTS_PARAMS.MREX.ADDRESS;
  }

  public rdaAddress() {
    return this.CONTRACTS_PARAMS.RDA.ADDRESS;
  }

  public busdAddress() {
    return this.CONTRACTS_PARAMS.BUSD.ADDRESS;
  }

  public pairAddress() {
    return this.CONTRACTS_PARAMS.REXPAIR.ADDRESS;
  }

  public airAddress() {
    return this.CONTRACTS_PARAMS.AIRDROP.ADDRESS;
  }

  public dexAddress() {
    return this.CONTRACTS_PARAMS.DEX.ADDRESS;
  }

  public devFundAddress() {
    return this.CONTRACTS_PARAMS.DEV.ADDRESS;
  }

  public compensationAddress() {
    return this.CONTRACTS_PARAMS.COMP.ADDRESS;
  }

  public routerAddress() {
    return this.CONTRACTS_PARAMS.ROUTER.ADDRESS;
  }

  public getPairReserves() {
    return this.REXPAIRContract.methods
      .getReserves()
      .call({ from: this.account.address });
  }

  public getPairReservesBUSD() {
    return this.BUSDPAIRContract.methods
      .getReserves()
      .call({ from: this.account.address });
  }

  public sendETHToAuction(amount, addr) {
    console.log("send eth, amount:", amount);
    const amount100 = new BigNumber(100 * amount);
    // const amountM = new BigNumber(amount*1E18);
    let tmpAddr = addr;
    if (tmpAddr === "") {
      tmpAddr = "0x0000000000000000000000000000000000000000";
    }
    if (tmpAddr === undefined) {
      tmpAddr = "0x0000000000000000000000000000000000000000";
    }
    const depositTokens = (resolve: any, reject: any) => {
      return this.RDAContract.methods
        .donateBUSD(amount, tmpAddr)
        .send({
          from: this.account.address,
        })
        .then((res) => {
          this.glbC.showBSCscanPopup(res.transactionHash);
          return this.checkTransaction(res);
        })
        .then(resolve, reject);
    };

    return new Promise((resolve: any, reject: any) => {
      this.checkBUSDApproval(amount, this.RDAContract.options.address).then(
        () => {
          depositTokens(resolve, reject);
        },
        () => {
          this.BUSDContract.methods
            .approve(this.RDAContract.options.address, amount100)
            .send({
              from: this.account.address,
            })
            .then(() => {
              depositTokens(resolve, reject);
            }, reject);
        }
      );
    });
  }

  public sendETHToLiquidity(amount) {
    const amount100 = new BigNumber(10 * amount);
    // const amountM = new BigNumber(amount*1E18);
    const depositTokens = (resolve: any, reject: any) => {
      return this.RDAContract.methods
        .sendLiquidityBUSD(amount)
        .send({
          from: this.account.address,
        })
        .then((res) => {
          this.glbC.showBSCscanPopup(res.transactionHash);
          return this.checkTransaction(res);
        })
        .then(resolve, reject);
    };

    return new Promise((resolve: any, reject: any) => {
      this.checkBUSDApproval(amount, this.RDAContract.options.address).then(
        () => {
          depositTokens(resolve, reject);
        },
        () => {
          this.BUSDContract.methods
            .approve(this.RDAContract.options.address, amount100)
            .send({
              from: this.account.address,
            })
            .then(() => {
              depositTokens(resolve, reject);
            }, reject);
        }
      );
    });
  }

  async getRewardsandPenalty(sessionId, days: number) {
    const response = await this.REXContract.methods
      ._checkRewardAmountbyID(this.account.address, sessionId, days)
      .call({ from: this.account.address });
    return response;
  }

  public async getAccountStakes(): Promise<{
    closed: DepositInterface[];
    opened: DepositInterface[];
    offered: DepositInterface[];
    sold: DepositInterface[];
    transferred: DepositInterface[];
  }> {
    console.warn("getAccountStakes called");
    try {
      const [currentREXday, sessionsIds] = await Promise.all([
        this.getCurrentRexDay(),
        this.REXContract.methods
          .stakesPagination(this.account.address, 0, 0)
          .call({ from: this.account.address }),
      ]);
      if (sessionsIds) {
        const sessionPromises = [];
        sessionsIds.forEach((sessionId) => {
          sessionPromises.push(
            this.collectStakesOfSessionId(sessionId, currentREXday)
          );
        });
        const allDeposits: DepositInterface[] = await Promise.all(
          sessionPromises
        );
        // set iIrr and isTrex booleans
        allDeposits.forEach((stake, index) => {
          if (Number(stake.isIrrTrex) === 1) {
            allDeposits[index].isIrr = true;
            allDeposits[index].isTrex = false;
          } else if (Number(stake.isIrrTrex) === 2) {
            allDeposits[index].isIrr = false;
            allDeposits[index].isTrex = true;
          } else if (Number(stake.isIrrTrex) === 3) {
            allDeposits[index].isIrr = true;
            allDeposits[index].isTrex = true;
          } else {
            allDeposits[index].isIrr = false;
            allDeposits[index].isTrex = false;
          }
        });
        return {
          closed: allDeposits.filter((deposit: DepositInterface) => {
            return deposit.isActive === 0;
          }),
          opened: allDeposits.filter((deposit: DepositInterface) => {
            return deposit.isActive === 1;
          }),
          offered: allDeposits.filter((deposit: DepositInterface) => {
            return deposit.isActive === 2;
          }),
          sold: allDeposits.filter((deposit: DepositInterface) => {
            return deposit.isActive === 3;
          }),
          transferred: allDeposits.filter((deposit: DepositInterface) => {
            return deposit.isActive === 4;
          }),
        };
      }
    } catch (err) {
      console.error("getAccounts error: ", err);
    }
    return {
      closed: [],
      opened: [],
      offered: [],
      sold: [],
      transferred: [],
    };
  }

  private async collectStakesOfSessionId(sessionId, currentREXday) {
    let stake: any;
    let rewardAmountByIdMax: any = {
      rewardAmount: 0,
    };
    let rewardAmountByIdEarly: any = {
      rewardAmount: 0,
    };
    let penaltyAmountById: any;

    try {
      stake = await this.REXContract.methods
        .stakes(this.account.address, sessionId)
        .call({ from: this.account.address });
      // console.log(
      //   `collectStakesOfSessionId session(${sessionId}) stake: `,
      //   stake
      // );
    } catch (err) {
      console.error("get stake error: ", err);
      return null;
    }
    try {
      rewardAmountByIdMax = await this.REXContract.methods
        ._checkRewardAmountbyID(this.account.address, sessionId, 0)
        .call({ from: this.account.address });
    } catch (err) {
      console.error("_checkRewardAmountbyIDMax error: ", err);
      rewardAmountByIdMax.rewardAmount = 0;
    }

    const currentlyMaturedDays = Math.max(
      Number(currentREXday) - Number(stake.startDay),
      0
    );
    try {
      rewardAmountByIdEarly = await this.REXContract.methods
        ._checkRewardAmountbyID(
          this.account.address,
          sessionId,
          currentlyMaturedDays
        )
        .call({ from: this.account.address });
    } catch (err) {
      console.error("_checkRewardAmountbyIDEarly error: ", err);
      rewardAmountByIdEarly.rewardAmount = 0;
    }

    try {
      penaltyAmountById = await this.REXContract.methods
        ._checkPenaltyAmountbyID(this.account.address, sessionId)
        .call({ from: this.account.address });
    } catch (err) {
      console.error("_checkPenaltyAmountbyID error: ", err);
      penaltyAmountById = 0;
    }
    return {
      shares: new BigNumber(stake.stakesShares),
      amount: new BigNumber(stake.stakedAmount),
      rewards: new BigNumber(stake.rewardAmount),
      penalty: new BigNumber(stake.penaltyAmount),
      start: stake.startDay,
      end: stake.finalDay,
      close: stake.closeDay,
      scrape: stake.withdrawDay,
      isActive: Number(stake.isActive),
      isIrrTrex: stake.isIrrTrex,
      isIrr: stake.isIrr,
      isSplit: stake.isSplit,
      sessionId,
      possibleRewardsMax: new BigNumber(rewardAmountByIdMax.rewardAmount),
      possibleRewardsEarly: new BigNumber(rewardAmountByIdEarly.rewardAmount),
      possiblePenalty: new BigNumber(penaltyAmountById),
      description: stake.description,
    };
  }

  public getOffers(
    totalOffers: number
  ): Promise<{ closed: DexInterface[]; opened: DexInterface[] }> {
    const offerIds: number[] = [];

    for (let i = 0; i < totalOffers; i++) {
      offerIds[i] = i;
    }
    const offerPromises: DexInterface[] = offerIds.map((id) => {
      return this.DEXContract.methods
        .dataOfOfferNumber(id)
        .call({ from: this.account.address })
        .then((offerData) => {
          return this.DEXContract.methods
            .stakeOffers(id)
            .call({ from: this.account.address })
            .then((offerData2) => {
              // return this.REXContract.methods
              //   .currentRxDay()
              //    .call({ from: this.account.address })
              //   .then((result) => {
              return {
                numbr: id,
                offerStaker: offerData.staker,
                offerIsActive: offerData._bools[0],
                stakeIsSplit: offerData._bools[1],
                stakeIsIrr: offerData.isIrrTrex,
                offerStart: offerData._days[0],
                offerDuration: offerData._days[1],
                stakeStartDay: offerData._days[2],
                stakeFinalDay: offerData._days[3],
                offerPrice: new BigNumber(offerData._amounts[0]),
                stakeShares: new BigNumber(offerData._amounts[1]),
                stakeAmount: new BigNumber(offerData._amounts[2]),
                offerStakeID: offerData2.stakeID,
              };
              // });
            });
        });
    });
    return Promise.all(offerPromises).then((allOffers: DexInterface[]) => {
      return {
        closed: allOffers.filter((offer: DexInterface) => {
          return !offer.offerIsActive;
        }),
        opened: allOffers.filter((offer: DexInterface) => {
          return offer.offerIsActive;
        }),
      };
    });
  }

  public updateREXBalance(callEmitter?) {
    console.log("update REX balance called");
    return new Promise((resolve: any, reject: any) => {
      if (!(this.account && this.account.address)) {
        console.log("no account found");
        reject();
      } else {
        this.REXContract.methods
          .balanceOf(this.account.address)
          .call({ from: this.account.address })
          .then((balance) => {
            console.log("update REX balance resolved");
            const bigBalance = new BigNumber(balance);
            this.account.balances = this.account.balances || {};
            this.account.balances.REX = {
              wei: balance,
              weiBigNumber: bigBalance,
              shortBigNumber: bigBalance.div(new BigNumber(10).pow(18)),
              display: bigBalance.div(new BigNumber(10).pow(18)).toFormat(0),
            };
            resolve();
            if (callEmitter) {
              this.callAllAccountsSubscribers();
            }
          })
          .catch((err) => {
            console.log("error in updateREXbalance", err);
            reject(err);
          });
      }
    });
  }

  public updateTREXBalance(callEmitter?) {
    return new Promise((resolve: any, reject: any) => {
      if (!(this.account && this.account.address)) {
        console.log("no account found");
        reject();
      } else {
        this.TREXContract.methods
          .balanceOf(this.account.address)
          .call({ from: this.account.address })
          .then((balance) => {
            this.account.balances = this.account.balances || {};
            this.account.balances.TREX = {
              display: balance,
            };
            resolve();
            if (callEmitter) {
              this.callAllAccountsSubscribers();
            }
          })
          .catch((err) => {
            console.log("error in trex balance update", err);
            reject(err);
          });
      }
    });
  }

  public updateMREXBalance(callEmitter?) {
    return new Promise((resolve: any, reject: any) => {
      if (!(this.account && this.account.address)) {
        console.log("no account found");
        reject();
      } else {
        this.MREXContract.methods
          .balanceOf(this.account.address)
          .call({ from: this.account.address })
          .then((balance) => {
            this.account.balances = this.account.balances || {};
            this.account.balances.MREX = {
              display: balance,
            };
            resolve();
            if (callEmitter) {
              this.callAllAccountsSubscribers();
            }
          })
          .catch((err) => {
            console.log("error in mrex balance update", err);
            reject(err);
          });
      }
    });
  }

  public updateBUSDBalance(callEmitter?) {
    return new Promise((resolve: any, reject: any) => {
      if (!(this.account && this.account.address)) {
        console.log("no account found");
        reject();
      } else {
        this.BUSDContract.methods
          .balanceOf(this.account.address)
          .call({ from: this.account.address })
          .then((balance) => {
            this.account.balances = this.account.balances || {};
            const bigBalance = new BigNumber(balance);
            this.account.balances.BUSD = {
              wei: balance,
              weiBigNumber: bigBalance,
              shortBigNumber: bigBalance.div(new BigNumber(10).pow(18)),
              display: bigBalance.div(new BigNumber(10).pow(18)).toFormat(0),
            };
            resolve();
            if (callEmitter) {
              this.callAllAccountsSubscribers();
            }
          })
          .catch((err) => {
            console.log("error in busd balance update", err);
            reject(err);
          });
      }
    });
  }

  public updateLPBalance(callEmitter?) {
    return new Promise((resolve: any, reject: any) => {
      if (!(this.account && this.account.address)) {
        console.log("no account found");
        reject();
      } else {
        this.REXPAIRContract.methods
          .balanceOf(this.account.address)
          .call({ from: this.account.address })
          .then((balance) => {
            this.account.balances = this.account.balances || {};
            const bigBalance = new BigNumber(balance);
            this.account.balances.REXPAIR = {
              wei: balance,
              weiBigNumber: bigBalance,
              shortBigNumber: bigBalance.div(new BigNumber(10).pow(18)),
              display: balance,
            };
            resolve();
            if (callEmitter) {
              this.callAllAccountsSubscribers();
            }
          })
          .catch((err) => {
            console.log("error in LP balance update", err);
            reject(err);
          });
      }
    });
  }

  public updateETHBalance(callEmitter?) {
    return new Promise((resolve: any, reject: any) => {
      if (!(this.account && this.account.address)) {
        console.log("no account found");
        reject();
      } else {
        this.web3Service
          .getBalance(this.account.address)
          .then((balance) => {
            const bigBalance = new BigNumber(balance);
            this.account.balances = this.account.balances || {};
            this.account.balances.ETH = {
              wei: balance,
              weiBigNumber: bigBalance,
              shortBigNumber: bigBalance.div(new BigNumber(10).pow(18)),
              display: bigBalance.div(new BigNumber(10).pow(18)).toFormat(0),
            };
            resolve();
            if (callEmitter) {
              this.callAllAccountsSubscribers();
            }
          })
          .catch((err) => {
            console.log("error in eth balance update", err);
            reject(err);
          });
      }
    });
  }

  public getCoinsDecimals() {
    return this.tokensDecimals;
  }

  // public loadAccountInfo() {
  //   const promises = [
  //     this.updateETHBalance(),
  //     this.updateREXBalance(),
  //     this.updateTREXBalance(),
  //     this.updateMREXBalance(),
  //     this.updateBUSDBalance(),
  //     this.updateLPBalance(),
  //   ];
  //   Promise.all(promises).then(() => {
  //     this.callAllAccountsSubscribers();
  //   });
  // }

  /**
   */
  public async loadAccountInfo() {
    console.log("loadAccountInfo called");
    return new Promise((resolve: any, reject: any) => {
      if (!this.REXContract) {
        console.log("REX Contract not initialized yet");

        this.initializeContracts();
      }
      const promises = [
        this.updateETHBalance(),
        this.updateREXBalance(),
        this.updateTREXBalance(),
        this.updateMREXBalance(),
        this.updateBUSDBalance(),
        this.updateLPBalance(),
      ];
      Promise.all(promises)
        .then(() => {
          this.glbC.balancesAreLoaded = true;
          // this.callAllAccountsSubscribers();
          console.log("loadAccountInfo resolved");
          this.glbC.account = this.account;
          console.log(this.glbC.account);

          resolve();
        })
        .catch((err) => {
          console.error("loadAccountInfo error: ", err);
          reject(err);
        });
    });
  }

  public isValidEthAddress(address: string) {
    return this.web3Service.isValidAddress(address);
  }

  private checkTx(tx, resolve, reject: any) {
    this.web3Service.web3.eth
      .getTransaction(tx.transactionHash)
      .then((txInfo) => {
        if (txInfo.blockNumber) {
          this.callAllTransactionsSubscribers(txInfo);
          resolve(tx);
        } else {
          setTimeout(() => {
            this.checkTx(tx, resolve, reject);
          }, 2000);
        }
      }, reject);
  }

  private checkTransaction(tx) {
    console.log("now in checkTransaction");
    return new Promise((resolve: any, reject: any) => {
      this.checkTx(tx, resolve, reject);
    });
  }

  public depositREX(amount, days, name, irr) {
    console.log("deposit rex");
    const amount10 = new BigNumber(1000 * amount);
    const amountToStake = amount.times(10 ** 18);
    BigNumber.config({ DECIMAL_PLACES: 0 });
    let tmpName = name;
    if (tmpName === "") {
      tmpName = "no_name";
    }
    if (tmpName === undefined) {
      tmpName = "no_name";
    }
    const fromAccount = this.account.address;
    const depositTokens = (resolve: any, reject: any) => {
      console.log("Deposit Tokens func");
      return this.REXContract.methods
        .createStake(this.account.address, amountToStake, days, tmpName, irr)
        .send({
          from: fromAccount,
        })
        .then((res) => {
          console.log("rex deposid success");
          this.glbC.resetBigNumberConfig();
          this.glbC.showBSCscanPopup(res.transactionHash);
          return this.checkTransaction(res);
        })
        .then(resolve, reject)
        .catch((error) => {
          console.log("Error in deposit rex", error);
        });
    };

    return new Promise((resolve: any, reject: any) => {
      this.checkREXApproval(amount10, this.REXContract.options.address)
        .then(
          () => {
            depositTokens(resolve, reject);
          },
          () => {
            this.REXContract.methods
              .approve(this.REXContract.options.address, amount10)
              .send({
                from: fromAccount,
              })
              .then(() => {
                depositTokens(resolve, reject);
              }, reject);
          }
        )
        .catch((err) => {
          console.error("checkREXApproval error: ", err);
          this.glbC.resetBigNumberConfig();
          reject(err);
        });
    });
  }

  public depositREXBulk(
    amountREX,
    amountStakes,
    interval,
    offset,
    name,
    irr,
    asLadder,
    asVarious
  ) {
    const fromAccount = this.account.address;
    let amount10;
    let tmpName;
    let rexAmountList = [];
    let stakeDurationList = [];
    let isIrrList = [];
    console.log("arrived in depositREXBulk");
    console.log("Stake is a Ladder", asLadder);
    console.log("Stakes are various", asVarious);
    if (!asVarious) {
      amount10 = new BigNumber(1000 * amountREX);
      let amountPerStake = new BigNumber(amountREX)
        .div(amountStakes)
        .times(10 ** 18);
      console.log("amountPerStake: ", amountPerStake);
      tmpName = name;
      if (tmpName === "") {
        tmpName = "bulk_no_name";
      }
      if (tmpName === undefined) {
        tmpName = "bulk_no_name";
      }

      for (let i = 0; i < amountStakes; i++) {
        rexAmountList.push(amountPerStake);
        if (asLadder) {
          stakeDurationList.push(interval * (i + 1) + Number(offset));
        } else {
          stakeDurationList.push(interval);
        }
        isIrrList.push(irr);
      }
    } else {
      tmpName = name;
      if (tmpName === "") {
        tmpName = "various_no_name";
      }
      if (tmpName === undefined) {
        tmpName = "various_no_name";
      }
      const totalREX = amountREX.reduce((a, b) => Number(a) + Number(b));
      console.log(totalREX);
      amount10 = new BigNumber(1000 * totalREX);
      console.log("amount10:", amount10);
      console.log(amountREX);
      for (let i = 0; i < amountREX.length; i++) {
        rexAmountList.push(new BigNumber(Number(amountREX[i])).times(10 ** 18));
      }
      stakeDurationList = interval;
      isIrrList = irr;
    }

    const depositTokens = (resolve: any, reject: any) => {
      console.warn("rexAmountList: ", rexAmountList);
      console.warn("stakeDurationList: ", stakeDurationList);
      console.warn("tmpName: ", tmpName);
      console.warn("isIrrList: ", isIrrList);
      this.REXContract.methods
        .createStakeBatch(rexAmountList, stakeDurationList, tmpName, isIrrList)
        .send({
          from: fromAccount,
        })
        .then((res) => {
          console.warn("createStakeBatch result: ", res);
          this.checkTransaction(res)
            .then(() => {
              resolve();
            })
            .catch((err) => {
              reject(err);
            });
        })
        .catch((err) => {
          console.warn("createStakeBatch error: ", err);
          reject(err);
        });
    };

    return new Promise((resolve: any, reject: any) => {
      this.checkREXApproval(amount10, this.REXContract.options.address)
        .then(
          () => {
            depositTokens(resolve, reject);
          },
          () => {
            this.REXContract.methods
              .approve(this.REXContract.options.address, amount10)
              .send({
                from: fromAccount,
              })
              .then(() => {
                depositTokens(resolve, reject);
              })
              .catch((err) => {
                reject(err);
              });
          }
        )
        .catch((err) => {
          console.error("checkREXApproval error: ", err);
          reject(err);
        });
    });
  }

  public getStakingContractInfo() {
    const promises = [
      this.REXContract.methods
        .currentRxDay()
        .call({ from: this.account.address })
        .then((result) => {
          return {
            key: "StepsFromStart",
            value: result,
          };
        }),
      this.REXContract.methods
        .getSharePrice()
        .call({ from: this.account.address })
        .then((result) => {
          return {
            key: "ShareRate",
            value: result,
          };
        }),
    ];
    return Promise.all(promises).then((results) => {
      const values = {};
      results.forEach((v) => {
        values[v.key] = v.value;
      });
      return values;
    });
  }

  private checkREXApproval(amount, address?): Promise<any> {
    console.log("check for REX approval");
    return new Promise((resolve: any, reject: any) => {
      this.REXContract.methods
        .allowance(this.account.address, address)
        .call({ from: this.account.address })
        .then((allowance: string) => {
          const allow = new BigNumber(allowance);
          const allowed = allow.minus(amount);
          console.log("Allow:", allow);
          console.log("Allowance", allowance);
          console.log("Allowed?:", allowed);
          console.log("Amount", amount);
          console.log("REX Approvel is positive: ", !allowed.isNegative());
          if (allowed.isNegative()) {
            reject();
          } else {
            resolve();
          }
          // allowed.isNegative() ? reject() : resolve();
        })
        .catch((error) => {
          console.log("error in checkREXApproval func:", error);
          reject();
        });
    });
  }

  public getClaimableCompensationBUSD(): Promise<any> {
    return new Promise((resolve: any) => {
      this.COMPContract.methods
        .getClaimableAmount(this.account.address)
        .call({ from: this.account.address })
        .then((amount) => {
          console.log("getClaimableCompensationBUSD amount: ", amount);
          resolve(amount);
        })
        .catch((err) => {
          console.error("getClaimableCompensationBUSD error: ", err);
          resolve(0);
        });
    });
  }

  public getClaimedCompensationBUSD() {
    return new Promise((resolve: any) => {
      this.COMPContract.methods
        .claimedAmount(this.account.address)
        .call({ from: this.account.address })
        .then((currentlyClaimed) => {
          console.log(
            "Already claimed BUSD from compensation: ",
            currentlyClaimed / 1e18
          );
          resolve(currentlyClaimed);
        })
        .catch((err) => {
          console.error("getClaimedCompensationBUSD error: ", err);
          resolve(0);
        });
    });
  }

  public getTotalPossibleCompensationBUSD() {
    return new Promise((resolve: any, reject: any) => {
      console.log(this.account.address);
      this.COMPContract.methods
        .claimableAmount(this.account.address)
        .call({ from: this.account.address })
        .then((totalPossibleClaim) => {
          console.log("Total possible claim:", totalPossibleClaim / 1e18);
          resolve(totalPossibleClaim);
        })
        .catch((error) => {
          console.error("getTotalPossibleCompensationBUSD error: ", error);
          reject(error);
        });
    });
  }

  public claimBUSDCOMP(): Promise<any> {
    console.log("claim BUSD in Index called");
    return new Promise((resolve: any) => {
      this.COMPContract.methods
        .claimBUSD()
        .send({
          from: this.account.address,
        })
        .then((response) => {
          console.log("claimBUSDCOMP response: ", response);
          resolve();
        })
        .catch((err) => {
          console.error("claimBUSDCOMP error: ", err);
          resolve();
        });
    });
  }

  public checkBUSDApproval(amount, address?): Promise<any> {
    return new Promise((resolve: any, reject: any) => {
      this.BUSDContract.methods
        .allowance(this.account.address, address)
        .call({ from: this.account.address })
        .then((allowance: string) => {
          console.log(allowance);
          const allow = new BigNumber(allowance);
          console.log(allow);
          const allowed = allow.minus(amount);
          console.log(allowed);
          console.log("allowed is neg", allowed.isNegative());
          if (allowed.isNegative()) {
            reject();
          } else {
            resolve();
          }
          // allowed.isNegative() ? reject() : resolve();
        })
        .catch((error) => {
          console.log("error in checkBUSDApproval", error);
        });
    });
  }

  public getAuctionsData(lastAuctionDay: number) {
    const auctionIds: number[] = [];
    const tmpLastAuctionDay =
      lastAuctionDay > this.glbC.lastAuctionDay
        ? this.glbC.lastAuctionDay
        : lastAuctionDay;
    for (let i = 1; i <= tmpLastAuctionDay; i++) {
      auctionIds[i - 1] = i;
    }
    const auctionsPromises = auctionIds.map((id) => {
      return this.RDAContract.methods
        .auctionStatsOfDay(id)
        .call({ from: this.account.address })
        .then((auctionData) => {
          return this.RDAContract.methods
            .donatorBalances(this.account.address, id)
            .call({ from: this.account.address })
            .then((myAuctionData) => {
              return this.RDAContract.methods
                .referrerBalances(this.account.address, id)
                .call({ from: this.account.address })
                .then((myRefData) => {
                  return {
                    id,
                    dailyGeneratedREX: auctionData[0],
                    dailyTotalDonation: auctionData[1],
                    donatorsOnDay: auctionData[2],
                    dailyRatio: auctionData[3],
                    myDon: myAuctionData,
                    myRef: myRefData,
                  };
                });
            });
        });
    });
    return Promise.all(auctionsPromises);
  }

  public async getAuctionDataDay(auctionDay: number) {
    const auctionData = await this.RDAContract.methods
      .auctionStatsOfDay(auctionDay)
      .call();

    return auctionData;
  }

  /**
   * loads past auction days from given rexDay and only goes back for amountOfDays. If amountOfDays is missing, all days will be fetched.
   * @param rexDay most recent Rex Day
   * @param amountOfDays amount of days to fetch from before given rexDay
   */
  public getPastXAuctionDays(
    rexDay: number,
    amountOfDays?: number
  ): Promise<any[]> {
    let tmpAmountOfDays: number = null;
    if (amountOfDays) {
      tmpAmountOfDays = rexDay < amountOfDays ? rexDay - 1 : amountOfDays;
    }
    return new Promise((resolve: any, reject: any) => {
      console.log(
        `getPastXAuctionDays called with rexDay: ${rexDay}, amountOfDays: ${amountOfDays}`
      );
      const promises: Promise<any>[] = [];
      const daysToFetch = tmpAmountOfDays || rexDay - 1;
      for (let i = 0; i < daysToFetch; i++) {
        promises.push(
          new Promise((res: any, rej: any) => {
            const auctionId = rexDay - i - 1;
            this.RDAContract.methods
              .auctionStatsOfDay(auctionId)
              .call({ from: this.account.address })
              .then((auctionData) => {
                Promise.all([
                  this.RDAContract.methods
                    .donatorBalances(this.account.address, auctionId)
                    .call({ from: this.account.address }),
                  this.RDAContract.methods
                    .referrerBalances(this.account.address, auctionId)
                    .call({ from: this.account.address }),
                ]).then((personalData) => {
                  const constrAuctionData = {
                    id: auctionId,
                    dailyGeneratedREX: auctionData[0],
                    dailyTotalDonation: auctionData[1],
                    donatorsOnDay: auctionData[2],
                    dailyRatio: auctionData[3],
                    myDon: personalData[0],
                    myRef: personalData[1],
                  };
                  res(constrAuctionData);
                });
              })
              .catch((err) => {
                rej(err);
              });
          })
        );
      }
      Promise.all(promises)
        .then((auctionData) => {
          console.log("auctionData: ", auctionData);
          resolve(auctionData);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public getTodaysSupply(day) {
    console.log(`getTodaysSupply day: `, day);
    const PRINCES_PER_REX = 10 ** 18;
    if (Number(day) === 1) {
      return PRINCES_PER_REX * 200000000;
    }
    if (Number(day) === 2) {
      return PRINCES_PER_REX * 140000000;
    }
    if (Number(day) === 3) {
      return PRINCES_PER_REX * 90000000;
    }
    if (Number(day) === this.glbC.lastAuctionDay) {
      return PRINCES_PER_REX * 53230000;
    }
    if (Number(day) > 3 && Number(day) < this.glbC.lastAuctionDay) {
      return PRINCES_PER_REX * (90000000 - (Number(day) - 3) * 130000);
    }

    return 0;
  }

  /*
  public getAuctions() {
    return new Promise((resolve: any) => {
      this.REXContract.methods.currentRxDay() .call({ from: this.account.address }).then((auctionId) => {
              this.getAuctionsData(auctionId).then(
                (auctions) => {
                  resolve(auctions);
                });
            });
    });
  }
*/

  /*
  public getAuctionInfo() {
    const retData = {} as any;
    return this.RDAContract.methods
      ._currentRexDay()
       .call({ from: this.account.address })
      .then((auctionId) => {
        const promises = [
          this.RDAContract.methods.dailyWeiContributed(auctionId) .call({ from: this.account.address }).then((result) => {
            this.RDAContract.methods.todaysSupply(auctionId) .call({ from: this.account.address }).then((results) => {
              retData.ethPool = results;
              retData.axnPool = result;
            });
          }),
        ];
        if (this.account) {
          promises.push(
            this.RDAContract.methods
              .myDonationOnDay(this.account.address)
               .call({ from: this.account.address })
              .then((result) => {
                retData.currentUserBalance = result;
              })
          );
        }
        return Promise.all(promises).then(() => {
          return retData;
        });
      });
  }
*/
  private initializeContracts() {
    console.log("Initiialize contracts");
    this.REXContract = this.web3Service.getContract(
      this.CONTRACTS_PARAMS.REX.ABI,
      this.CONTRACTS_PARAMS.REX.ADDRESS
    );

    this.DEXContract = this.web3Service.getContract(
      this.CONTRACTS_PARAMS.DEX.ABI,
      this.CONTRACTS_PARAMS.DEX.ADDRESS
    );

    this.AIRDROPContract = this.web3Service.getContract(
      this.CONTRACTS_PARAMS.AIRDROP.ABI,
      this.CONTRACTS_PARAMS.AIRDROP.ADDRESS
    );

    this.TREXContract = this.web3Service.getContract(
      this.CONTRACTS_PARAMS.TREX.ABI,
      this.CONTRACTS_PARAMS.TREX.ADDRESS
    );

    this.MREXContract = this.web3Service.getContract(
      this.CONTRACTS_PARAMS.MREX.ABI,
      this.CONTRACTS_PARAMS.MREX.ADDRESS
    );

    this.RDAContract = this.web3Service.getContract(
      this.CONTRACTS_PARAMS.RDA.ABI,
      this.CONTRACTS_PARAMS.RDA.ADDRESS
    );

    this.BUSDContract = this.web3Service.getContract(
      this.CONTRACTS_PARAMS.BUSD.ABI,
      this.CONTRACTS_PARAMS.BUSD.ADDRESS
    );

    this.REXPAIRContract = this.web3Service.getContract(
      this.CONTRACTS_PARAMS.REXPAIR.ABI,
      this.CONTRACTS_PARAMS.REXPAIR.ADDRESS
    );

    this.BUSDPAIRContract = this.web3Service.getContract(
      this.CONTRACTS_PARAMS.BUSDPAIR.ABI,
      this.CONTRACTS_PARAMS.BUSDPAIR.ADDRESS
    );

    this.ROUTERContract = this.web3Service.getContract(
      this.CONTRACTS_PARAMS.ROUTER.ABI,
      this.CONTRACTS_PARAMS.ROUTER.ADDRESS
    );

    this.DEVFUNDContract = this.web3Service.getContract(
      this.CONTRACTS_PARAMS.DEV.ABI,
      this.CONTRACTS_PARAMS.DEV.ADDRESS
    );

    this.COMPContract = this.web3Service.getContract(
      this.CONTRACTS_PARAMS.COMP.ABI,
      this.CONTRACTS_PARAMS.COMP.ADDRESS
    );

    this.LOTTERIEContract = this.web3Service.getContract(
      this.CONTRACTS_PARAMS.LOTTERIE.ABI,
      this.CONTRACTS_PARAMS.LOTTERIE.ADDRESS
    );
  }

  /**
   * Makes requests to the blockchain to obtain the donation and referal amount of the logged in account
   * @param startDate First day to load
   * @param endDate Last day to load
   * @returns Object with properties "day", "myDonAmount" und "myRefAmount"
   */
  public getPersonalAuctionData(
    startDate: number,
    endDate: number
  ): Promise<any[]> {
    return new Promise((resolve: any, reject: any) => {
      const promises: Promise<any>[] = [];
      const daysToFetch = Number(endDate) + 1 - Number(startDate);
      for (let i = 0; i < daysToFetch; i++) {
        promises.push(
          new Promise((res: any) => {
            const auctionId = Number(startDate) + i;
            Promise.all([
              this.RDAContract.methods
                .donatorBalances(this.account.address, auctionId)
                .call({ from: this.account.address }),
              this.RDAContract.methods
                .referrerBalances(this.account.address, auctionId)
                .call({ from: this.account.address }),
            ]).then((personalData) => {
              const constrAuctionData = {
                day: auctionId,
                myDonAmount: personalData[0],
                myRefAmount: personalData[1],
              };
              res(constrAuctionData);
            });
          })
        );
      }
      Promise.all(promises)
        .then((auctionData) => {
          resolve(auctionData);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  /**
   * Requests how many REX this account has received in total from the auctions
   * @returns Amount of received REX
   */
  public async getTotalReceivedREX() {
    return this.RDAContract.methods
      .donatorTotalRexReceived(this.account.address)
      .call({ from: this.account.address });
  }

  /**
   * Requests triggered events from the contracts
   * @param contract Which contract was triggered? RDA? REX?
   * @param eventType Type of event; "allEvents" to receive all events
   * @param startBlock First block to check
   * @param endBlock Last block to check
   * @returns List of events
   */
  public async getPastEvents(
    contract: string,
    eventType: string,
    startBlock: string,
    endBlock: string
  ) {
    let result: any;
    if (contract === "rex") {
      result = await this.REXContract.getPastEvents(eventType, {
        fromBlock: startBlock,
        toBlock: endBlock,
      });
    } else if (contract === "rda") {
      console.log(this.RDAContract);
      console.log(this.RDAContract.options.address);
      console.log(
        "GET rda contact events of type " +
          eventType +
          " from block " +
          startBlock +
          " until Block " +
          endBlock
      );
      this.RDAContract.getPastEvents(eventType)
        .then((events) => {
          console.log(events);
          result = events;
        })
        .catch((error) => {
          console.warn(error);
          result = error;
        });
    }
    return result;
  }

  // Part for the lotterie functions

  public async getCurrentRound() {
    return this.LOTTERIEContract.methods
      .currentRound()
      .call({ from: this.account.address });
  }

  public async getSoldTickets() {
    return this.LOTTERIEContract.methods
      .soldTickets()
      .call({ from: this.account.address });
  }

  public async getRoundIsDistributed(roundNumber: Number) {
    return this.LOTTERIEContract.methods
      .roundIsDistributed(roundNumber)
      .call({ from: this.account.address });
  }

  public async getPersonalBusdClaim() {
    return this.LOTTERIEContract.methods
      .cashbackBusdAvail(this.account.address)
      .call({ from: this.account.address });
  }

  public async getLotterieBusdClaim(address: string) {
    return this.LOTTERIEContract.methods
      .cashbackBusdAvail(address)
      .call({ from: this.account.address });
  }

  public async getTotalPersonalBUSDrecvd() {
    return this.LOTTERIEContract.methods
      .cashbackBusdRecvd(this.account.address)
      .call({ from: this.account.address });
  }

  public async getTotalPersonalBUSDspent() {
    return this.LOTTERIEContract.methods
      .addrTotalSpentBusd(this.account.address)
      .call({ from: this.account.address });
  }

  public async getTicketsOfAddr(roundNumber: Number) {
    return this.LOTTERIEContract.methods
      .addrTicketsRound(this.account.address, roundNumber)
      .call({ from: this.account.address });
  }

  public getTicketsOfAddrTotal(lastRoundNumber: number): Promise<any[]> {
    return new Promise((resolve: any, reject: any) => {
      const promises: Promise<any>[] = [];
      const daysToFetch = Number(lastRoundNumber) + 1;
      for (let i = 0; i < daysToFetch; i++) {
        promises.push(
          new Promise((res: any, rej: any) => {
            Promise.all([
              this.LOTTERIEContract.methods
                .addrTicketsRound(this.account.address, i)
                .call({ from: this.account.address }),
            ])
              .then((personalLotterieData) => {
                const constrAuctionData = {
                  day: i,
                  numberOfTickets: personalLotterieData[0],
                };
                res(constrAuctionData);
              })
              .catch((error) => {
                rej(error);
              });
          })
        );
      }
      Promise.all(promises)
        .then((lotterieData) => {
          resolve(lotterieData);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public claimCashbackBusdLotterie() {
    return this.LOTTERIEContract.methods
      .claimCashbackBusd()
      .send({
        from: this.account.address,
      })
      .then((res) => {
        console.log("Claimed Liqquidery BUSD successfully: ", res);
        this.glbC.showBSCscanPopup(res.transactionHash);
        return this.checkTransaction(res);
      })
      .catch((error) => {
        console.log("Error in airdrop stake", error);
      });
  }

  public async getLotterieParticipants() {
    return this.LOTTERIEContract.methods
      .participants()
      .call({ from: this.account.address });
  }

  public async getSaleOpen() {
    return this.LOTTERIEContract.methods
      .saleOpen()
      .call({ from: this.account.address });
  }

  public async getOracleReady() {
    return this.LOTTERIEContract.methods
      .oracleReady()
      .call({ from: this.account.address });
  }

  public async getRndmNumber() {
    return this.LOTTERIEContract.methods
      .randomNumber()
      .call({ from: this.account.address });
  }

  public distributeLotterieRound() {
    return this.LOTTERIEContract.methods.distributeRound().call({
      from: this.account.address,
    });
  }

  public blockUserForXDays(days: number) {
    console.log("Block user for " + days + " days");
    return this.LOTTERIEContract.methods
      .blockMeForXDays(Number(days))
      .send({
        from: this.account.address,
      })
      .then((res) => {
        console.log("Block User successfully: ", res);
        this.glbC.showBSCscanPopup(res.transactionHash);
        return this.checkTransaction(res);
      })
      .catch((error) => {
        console.log("Error in block user", error);
      });
  }

  public userIsBlocked() {
    return this.LOTTERIEContract.methods.isBlocked(this.account.address).call({
      from: this.account.address,
    });
  }

  public buyLotterieTicket(noOfTickets) {
    console.log(this.web3Service.web3);
    const depositTokens = (resolve: any, reject: any) => {
      return this.LOTTERIEContract.methods
        .buyTickets(noOfTickets)
        .send({
          from: this.account.address,
        })
        .then((res) => {
          this.glbC.showBSCscanPopup(res.transactionHash);
          return this.checkTransaction(res);
        })
        .then(resolve, reject);
    };

    return new Promise((resolve: any, reject: any) => {
      const requiredBUSD = new BigNumber(noOfTickets * 20).times(
        new BigNumber(10).pow(18)
      );
      console.log("required BUSD: " + requiredBUSD);
      this.checkBUSDApproval(
        requiredBUSD,
        this.LOTTERIEContract.options.address
      )
        .then(
          () => {
            console.log("BUSD Approval good 1");
            depositTokens(resolve, reject);
          },
          () => {
            console.log("BUSD Approval good 2");
            console.log(this.web3Service.web3);

            this.BUSDContract.methods
              .approve(this.LOTTERIEContract.options.address, requiredBUSD)
              .send({
                from: this.account.address,
              })
              .then(() => {
                depositTokens(resolve, reject);
              }, reject);
          }
        )
        .catch((error) => {
          console.log("Error in BUSD Approval: ", error);
        });
    });
  }
}
