import { Component, NgZone, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators,
} from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import BigNumber from "bignumber.js";
import { Subscription } from "rxjs";
import { ContractService, LAST_CLAIM_DAY } from "src/app/services/contract";
import { GlobalControllerService } from "src/app/services/global-controller.service";
import { chartInstance, HttpService } from "src/app/services/http.service";

@Component({
  selector: "app-dashboard-page",
  templateUrl: "./dashboard-page.component.html",
  styleUrls: ["./dashboard-page.component.scss"],
})
export class DashboardPageComponent implements OnInit {
  activeRexTrend: number = 0;

  totalRex: any;

  initSubscription: Subscription = null;

  iframeIsLoaded: boolean = false;

  somethingIsClaimable: boolean = false;

  rexAmount: any;

  mrexAmount: any;

  trexAmount: any;

  busdAmountInWallet: any;

  rexStaked: any = 0;

  getFreeBUSDProgress: boolean = false;

  buyMREXProgress: boolean = false;

  claimableRexFromAuctions: any;

  claimRexFromAuctionProgress: boolean = false;

  claimableRexFromReferrals: any;

  claimRexFromReferralsProgress: boolean = false;

  claimableBUSDFromBPD: any;

  claimBUSDFromBPDProgress: boolean = false;

  claimableBUSDFromReferrals: any;

  claimBUSDFromReferalsProgress: boolean = false;

  claimableBUSDFromTreasury: any;

  claimBUSDFromTreasuryProgress: boolean = false;

  claimLiqquideryProgress: boolean = false;

  canClaimTrexAirdropNow: boolean = false;

  claimableTrexAirdrop: any;

  claimTrexAirdropProgress: boolean = false;

  hasClaimedRexAirdrop: boolean = false;

  claimableRexFromAIRDROP: any;

  claimRexFromAIRDROPProgress: boolean = false;

  claimableCompBUSD: any;

  alreadyClaimedCompBUSD: any;

  totalPossibleCompBUSD: any;

  possibleLotteryClaim: any;

  claimClaimableCompBUSD: boolean = false;

  airDropClaimStatus: string = "invalid";

  auctionClaimStatus: string = "invalid";

  airDropStakeDuration: number = 0;

  auctionStakeDuration: number = 0;

  totalClaimableAirdrop: BigNumber;

  rexAirdropBonus: any = 1;

  rexAuctionBonus: any = 1;

  rexAirdropBonusLPD: any = 0;

  trexPrice: any;

  busdInTrex: BigNumber;

  isLiquidTrexDex: boolean;

  sendAuctionTrexProgress: boolean = false;

  soldTREX: any;

  noOfTREXSellable: any;

  trexAirBalLeft: any;

  sellTrexProgress: boolean = false;

  liquidityBalance: any;

  initialLpTokens: any;

  totalLpTokens: any;

  withdrawableLPTokens: any;

  withdrawableLPTokensProgress: boolean = false;

  provideLiquidityForm: FormGroup;

  addBPDaddressForm: FormGroup;

  bpdAddressToAdd: string;

  bpdAddressNameToAdd: string;

  formBusdRex: FormGroup;

  formRexBusd: FormGroup;

  swapBusdRexProgress: boolean = false;

  swapRexBusdProgress: boolean = false;

  sendLiquidityProgress: boolean = false;

  lotterieWinClaimable: boolean = false;

  anyAddressHasBPD: boolean = false;

  busdAmountToSwap: number = 0.0;

  rexAmountToSwap: number = 0.0;

  lPValidationMessages = {
    busdAmount: [
      {
        type: "required",
        message: "ERRORMESSAGES.lp_busdAmount_required",
      },
      {
        type: "min",
        message: "ERRORMESSAGES.lp_busdAmount_min",
      },
      {
        type: "max",
        message: "ERRORMESSAGES.lp_busdAmount_max",
      },
    ],
  };

  swapValidationMessages = {
    rexAmount: [
      {
        type: "required",
        message: "ERRORMESSAGES.swap_amount_required",
      },
      {
        type: "max",
        message: "ERRORMESSAGES.swap_amount_max",
      },
    ],
    busdAmount: [
      {
        type: "required",
        message: "ERRORMESSAGES.swap_amount_required",
      },
      {
        type: "max",
        message: "ERRORMESSAGES.swap_amount_max",
      },
    ],
  };

  priceLineChart: chartInstance = {
    type: "line",
    label: [],
    data: [
      {
        data: [],
        borderColor: this.httpService.colorRexBlue,
        pointBackgroundColor: this.httpService.colorRexBlue,
        pointRadius: 1,
      },
    ],
    options: {
      maintainAspectRatio: false,
      hitRadius: this.httpService.hitradiusCharts,
      elements: {
        line: {
          tension: 0.3,
        },
      },
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          callbacks: {
            label(context) {
              return `$${context.raw}`;
            },
          },
        },
      },
      scales: {
        x: {
          grid: {},
          ticks: {
            autoSkip: true,
            maxTicksLimit: 10,
            color: this.httpService.colorRexBlue,
          },
        },
        y: {
          ticks: {
            color: this.httpService.colorRexBlue,
            callback(value) {
              const callbackVal = value.toFixed(6);
              return `$${callbackVal}`;
            },
          },
        },
      },
    },
  };

  supplyChart: chartInstance = {
    type: "line",
    label: [],
    data: [
      {
        data: [],
        borderColor: this.httpService.colorRexBlue,
        pointBackgroundColor: this.httpService.colorRexBlue,
        pointRadius: 1,
      },
    ],
    options: {
      maintainAspectRatio: false,
      hitRadius: this.httpService.hitradiusCharts,
      elements: {
        line: {
          tension: 0.3,
        },
      },
      plugins: {
        legend: {
          display: false,
        },
      },
      scales: {
        x: {
          ticks: {
            autoSkip: true,
            maxTicksLimit: 10,
            color: this.httpService.colorRexBlue,
          },
        },
        y: {
          ticks: {
            color: this.httpService.colorRexBlue,
          },
        },
      },
    },
  };

  everythingIsLoaded: boolean = false;

  liquidityDrawn: any;

  rexPairAmount: number;

  listOfBPDaddresses: any[] = [];

  bpdAddressesObjects: any[] = [];

  constructor(
    public glbC: GlobalControllerService,
    private contractService: ContractService,
    private ngZone: NgZone,
    private translate: TranslateService,
    private formBuilder: FormBuilder,
    public httpService: HttpService
  ) {}

  ngOnInit() {
    console.log("dashboard ngOnInit");

    // check init status:
    this.initSubscription = this.glbC.initSubject.subscribe(async (data) => {
      console.log("initSubscription data: ", data);
      if (data.doInit) {
        if (this.glbC.isConnected) {
          this.initContract();
        }
      }
    });

    if (!this.glbC.isConnected) {
      if (
        this.glbC.getHasConnectedBefore("metamask") &&
        !this.glbC.isOnWrongNetwork
      ) {
        this.loginWithMetamask();
      } else if (this.glbC.getHasConnectedBefore("walletConnect")) {
        this.loginWithWalletConnect();
      }
    } else if (
      (!this.glbC.isOnWrongNetwork && this.glbC.walletType === "metamask") ||
      this.glbC.walletType === "walletConnect"
    ) {
      this.initContract();
    }

    // update statistics data:
    this.loadStatisticsData();
  }

  ngOnDestroy() {
    this.initSubscription.unsubscribe();
  }

  async loadStatisticsData() {
    this.updatePriceChart(0);
    this.updateSupplyChart(0);
  }

  private initProvideLiquidityForm() {
    // translate errorMessages:
    this.translate
      .get([
        this.lPValidationMessages.busdAmount[0].message,
        this.lPValidationMessages.busdAmount[1].message,
        this.lPValidationMessages.busdAmount[2].message,
      ])
      .toPromise()
      .then((translations) => {
        this.lPValidationMessages.busdAmount[0].message =
          translations[this.lPValidationMessages.busdAmount[0].message];
        this.lPValidationMessages.busdAmount[1].message =
          translations[this.lPValidationMessages.busdAmount[1].message];
        this.lPValidationMessages.busdAmount[2].message =
          translations[this.lPValidationMessages.busdAmount[2].message];
      });
    this.provideLiquidityForm = this.formBuilder.group({
      busdAmount: new FormControl(
        "",
        Validators.compose([
          Validators.required,
          Validators.min(100),
          Validators.max(500000),
        ])
      ),
    });
  }

  initBPDCheckerForm() {
    this.addBPDaddressForm = this.formBuilder.group({
      addressToAdd: new FormControl(
        "",
        Validators.compose([Validators.required])
      ),
      addressName: new FormControl("", Validators.compose([])),
    });
  }

  initSwapForms() {
    this.translate
      .get([
        this.swapValidationMessages.busdAmount[0].message,
        this.swapValidationMessages.busdAmount[1].message,
        this.swapValidationMessages.rexAmount[0].message,
        this.swapValidationMessages.rexAmount[1].message,
      ])
      .toPromise()
      .then((translations) => {
        this.swapValidationMessages.busdAmount[0].message =
          translations[this.swapValidationMessages.busdAmount[0].message];
        this.swapValidationMessages.busdAmount[1].message =
          translations[this.swapValidationMessages.busdAmount[1].message];
        this.swapValidationMessages.rexAmount[0].message =
          translations[this.swapValidationMessages.rexAmount[0].message];
        this.swapValidationMessages.rexAmount[1].message =
          translations[this.swapValidationMessages.rexAmount[1].message];
      });
    this.formBusdRex = this.formBuilder.group({
      busdAmount: new FormControl(
        "",
        Validators.compose([Validators.required, Validators.max(999)])
      ),
    });
    this.formRexBusd = this.formBuilder.group({
      rexAmount: new FormControl("", Validators.compose([Validators.required])),
    });
  }

  resetAllClaimableValues() {
    this.somethingIsClaimable = false;
    this.lotterieWinClaimable = false;
    this.claimableBUSDFromBPD = 0;
    this.claimableBUSDFromReferrals = 0;
    this.claimableBUSDFromTreasury = 0;
    this.claimableRexFromAIRDROP = 0;
    this.claimableRexFromAuctions = 0;
    this.claimableRexFromReferrals = 0;
    this.claimableTrexAirdrop = 0;
    this.possibleLotteryClaim = 0;
  }

  async loadCurrentValuesFromContract() {
    console.log("loadCurrentValuesFromContract called");
    this.glbC.showLoader(true);
    const promises: Promise<any>[] = [];
    await this.contractService.loadAccountInfo();
    this.glbC.currRDay = await this.contractService.getCurrentRexDay();
    console.log("getCurrentRexDay currRxDay: ", this.glbC.currRDay);
    this.initProvideLiquidityForm();
    this.initSwapForms();
    this.initBPDCheckerForm();

    promises.push(
      new Promise((res: any) => {
        this.contractService.getMyTotalStakedREX().then((result) => {
          // const staked = result;
          this.ngZone.run(() => {
            this.rexStaked = result;
            res();
          });
        });
      })
    );
    if (this.glbC.currRDay === 0) {
      promises.push(
        new Promise((res: any) => {
          this.contractService.getTotalLpTokens().then((result) => {
            this.initialLpTokens = result;
            res();
          });
        })
      );
    } else {
      promises.push(
        new Promise((res: any) => {
          this.contractService.getINITIAL_LIQ_BUSD().then((result) => {
            this.totalLpTokens = result;
            res();
          });
        })
      );
    }

    promises.push(
      new Promise((res: any) => {
        this.contractService.getLiquidityBalances().then((lBalance) => {
          this.liquidityBalance = lBalance;
          res();
        });
      })
    );

    promises.push(
      new Promise((res: any) => {
        this.contractService.getLiquidityDrawn().then((result) => {
          this.liquidityDrawn = result;
          res();
        });
      })
    );
    promises.push(
      new Promise((res: any) => {
        this.contractService.getRexAmount().then((result) => {
          this.rexAmount = result;
          res();
        });
      })
    );
    promises.push(this.loadTrexDexValues());
    promises.push(this.loadClaimables());
    promises.push(this.loadTrexDexValues());
    promises.push(this.loadBPDsFromListName());

    await Promise.all(promises).then(() => {
      this.refreshAirdropBonus();
      console.log(this.glbC.account);
      if (this.glbC.account && this.glbC.account.balances) {
        if (this.glbC.account.balances.MREX.display) {
          this.mrexAmount = parseInt(
            this.glbC.account.balances.MREX.display,
            10
          );
        } else {
          this.mrexAmount = 0;
        }
        if (this.glbC.account.balances.TREX.display) {
          this.trexAmount = parseInt(
            this.glbC.account.balances.TREX.display,
            10
          );
        } else {
          this.trexAmount = 0;
        }
        if (this.glbC.account.balances.BUSD.display) {
          this.busdAmountInWallet = this.glbC.account.balances.BUSD.wei;
          console.log("BUSD amount:", this.busdAmountInWallet);
        } else {
          this.busdAmountInWallet = 0;
        }
        if (this.glbC.account.balances.REXPAIR.display) {
          this.rexPairAmount = parseInt(
            this.glbC.account.balances.REXPAIR.display,
            10
          );
        } else {
          this.rexPairAmount = 0;
        }
      }
      this.everythingIsLoaded = true;
      this.glbC.showLoader(false);
    });
  }

  loadClaimables() {
    if (this.glbC.currRDay <= 1) {
      this.contractService
        .getClaimableAirdropAmount()
        .then((claimableRexFromAIRDROP) => {
          this.claimableRexFromAIRDROP = claimableRexFromAIRDROP;
          this.ngZone.run(() => {
            if (
              this.claimableRexFromAIRDROP &&
              this.claimableRexFromAIRDROP > 0
            ) {
              this.somethingIsClaimable = true;
            }
          });
        });
    }
    return new Promise((resolve: any) => {
      const promises = [];
      // LOAD CLAIMABLES:
      this.resetAllClaimableValues();
      this.somethingIsClaimable = false;
      promises.push(
        new Promise((res: any) => {
          this.contractService
            .addressCanClaimNowAirdrop()
            .then((canClaimNow) => {
              if (!canClaimNow) {
                res();
              } else {
                this.contractService
                  .addressHasClaimedAIR()
                  .then((hasClaimedRexAirdrop) => {
                    this.hasClaimedRexAirdrop = hasClaimedRexAirdrop;
                    if (!this.hasClaimedRexAirdrop) {
                      this.contractService
                        .getClaimableAirdropAmount()
                        .then((claimableRexFromAIRDROP) => {
                          this.calculateLongerPaysBetterFactor(0, "airdrop");
                          this.claimableRexFromAIRDROP =
                            claimableRexFromAIRDROP;
                          this.ngZone.run(() => {
                            if (
                              this.claimableRexFromAIRDROP &&
                              this.claimableRexFromAIRDROP > 0
                            ) {
                              this.somethingIsClaimable = true;
                            }

                            res();
                          });
                        });
                    } else {
                      res();
                    }
                  });
              }
            });
        })
      );
      promises.push(
        new Promise((res: any) => {
          this.contractService.getCanClaimTrex().then((trexAirClaimable) => {
            this.canClaimTrexAirdropNow = trexAirClaimable;
            if (this.canClaimTrexAirdropNow) {
              this.contractService
                .getAirdropBalances()
                .then((claimableTrexAirdrop) => {
                  this.ngZone.run(() => {
                    this.claimableTrexAirdrop = claimableTrexAirdrop;
                    if (this.claimableTrexAirdrop) {
                      this.somethingIsClaimable = true;
                    }
                    console.log(
                      "this.claimableTrexAirdrop: ",
                      this.claimableTrexAirdrop
                    );
                    res();
                  });
                });
            } else {
              res();
            }
            console.log(
              "this.canClaimTrexAirdropNow: ",
              this.canClaimTrexAirdropNow
            );
          });
        })
      );
      promises.push(
        new Promise((res: any) => {
          const testObj = this.contractService.getClaimableRexAuctions();
          testObj.then((result) => {
            this.claimableRexFromAuctions = Number(result);
            if (this.claimableRexFromAuctions) {
              this.calculateLongerPaysBetterFactor(0, "auction");
              this.ngZone.run(() => {
                this.somethingIsClaimable = true;
              });
            }
            console.log(
              "this.claimableRexFromAuctions: ",
              this.claimableRexFromAuctions
            );
            res();
          });
        })
      );
      promises.push(
        new Promise((res: any) => {
          this.contractService.getClaimableRexReferrals().then((result) => {
            this.claimableRexFromReferrals = Number(result);
            if (this.claimableRexFromReferrals) {
              this.ngZone.run(() => {
                this.somethingIsClaimable = true;
              });
            }
            console.log(
              "this.claimableRexFromReferrals: ",
              this.claimableRexFromReferrals
            );
            res();
          });
        })
      );
      promises.push(
        new Promise((res: any) => {
          this.contractService.getClaimableBUSDReferrals().then((result) => {
            console.warn(
              "TO BE TESTED getClaimableBUSDReferrals result: ",
              result
            );
            this.claimableBUSDFromReferrals = Number(result);
            if (this.claimableBUSDFromReferrals) {
              this.ngZone.run(() => {
                this.somethingIsClaimable = true;
              });
            }
            console.log(
              "this.claimableBUSDFromReferrals: ",
              this.claimableBUSDFromReferrals
            );
            res();
          });
        })
      );
      promises.push(
        new Promise((res: any) => {
          this.contractService.getClaimableBUSDBPD().then((result) => {
            console.warn("TO BE TESTED getClaimableBUSDBPD result: ", result);
            this.claimableBUSDFromBPD = Number(result);
            if (this.claimableBUSDFromBPD) {
              this.ngZone.run(() => {
                this.somethingIsClaimable = true;
              });
            }
            console.log(
              "this.claimableBUSDFromBPD: ",
              this.claimableBUSDFromBPD
            );
            res();
          });
        })
      );
      if (
        this.glbC.currRDay >= this.glbC.treasuryClaimDay &&
        this.glbC.currRDay < this.glbC.lastContractDay
      ) {
        promises.push(
          new Promise((res: any) => {
            this.contractService.getClaimableBUSDTre().then((result) => {
              console.warn("TO BE TESTED getClaimableBUSDTre result: ", result);
              this.claimableBUSDFromTreasury = Number(result);
              if (this.claimableBUSDFromTreasury) {
                this.ngZone.run(() => {
                  this.somethingIsClaimable = true;
                });
              }
              console.log(
                "this.claimableBUSDFromTreasury: ",
                this.claimableBUSDFromTreasury
              );
              res();
            });
          })
        );
      }
      promises.push(
        new Promise((res: any) => {
          this.contractService.getWithdrawableLPTokens().then((wLPTokens) => {
            this.withdrawableLPTokens = Number(wLPTokens);
            if (this.withdrawableLPTokens) {
              this.ngZone.run(() => {
                this.somethingIsClaimable = true;
              });
            }
            console.log(
              "this.withdrawableLPTokens: ",
              this.withdrawableLPTokens
            );
            res();
          });
        })
      );
      promises.push(
        new Promise((res: any) => {
          this.contractService
            .getClaimableCompensationBUSD()
            .then((claimableCompBUSD) => {
              this.claimableCompBUSD = Number(claimableCompBUSD);
              if (this.claimableCompBUSD) {
                this.ngZone.run(() => {
                  this.somethingIsClaimable = true;
                });
              }
              console.log("this.claimableCompBUSD: ", this.claimableCompBUSD);
              res();
            });
        })
      );
      promises.push(
        new Promise((res: any) => {
          this.contractService
            .getClaimedCompensationBUSD()
            .then((alreadyClaimedCompBUSD) => {
              this.alreadyClaimedCompBUSD = Number(alreadyClaimedCompBUSD);
              console.log(
                "this.alreadyClaimedCompBUSD: ",
                this.alreadyClaimedCompBUSD
              );
              res();
            });
        })
      );
      promises.push(
        new Promise((res: any) => {
          this.contractService
            .getTotalPossibleCompensationBUSD()
            .then((totalPossibleCompBUSD) => {
              this.totalPossibleCompBUSD = Number(totalPossibleCompBUSD);
              console.log(
                "this.totalPossibleCompBUSD: ",
                this.totalPossibleCompBUSD
              );
              res();
            });
        })
      );
      promises.push(
        new Promise((res: any) => {
          this.contractService
            .getPersonalBusdClaim()
            .then((possibleLotteryClaim) => {
              this.possibleLotteryClaim = Number(possibleLotteryClaim);
              console.log(
                "this.possibleLotteryClaim: ",
                this.possibleLotteryClaim
              );

              if (Number(this.possibleLotteryClaim) > 0) {
                this.ngZone.run(() => {
                  this.lotterieWinClaimable = true;
                  this.possibleLotteryClaim = this.possibleLotteryClaim;
                });
              }
              res();
            });
        })
      );
      Promise.all(promises).then(() => {
        resolve();
      });
    });
  }
  /*
  addBPDaddress(forceAdd: boolean = false) {
    let doNotAdd: boolean = false;
    if (this.addBPDaddressForm.valid) {
      const addressToAdd = this.bpdAddressToAdd;
      const addressNameToAdd = this.bpdAddressNameToAdd;
      console.log(addressToAdd);
      console.log(addressNameToAdd);
      if (this.glbC.cookieService.get("bpdList")) {
        const addressResult = this.glbC.cookieService.get("bpdList");
        this.listOfBPDaddresses = JSON.parse(addressResult);
        console.log(this.listOfBPDaddresses);
        if (this.listOfBPDaddresses.includes(addressToAdd)) {
          console.log("address already exists");
          doNotAdd = true;
          this.glbC.showPopupMessage({
            title: this.translate.instant("POPUPS.not_valid_address_title"),
            msg: `<p>${this.translate.instant("POPUPS.address_already_exists", {
              address: addressToAdd,
            })}</p>`,
            acceptButtonText: this.translate.instant("BUTTONS.ok"),
            removeOtherPopups: true,
            onAcceptCallback: () => {
              console.log("abort adding");
              return false;
            },
          });
        }
      }
      console.log(doNotAdd);
      if (
        (this.contractService.isValidEthAddress(addressToAdd) || forceAdd) &&
        !doNotAdd
      ) {
        console.log("Begin Add BPD address, no bpd cookie before");
        let currentBPDlist;
        let completeStringToAdd: string;
        console.log(addressToAdd);
        this.addBPDaddressForm.patchValue({
          addressToAdd: "",
        });

        // Get current stored addresses and add new one
        if (this.glbC.cookieService.get("bpdList")) {
          currentBPDlist = this.glbC.cookieService.get("bpdList");
          console.log("Current bpd list: ", currentBPDlist);
          currentBPDlist = currentBPDlist.slice(0, -1);
          console.log(currentBPDlist);
          if (currentBPDlist === "[") {
            completeStringToAdd = `${currentBPDlist}"${addressToAdd}"]`;
          } else {
            completeStringToAdd = `${currentBPDlist}, "${addressToAdd}"]`;
          }
        } else {
          completeStringToAdd = `["${addressToAdd}"]`;
        }
        this.glbC.cookieService.set(`bpdList`, completeStringToAdd, {
          expires: 200,
          path: "/",
        });
        this.loadBPDsFromList(true);
      } else if (!doNotAdd) {
        this.glbC.showPopupMessage({
          title: this.translate.instant("POPUPS.not_valid_address_title"),
          msg: `<p>${this.translate.instant(
            "POPUPS.not_valid_address_template",
            {
              address: addressToAdd,
            }
          )}</p>`,
          declineButtonText: this.translate.instant("BUTTONS.edit_address"),
          acceptButtonText: this.translate.instant("BUTTONS.add_address"),
          removeOtherPopups: true,
          onAcceptCallback: () => {
            this.addBPDaddress(true);
          },
        });
      }
      doNotAdd = false;
    } else {
      console.log("is not valid");
    }
  } */

  loadIframe() {
    this.glbC.showPelerinBox = true;
    setInterval(() => {
      this.iframeIsLoaded = true;
    }, 3000);
  }

  addBPDaddressWithName() {
    const addressToAdd = this.bpdAddressToAdd;
    const addressNameToAdd = this.bpdAddressNameToAdd;

    if (!this.contractService.isValidEthAddress(addressToAdd)) {
      // If the entered address is not valid
      this.glbC.showPopupMessage({
        title: this.translate.instant("POPUPS.not_valid_address_title"),
        msg: `<p>${this.translate.instant("POPUPS.add_address_not_valid", {
          address: addressToAdd,
        })}</p>`,
        declineButtonText: this.translate.instant("BUTTONS.ok"),
        removeOtherPopups: true,
      });
      return false;
    } else if (this.glbC.cookieService.get("bpdListName")) {
      // If cookie already exists and address is valid
      console.log("bpdListName cookie exists already");
      let currentCookie = this.glbC.cookieService.get("bpdListName");
      let currentCookieParsed = JSON.parse(currentCookie);
      console.log(currentCookieParsed);
      console.log(currentCookie);
      // Check if current list already includes the address
      let addressAlreadyExists: boolean = false;
      for (let i = 0; i < currentCookieParsed.length; i++) {
        if (currentCookieParsed[i][0] === addressToAdd) {
          addressAlreadyExists = true;
        }
      }
      if (addressAlreadyExists) {
        this.glbC.showPopupMessage({
          title: this.translate.instant("POPUPS.not_valid_address_title"),
          msg: `<p>${this.translate.instant("POPUPS.address_already_exists", {
            address: addressToAdd,
          })}</p>`,
          acceptButtonText: this.translate.instant("BUTTONS.ok"),
          removeOtherPopups: true,
          onAcceptCallback: () => {
            console.log("abort adding");
            return false;
          },
        });
      } else {
        console.log("add new cookie");
        console.log(currentCookieParsed.length);
        let completeStringToAdd = "";
        this.addBPDaddressForm.patchValue({
          addressToAdd: "",
        });
        this.addBPDaddressForm.patchValue({
          addressName: "",
        });
        // If current set cookie is only an empty array
        if (currentCookieParsed.length === 0) {
          completeStringToAdd =
            currentCookie.slice(0, -1) +
            '["' +
            addressToAdd +
            '", "' +
            addressNameToAdd +
            '"]]';
        } else {
          completeStringToAdd =
            currentCookie.slice(0, -1) +
            ',["' +
            addressToAdd +
            '", "' +
            addressNameToAdd +
            '"]]';
        }

        this.glbC.cookieService.set(`bpdListName`, completeStringToAdd, {
          expires: 200,
          path: "/",
        });
        this.loadBPDsFromListName();
      }
    } else {
      //If no cookie exists and address is valid
      console.log("Begin Add BPD address with name, no bpd cookie before");
      let completeStringToAdd: string;
      console.log(addressToAdd);
      console.log(addressNameToAdd);
      this.addBPDaddressForm.patchValue({
        addressToAdd: "",
      });
      this.addBPDaddressForm.patchValue({
        addressName: "",
      });
      completeStringToAdd = `[["${addressToAdd}", "${addressNameToAdd}"]]`;
      this.glbC.cookieService.set(`bpdListName`, completeStringToAdd, {
        expires: 200,
        path: "/",
      });
      this.loadBPDsFromListName();
    }
  }

  /*  loadBPDsFromList(dontShowAlert: boolean = false) {
    this.bpdAddressesObjects = [];
    return new Promise((resolve: any, reject: any) => {
      const promises: Promise<any>[] = [];
      if (this.glbC.cookieService.get("bpdList")) {
        const addressResult = this.glbC.cookieService.get("bpdList");
        this.listOfBPDaddresses = JSON.parse(addressResult);
        console.log(this.listOfBPDaddresses);
      }
      for (let i = 0; i < this.listOfBPDaddresses.length; i++) {
        if (
          this.contractService.isValidEthAddress(this.listOfBPDaddresses[i])
        ) {
          promises.push(
            new Promise((res: any, rej: any) => {
              this.contractService
                .getClaimableBUSDBPD(this.listOfBPDaddresses[i])
                .then((result) => {
                  this.bpdAddressesObjects.push({
                    address: this.listOfBPDaddresses[i],
                    claimableAmount: Number(result),
                  });
                  res();
                })
                .catch((error) => {
                  console.log(error);
                  rej();
                });
            })
          );
        } else {
          this.bpdAddressesObjects.push({
            address: this.listOfBPDaddresses[i],
            claimableAmount: 0,
          });
          if (!dontShowAlert) {
            this.glbC.showPopupMessage({
              title: this.translate.instant("POPUPS.not_valid_address_title"),
              msg: `<p>${this.translate.instant(
                "POPUPS.not_valid_address_template",
                {
                  address: this.listOfBPDaddresses[i],
                }
              )}</p>`,
              declineButtonText: this.translate.instant("BUTTONS.keep_address"),
              acceptButtonText: this.translate.instant(
                "BUTTONS.delete_address"
              ),
              removeOtherPopups: true,
            });
          }

          console.log("not valid");
        }
      }
      Promise.all(promises)
        .then(() => {
          console.log(this.bpdAddressesObjects);
          resolve();
        })
        .catch((error) => {
          console.log(error);
          reject();
        });
    });
  }
 */
  loadBPDsFromListName(dontShowAlert: boolean = false) {
    this.listOfBPDaddresses = [];
    this.bpdAddressesObjects = [];
    return new Promise((resolve: any, reject: any) => {
      const promises: Promise<any>[] = [];
      if (this.glbC.cookieService.get("bpdListName")) {
        console.log("cookie with name exists");
        const currentCookie = this.glbC.cookieService.get("bpdListName");
        const currentCookieParsed = JSON.parse(currentCookie);
        for (let i = 0; i < currentCookieParsed.length; i++) {
          this.listOfBPDaddresses.push([
            currentCookieParsed[i][0],
            currentCookieParsed[i][1],
          ]);
        }
      } else {
        // Cookie without name already exists. Copy the data to the new cookie
        console.log("cookie with name doens't exists");
        if (this.glbC.cookieService.get("bpdList")) {
          let addressResult = this.glbC.cookieService.get("bpdList");
          addressResult = JSON.parse(addressResult);

          let completeStringToAdd = "[";
          for (let i = 0; i < addressResult.length; i++) {
            if (i === addressResult.length - 1) {
              completeStringToAdd += '["' + addressResult[i] + '", ""]';
            } else {
              completeStringToAdd += '["' + addressResult[i] + '", ""],';
            }
          }
          completeStringToAdd += "]";

          this.glbC.cookieService.set(`bpdListName`, completeStringToAdd, {
            expires: 200,
            path: "/",
          });
          this.loadBPDsFromListName();
          resolve();
          return false;
        }
      }
      for (let i = 0; i < this.listOfBPDaddresses.length; i++) {
        if (
          this.contractService.isValidEthAddress(this.listOfBPDaddresses[i][0])
        ) {
          promises.push(
            new Promise((res: any, rej: any) => {
              this.contractService
                .getLotterieBusdClaim(this.listOfBPDaddresses[i][0])
                .then((resultLiq) => {
                  this.bpdAddressesObjects.push({
                    address: this.listOfBPDaddresses[i][0],
                    name: this.listOfBPDaddresses[i][1],
                    possibleLotteryClaim: Number(resultLiq),
                  });
                  res();
                })
                .catch((error) => {
                  console.log(error);
                  rej();
                });
            })
          );
        } else {
          this.bpdAddressesObjects.push({
            address: this.listOfBPDaddresses[i][0],
            name: this.listOfBPDaddresses[i][1],
            claimableAmount: 0,
          });
          if (!dontShowAlert) {
            this.glbC.showPopupMessage({
              title: this.translate.instant("POPUPS.not_valid_address_title"),
              msg: `<p>${this.translate.instant(
                "POPUPS.not_valid_address_template",
                {
                  address: this.listOfBPDaddresses[i][0],
                }
              )}</p>`,
              declineButtonText: this.translate.instant("BUTTONS.keep_address"),
              acceptButtonText: this.translate.instant(
                "BUTTONS.delete_address"
              ),
              removeOtherPopups: true,
              onAcceptCallback: () => {
                this.deleteAddressFromCookieName(this.listOfBPDaddresses[i][0]);
              },
            });
          }

          console.log("not valid");
        }
      }
      Promise.all(promises)
        .then(() => {
          console.log(this.bpdAddressesObjects);
          resolve();
        })
        .catch((error) => {
          console.log(error);
          reject();
        });
    });
  }

  deleteAddressFromCookieName(address: string) {
    console.log("delete address with name", address);

    this.bpdAddressesObjects = this.bpdAddressesObjects.filter(function (el) {
      return el.address != address;
    });

    this.listOfBPDaddresses = this.listOfBPDaddresses.filter(
      (e) => e[0] !== address
    );

    const completeStringToAdd = JSON.stringify(this.listOfBPDaddresses);

    this.glbC.cookieService.set(`bpdListName`, completeStringToAdd, {
      expires: 200,
      path: "/",
    });
    this.loadBPDsFromListName();
  }

  addCookieNameFromTable(event) {
    console.log("add");
    console.log(this.bpdAddressesObjects);
    let currentCookie = this.glbC.cookieService.get("bpdListName");
    let currentCookieParsed = JSON.parse(currentCookie);
    console.log(currentCookieParsed);

    const addressOfName = event.srcElement.attributes[2].value;
    const inputName =
      event.srcElement.previousSibling.childNodes[0].childNodes[0].childNodes[0]
        .value;
    for (let i = 0; i < currentCookieParsed.length; i++) {
      if (currentCookieParsed[i][0] === addressOfName) {
        currentCookieParsed[i][1] = inputName;
      }
    }
    console.log("Change name of " + addressOfName + ". New name: " + inputName);
    const completeStringToAdd = JSON.stringify(currentCookieParsed);
    this.glbC.cookieService.set(`bpdListName`, completeStringToAdd, {
      expires: 200,
      path: "/",
    });
    this.loadBPDsFromListName();
  }

  loadTrexDexValues() {
    return new Promise((resolve: any) => {
      const promises: Promise<any>[] = [];
      promises.push(
        new Promise((res: any) => {
          this.contractService
            .getSoldTrex()
            .then((result) => {
              this.soldTREX = result;
            })
            .catch((err) => {
              console.error("contractService.getSoldTrex error: ", err);
            });
          res();
        })
      );
      promises.push(
        new Promise((res: any) => {
          this.contractService.getNoOfTREXSellable().then((result) => {
            this.noOfTREXSellable = result;
          });
          res();
        })
      );
      promises.push(
        new Promise((res: any) => {
          this.contractService.getAirdropBalancesLeft().then((result) => {
            this.trexAirBalLeft = result;
          });
          res();
        })
      );
      promises.push(
        new Promise((res: any) => {
          this.contractService.getTrexPrice().then((trexPrice) => {
            this.trexPrice = new BigNumber(trexPrice);
            this.contractService.getBusdInTrex().then((busdInTrex) => {
              this.busdInTrex = new BigNumber(busdInTrex);
              this.isLiquidTrexDex = this.busdInTrex.gte(this.trexPrice);
            });
          });
          res();
        })
      );
      Promise.all(promises).then(() => {
        resolve();
      });
    });
  }

  buyOneTrex() {
    this.sendAuctionTrexProgress = true;
    let priceT = new BigNumber(this.trexPrice);
    const priceTNew = priceT.times(10 ** 18);
    if (priceT.lt(500000000000000000)) {
      priceT = priceTNew;
    }
    this.contractService
      .buyTrex(priceT)
      .then(() => {
        this.loadCurrentValuesFromContract().then(() => {
          this.sendAuctionTrexProgress = false;
        });
      })
      .catch(() => {
        this.sendAuctionTrexProgress = false;
      });
  }

  sellATrex() {
    this.sellTrexProgress = true;
    this.contractService
      .sellAirTrex()
      .then(() => {
        this.loadCurrentValuesFromContract().then(() => {
          this.sellTrexProgress = false;
        });
      })
      .catch(() => {
        this.sellTrexProgress = false;
      });
  }

  lpBUSDInputChanged(event) {
    console.log("lpBUSDInputChanged event: ", event);
    this.provideLiquidityForm.patchValue({
      busdAmount: event.target.value,
    });
  }

  formInputChanged(event: any, formType: string) {
    switch (formType) {
      case "liquidity":
        console.log("lpBUSDInputChanged event: ", event);
        this.provideLiquidityForm.patchValue({
          busdAmount: event.target.value,
        });
        break;
      case "busdRex":
        console.log("busdRex event: ", event.target.value);
        this.busdAmountToSwap = event.target.value;
        this.formBusdRex.patchValue({
          busdAmount: event.target.value,
        });
        break;
      case "rexBusd":
        console.log("rexBusd event: ", event.target.value);
        this.rexAmountToSwap = event.target.value;
        this.formRexBusd.patchValue({
          rexAmount: event.target.value,
        });
        break;
      case "bpdAddress":
        console.log("bpdAddress event: ", event.target.value);
        this.bpdAddressToAdd = event.target.value;
        this.addBPDaddressForm.patchValue({
          addressToAdd: event.target.value,
        });
        break;
      case "bpdAddressName":
        console.log("bpdAddressName event: ", event.target.value);
        this.bpdAddressNameToAdd = event.target.value;
        this.addBPDaddressForm.patchValue({
          addressName: event.target.value,
        });
        break;
      default:
        break;
    }
  }

  formDropdownSelect(event: any, formType: string) {
    console.log(event);
    switch (formType) {
      case "busdRex":
        console.log("Value:", event.target.value);
        this.busdAmountToSwap = event.target.value;
        this.formBusdRex.patchValue({
          busdAmount: event.target.value,
        });
        break;
      case "rexBusd":
        console.log("Value:", event.target.value);
        this.rexAmountToSwap = event.target.value;
        this.formRexBusd.patchValue({
          rexAmount: event.target.value,
        });
        break;
      default:
        break;
    }
  }

  rexBusdInputChanged(event) {
    console.log(event);
  }

  busdRexInputChanged(event) {
    console.log(event);
  }

  swapRexToBusd() {
    if (this.formRexBusd.valid) {
      console.log(
        "Begin REX/BUSD Swap. Amount of REX to swap:",
        this.formRexBusd.value.rexAmount
      );
      this.swapRexBusdProgress = true;
      const rexSwapAmount = this.formRexBusd.value.rexAmount;

      this.contractService
        .swapForBusdTest(rexSwapAmount)
        .then(() => {
          this.loadCurrentValuesFromContract().then(() => {
            console.log("rex balance updated");
            this.swapRexBusdProgress = false;
            this.formRexBusd.patchValue({
              rexAmount: "0.0",
            });
            this.rexAmountToSwap = 0;
          });
        })
        .catch((error) => {
          console.log("here is the error");
          console.log(error);
          this.swapRexBusdProgress = false;
        });
    } else {
      console.log("form is not valid");
      const errorMessages = [];
      console.log(this.formRexBusd.controls);
      Object.keys(this.formRexBusd.controls).forEach((key) => {
        const tempErrors: ValidationErrors = this.formRexBusd.get(key).errors;
        if (tempErrors != null) {
          Object.keys(tempErrors).forEach((errorKey) => {
            this.swapValidationMessages[key].forEach((msg) => {
              if (msg.type === errorKey) {
                errorMessages.push(msg.message);
              }
            });
          });
        }
      });
      console.log("errorMessages: ", errorMessages);
      let formattedErrorMessage: string = "";
      errorMessages.forEach((errMsg) => {
        formattedErrorMessage += `<p>${errMsg}</p><br>`;
      });
      this.translate
        .get(["POPUPS.swap_error", "BUTTONS.ok"])
        .toPromise()
        .then((translations) => {
          this.glbC.showPopupMessage({
            title: translations["POPUPS.swap_error"],
            msg: formattedErrorMessage,
            declineButtonText: translations["BUTTONS.ok"],
          });
        });
    }
  }

  swapBusdToRex() {
    if (this.formBusdRex.valid) {
      console.log(
        "Begin BUSD/REX Swap. Amount of BUSD to swap:",
        this.formBusdRex.value.busdAmount
      );
      this.swapBusdRexProgress = true;
      const busdAmountToSwap = this.formBusdRex.value.busdAmount;
      console.log(busdAmountToSwap);

      this.contractService
        .swapForRex(busdAmountToSwap)
        .then(() => {
          this.loadCurrentValuesFromContract().then(() => {
            console.log("rex balance updated");
            this.swapBusdRexProgress = false;
            this.formBusdRex.patchValue({
              busdAmount: "0.0",
            });
            this.busdAmountToSwap = 0;
          });
        })
        .catch((error) => {
          console.log("here is the error");
          console.log(error);
          this.swapBusdRexProgress = false;
        });
    } else {
      console.log("form is not valid");
      const errorMessages = [];
      console.log(this.formBusdRex.controls);
      Object.keys(this.formBusdRex.controls).forEach((key) => {
        const tempErrors: ValidationErrors = this.formBusdRex.get(key).errors;
        if (tempErrors != null) {
          Object.keys(tempErrors).forEach((errorKey) => {
            this.swapValidationMessages[key].forEach((msg) => {
              if (msg.type === errorKey) {
                errorMessages.push(msg.message);
              }
            });
          });
        }
      });
      console.log("errorMessages: ", errorMessages);
      let formattedErrorMessage: string = "";
      errorMessages.forEach((errMsg) => {
        formattedErrorMessage += `<p>${errMsg}</p><br>`;
      });
      this.translate
        .get(["POPUPS.swap_error", "BUTTONS.ok"])
        .toPromise()
        .then((translations) => {
          this.glbC.showPopupMessage({
            title: translations["POPUPS.swap_error"],
            msg: formattedErrorMessage,
            declineButtonText: translations["BUTTONS.ok"],
          });
        });
    }
  }

  provideLiquidityFormSubmit() {
    console.log(this.provideLiquidityForm.value.busdAmount);
    if (this.provideLiquidityForm.valid) {
      console.log("is valid");
      this.sendBUSDToLiquidity(this.provideLiquidityForm.value.busdAmount);
    } else {
      console.log("is not valid");
      const errorMessages = [];
      Object.keys(this.provideLiquidityForm.controls).forEach((key) => {
        console.log(key);
        const tempErrors: ValidationErrors =
          this.provideLiquidityForm.get(key).errors;
        console.log(tempErrors);
        if (tempErrors != null) {
          Object.keys(tempErrors).forEach((errorKey) => {
            this.lPValidationMessages[key].forEach((msg) => {
              if (msg.type === errorKey) {
                errorMessages.push(msg.message);
                console.log(msg.message);
              }
            });
          });
        }
      });
      console.log("errorMessages: ", errorMessages);
      let formattedErrorMessage: string = "";
      errorMessages.forEach((errMsg) => {
        formattedErrorMessage += `<p>${errMsg}</p><br>`;
      });
      /*       formattedErrorMessage += "</ul>"; */
      this.translate
        .get(["POPUPS.provide_liq_error", "BUTTONS.ok"])
        .toPromise()
        .then((translations) => {
          this.glbC.showPopupMessage({
            title: translations["POPUPS.provide_liq_error"],
            msg: formattedErrorMessage,
            declineButtonText: translations["BUTTONS.ok"],
          });
        });
    }
  }

  sendBUSDToLiquidity(amount: number) {
    console.log("sendBUSDToLiquidity called, amount: ", amount);
    if (amount % 100 !== 0) {
      console.log("is not multiple of 100");
      this.translate
        .get([
          "POPUPS.provide_liq_error",
          "POPUPS.liquidity_not_mult_of_100",
          "BUTTONS.ok",
        ])
        .toPromise()
        .then((translations) => {
          this.glbC.showPopupMessage({
            title: translations["POPUPS.provide_liq_error"],
            msg: translations["POPUPS.liquidity_not_mult_of_100"],
            declineButtonText: translations["BUTTONS.ok"],
          });
        });
    } else {
      console.log("is multiple");
      const auct = new BigNumber(amount);
      const auctNew = auct.times(10 ** 18);
      this.sendLiquidityProgress = true;
      this.contractService
        .sendETHToLiquidity(auctNew)
        .then(() => {
          this.loadCurrentValuesFromContract().then(() => {
            this.sendLiquidityProgress = false;
          });

          this.provideLiquidityForm.patchValue({
            busdAmount: null,
          });
        })
        .catch((err) => {
          console.error("contractService.sendETHToLiquidity error: ", err);
          // TODO handle errors
          this.sendLiquidityProgress = false;
        });
    }
  }

  claimBoxBPDClicked() {
    console.log(
      `claimBoxBPDClicked claimableBUSDFromBPD: ${this.claimableBUSDFromBPD}`
    );
    this.claimBUSDFromBPDProgress = true;
    this.contractService
      .withdrawFromAuctionEthBpd()
      .then(() => {
        /*         this.contractService.updateBUSDBalance(true); */
        /*         this.contractService.updateETHBalance(true); */
        this.loadCurrentValuesFromContract().then(() => {
          this.claimBUSDFromBPDProgress = false;
        });
      })
      .catch((err) => {
        console.error("withdrawFromAuctionEthBpd error: ", err);
        this.claimBUSDFromBPDProgress = false;
      });
  }

  claimBUSDCompClicked() {
    console.log(
      `claimBUSDCompClicked claimableBUSDFromComp: ${this.claimableCompBUSD}`
    );
    this.claimClaimableCompBUSD = true;
    this.contractService
      .claimBUSDCOMP()
      .then(() => {
        console.log("is claimed");
        /*         this.contractService.updateBUSDBalance(true); */
        /*         this.contractService.updateETHBalance(true); */
        this.loadCurrentValuesFromContract().then(() => {
          this.claimClaimableCompBUSD = false;
        });
      })
      .catch((err) => {
        console.error("withdrawFromAuctionEthBpd error: ", err);
        this.claimClaimableCompBUSD = false;
      });
  }

  claimBoxRefBUSDClick() {
    console.log(
      `claimBoxRefBUSDClick claimableBUSDFromReferrals: ${this.claimableBUSDFromReferrals}`
    );
    this.claimBUSDFromReferalsProgress = true;
    this.contractService
      .withdrawFromAuctionEthRef()
      .then(() => {
        this.loadCurrentValuesFromContract().then(() => {
          this.claimBUSDFromReferalsProgress = false;
        });
      })
      .catch((err) => {
        console.error("withdrawFromAuctionEthRef error: ", err);
        this.claimBUSDFromReferalsProgress = false;
      });
  }

  claimBoxRefRexClick() {
    console.log(
      `claimBoxRefRexClick claimableRexFromReferrals: ${this.claimableRexFromReferrals}`
    );
    this.claimRexFromReferralsProgress = true;
    this.contractService
      .withdrawFromAuctionRexRef()
      .then(() => {
        this.loadCurrentValuesFromContract().then(() => {
          this.claimRexFromReferralsProgress = false;
        });
      })
      .catch((err) => {
        console.error("withdrawFromAuctionRexRef error: ", err);
        this.claimRexFromReferralsProgress = false;
      });
  }

  claimAuctionREXClick(clickType: string) {
    this.claimRexFromAuctionProgress = true;
    console.log(
      `claimBoxAuctionREXClick claimableRexFromAuctions: ${this.claimableRexFromAuctions}`
    );
    if (
      clickType === "liquid" ||
      (clickType === "stake" && this.auctionStakeDuration < 222)
    ) {
      this.glbC.showPopupMessage({
        title: this.translate.instant("POPUPS.losing_eligibility_title"),
        msg: `<p>${this.translate.instant(
          "POPUPS.losing_eligibility_template"
        )}</p>`,
        declineButtonText: this.translate.instant("BUTTONS.abort"),
        acceptButtonText: this.translate.instant("BUTTONS.continue"),
        onAcceptCallback: () => {
          this.actuallyClaimAuctionREX(clickType);
        },
        onCancelCallback: () => {
          this.claimRexFromAuctionProgress = false;
        },
      });
    } else {
      this.actuallyClaimAuctionREX(clickType);
    }
  }

  actuallyClaimAuctionREX(claimType: string) {
    if (this.glbC.currRDay <= LAST_CLAIM_DAY) {
      if (claimType === "stake") {
        console.warn("claim as stake days: ", this.auctionStakeDuration);
        this.contractService
          .withdrawFromAuctionRexAucS(Number(this.auctionStakeDuration))
          .then(() => {
            this.loadCurrentValuesFromContract().then(() => {
              this.claimRexFromAuctionProgress = false;
            });
          })
          .catch((err) => {
            console.error("withdrawFromAuctionRexAucS error: ", err);
            this.claimRexFromAuctionProgress = false;
          });
      } else if (claimType === "liquid") {
        console.warn("claim as liquid");
        this.contractService
          .withdrawFromAuctionRexAuc()
          .then(() => {
            this.loadCurrentValuesFromContract().then(() => {
              this.claimRexFromAuctionProgress = false;
            });
          })
          .catch((err) => {
            console.error("withdrawFromAuctionRexAuc error: ", err);
            this.claimRexFromAuctionProgress = false;
          });
      }
    } else {
      // TODO what to do if claim phase is over
    }
  }

  claimBoxBUSDFromTreasury() {
    console.log(
      `claimBoxBUSDFromTreasury claimableBUSDFromTreasury: ${this.claimableBUSDFromTreasury}`
    );
    this.claimBUSDFromTreasuryProgress = true;
    this.contractService
      .withdrawFromAuctionEthTre()
      .then(() => {
        this.loadCurrentValuesFromContract().then(() => {
          this.claimBUSDFromTreasuryProgress = false;
        });
      })
      .catch(() => {
        this.claimBUSDFromTreasuryProgress = false;
      });
  }

  claimLiqquideryWins() {
    console.log(
      "Withdraw Liqqquidery Wins. Amount: " + this.possibleLotteryClaim
    );
    this.claimLiqquideryProgress = true;
    this.contractService
      .claimCashbackBusdLotterie()
      .then(() => {
        this.loadCurrentValuesFromContract().then(() => {
          console.log("Succes claiming Liqquidery Wins:");
          this.claimLiqquideryProgress = false;
        });
      })
      .catch((error) => {
        console.log("Error in claiming Liqquidery Wins: ", error);
        this.claimLiqquideryProgress = false;
      });
  }

  claimTrexAirdropClicked() {
    this.claimTrexAirdropProgress = true;
    this.contractService
      .claimTrex()
      .then(() => {
        this.loadCurrentValuesFromContract().then(() => {
          this.claimTrexAirdropProgress = false;
        });
      })
      .catch((err) => {
        console.error("claimTrex error: ", err);
        this.claimTrexAirdropProgress = false;
      });
  }

  claimRexAidropDayStakeChanged(stakeDuration: number) {
    console.log("claimRexAidropDayStakeChanged stakeDuration: ", stakeDuration);
    if (stakeDuration >= 30 && stakeDuration <= this.glbC.maxStakeDuration) {
      // claim as stake
      this.airDropClaimStatus = "stake";
      this.airDropStakeDuration = Number(stakeDuration);
    } else {
      // invalid
      this.airDropClaimStatus = "invalid";
      this.airDropStakeDuration = Number(stakeDuration);
    }
    this.refreshAirdropBonus();
    this.calculateLongerPaysBetterFactor(stakeDuration, "airdrop");
  }

  claimRexAuctionDayStakeChanged(stakeDuration: number) {
    console.log(
      "claimRexAuctionDayStakeChanged stakeDuration: ",
      stakeDuration
    );
    if (stakeDuration >= 7 && stakeDuration <= this.glbC.maxStakeDuration) {
      // claim as stake
      this.auctionClaimStatus = "stake";
      this.auctionStakeDuration = Number(stakeDuration);
    } else {
      // invalid
      this.auctionClaimStatus = "invalid";
      this.auctionStakeDuration = Number(stakeDuration);
    }
    this.calculateLongerPaysBetterFactor(stakeDuration, "auction");
  }

  claimRexAirdropClicked(clickType: string) {
    console.log(clickType);
    this.claimRexFromAIRDROPProgress = true;
    console.log("this.airDropStakeDuration: ", this.airDropStakeDuration);
    console.log("this.glbC.account: ", this.glbC.account);
    if (this.airDropStakeDuration && clickType === "stake") {
      // claim as stake
      this.contractService
        .claimAIRTokensStake(this.airDropStakeDuration)
        .then(() => {
          this.loadClaimables().then(() => {
            this.claimableRexFromAIRDROP = 0;
          });
        })
        .catch((err) => {
          console.error("claimRexStake error: ", err);
        })
        .finally(() => {
          this.ngZone.run(() => {
            this.claimRexFromAIRDROPProgress = false;
          });
        });
    } else {
      // claim as liquidity
      this.contractService
        .claimAIRTokens()
        .then(() => {
          this.claimableRexFromAIRDROP = 0;
        })
        .catch((err) => {
          console.error("claimTrex error: ", err);
        })
        .finally(() => {
          this.ngZone.run(() => {
            this.claimRexFromAIRDROPProgress = false;
          });
        });
    }
  }

  claimRexAuctionClicked(clickType: string) {
    console.log(clickType);
    this.claimRexFromAIRDROPProgress = true;
    console.log("this.airDropStakeDuration: ", this.airDropStakeDuration);
    console.log("this.glbC.account: ", this.glbC.account);
    if (this.airDropStakeDuration && clickType === "stake") {
      // claim as stake
      this.contractService
        .claimAIRTokensStake(this.airDropStakeDuration)
        .then(() => {
          this.loadClaimables().then(() => {
            this.claimableRexFromAIRDROP = 0;
          });
        })
        .catch((err) => {
          console.error("claimRexStake error: ", err);
        })
        .finally(() => {
          this.ngZone.run(() => {
            this.claimRexFromAIRDROPProgress = false;
          });
        });
    } else {
      // claim as liquidity
      this.contractService
        .claimAIRTokens()
        .then(() => {
          this.claimableRexFromAIRDROP = 0;
        })
        .catch((err) => {
          console.error("claimTrex error: ", err);
        })
        .finally(() => {
          this.ngZone.run(() => {
            this.claimRexFromAIRDROPProgress = false;
          });
        });
    }
  }

  withdrawLPTokensRD() {
    console.log("wiithdraw token");
    this.withdrawableLPTokensProgress = true;
    this.contractService
      .withdrawLPTokensRDA()
      .then(() => {
        console.log("Success in LP prodving");
        this.contractService.updateREXBalance(true);
        this.contractService.updateLPBalance(true).then(() => {
          this.withdrawableLPTokens = 0;
          this.loadClaimables().then(() => {
            this.withdrawableLPTokensProgress = false;
          });
        });
      })
      .catch((error) => {
        console.warn("Error in withdraw LP token:", error);
        this.withdrawableLPTokensProgress = false;
      });
  }

  public addToken(type: string) {
    this.contractService.addTokenToMetamask(type);
  }

  getREXAddress() {
    return this.contractService.rexAddress();
  }

  getTREXAddress() {
    return this.contractService.trexAddress();
  }

  getMREXAddress() {
    return this.contractService.mrexAddress();
  }

  getBUSDAddress() {
    return this.contractService.busdAddress();
  }

  getRDAAddress() {
    return this.contractService.rdaAddress();
  }

  getPAIRAddress() {
    return this.contractService.pairAddress();
  }

  getAIRDROPAddress() {
    return this.contractService.airAddress();
  }

  getDEXAddress() {
    return this.contractService.dexAddress();
  }

  getDEVFUNDAddress() {
    return this.contractService.devFundAddress();
  }

  getCOMPAddress() {
    return this.contractService.compensationAddress();
  }

  getROUTERAddress() {
    return this.contractService.routerAddress();
  }

  updateSupplyChart(timeNum: number) {
    this.httpService.updateChart(
      this.httpService.DBglobals,
      "timestamp",
      "totalSupply",
      0,
      0,
      timeNum,
      this.supplyChart
    );
  }

  updatePriceChart(timeNum: number) {
    this.httpService.updateChart(
      this.httpService.DBprice,
      "timestamp",
      "REXtoUSD",
      3,
      0,
      timeNum,
      this.priceLineChart
    );
  }

  /*   swapCoinClicked(type: string) {
    if (type === "rex_busd") {
      this.glbC.openPCSSwap(
        this.contractService.rexAddress(),
        this.contractService.busdAddress()
      );
    } else if (type === "busd_rex") {
      this.glbC.openPCSSwap(
        this.contractService.busdAddress(),
        this.contractService.rexAddress()
      );
    }
  } */

  /* refreshAuctionBonus() {
    console.log("recalculate auction bonus");
    const tmpAmount = new BigNumber(100);



    if (this.auctionStakeDuration < 7) {
      this.rexAuctionBonus = 0;
      this.totalClaimableAirdrop = this.claimableRexFromAIRDROP;
      return;
    }
    const PercentMoreX1E16 = new BigNumber(
      tmpStakingDaysCapped.times(701754385964912).plus(78947368421100000)
    );
    const amount = new BigNumber(
      tmpAmount.plus(
        tmpAmount
          .times(PercentMoreX1E16)
          .div(100)
          .div(10 ** 16)
      )
    );
    const result = new BigNumber(Math.round(amount.toNumber()));
    const corrected = result.minus(100);
    if (Number.isNaN(corrected.toNumber())) {
      this.rexAirdropBonus = 0;
      this.totalClaimableAirdrop = this.claimableRexFromAIRDROP;
      return;
    }
    // calculate total airdrop
    const tmpMulti = corrected.div(100).plus(1);
    this.totalClaimableAirdrop = tmpMulti.times(
      this.claimableRexFromAIRDROP,
      10
    );
    this.rexAirdropBonus = corrected;
    // return corrected;
  } */

  private calculateLongerPaysBetterFactor(days: number, claimType: string) {
    const stakeDuration: number = days;
    let lpbFactor: any = 1;
    let minStakeDuration: number = 7;
    if (claimType === "airdrop") {
      minStakeDuration = 30;
    }

    let tmpDays: number = 0;
    if (stakeDuration <= 365) {
      tmpDays = stakeDuration;
    } else if (stakeDuration <= 3653) {
      for (let j = 0; j < Math.floor(stakeDuration / 365); j++) {
        tmpDays = Number(tmpDays) + Number(stakeDuration) - j * 365;
        console.log(`tmpDays(${j}): ${tmpDays}`);
      }
      tmpDays += stakeDuration - Math.floor(stakeDuration / 365) * 365;
    }
    if (stakeDuration >= minStakeDuration && stakeDuration <= 3653) {
      lpbFactor = (10000000000 + (tmpDays * 10000000000) / 7300) / 10000000000;
    } else {
      lpbFactor = 1;
    }

    console.log(lpbFactor);
    if (claimType === "auction") {
      this.rexAuctionBonus = lpbFactor.toFixed(5);
    } else if (claimType === "airdrop") {
      this.rexAirdropBonusLPD = lpbFactor.toFixed(5);
    }
  }

  refreshAirdropBonus() {
    console.log("recalculate airdrop bonus");
    const tmpAmount = new BigNumber(100);

    // BONUS for staking duration: 30 days: +10% - linear rising to: 600 days + 50%
    // y = m*x+b; where M = 0.0701754385964912 and B = 7.89473684211
    // cap BONUS at 600 days
    const tmpStakingDaysCapped = new BigNumber(
      this.airDropStakeDuration > 600 ? 600 : this.airDropStakeDuration
    );
    console.log(tmpStakingDaysCapped);
    if (tmpStakingDaysCapped.lt(30)) {
      this.rexAirdropBonus = 0;
      this.totalClaimableAirdrop = this.claimableRexFromAIRDROP;
      return;
    }
    const PercentMoreX1E16 = new BigNumber(
      tmpStakingDaysCapped.times(701754385964912).plus(78947368421100000)
    );
    const amount = new BigNumber(
      tmpAmount.plus(
        tmpAmount
          .times(PercentMoreX1E16)
          .div(100)
          .div(10 ** 16)
      )
    );
    const result = new BigNumber(Math.round(amount.toNumber()));
    const corrected = result.minus(100);
    if (Number.isNaN(corrected.toNumber())) {
      this.rexAirdropBonus = 0;
      this.totalClaimableAirdrop = this.claimableRexFromAIRDROP;
      return;
    }
    // calculate total airdrop
    const tmpMulti = corrected.div(100).plus(1);
    this.totalClaimableAirdrop = tmpMulti.times(
      this.claimableRexFromAIRDROP,
      10
    );
    this.rexAirdropBonus = corrected;
    // return corrected;
  }

  resetProgresses() {
    this.sellTrexProgress = false;
    this.sendLiquidityProgress = false;
    this.sendAuctionTrexProgress = false;
    this.claimTrexAirdropProgress = false;
    this.claimRexFromAuctionProgress = false;
    this.claimBUSDFromBPDProgress = false;
    this.withdrawableLPTokensProgress = false;
    this.claimBUSDFromReferalsProgress = false;
    this.claimBUSDFromTreasuryProgress = false;
    this.claimRexFromReferralsProgress = false;
    this.claimRexFromAIRDROPProgress = false;
  }

  initContract(reinit?: boolean) {
    return new Promise((resolve: any) => {
      if (!this.glbC.isConnected) {
        console.log("Init contract, not logged in yet");
        this.glbC.showLoader(true);
        this.resetProgresses();
        this.glbC
          .subscribeAccount(reinit)
          .then(() => {
            this.glbC.isConnected = true;
            console.log("this.glbC.isConnected", this.glbC.isConnected);
            this.loadCurrentValuesFromContract();
            resolve();
          })
          .catch((err) => {
            this.glbC.showLoader(false);

            console.warn(`subscribeAccount error: `, err);
            if (err && err.code === 2) {
              // show wrong network error
              console.log("WRONG NETWORK");
            } else if (err && err.message === "Not authorized") {
              console.log("not loggin in to metamask");
              this.glbC.showPopupMessage({
                title: this.translate.instant("POPUPS.not_authorized"),
                msg: `<p>${this.translate.instant(
                  "POPUPS.please_login_to_metamask"
                )}</p>`,
                declineButtonText: this.translate.instant("BUTTONS.ok"),
              });
              this.glbC.waitingForAuthorization = false;
            } else {
              // TODO show error modal
              console.error("subscribeAccount error: ", err);
            }
          });
      } else {
        this.glbC
          .subscribeAccount()
          .then(() => {
            this.loadCurrentValuesFromContract();
            resolve();
          })
          .catch((error) => {
            console.log("Error in init contract", error);
          });
      }
    });
  }
  /*
  /**
   * connectClicked tries to subscribe the MetaMask Account, if it fails, it shows an Error Message, it it succeeds, it switches isConnected to true

  connectClicked() {
    console.log("connectClicked");
    this.glbC.showConnectBox = true;

  } */

  loginWithMetamask() {
    console.log("login metamask clicked");
    if (!this.glbC.waitingForAuthorization) {
      this.glbC.walletType = "metamask";
      this.glbC.hasConnectedBefore(true, "metamask");
      this.initContract();
    }
  }

  loginWithWalletConnect() {
    console.log("login walletConnect clicked");
    if (!this.glbC.waitingForAuthorization) {
      this.glbC.walletType = "walletConnect";
      this.glbC.hasConnectedBefore(true, "walletConnect");
      this.initContract();
    }
  }

  /*   async loginWithWalletConnect() {
    const provider = await new WalletConnectProvider({
      rpc: {
        56: "https://bsc-dataseed1.binance.org",
      },
      chainId: 56,
      network: "binance",
      qrcode: true,
      qrcodeModalOptions: {
        mobileLinks: ["metamask", "trust"],
      },
    });
    provider.networkId = 56;
    await provider.enable();
    //  Create Web3 instance
    const web3 = new Web3(provider);
    window.w3 = web3;
    console.log(web3);

    const accounts = await web3.eth.getAccounts(); // get all connected accounts
    console.log(accounts);
    this.glbC.account.address = accounts[0]; // get the primary account
    console.log(this.glbC.account);

    this.glbC.isConnected = true;
    this.glbC.showConnectBox = false;
    if (!this.glbC.waitingForAuthorization) {
      this.glbC.hasConnectedBefore(true);
      this.initContract("walletConnect");
    }
  } */

  // ########### TESTNET FUNCTIONS ############

  public buyTenThousBusd() {
    this.getFreeBUSDProgress = true;
    this.contractService
      .buyBusd()
      .then(() => {
        this.contractService.updateBUSDBalance(true).then(() => {
          this.getFreeBUSDProgress = false;
        });
      })
      .catch(() => {
        this.getFreeBUSDProgress = false;
      });
  }

  public buyOneMrex() {
    this.buyMREXProgress = true;
    this.contractService
      .buyMrex()
      .then(() => {
        this.contractService.updateETHBalance(true).then(() => {
          this.buyMREXProgress = false;
        });
      })
      .catch(() => {
        this.buyMREXProgress = false;
      });
  }
}
