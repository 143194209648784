import { DatePipe } from "@angular/common";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { ContractService } from "src/app/services/contract";
import { GlobalControllerService } from "src/app/services/global-controller.service";
import { chartInstance, HttpService } from "../../../../services/http.service";

@Component({
  selector: "app-your-stats",
  templateUrl: "./your-stats.component.html",
  styleUrls: ["./your-stats.component.scss"],
})
export class YourStatsComponent implements OnInit {
  @ViewChild("dropdownItems", { static: false }) dropdownItemsRef: ElementRef;

  currentRexDay: number;

  personalStakes = {
    closed: [],
    offered: [],
    opened: [],
    sold: [],
    transferred: [],
  };

  chart1LabelArr: any[] = [[], []];

  chart2LabelArr: any[] = [];

  allStakesConcat: any[] = [];

  allStakesChart1Objects: any[];

  allStakesChart2Objects: any[];

  months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  lineChart: chartInstance = {
    type: "line",
    label: [],
    data: [],
    options: {
      maintainAspectRatio: false,
      hitRadius: this.service.hitradiusCharts,
      elements: {
        line: {
          tension: 0.1, // disables bezier curves
        },
      },

      plugins: {
        legend: {
          display: false,
        },
      },
      scales: {
        x: {
          grid: {},
          ticks: {
            autoSkip: true,
            maxTicksLimit: 10,
            color: this.service.colorRexBlue,
          },
        },
        y: {
          min: 0,
          ticks: {
            color: this.service.colorRexBlue,
          },
        },
      },
    },
  };

  barChart: chartInstance = {
    type: "bar",
    label: [],
    data: [],
    options: {
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
        },
      },
      scales: {
        x: {
          stacked: true,
          grid: {},
          ticks: {
            color: this.service.colorRexBlue,
            maxTicksLimit: 15,
          },
        },
        y: {
          stacked: true,
          title: {
            display: true,
            text: "REX Payout",
            font: {
              weight: "lighter",
              size: "14",
            },
            color: this.service.colorRexBlue,
          },
          ticks: {
            color: this.service.colorRexBlue,
            beginAtZero: true,
          },
        },
      },
    },
  };

  colorArray: any[] = [
    "Blue",
    "Green",
    "fuchsia",
    "maroon",
    "teal",
    "Orange",
    "Violet",
    "olive",
    "Indigo",
    "navy",
    "Yellow",
    "Black",
    "aqua",
    "White",
    "Pink",
    "tan",
  ];

  currentMonth = new Date().getMonth();

  currentYear = new Date().getFullYear();

  isTablet: boolean = false;

  isMobile: boolean = false;

  stakesAreLoaded: boolean = false;

  totalAmountStakes: number;

  totalShares: number = 0;

  totalRewards: number = 0;

  constructor(
    public service: HttpService,
    public glbC: GlobalControllerService,
    private contractService: ContractService,
    private datePipe: DatePipe
  ) {}

  ngOnInit() {
    this.glbC.showLoader(true);
    this.service.isInitializing = true;
    this.service.updatePageYourStat();
    this.service.waitForInitialization().then(() => {
      this.loadPageData();
      this.adjustLabelNumber(window.innerWidth);
    });
    window.addEventListener("resize", () => {
      this.adjustLabelNumber(window.innerWidth);
    });
  }

  /** Calculates the days depending on the selected timeperiod
   *
   * @param timeperiod Either 0 (="week"), 1 (="month"), 2 (="year") or 3(="max")
   * @param allStakes All existing stakes (closed and opened) of the linked account
   */
  calcLabelArrayChart1(timeperiod: number, allStakes: any) {
    switch (timeperiod) {
      case 0:
        for (let i = this.currentRexDay - 7; i < this.currentRexDay; i++) {
          this.chart1LabelArr[0].push(i);
        }
        break;

      case 1:
        for (let i = this.currentRexDay - 31; i < this.currentRexDay; i++) {
          this.chart1LabelArr[0].push(i);
        }
        break;

      case 2:
        for (let i = this.currentRexDay - 365; i < this.currentRexDay; i++) {
          this.chart1LabelArr[0].push(i);
        }
        break;

      case 3:
        {
          const stakesStartDays = [];
          this.allStakesConcat = Array.prototype.concat.apply(
            [],
            Object.values(allStakes)
          );
          for (let i = 0; i < this.allStakesConcat.length; i++) {
            stakesStartDays[i] = Number(this.allStakesConcat[i].start);
          }
          for (
            let i = Math.min(...stakesStartDays) - 2;
            i < this.currentRexDay;
            i++
          ) {
            this.chart1LabelArr[0].push(i);
          }
        }
        break;

      default:
        break;
    }

    for (let i = 0; i < this.chart1LabelArr[0].length; i++) {
      this.chart1LabelArr[1][i] = this.datePipe.transform(
        this.glbC.rexDayToTimestamp(this.chart1LabelArr[0][i]),
        "MMM dd, yyyy "
      );
    }
  }

  calcLabelArrayChart2(timeperiod: number) {
    const labelArrayRexDays: any[] = [];
    switch (timeperiod) {
      case 0:
        for (
          let i = this.currentRexDay;
          i < Number(this.currentRexDay) + 7;
          i++
        ) {
          labelArrayRexDays.push(i);
        }
        for (let i = 0; i < labelArrayRexDays.length; i++) {
          this.chart2LabelArr[i] = [
            this.datePipe.transform(
              this.glbC.rexDayToTimestamp(labelArrayRexDays[i]),
              "MMM dd"
            ),
            ` Day ${labelArrayRexDays[i]}`,
          ];
        }
        break;
      case 1:
        for (
          let i = this.currentRexDay;
          i < Number(this.currentRexDay) + 31;
          i++
        ) {
          labelArrayRexDays.push(i);
        }

        for (let i = 0; i < labelArrayRexDays.length; i++) {
          this.chart2LabelArr[i] = [
            this.datePipe.transform(
              this.glbC.rexDayToTimestamp(labelArrayRexDays[i]),
              "MMM dd"
            ),
            ` Day ${labelArrayRexDays[i]}`,
          ];
        }
        break;
      case 2:
        {
          let newYear = 0;
          for (let i = this.currentMonth; i < this.currentMonth + 12; i++) {
            if (i % 12 === 0 && this.currentMonth !== 0) {
              newYear = 1;
            }
            this.chart2LabelArr.push(
              `${this.months[i % 12]} ${(this.currentYear + newYear) % 1000}`
            );
          }
        }
        break;
      case 3: {
        for (let i = this.currentYear; i < this.currentYear + 10; i++) {
          this.chart2LabelArr.push(i.toString());
        }
        break;
      }

      default:
        break;
    }
  }

  /** Calculates the corresponding staking amount for the given input array.
   * Further, it also prints the values to the chart
   * @param allStakes All stakes linked to that account
   */
  calcValueArrayChart1(allStakes) {
    const allStakesValuesArray = [];
    this.allStakesChart1Objects = [];

    this.allStakesConcat = Array.prototype.concat.apply(
      [],
      Object.values(allStakes)
    );

    for (let i = 0; i < this.allStakesConcat.length; i++) {
      allStakesValuesArray.push([]);
    }
    // Fill array with the staked amount of each stake since REX Day 0
    for (let s = 0; s < this.allStakesConcat.length; s++) {
      for (let i = 0; i <= this.currentRexDay; ++i) {
        if (
          Number(this.allStakesConcat[s].start) <= i &&
          Number(this.allStakesConcat[s].end) > i
        ) {
          allStakesValuesArray[s][i] =
            this.allStakesConcat[s].amount.c[0] / 10000;
        } else {
          allStakesValuesArray[s][i] = 0;
        }
      }
    }

    // Only take as many days as are to be displayed. If there are days before REX Day 0, don't display something at that time
    if (this.chart1LabelArr[1].length <= this.currentRexDay) {
      for (let i = 0; i < allStakesValuesArray.length; i++) {
        allStakesValuesArray[i] = allStakesValuesArray[i].slice(
          -this.chart1LabelArr[1].length
        );
      }
    } else {
      for (
        let i = 0;
        i < this.chart1LabelArr[1].length - 1 - this.currentRexDay;
        i++
      ) {
        for (let j = 0; j < allStakesValuesArray.length; j++) {
          allStakesValuesArray[j].unshift(undefined);
        }
      }
    }

    // Create sum of all stakes for "combined Stakes"
    let sumREXonDay: Number = 0;
    const sumStakedREXarr = [];
    for (let i = 0; i < this.chart1LabelArr[1].length; i++) {
      sumREXonDay = 0;
      for (let j = 0; j < allStakesValuesArray.length; j++) {
        sumREXonDay += allStakesValuesArray[j][i];
      }
      sumStakedREXarr.push(sumREXonDay);
    }

    this.allStakesChart1Objects.push({
      label: "REX staked",
      data: sumStakedREXarr,
      borderColor: this.service.colorRexBlue,
      pointBackgroundColor: this.service.colorRexBlue,
      pointRadius: 1,
      fill: true,
    });

    this.lineChart.data = this.allStakesChart1Objects;
  }

  calcValueArrayChart2(timeperiod: number, allStakes) {
    const valuesAllStakesAllTime = [];
    this.allStakesChart2Objects = [];

    for (let i = 0; i < allStakes.opened.length; i++) {
      valuesAllStakesAllTime.push([]);
    }

    // For calculation of the values, depending on which timeperiod was selected
    if (timeperiod === 0 || timeperiod === 1) {
      for (let i = 0; i < this.chart2LabelArr.length; i++) {
        for (let k = 0; k < allStakes.opened.length; k++) {
          if (
            Number(this.chart2LabelArr[i][1].substring(4)) ===
            Number(allStakes.opened[k].end)
          ) {
            valuesAllStakesAllTime[k].push(
              (allStakes.opened[k].amount.c[0] / 10000).toFixed(2)
            );
          } else {
            valuesAllStakesAllTime[k].push(undefined);
          }
        }
      }
    } else if (timeperiod === 2) {
      for (let i = 0; i < this.chart2LabelArr.length; i++) {
        for (let k = 0; k < allStakes.opened.length; k++) {
          if (
            this.chart2LabelArr[i].substring(0, 3) ===
              this.service
                .rexDayToDate(Number(allStakes.opened[k].end), true)
                .substring(0, 3) &&
            Number(
              this.service
                .rexDayToDate(Number(allStakes.opened[k].end), true)
                .slice(-2)
            ) === Number(this.chart2LabelArr[i].slice(-2))
          ) {
            valuesAllStakesAllTime[k].push(
              (allStakes.opened[k].amount.c[0] / 10000).toFixed(2)
            );
          } else {
            valuesAllStakesAllTime[k].push(undefined);
          }
        }
      }
    } else if (timeperiod === 3) {
      for (let i = 0; i < this.chart2LabelArr.length; i++) {
        for (let k = 0; k < allStakes.opened.length; k++) {
          if (
            this.chart2LabelArr[i].substring(2, 4) ===
            this.service
              .rexDayToDate(Number(allStakes.opened[k].end), true)
              .slice(-2)
              .toString()
          ) {
            valuesAllStakesAllTime[k].push(
              (allStakes.opened[k].amount.c[0] / 10000).toFixed(2)
            );
          } else {
            valuesAllStakesAllTime[k].push(undefined);
          }
        }
      }
    }

    console.log(valuesAllStakesAllTime);

    // Create Object for each stake, so it can be displayed and for detecting, if another stake expires on the same end date as the stake
    for (let i = 0; i < valuesAllStakesAllTime.length; i++) {
      this.allStakesChart2Objects.push({
        label: allStakes.opened[i].description,
        barPercentage: 1,
        barThickness: 10,
        maxBarThickness: 10,
        minBarLength: 2,
        backgroundColor: this.service.colorRexBlue,
        borderRadius: "6",
        data: valuesAllStakesAllTime[i],
        endDay: allStakes.opened[i].end,
        stakedAmount: allStakes.opened[i].amount.c[0],
        indexStakedAmount: valuesAllStakesAllTime[i].indexOf(
          allStakes.opened[i].amount.c[0]
        ),
      });
    }
    console.log(this.allStakesChart2Objects);
    // Changes the colors of the bars
    for (let i = 0; i < this.allStakesChart2Objects.length - 1; i++) {
      let colorCounter = 0;
      for (let k = i + 1; k < this.allStakesChart2Objects.length; k++) {
        if (
          this.allStakesChart2Objects[i].indexStakedAmount ===
            this.allStakesChart2Objects[k].indexStakedAmount &&
          this.allStakesChart2Objects[i].backgroundColor ===
            this.allStakesChart2Objects[k].backgroundColor
        ) {
          this.allStakesChart2Objects[k].backgroundColor =
            this.colorArray[colorCounter];
          colorCounter += 1;
        }
      }
    }

    return this.allStakesChart2Objects;
  }

  /**
   * Updates the first chart on the page "your statistics"
   * @param timeperiod Either 0 (="week"), 1 (="month"), 2 (="year") or 3(="max")
   * @param allStakes All stakes linked to the connected account
   * @param displayType Eighter "staked", "intereset" or "combined"
   */
  async updateStakedChart(timeperiod: number, allStakes) {
    // Change active state of buttons
    this.service.activeTimeArray[4][0].forEach((value, index) => {
      this.service.activeTimeArray[4][0][index] = false;
    });
    this.service.activeTimeArray[4][0][timeperiod] = true;

    this.calcLabelArrayChart1(timeperiod, allStakes);
    await this.calcValueArrayChart1(allStakes);

    [, this.lineChart.label] = this.chart1LabelArr;

    this.chart1LabelArr[0] = [];
    this.chart1LabelArr[1] = [];
  }

  /**
   *  Updates the second chart on the page "your statistics"
   * @param timeperiod Either 0 (="week"), 1 (="month"), 2 (="year") or 3(="max")
   * @param allStakes All stakes linked to the connected account
   */
  async updateBarChart(timeperiod: number, allStakes) {
    // Change active state of buttons
    this.service.activeTimeArray[4][1].forEach((value, index) => {
      this.service.activeTimeArray[4][1][index] = false;
    });
    this.service.activeTimeArray[4][1][timeperiod] = true;

    this.calcLabelArrayChart2(timeperiod);
    this.calcValueArrayChart2(timeperiod, allStakes);

    this.barChart.label = this.chart2LabelArr;
    this.barChart.data = this.allStakesChart2Objects;

    this.chart2LabelArr = [];
  }

  /**
   * Adjusts the tick number of the "expiring stakes" diagram, depending on the window size
   * @param windowWidth Current window width
   */
  adjustLabelNumber(windowWidth) {
    if (windowWidth < 400 && !this.isMobile) {
      this.isTablet = false;
      this.isMobile = true;
      this.barChart.options.scales.x = {
        stacked: true,
        grid: {},
        ticks: {
          color: this.service.colorRexBlue,
          maxTicksLimit: 5,
        },
      };
    } else if (windowWidth < 600 && windowWidth > 400 && !this.isTablet) {
      this.isMobile = false;
      this.isTablet = true;
      this.barChart.options.scales.x = {
        stacked: true,
        grid: {},
        ticks: {
          color: this.service.colorRexBlue,
          maxTicksLimit: 8,
        },
      };
    } else if (windowWidth > 600 && this.isTablet) {
      this.isTablet = false;
      this.barChart.options.scales.x = {
        stacked: true,
        grid: {},
        ticks: {
          color: this.service.colorRexBlue,
          maxTicksLimit: 15,
        },
      };
    }
  }

  /**
   * Loads the necessary page data by requesting it from the service script
   */
  async loadPageData() {
    // eslint-disable-next-line prefer-destructuring
    [this.personalStakes, this.currentRexDay] = await Promise.all([
      this.contractService.getAccountStakes(),
      this.contractService.getCurrentRexDay(),
    ]);

    console.log(this.personalStakes);
    this.stakesAreLoaded = true;

    this.totalAmountStakes =
      this.personalStakes.opened.length +
      this.personalStakes.closed.length +
      this.personalStakes.offered.length +
      this.personalStakes.sold.length +
      this.personalStakes.transferred.length;

    for (let i = 0; i < this.personalStakes.opened.length; i++) {
      this.totalShares += this.personalStakes.opened[i].shares.c[0];
    }

    for (let i = 0; i < this.personalStakes.opened.length; i++) {
      this.totalRewards +=
        this.personalStakes.opened[i].possibleRewardsMax.c[0];
    }

    this.updateStakedChart(0, this.personalStakes);
    this.updateBarChart(0, this.personalStakes);
  }
}
