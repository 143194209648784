import { Component, HostBinding, OnInit } from "@angular/core";
import { GlobalControllerService } from "src/app/services/global-controller.service";

@Component({
  selector: "app-rex-claim-door-animation",
  templateUrl: "./rex-claim-door-animation.component.html",
  styleUrls: ["./rex-claim-door-animation.component.scss"],
})
export class RexClaimDoorAnimationComponent implements OnInit {
  @HostBinding("class.removed") isRemoved: boolean = false;

  extraClasses: string[] = [];

  doorClasses: string[] = [];

  constructor(public glbC: GlobalControllerService) {}

  ngOnInit() {}

  /**
   * doorClicked will trigger the open door animation and sets this element to display: none afterwards
   */
  doorClicked() {
    this.doorClasses.push("animate-out");
    setTimeout(() => {
      this.extraClasses.push("fadeOut");
      setTimeout(() => {
        this.isRemoved = true;
        this.glbC.rewardWindowAlreadeyOpen = true;
      }, 700);
    }, 600);
  }
}
