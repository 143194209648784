import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  HostBinding,
} from "@angular/core";
import { GlobalControllerService } from "src/app/services/global-controller.service";

@Component({
  selector: "app-rex-claimable-box",
  templateUrl: "./rex-claimable-box.component.html",
  styleUrls: ["./rex-claimable-box.component.scss"],
})
export class RexClaimableBoxComponent implements OnInit {
  @Input() bigInfo: string;

  @Input() smallInfo: string;

  @Input() smallInfoValue: string;

  @Input() smallIconSrc: string;

  @Input() progress: boolean = false;

  @Input() hasInput: boolean = false;

  @Input() extraInfoText: string;

  @Input() buttonText: string;

  @Input() disableButton: boolean = false;

  @Input() disableButton2: boolean = false;

  @Input() extraClasses: string[];

  @Input() hasTwoButtons: boolean = false;

  @Input() hasCheckmarkIcon: boolean = false;

  @Input() placeholder: string = "0";

  @Output() onClick = new EventEmitter<any>();

  @Input() @HostBinding("style.height") height: number;

  @Output() onClickStake = new EventEmitter<any>();

  @Output() inputFieldChanged = new EventEmitter<any>();

  constructor(public glbC: GlobalControllerService) {}

  ngOnInit() {}

  /**
   * Emits the onClick event of the component
   */
  claimClicked($event) {
    this.onClick.emit($event);
  }

  claimClickedStake($event) {
    this.onClickStake.emit($event);
  }

  inputChanged($event) {
    this.inputFieldChanged.emit($event.target.value);
  }
}
