import { Component, OnInit } from "@angular/core";
import { GlobalControllerService } from "src/app/services/global-controller.service";
import { chartInstance, HttpService } from "../../../../services/http.service";

@Component({
  selector: "app-staking",
  templateUrl: "./staking.component.html",
  styleUrls: ["./staking.component.scss"],
})
export class StakingComponent implements OnInit {
  stakedChart: chartInstance = {
    type: "line",
    label: [],
    data: [
      {
        data: [],
        borderColor: this.service.colorRexBlue,
        pointBackgroundColor: this.service.colorRexBlue,
        pointRadius: 1,
      },
    ],
    options: {
      maintainAspectRatio: false,
      hitRadius: this.service.hitradiusCharts,
      elements: {
        line: {
          tension: 0.3,
        },
      },
      plugins: {
        legend: {
          display: false,
        },
      },
      scales: {
        x: {
          grid: {},
          ticks: {
            autoSkip: true,
            maxTicksLimit: 10,
            color: this.service.colorRexBlue,
          },
        },
        y: {
          ticks: {
            color: this.service.colorRexBlue,
          },
        },
      },
    },
  };

  expSharesChart: chartInstance = {
    type: "line",
    label: [],
    data: [
      {
        data: [],
        borderColor: this.service.colorRexBlue,
        pointBackgroundColor: this.service.colorRexBlue,
        pointRadius: 1,
      },
    ],
    options: {
      maintainAspectRatio: false,
      hitRadius: this.service.hitradiusCharts,
      elements: {
        line: {
          tension: 0.3,
        },
      },
      plugins: {
        legend: {
          display: false,
        },
      },
      scales: {
        x: {
          grid: {},
          ticks: {
            autoSkip: true,
            maxTicksLimit: 10,
            color: this.service.colorRexBlue,
          },
        },
        y: {
          ticks: {
            color: this.service.colorRexBlue,
          },
        },
      },
    },
  };

  sharesLabelArray: any[] = [];

  sharesValueArray: any[] = [];

  constructor(
    public service: HttpService,
    public glbC: GlobalControllerService
  ) {}

  ngOnInit() {
    this.glbC.showLoader(true);
    this.service.isInitializing = true;
    this.service.updatePageStaking();
    this.service.waitForInitialization().then(() => {
      this.updatePage();
    });
  }

  updateStakedChart(timeNum: number) {
    this.service.updateChart(
      this.service.DBglobals,
      "timestamp",
      "totalRexStaked",
      2,
      0,
      timeNum,
      this.stakedChart
    );
  }

  updateSharesChart(timeNum: number) {
    // Change active state of buttons
    this.service.activeTimeArray[2][1].forEach((value, index) => {
      this.service.activeTimeArray[2][1][index] = false;
    });
    this.service.activeTimeArray[2][1][timeNum] = true;

    this.service.calculateSharesChartLabels(timeNum);
    this.service.calculateSharesChartValues(timeNum, this.expSharesChart);
  }

  async updatePage() {
    await this.updateStakedChart(0);
    await this.updateSharesChart(0);

    this.service.isInitializing = false;
    this.service.isReady = false;
    this.glbC.showLoader(false);
  }
}
