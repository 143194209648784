import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuctionsPageComponent } from "./pages/auctions-page/auctions-page.component";
import { DashboardPageComponent } from "./pages/dashboard-page/dashboard-page.component";
import { DexPageComponent } from "./pages/dex-page/dex-page.component";
import { LiquidderyPageComponent } from "./pages/liquiddery-page/liquiddery-page.component";
import { StakingPageComponent } from "./pages/staking-page/staking-page.component";
import { StatisticsPageComponent } from "./pages/statistics-page/statistics-page.component";
import { StylesheetPageComponent } from "./pages/stylesheet-page/stylesheet-page.component";

const routes: Routes = [
  {
    path: "",
    redirectTo: "dashboard",
    pathMatch: "full",
  },
  {
    path: "dashboard",
    component: DashboardPageComponent,
  },
  {
    path: "auctions",
    component: AuctionsPageComponent,
  },
  {
    path: "staking",
    component: StakingPageComponent,
  },
  {
    path: "statistics",
    component: StatisticsPageComponent,
  },
  {
    path: "dex",
    component: DexPageComponent,
  },
  {
    path: "liquiddery",
    component: LiquidderyPageComponent,
  },
  {
    path: "stylesheet",
    component: StylesheetPageComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
