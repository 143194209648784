import { Injectable } from "@angular/core";
import Web3 from "web3";
import { Observable } from "rxjs";
import WalletConnectProvider from "@walletconnect/web3-provider";

import { AppConfig } from "../app-config.service";
import { GlobalControllerService } from "../global-controller.service";

@Injectable({
  providedIn: "root",
})
export class MetamaskService {
  window: any;

  private metaMaskWeb3: any;

  public walletConnectWeb3: any;

  private usedNetworkVersion: number;

  public userClosedWalletConnect: boolean = false;

  private net: string;

  private IS_PRODUCTION: boolean;

  private networks = {
    production: "mainnet",
    testnet: "bsctestnet",
  };

  public glbC: GlobalControllerService = null;

  constructor(private config: AppConfig) {
    const settingsApp = config.getConfig();

    this.networks.testnet = settingsApp.settings.network;

    this.IS_PRODUCTION = settingsApp.settings.production;
    this.usedNetworkVersion = settingsApp.settings.production
      ? 1
      : settingsApp.settings.net;
    this.net =
      this.usedNetworkVersion === 1 ? "mainnet" : settingsApp.settings.network;
  }

  public setGlbC(glbCRef: GlobalControllerService) {
    this.glbC = glbCRef;
  }

  private providers;

  private provider;

  public web3;

  public web3WalletConnect;

  public currentBNB;

  public getContract(abi, address) {
    return new this.web3.eth.Contract(abi, address);
  }

  public encodeFunctionCall(name, type, inputs, params) {
    return this.web3.eth.abi.encodeFunctionCall(
      {
        name,
        type,
        inputs,
      },
      params
    );
  }

  public gasPrice() {
    return this.web3.eth.getGasPrice().then((res) => {
      return res;
    });
  }

  public estimateGas(from, to, value, data, gasPrice) {
    return this.web3.eth
      .estimateGas({ from, to, value, data, gasPrice })
      .then((res) => {
        return res;
      });
  }

  public async addToken(tokenOptions) {
    console.log("add token 2", tokenOptions);
    try {
      const wasAdded = await this.metaMaskWeb3.request({
        method: "wallet_watchAsset",
        params: {
          type: "ERC20",
          options: tokenOptions,
        },
      });

      if (wasAdded) {
        console.log("Complete");
      }
    } catch (error) {
      console.log(error);
    }
  }

  public async getBalance(address: string) {
    return this.web3.eth.getBalance(address);
  }

  public getBlock() {
    return this.web3.eth.getBlock("latest");
  }

  public async initWeb3Connection(walletType: string) {
    console.log("In func initWeb3Connection");
    this.providers = {};
    if (walletType === "metamask") {
      this.providers.metamask = await Web3.givenProvider;
      this.metaMaskWeb3 = await window.ethereum;
      console.log("RPC Test MM");
      console.log(Web3);
      console.log(this.providers.metamask);
    } else if (walletType === "walletConnect") {
      console.log("RPC Test WC");
      this.glbC.globalLoading = false;
      this.userClosedWalletConnect = false;
      /*  this.glbC.globalLoading = false; */
      try {
        this.provider = await new WalletConnectProvider({
          rpc: {
            56: "https://bsc-dataseed1.binance.org",
          },
          chainId: 56,
          qrcode: true,
          qrcodeModalOptions: {
            mobileLinks: ["metamask", "trust"],
          },
        });
        await this.provider.enable();

        //  Create Web3 instance
        this.web3 = new Web3(<any>this.provider);
        this.web3WalletConnect = new Web3(<any>this.provider);
        /*  window.w3 = this.web3WalletConnect; */

        const accounts = await this.web3.eth.getAccounts(); // get all connected accounts

        [this.glbC.account.address] = accounts; // get the primary account

        this.providers.walletConnect = await this.web3WalletConnect;

        console.log(
          "this.providers.walletConnect: ",
          this.providers.walletConnect
        );

        this.walletConnectWeb3 = this.providers.walletConnect;
      } catch (error) {
        console.log("error in wallet connect", error);
        this.userClosedWalletConnect = true;
      }
    }
  }

  public async getAccountOnceWalletConnect() {
    if (this.web3WalletConnect) {
      this.web3WalletConnect.setProvider(this.providers.walletConnect);
    } else {
      this.web3WalletConnect = new Web3(this.providers.walletConnect);
      this.web3WalletConnect.setProvider(this.providers.walletConnect);
    }
  }

  public endWeb3Connection() {
    console.log("end web 3 connection");
    this.provider.disconnect();
  }

  /**
   * This account is a copy of the original getAccounts, but only runs once and not as an observer
   *
   * @param noEnable
   * @returns
   */
  public async getAccountsOnce(noEnable?: boolean) {
    console.warn("getAccountsOnce called");
    console.log("this.metaMaskWeb3: ", this.metaMaskWeb3);
    this.glbC.isOnWrongNetwork =
      this.glbC.contractService.settingsApp.settings.net !==
      Number(this.metaMaskWeb3.networkVersion);

    if (this.metaMaskWeb3) {
      if (!this.metaMaskWeb3.selectedAddress && !noEnable) {
        try {
          // return object containing a promise to wait for authorization:
          const response = {
            waitingForAuthorization: true,
            promise: this.metaMaskWeb3.request({
              method: "eth_requestAccounts",
            }),
          };
          return response;
          // const ethAccounts = await this.metaMaskWeb3.request({
          //   method: "eth_requestAccounts",
          // });
          // console.warn("ethAccounts: ", ethAccounts);
        } catch (err) {
          console.warn("this.metaMaskWeb3.send('eth_requestAccounts');", err);
          throw new Error("Not authorized");
          // return { code: 3, msg: "Not authorized" };
        }
      }
      if (this.metaMaskWeb3.selectedAddress) {
        if (this.web3) {
          this.web3.setProvider(this.providers.metamask);
        } else {
          console.log("second option");
          this.web3 = new Web3(this.providers.metamask);
          /* this.web3 = new Web3("https://bscrpc.com"); */
        }
        console.log("return function getAccountsOnce");
        return {
          address: this.metaMaskWeb3.selectedAddress,
          network: this.net,
        };
      }
      throw new Error("Not authorized");
      // return { code: 3, msg: "Not authorized" };
    } else {
      throw new Error(
        `Metamask extension is not found. You can install it from <a href="https://metamask.io" target="_blank">metamask.io</a>`
      );
      // return {
      //   code: 1,
      //   msg: `Metamask extension is not found. You can install it from <a href="https://metamask.io" target="_blank">metamask.io</a>`,
      // };
    }
  }

  public isValidAddress(address: string) {
    return this.web3.utils.isAddress(address);
  }

  public setProviderAndReturnAccount() {
    console.log("setProviderAndReturnAccount");
    if (this.metaMaskWeb3.selectedAddress) {
      if (this.web3) {
        this.web3.setProvider(this.providers.metamask);
      } else {
        this.web3 = new Web3(this.providers.metamask);
      }
      return {
        address: this.metaMaskWeb3.selectedAddress,
        network: this.net,
      };
    }
    return null;
  }

  public async isValidMetaMaskNetwork(): Promise<boolean> {
    const result = await this.metaMaskWeb3.request({ method: "net_version" });
    return Number(result) === this.usedNetworkVersion;
  }

  public getAccounts(noEnable?) {
    console.log("getAccounts");
    const onAuth = (observer, address) => {
      if (this.web3) {
        this.web3.setProvider(this.providers.metamask);
      } else {
        this.web3 = new Web3(this.providers.metamask);
      }
      observer.next({
        address,
        network: this.net,
      });
      if (noEnable) {
        observer.complete();
      }
    };

    const onError = (observer, errorParams) => {
      // this.web3.setProvider(this.providers.infura);
      observer.error(errorParams);
      if (noEnable) {
        observer.complete();
      }
    };

    // const isValidMetaMaskNetwork = (observer, chain?) => {
    //   return new Promise((resolve: any, reject: any) => {
    //     this.metaMaskWeb3
    //       .request({
    //         method: "net_version",
    //       })
    //       .then((result) => {
    //         console.log("isValidMetaMaskNetwork result: ", result);
    //         console.log("this.usedNetworkVersion: ", this.usedNetworkVersion);
    //         if (this.usedNetworkVersion !== Number(result)) {
    //           if (chain) {
    //             onError(observer, {
    //               code: 3,
    //               msg: "Not authorized",
    //             });
    //           }

    //           observer.error({
    //             code: 2,
    //             msg: `Please choose ${this.net} network in Metamask.`,
    //           });
    //           reject();
    //         }
    //         resolve();
    //       });
    //   });
    // };

    return new Observable((observer) => {
      if (this.metaMaskWeb3 && this.metaMaskWeb3.isMetaMask) {
        this.metaMaskWeb3.on("chainChanged", (chainId) => {
          console.log(
            "metaMaskWeb3 chainChanged triggered, chainId: ",
            chainId
          );
          this.glbC.networkOrAccountChangedSubject.next({
            type: "chain",
            data: chainId,
          });
          // this.isValidMetaMaskNetwork().then((isValid) => {
          //   if (isValid) {
          //     console.log("chainChanged triggered");
          //   } else {
          //     onError(observer, {
          //       code: 2,
          //       msg: `Please choose ${this.net} network in Metamask.`,
          //     });
          //   }
          // });
        });
        this.metaMaskWeb3.on("accountsChanged", (accounts) => {
          console.log(
            "metaMaskWeb3 accountsChanged triggered, accounts: ",
            accounts
          );
          this.glbC.networkOrAccountChangedSubject.next({
            type: "account",
            data: accounts,
          });
        });

        if (!this.metaMaskWeb3.selectedAddress && !noEnable) {
          this.metaMaskWeb3.enable().catch(() => {
            onError(observer, {
              code: 3,
              msg: "Not authorized",
            });
          });
        } else if (this.metaMaskWeb3.selectedAddress) {
          onAuth(observer, this.metaMaskWeb3.selectedAddress);
        } else {
          onError(observer, {
            code: 3,
            msg: "Not authorized",
          });
        }
      } else {
        onError(observer, {
          code: 1,
          msg: 'Metamask extension is not found. You can install it from <a href="https://metamask.io" target="_blank">metamask.io</a>',
        });
      }
      return {
        unsubscribe() {},
      };
    });
  }
}
