import { DatePipe } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { of } from "rxjs";
import { ContractService } from "src/app/services/contract";
import { GlobalControllerService } from "src/app/services/global-controller.service";
import { HttpService } from "src/app/services/http.service";
import { RexPopupOptions } from "../../models/data-models";

@Component({
  selector: "app-dex-page",
  templateUrl: "./dex-page.component.html",
  styleUrls: ["./dex-page.component.scss"],
})
export class DexPageComponent implements OnInit {
  mrexHolder: boolean = false;

  busdAmountUser: any;
  pageIsTranslated: boolean = false;

  constructor(
    public service: HttpService,
    public glbC: GlobalControllerService,
    private translateService: TranslateService,
    public contractService: ContractService,
    private datePipe: DatePipe
  ) {}

  ngOnInit() {
    if (!this.glbC.isConnected) {
      if (this.glbC.getHasConnectedBefore("metamask")) {
        this.loginWithMetamask();
      } else if (this.glbC.getHasConnectedBefore("walletConnect")) {
        this.loginWithWalletConnect();
      }
    } else if (
      (!this.glbC.isOnWrongNetwork && this.glbC.walletType === "metamask") ||
      this.glbC.walletType === "walletConnect"
    ) {
      this.glbC.showLoader(true);
      this.service.isInitializing = true;
      this.service.updatePageDEX();
      this.service.waitForInitialization().then(() => {
        this.loadPageData();
      });
    }
  }

  currREXDay: number;

  offersAreLoaded: boolean = false;

  filterActiveForm: FormGroup;

  dexOffers: any;

  allOffers = {
    open: [],
    expired: [],
    closed: [],
  };

  // Extra object, so that the filter function will be applied on all offers
  filteredOffers = {
    open: [],
    expired: [],
    closed: [],
  };

  appliedFilter = {
    open: [],
    expired: [],
    closed: [],
  };

  showFilterRow = {
    open: false,
    expired: false,
    closed: false,
  };

  filterOptionsOpen = [
    {
      minType: "minShares",
      maxType: "maxShares",
      displayName: "COMPONENTS.FILTEROPTIONS.shares",
    },
    {
      minType: "minSharesBusd",
      maxType: "maxSharesBusd",
      displayName: "COMPONENTS.FILTEROPTIONS.shares_busd",
    },
    {
      minType: "minREX",
      maxType: "maxREX",
      displayName: "COMPONENTS.FILTEROPTIONS.rex",
    },

    {
      minType: "minBusdREX",
      maxType: "maxBusdREX",
      displayName: "COMPONENTS.FILTEROPTIONS.busd_rex",
    },
    {
      minType: "minPrice",
      maxType: "maxPrice",
      displayName: "COMPONENTS.FILTEROPTIONS.price",
    },
    {
      minType: "minStakeDaysLeft",
      maxType: "maxStakeDaysLeft",
      displayName: "COMPONENTS.FILTEROPTIONS.stake_days_left",
    },
  ];

  filterOptionsNotOpen = [
    {
      minType: "minShares",
      maxType: "maxShares",
      displayName: "COMPONENTS.FILTEROPTIONS.shares",
    },
    {
      minType: "minSharesBusd",
      maxType: "maxSharesBusd",
      displayName: "COMPONENTS.FILTEROPTIONS.shares_busd",
    },
    {
      minType: "minREX",
      maxType: "maxREX",
      displayName: "COMPONENTS.FILTEROPTIONS.rex",
    },

    {
      minType: "minBusdREX",
      maxType: "maxBusdREX",
      displayName: "COMPONENTS.FILTEROPTIONS.busd_rex",
    },
    {
      minType: "minPrice",
      maxType: "maxPrice",
      displayName: "COMPONENTS.FILTEROPTIONS.price",
    },
  ];

  buyStakeProcess: boolean = false;

  revokeOfferProcess: boolean = false;

  isMREXholderSentence: string =
    "If you own an MREX, you don't have to pay this fee!";

  amountStakesPerPage = {
    open: 20,
    expired: 20,
    closed: 20,
  };

  currentPagination = {
    open: {
      first: 0,
      last: this.amountStakesPerPage.open,
    },
    expired: {
      first: 0,
      last: this.amountStakesPerPage.expired,
    },
    closed: {
      first: 0,
      last: this.amountStakesPerPage.closed,
    },
  };

  dropdownContentStakesPerPage: any[] = [
    { name: "3", value: 3 },
    { name: "5", value: 5 },
    { name: "10", value: 10 },
    { name: "15", value: 15 },
    { name: "20", value: 20 },
    { name: "50", value: 50 },
  ];

  appliedFilterDisplayNames = {
    minShares: "DEX.filter_min_shares",
    maxShares: "DEX.filter_max_shares",
    minSharesBusd: "DEX.filter_min_sharesBusd",
    maxSharesBusd: "DEX.filter_max_sharesBusd",
    minREX: "DEX.filter_min_rex",
    maxREX: "DEX.filter_max_rex",
    minBusdREX: "DEX.filter_min_busdREX",
    maxBusdREX: "DEX.filter_max_busdREX",
    minPrice: "DEX.filter_min_price",
    maxPrice: "DEX.filter_max_price",
    minStakeDaysLeft: "DEX.filter_min_stake_days_left",
    maxStakeDaysLeft: "DEX.filter_max_stake_days_left",
  };

  tableHeads: any[] = [
    {
      displayName: "DEX.table_head_col1",
      value: "numbr",
    },
    {
      displayName: "DEX.table_head_col2",
      value: "shares",
    },
    {
      displayName: "DEX.table_head_col3",
      value: "sharesBusd",
    },
    {
      displayName: "DEX.table_head_col4",
      value: "rexPrincipal",
    },
    {
      displayName: "DEX.table_head_col5",
      value: "busdREX",
    },
    {
      displayName: "DEX.table_head_col6",
      value: "price",
    },
    {
      displayName: "DEX.table_head_col7",
      value: "stakeDaysLeft",
    },
    {
      displayName: "DEX.table_head_col8",
      value: "offerDaysLeft",
    },
    {
      displayName: "",
      value: "",
    },
  ];

  tableHeadsSimple: any[] = [
    "DEX.table_head_col1",
    "DEX.table_head_col2",
    "DEX.table_head_col3",
    "DEX.table_head_col4",
    "DEX.table_head_col5",
    "DEX.table_head_col6",
    "DEX.table_head_col7",
    "DEX.table_head_col8",
    "DEX.table_head_col9",
  ];

  translatedTableHeads: any[] = [];

  tableHeadsClosedStakes: any[] = [
    {
      displayName: "DEX.table_head_col1",
      value: "numbr",
    },
    {
      displayName: "DEX.table_head_col2",
      value: "shares",
    },
    {
      displayName: "DEX.table_head_col3",
      value: "sharesBusd",
    },
    {
      displayName: "DEX.table_head_col4",
      value: "rexPrincipal",
    },
    {
      displayName: "DEX.table_head_col5",
      value: "busdREX",
    },
    {
      displayName: "DEX.table_head_col6",
      value: "price",
    },
    {
      displayName: "DEX.table_head_col9",
      value: "lastOfferDay",
    },
    {
      displayName: "",
      value: "",
    },
  ];

  translatedTableHeadsClosed: any[] = [];

  tableHeaderMobile: any[] = [
    "DEX.mobile_table_head_col1",
    "DEX.mobile_table_head_col2",
    "DEX.mobile_table_head_col3",
    "",
  ];

  /**
   * Loads and formats the currently listed stakes in the dex
   */
  async loadCurrentOffers() {
    const numberDexOffers: number =
      await this.contractService.getNoOfOffersDex();

    if (numberDexOffers > 0) {
      this.dexOffers = await this.contractService.getOffers(numberDexOffers);
      this.offersAreLoaded = true;
      console.log(this.dexOffers);
      this.allOffers.open = this.dexOffers.opened;
      this.allOffers.closed = this.dexOffers.closed;
      for (let i = 0; i < this.allOffers.open.length; i++) {
        if (
          this.allOffers.open[i].stakeIsIrr === "1" ||
          this.allOffers.open[i].stakeIsIrr === "3"
        ) {
          this.allOffers.open[i].stakeIsIrrevocable = true;
        } else {
          this.allOffers.open[i].stakeIsIrrevocable = false;
        }
        if (
          this.allOffers.open[i].stakeIsIrr === "2" ||
          this.allOffers.open[i].stakeIsIrr === "3"
        ) {
          this.allOffers.open[i].stakeHasTREX = true;
        } else {
          this.allOffers.open[i].stakeHasTREX = false;
        }
        if (
          Number(this.allOffers.open[i].stakeFinalDay) -
            Number(this.currREXDay) >=
          0
        ) {
          this.allOffers.open[i].stakeDaysLeft =
            this.allOffers.open[i].stakeFinalDay - this.currREXDay;
        } else {
          this.allOffers.open[i].stakeDaysLeft = 0;
        }
        if (
          Number(this.allOffers.open[i].offerStart) +
            Number(this.allOffers.open[i].offerDuration) -
            Number(this.currREXDay) >
          0
        ) {
          this.allOffers.open[i].offerDaysLeft =
            Number(this.allOffers.open[i].offerStart) +
            Number(this.allOffers.open[i].offerDuration) -
            this.currREXDay;
          this.allOffers.open[i].lastOfferDay =
            Number(this.allOffers.open[i].offerStart) +
            Number(this.allOffers.open[i].offerDuration);
          this.allOffers.open[i].unixLastOfferDay = Date.parse(
            this.datePipe.transform(
              this.glbC.rexDayToTimestamp(this.allOffers.open[i].lastOfferDay),
              "MMM dd, yyyy "
            )
          );
        } else {
          this.allOffers.open[i].offerDaysLeft = 0;
          this.allOffers.open[i].lastOfferDay =
            Number(this.allOffers.open[i].offerStart) +
            Number(this.allOffers.open[i].offerDuration);
          this.allOffers.open[i].unixLastOfferDay = Date.parse(
            this.datePipe.transform(
              this.glbC.rexDayToTimestamp(this.allOffers.open[i].lastOfferDay),
              "MMM dd, yyyy "
            )
          );
        }

        this.allOffers.open[i].shares =
          this.allOffers.open[i].stakeShares.c[0] / 10000;
        this.allOffers.open[i].rexPrincipal =
          this.allOffers.open[i].stakeAmount.c[0] / 10000;
        this.allOffers.open[i].price =
          this.allOffers.open[i].offerPrice.c[0] / 10000;
        this.allOffers.open[i].busdREX =
          Number(this.allOffers.open[i].price) /
          Number(this.allOffers.open[i].rexPrincipal);
        this.allOffers.open[i].sharesBusd =
          Number(this.allOffers.open[i].shares) /
          Number(this.allOffers.open[i].price);
      }
      // For all the closed stake offers
      for (let i = 0; i < this.allOffers.closed.length; i++) {
        if (
          this.allOffers.closed[i].stakeIsIrr === "1" ||
          this.allOffers.closed[i].stakeIsIrr === "3"
        ) {
          this.allOffers.closed[i].stakeIsIrrevocable = true;
        } else {
          this.allOffers.closed[i].stakeIsIrrevocable = false;
        }
        if (
          this.allOffers.closed[i].stakeIsIrr === "2" ||
          this.allOffers.closed[i].stakeIsIrr === "3"
        ) {
          this.allOffers.closed[i].stakeHasTREX = true;
        } else {
          this.allOffers.closed[i].stakeHasTREX = false;
        }
        if (
          Number(this.allOffers.closed[i].stakeFinalDay) -
            Number(this.currREXDay) >=
          0
        ) {
          this.allOffers.closed[i].stakeDaysLeft =
            this.allOffers.closed[i].stakeFinalDay - this.currREXDay;
        } else {
          this.allOffers.closed[i].stakeDaysLeft = 0;
        }
        this.allOffers.closed[i].offerDaysLeft = 0;
        this.allOffers.closed[i].shares =
          this.allOffers.closed[i].stakeShares.c[0] / 10000;
        this.allOffers.closed[i].rexPrincipal =
          this.allOffers.closed[i].stakeAmount.c[0] / 10000;
        this.allOffers.closed[i].price =
          this.allOffers.closed[i].offerPrice.c[0] / 10000;
        this.allOffers.closed[i].busdREX =
          Number(this.allOffers.closed[i].price) /
          Number(this.allOffers.closed[i].rexPrincipal);
        this.allOffers.closed[i].sharesBusd =
          Number(this.allOffers.closed[i].shares) /
          Number(this.allOffers.closed[i].price);
        this.allOffers.closed[i].lastOfferDay =
          Number(this.allOffers.closed[i].offerStart) +
          Number(this.allOffers.closed[i].offerDuration);
        this.allOffers.closed[i].unixLastOfferDay = Date.parse(
          this.datePipe.transform(
            this.glbC.rexDayToTimestamp(this.allOffers.closed[i].lastOfferDay),
            "MMM dd, yyyy "
          )
        );
      }
      // Filter out all stakes which are already matured and those, where the offer time is exceeded
      this.allOffers.expired = this.allOffers.open.filter(
        (item) => item.offerDaysLeft <= 0 || item.stakeDaysLeft <= 0
      );

      this.allOffers.open = this.allOffers.open.filter(
        (item) => item.offerDaysLeft >= 1 && item.stakeDaysLeft >= 1
      );
      this.filteredOffers.open = [...this.allOffers.open];
      this.filteredOffers.expired = [...this.allOffers.expired];
      this.filteredOffers.closed = [...this.allOffers.closed];

      this.shiftPersonalOffer("open");
      this.shiftPersonalOffer("expired");
      this.shiftPersonalOffer("closed");

      this.checkCookieForTableLength("tb_dex_open", "open");
      this.checkCookieForTableLength("tb_dex_expired", "expired");
      this.checkCookieForTableLength("tb_dex_closed", "closed");
    } else {
      this.offersAreLoaded = true;
    }
  }

  shiftPersonalOffer(offertype: string) {
    for (let i = 0; i < this.filteredOffers[offertype].length; i++) {
      if (
        this.filteredOffers[offertype][i].offerStaker.toLowerCase() ===
        this.glbC.account.address.toLowerCase()
      ) {
        console.log("this is your stake");
        this.filteredOffers[offertype].unshift(
          this.filteredOffers[offertype].splice(i, 1)[0]
        );
      }
    }
  }

  filterTable(event: any, table: string) {
    console.log("filter in front");
    this.showFilterRow[table] = false;
    console.log(event);
    this.filterAllNotNullElements(event, table);
  }

  deleteAllFilters(table: string) {
    console.log("delete all filters");
    this.showFilterRow[table] = false;
    this.filteredOffers[table] = this.allOffers[table];
  }

  addFilterToArray(
    key: string,
    offerType: string,
    value: any,
    displayName: string
  ) {
    let allExistingKeys: string[] = [];
    // If an entry with that key already exists update it, if not create a new object
    for (let i = 0; i < this.appliedFilter[offerType].length; i++) {
      allExistingKeys = allExistingKeys.concat(
        Object.values(this.appliedFilter[offerType][i])
      );
      if (this.appliedFilter[offerType][i].type === key) {
        console.log("key exists already");
        this.appliedFilter[offerType][i].value = value;
      }
    }
    if (!allExistingKeys.includes(key)) {
      console.log("key is not included yet");
      this.appliedFilter[offerType].push({
        type: key,
        value,
        displayName,
      });
    }
  }

  translateAppliedFilterNames() {
    const valuesArray = Object.values(this.appliedFilterDisplayNames);
    this.translateService
      .get(valuesArray)
      .toPromise()
      .then((p) => {
        this.appliedFilterDisplayNames = {
          minShares: `${p[valuesArray[0]]}`,
          maxShares: `${p[valuesArray[1]]}`,
          minSharesBusd: `${p[valuesArray[2]]}`,
          maxSharesBusd: `${p[valuesArray[3]]}`,
          minREX: `${p[valuesArray[4]]}`,
          maxREX: `${p[valuesArray[5]]}`,
          minBusdREX: `${p[valuesArray[6]]}`,
          maxBusdREX: `${p[valuesArray[7]]}`,
          minPrice: `${p[valuesArray[8]]}`,
          maxPrice: `${p[valuesArray[9]]}`,
          minStakeDaysLeft: `${p[valuesArray[10]]}`,
          maxStakeDaysLeft: `${p[valuesArray[11]]}`,
        };
        console.log(this.appliedFilterDisplayNames);
      });
  }

  deleteFilterItem(event: any, formType: string, offerType: string) {
    console.log(event.srcElement.attributes.filtertype.nodeValue);
    const clickedType = event.srcElement.attributes.filtertype.nodeValue;

    this[formType].controls[clickedType].patchValue(null);

    for (let i = 0; i < this.appliedFilter[offerType].length; i++) {
      if (
        Object.values(this.appliedFilter[offerType][i]).includes(clickedType)
      ) {
        console.log("object includes this key");
        this.appliedFilter[offerType].splice(i, 1);
      }
    }
    this.filterAllNotNullElements(formType, offerType);
  }

  filterAllNotNullElements(formControl: any, offerType: string) {
    let filterReturnArray: any[] = [...this.allOffers[offerType]];
    if (formControl.minShares) {
      filterReturnArray = filterReturnArray.filter((stake) => {
        return formControl.minShares <= stake.shares;
      });
    }
    if (formControl.maxShares) {
      filterReturnArray = filterReturnArray.filter((stake) => {
        return formControl.maxShares >= stake.shares;
      });
    }
    if (formControl.minSharesBusd) {
      filterReturnArray = filterReturnArray.filter((stake) => {
        return formControl.minSharesBusd <= stake.sharesBusd;
      });
    }
    if (formControl.maxSharesBusd) {
      filterReturnArray = filterReturnArray.filter((stake) => {
        return formControl.maxSharesBusd >= stake.sharesBusd;
      });
    }
    if (formControl.minREX) {
      filterReturnArray = filterReturnArray.filter((stake) => {
        return formControl.minREX <= stake.rexPrincipal;
      });
    }
    if (formControl.maxREX) {
      filterReturnArray = filterReturnArray.filter((stake) => {
        return formControl.maxREX >= stake.rexPrincipal;
      });
    }
    if (formControl.minBusdRex) {
      filterReturnArray = filterReturnArray.filter((stake) => {
        return formControl.minBusdRex <= stake.busdREX;
      });
    }
    if (formControl.maxBusdRex) {
      filterReturnArray = filterReturnArray.filter((stake) => {
        return formControl.maxBusdRex >= stake.busdREX;
      });
    }
    if (formControl.minPrice) {
      filterReturnArray = filterReturnArray.filter((stake) => {
        return formControl.minPrice <= stake.price;
      });
    }
    if (formControl.maxPrice) {
      filterReturnArray = filterReturnArray.filter((stake) => {
        return formControl.maxPrice >= stake.price;
      });
    }
    if (formControl.minStakeDaysLeft) {
      filterReturnArray = filterReturnArray.filter((stake) => {
        return formControl.minStakeDaysLeft <= stake.stakeDaysLeft;
      });
    }
    if (formControl.maxStakeDaysLeft) {
      filterReturnArray = filterReturnArray.filter((stake) => {
        return formControl.maxStakeDaysLeft >= stake.stakeDaysLeft;
      });
    }
    console.log(filterReturnArray);
    this.filteredOffers[offerType] = filterReturnArray;
  }

  changeTablePage(newNumbers: any, tabletype: string) {
    this.currentPagination[tabletype].first = newNumbers.lowestNumber;
    this.currentPagination[tabletype].last = newNumbers.highestNumber;

    if (newNumbers.defaultAmount) {
      console.log(
        "change default amount of table to ",
        newNumbers.defaultAmount
      );
      this.glbC.cookieService.set(
        `tb_dex_${tabletype}`,
        newNumbers.defaultAmount,
        {
          expires: 365,
          path: "/",
        }
      );
    }
  }

  sortContent(sortingType: any, tabletype: string) {
    this.filteredOffers[tabletype].sort(
      (a, b) => Number(a[sortingType]) - Number(b[sortingType])
    );
  }

  /**
   * Checks if there is a local cookie stored for the table. If yes, use it as table size
   * @param cookieName Name of the set cookie
   * @param tableName Which table will it affect
   */
  checkCookieForTableLength(cookieName: string, tableName: string) {
    // Check if there is a cookie set for table size
    if (this.glbC.cookieService.get(cookieName)) {
      const cookieTableLength = Number(this.glbC.cookieService.get(cookieName));
      this.amountStakesPerPage[tableName] = cookieTableLength;

      this.currentPagination[tableName].last =
        this.currentPagination[tableName].first + cookieTableLength;
    }
  }

  buyDEXoffer(offer, event) {
    console.log("buy offer", offer);
    let price = offer.offerPrice;
    if (event.srcElement.attributes.numbr) {
      event.srcElement.classList.add("isBuying");
    } else if (event.srcElement.parentNode.attributes.numbr) {
      event.srcElement.parentNode.classList.add("isBuying");
    }

    if (!this.mrexHolder) {
      price = price.times(102).div(100);
    }

    if (price.gt(this.busdAmountUser)) {
      console.log("price too high");
      // Show error message if balance of wallet is not big enough
      this.translateService
        .get([
          "POPUPS.not_enough_busd_title",
          "POPUPS.not_enough_busd_template",
          "BUTTONS.ok",
        ])
        .toPromise()
        .then((p) => {
          this.glbC.showPopupMessage({
            title: `${p["POPUPS.not_enough_busd_title"]}`,
            msg: `<p>${p["POPUPS.not_enough_busd_template"]}</p>`,
            acceptButtonText: `${p["BUTTONS.ok"]}`,
            onAcceptCallback: () => {
              console.log("accept clicked");
            },
          });
          return false;
        });
      return;
    }
    this.buyStakeProcess = true;
    this.contractService
      .buyStake(price, offer.numbr)
      .then(() => {
        console.log("first success");
        this.contractService.updateBUSDBalance(true).then(() => {
          console.log("second success");
          this.buyStakeProcess = false;
          if (event.srcElement.attributes.numbr) {
            event.srcElement.classList.remove("isBuying");
          } else if (event.srcElement.parentNode.attributes.numbr) {
            event.srcElement.parentNode.classList.remove("isBuying");
          }
        });
      })
      .catch((error) => {
        console.log("first fail");
        console.log(error);
        this.buyStakeProcess = false;
        if (event.srcElement.attributes.numbr) {
          event.srcElement.classList.remove("isBuying");
        } else if (event.srcElement.parentNode.attributes.numbr) {
          event.srcElement.parentNode.classList.remove("isBuying");
        }
      });
  }

  revokeDEXoffer(offer) {
    this.revokeOfferProcess = true;
    console.log("revoce offer id ", offer.numbr);
    this.contractService
      .revokeOffer(offer.numbr)
      .then(() => {
        this.contractService.updateREXBalance(true).then(() => {
          this.revokeOfferProcess = false;
          this.loadCurrentOffers();
        });
      })
      .catch(() => {
        this.revokeOfferProcess = false;
      });
  }

  showLinkedFilterRow(event: any) {
    // If arrow was clicked and not the row
    if (event.srcElement.nextSibling === null) {
      event.srcElement.classList.toggle("flipped");
      event.srcElement.parentNode.nextSibling.classList.toggle("active");
    } else {
      event.srcElement.nextSibling.classList.toggle("active");
      event.srcElement.childNodes[1].classList.toggle("flipped");
    }
  }

  showStakeInfos(event: any, offerType: string) {
    const stakeID = event.target.attributes.stakeid.value;

    const linkedStake = this.allOffers[offerType].filter((obj) => {
      return Number(obj.numbr) === Number(stakeID);
    })[0];
    console.log(linkedStake);
    let stakeSymbolIrr = "&#10060;";
    let stakeSymbolTREX = "&#10060;";
    let stakeSymbolSplit = "&#10060;";
    if (linkedStake.stakeIsIrrevocable) {
      stakeSymbolIrr = "&#9989;";
    }
    if (linkedStake.stakeHasTREX) {
      stakeSymbolTREX = "&#9989;";
    }
    if (!linkedStake.stakeIsSplit) {
      stakeSymbolSplit = "&#9989;";
    }
    const infoTable = `<table><tr><td>${this.translateService.instant(
      "DEX.popup_offer_id"
    )}</td><td class="numberFont">${
      linkedStake.numbr
    }</td></tr><tr><td>${this.translateService.instant(
      "DEX.table_head_col4"
    )}</td><td class="numberFont">${this.service.formatBigNumber(
      linkedStake.rexPrincipal,
      0,
      0
    )}</td></tr><tr><td>${this.translateService.instant(
      "DEX.table_head_col5"
    )}</td><td class="numberFont">${linkedStake.busdREX.toFixed(
      8
    )}</td></tr><tr><td>${this.translateService.instant(
      "DEX.table_head_col2"
    )}</td><td class="numberFont">${this.service.formatBigNumber(
      linkedStake.shares,
      0,
      0
    )}</td></tr><tr><td>${this.translateService.instant(
      "DEX.table_head_col3"
    )}</td><td class="numberFont">${this.service.formatBigNumber(
      linkedStake.sharesBusd,
      0,
      2
    )}</td></tr><tr><td>${this.translateService.instant(
      "DEX.table_head_col6"
    )}</td><td class="numberFont">$ ${this.service.formatBigNumber(
      linkedStake.price,
      0,
      0
    )}</td></tr><tr><td>${this.translateService.instant(
      "DEX.popup_stake_start"
    )}</td><td>${this.datePipe.transform(
      this.glbC.rexDayToTimestamp(linkedStake.stakeStartDay),
      "MMM dd, yyyy "
    )}<br>REX ${this.translateService.instant("AUCTIONS.th_day")} ${
      linkedStake.stakeStartDay
    }</td></tr><tr><td>${this.translateService.instant(
      "DEX.popup_stake_end"
    )}</td><td>${this.datePipe.transform(
      this.glbC.rexDayToTimestamp(linkedStake.stakeFinalDay),
      "MMM dd, yyyy "
    )}<br>REX ${this.translateService.instant("AUCTIONS.th_day")} ${
      linkedStake.stakeFinalDay
    }</td></tr><tr><td>${this.translateService.instant(
      "DEX.popup_stake_left"
    )}</td><td>${
      linkedStake.stakeDaysLeft
    }</td></tr><tr><td>${this.translateService.instant(
      "DEX.popup_offer_start"
    )}</td><td>${this.datePipe.transform(
      this.glbC.rexDayToTimestamp(linkedStake.offerStart),
      "MMM dd, yyyy "
    )}<br>REX ${this.translateService.instant("AUCTIONS.th_day")} ${
      linkedStake.offerStart
    }</td></tr><tr><td>${this.translateService.instant(
      "DEX.popup_offer_end"
    )}</td><td>${this.datePipe.transform(
      this.glbC.rexDayToTimestamp(linkedStake.lastOfferDay),
      "MMM dd, yyyy "
    )}<br>REX ${this.translateService.instant("AUCTIONS.th_day")} ${
      linkedStake.lastOfferDay
    }</td></tr><tr><td>${this.translateService.instant(
      "DEX.popup_offer_duration"
    )}</td><td>${linkedStake.offerDuration} ${this.translateService.instant(
      "days"
    )}</td></tr><tr><td>${this.translateService.instant(
      "DEX.popup_offer_left"
    )}</td><td>${
      linkedStake.offerDaysLeft
    }</td></tr><tr><td>${this.translateService.instant(
      "DEX.popup_stake_irr"
    )}</td><td>${stakeSymbolIrr}</td></tr><tr><td>${this.translateService.instant(
      "DEX.popup_stake_trex"
    )}</td><td>${stakeSymbolTREX}</td></tr>
    <tr><td>${this.translateService.instant(
      "DEX.popup_stake_split"
    )}</td><td>${stakeSymbolSplit}</td></tr>  </table>`;
    /* <tr><td>${this.translateService.instant(
      "DEX.popup_stake_owner"
    )}</td><td>${
      linkedStake.offerStaker
    }</td></tr><tr><td>${this.translateService.instant(
      "DEX.popup_stake_id"
      )}</td><td>${linkedStake.offerStakeID}</td></tr> */

    const currentWidth = document.documentElement.clientWidth;
    let classToAdd: string;
    if (currentWidth > 768) {
      classToAdd = "dexInfoPopup";
    } else {
      classToAdd = "smallDexInfoPopup";
    }
    let options: RexPopupOptions;

    console.log(
      Number(linkedStake.offerStart) + Number(linkedStake.offerDuration) >=
        Number(this.glbC.currRDay)
    );
    console.log(linkedStake.offerIsActive);

    if (
      linkedStake.offerIsActive &&
      Number(linkedStake.offerStart) + Number(linkedStake.offerDuration) >=
        Number(this.glbC.currRDay)
    ) {
      options = {
        title:
          this.translateService.instant("DEX.popup_stake_info") +
          " " +
          linkedStake.numbr,
        msg: infoTable,
        acceptButtonText: this.translateService.instant("BUTTONS.buy_stake"),
        declineButtonText: this.translateService.instant("BUTTONS.ok"),
        extraClass: classToAdd,
        removeOtherPopups: true,
        onAcceptCallback: () => {
          console.log("Buy clicked Stake offer");
          this.buyDEXoffer(linkedStake, event);
        },
      };
    } else {
      options = {
        title: this.translateService.instant(
          `Information about stake offer ${linkedStake.numbr}`
        ),
        msg: infoTable,

        declineButtonText: this.translateService.instant("BUTTONS.ok"),
        extraClass: classToAdd,
        removeOtherPopups: true,
      };
    }

    this.glbC.showPopupMessage(options);
  }

  translateFilterLists(filterType: string) {
    const valuesArray = this[filterType].map(Object.values);
    const valuesArrayFlat = [].concat.apply([], valuesArray);
    this.translateService
      .get(valuesArrayFlat)
      .toPromise()
      .then((t) => {
        let counter = 0;
        for (let i = 2; i < valuesArrayFlat.length; i = i + 3) {
          this[filterType][counter].displayName = t[valuesArrayFlat[i]];
          counter++;
        }
        // Translates the checkbox texts
        for (let i = 0; i < this[filterType].length; i++) {
          if (this[filterType][i].checkboxText) {
            const indexInTranslatedArray = i * 3 + 1;
            this[filterType][i].checkboxText =
              t[valuesArrayFlat[indexInTranslatedArray]];
          }
        }
      });
  }

  async loadPageData() {
    this.currREXDay = await this.contractService.getCurrentRexDay();
    this.currREXDay = Number(this.currREXDay);
    this.translateTableHeads();
    this.translateFilterLists("filterOptionsOpen");
    this.translateFilterLists("filterOptionsNotOpen");
    this.translateAppliedFilterNames();
    if (this.currREXDay >= 111) {
      await this.loadCurrentOffers();
      this.contractService.getMrex().then((result) => {
        if (result > 0) {
          this.mrexHolder = true;
          /*           this.isMREXholderSentence = "DEX.mrex_sentence_has_mrex"; */
        } else {
          this.mrexHolder = false;
          /*           this.isMREXholderSentence = "DEX.mrex_sentence_no_mrex"; */
        }
        /*         this.translateService
          .get(this.isMREXholderSentence)
          .toPromise()
          .then((t) => {
            this.isMREXholderSentence = t[this.isMREXholderSentence];
          }); */
        this.contractService.getBusdAmount().then((result) => {
          this.busdAmountUser = result;
        });
      });
    }

    this.service.isInitializing = false;
    this.service.isReady = false;
    this.glbC.showLoader(false);
  }

  translateTableHeads() {
    this.translateService
      .get(this.tableHeadsSimple)
      .toPromise()
      .then((t) => {
        this.translatedTableHeads = [
          t[this.tableHeadsSimple[0]],
          t[this.tableHeadsSimple[1]],
          t[this.tableHeadsSimple[2]],
          t[this.tableHeadsSimple[3]],
          t[this.tableHeadsSimple[4]],
          t[this.tableHeadsSimple[5]],
          t[this.tableHeadsSimple[6]],
          t[this.tableHeadsSimple[7]],
          t[this.tableHeadsSimple[8]],
        ];
        console.log(this.translatedTableHeads);
        this.pageIsTranslated = true;
      });
  }

  loginWithMetamask() {
    console.log("login metamask clicked");
    if (!this.glbC.waitingForAuthorization) {
      this.glbC.walletType = "metamask";
      this.glbC.hasConnectedBefore(true, "metamask");
      this.initContract();
    }
  }

  loginWithWalletConnect() {
    console.log("login walletConnect clicked");
    if (!this.glbC.waitingForAuthorization) {
      this.glbC.walletType = "walletConnect";
      this.glbC.hasConnectedBefore(true, "walletConnect");
      this.initContract();
    }
  }

  initContract() {
    if (!this.glbC.waitingForAuthorization) {
      this.glbC.showLoader(true);
      this.glbC
        .subscribeAccount()
        .then(() => {
          this.glbC.isConnected = true;
          console.log("this.glbC.isConnected", this.glbC.isConnected);
          this.service.updatePageDEX();
          this.service.waitForInitialization().then(() => {
            this.loadPageData();
          });
        })
        .catch((err) => {
          this.glbC.showLoader(false);
          console.warn("Error in initContract", err);
        });
    }
  }
}
