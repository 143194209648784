import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import Chart, { ChartTypeRegistry } from "chart.js/auto";

@Component({
  selector: "app-chart",
  templateUrl: "./chart.component.html",
  styleUrls: ["./chart.component.scss"],
})
export class ChartComponent implements OnInit {
  myChart;

  counter = 0;

  @ViewChild("chart", { static: false }) chartRef: ElementRef;

  @Input()
  chartContent: any;

  @Input()
  chartHeight: string;

  @Input()
  labels: any[];

  @Input()
  chartType: keyof ChartTypeRegistry = "line";

  @Input()
  datasets!: any;

  @Input()
  options!: any;

  @Input()
  hasExtraTextField: boolean = false;

  @Input()
  extraTextFieldText: string = "";

  @Input()
  extraTextFieldContent: any;

  @Input() extraClasses: string[];

  constructor() {
    this.options = {
      responsive: true,
      maintainAspectRatio: false,
      elements: {
        line: {
          tension: 0.3,
        },
      },
      plugins: {
        legend: {
          display: false,
        },
      },
      scales: {
        x: {
          grid: {},
          ticks: {
            autoSkip: true,
            maxTicksLimit: 10,
            color: "#00ffff",
          },
        },
        y: {
          ticks: {
            color: "#00ffff",
          },
        },
      },
    };
  }

  ngOnInit(): void {}

  ngOnChanges() {
    if (this.counter !== 0) {
      this.myChart.type = this.chartType;
      this.myChart.data.labels = this.labels;
      this.myChart.data.datasets = this.datasets;
      this.myChart.options = this.options;
      this.myChart.update();
    }
  }

  ngAfterViewInit() {
    this.counter += 1;

    this.myChart = new Chart(this.chartRef.nativeElement, {
      type: this.chartType,
      data: {
        labels: this.labels,
        datasets: this.datasets,
      },
      options: this.options,
    });
  }
}
