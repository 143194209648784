import { Component, OnInit } from "@angular/core";
import { GlobalControllerService } from "src/app/services/global-controller.service";
import { HttpService } from "../../services/http.service";

@Component({
  selector: "app-statistics-page",
  templateUrl: "./statistics-page.component.html",
  styleUrls: ["./statistics-page.component.scss"],
})
export class StatisticsPageComponent implements OnInit {
  activeStatPage: boolean[] = [true, false, false, false, false];

  activePageTitle: string = "Global";

  initSubscription: any;

  constructor(
    public service: HttpService,
    public glbC: GlobalControllerService
  ) {}

  ngOnInit() {
    // check init status:
    this.initSubscription = this.glbC.initSubject.subscribe(async (data) => {
      console.log("initSubscription data: ", data);
      if (data.doInit) {
        if (this.glbC.isConnected) {
          this.initContract();
        }
      }
    });

    if (!this.glbC.isConnected) {
      console.log(this.glbC.isOnWrongNetwork);
      console.log(this.glbC.chainNetwork);
      if (
        this.glbC.getHasConnectedBefore("metamask") &&
        !this.glbC.isOnWrongNetwork
      ) {
        this.loginWithMetamask();
      } else if (this.glbC.getHasConnectedBefore("walletConnect")) {
        this.loginWithWalletConnect();
      }
    } else if (
      (!this.glbC.isOnWrongNetwork && this.glbC.walletType === "metamask") ||
      this.glbC.walletType === "walletConnect"
    ) {
      this.initContract();
    }
  }

  ngOnDestroy() {
    this.initSubscription.unsubscribe();
  }

  changePanel(id: number, name: string): void {
    this.activeStatPage.forEach(function (value, index, arr) {
      const array = arr;
      array[index] = false;
    });
    this.activeStatPage[id] = true;
    this.activePageTitle = name;
  }

  loginWithMetamask() {
    console.log("login metamask clicked");
    if (!this.glbC.waitingForAuthorization) {
      this.glbC.walletType = "metamask";
      this.glbC.hasConnectedBefore(true, "metamask");
      this.initContract();
    }
  }

  loginWithWalletConnect() {
    console.log("login walletConnect clicked");
    if (!this.glbC.waitingForAuthorization) {
      this.glbC.walletType = "walletConnect";
      this.glbC.hasConnectedBefore(true, "walletConnect");
      this.initContract();
    }
  }

  initContract() {
    if (!this.glbC.waitingForAuthorization) {
      this.glbC.showLoader(true);
      this.glbC
        .subscribeAccount()
        .then(() => {
          this.glbC.isConnected = true;
          console.log("this.glbC.isConnected", this.glbC.isConnected);
          // Update the current page
          if (this.activeStatPage[0]) {
            this.service.updatePageGlobal(true);
          } else if (this.activeStatPage[1]) {
            this.service.updatePageAuction(true);
            window.location.reload();
          }
          if (this.activeStatPage[2]) {
            this.service.updatePageStaking(true);
          }
          if (this.activeStatPage[3]) {
            this.service.updatePagePrice(true);
          }
          if (this.activeStatPage[4]) {
            this.service.updatePageYourStat(true);
          }
          this.glbC.showLoader(false);
        })
        .catch((err) => {
          this.glbC.showLoader(false);
          console.warn("Error in initContract", err);
        });
    }
  }
}
