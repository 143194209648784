import {
  Directive,
  Injector,
  Input,
  OnInit,
  Pipe,
  PipeTransform,
} from "@angular/core";
import { NgControl } from "@angular/forms";
import BigNumber from "bignumber.js";
import { Console } from "console";

@Directive({
  selector: "[appBigNumber]",
})
export class BigNumberDirective implements OnInit {
  private control: NgControl;

  private latestValue;

  private decimalPart;

  private oldDecimal;

  private currentDecimals: number;

  private withEndPoint;

  @Input("appBigNumber") appBigNumber;

  @Input("ngModel") ngModel;

  @Input("minValueChange") minValueChange;

  @Input("maxValueChange") maxValueChange;

  @Input("decimalsChange") decimalsChange;

  @Input("required") required;

  constructor(private injector: Injector) {
    this.control = this.injector.get(NgControl);
  }

  ngOnInit() {
    const originalWriteVal = this.control.valueAccessor.writeValue.bind(
      this.control.valueAccessor
    );
    this.control.valueAccessor.writeValue = (value) =>
      originalWriteVal(this.maskValue(value));

    this.currentDecimals = !isNaN(this.appBigNumber.decimals)
      ? parseInt(this.appBigNumber.decimals, 10)
      : 0;

    const checkValue = () => {
      this.currentDecimals = !isNaN(this.appBigNumber.decimals)
        ? parseInt(this.appBigNumber.decimals, 10)
        : 0;
      setTimeout(() => {
        this.control.control.setValue(this.latestValue);
      });
    };

    if (this.minValueChange) {
      this.minValueChange.subscribe(() => {
        checkValue();
      });
    }
    if (this.maxValueChange) {
      this.maxValueChange.subscribe(() => {
        checkValue();
      });
    }
    if (this.decimalsChange) {
      this.decimalsChange.subscribe(() => {
        checkValue();
      });
    }

    this.control.valueChanges.subscribe((result: string) => {
      result = result || "";

      let originalValue = result.split(",").join("").replace(/\.$/, "");

      if (new BigNumber(originalValue).isNaN()) {
        originalValue = result !== "" ? this.latestValue : "";
      } else {
        const fixedResult = result.replace(/\.+$/, ".");
        this.withEndPoint = fixedResult.indexOf(".") === fixedResult.length - 1;
        this.decimalPart = originalValue ? result.split(".")[1] : "";
      }

      let bigNumberValue = new BigNumber(originalValue);

      const errors: any = {};

      let modelValue;

      if (!originalValue || bigNumberValue.isNaN()) {
        this.latestValue = "";
        if (originalValue) {
          errors.pattern = true;
        } else if (this.required) {
          errors.required = true;
        }
        modelValue = "";
      } else {
        if (
          this.decimalPart &&
          this.decimalPart.length > this.currentDecimals
        ) {
          bigNumberValue = bigNumberValue.dp(this.currentDecimals);
        }

        modelValue = bigNumberValue.times(Math.pow(10, this.currentDecimals));

        if (bigNumberValue.div(Math.pow(2, 256) - 1).toNumber() > 1) {
          errors.totalMaximum = true;
        }

        if (modelValue.minus(this.appBigNumber.min).toNumber() < 0) {
          errors.min = true;
          this.latestValue = modelValue
            .div(Math.pow(10, this.currentDecimals))
            .toString();
        }
        if (modelValue.minus(this.appBigNumber.max).toNumber() >= 0) {
          modelValue = new BigNumber(this.appBigNumber.max);
          originalValue = modelValue
            .div(Math.pow(10, this.currentDecimals))
            .toString()
            .replace(/\.([0-9]+)[0]+$/, "$1");
          this.decimalPart = originalValue.split(".")[1];
        }
      }

      modelValue = modelValue.toString(10);
      if (JSON.stringify(errors) === "{}") {
        this.latestValue = originalValue;
        this.control.control.setValue(modelValue, {
          emitEvent: false,
        });
        this.control.control.setErrors(null);
      } else {
        if (this.latestValue) {
          modelValue = new BigNumber(this.latestValue)
            .times(Math.pow(10, this.currentDecimals))
            .toString();
        }
        if (modelValue) {
          this.control.control.markAsTouched();
        }
        this.control.control.setValue(modelValue, {
          emitEvent: false,
        });
        this.control.control.setErrors(errors);
      }
    });
  }

  private maskValue(value) {
    const visibleValue = this.latestValue
      ? new BigNumber(this.latestValue)
      : value
      ? new BigNumber(value).div(Math.pow(10, this.currentDecimals))
      : "";

    return visibleValue
      ? visibleValue
          .toFormat(
            Math.min(
              this.decimalPart ? this.decimalPart.length : 0,
              this.currentDecimals || 0
            ),
            1,
            { groupSeparator: ",", groupSize: 3, decimalSeparator: "." }
          )
          .toString() + (this.withEndPoint ? "." : "")
      : "";
  }
}

@Pipe({ name: "bigNumberFormat" })
export class BigNumberFormat implements PipeTransform {
  transform(value, decimals, format, asBN, round) {
    const formatNumberParams = {
      groupSeparator: ",",
      groupSize: 3,
      decimalSeparator: ".",
    };

    const bigNumberValue = new BigNumber(value).div(Math.pow(10, decimals));

    if (bigNumberValue.isNaN()) {
      return value;
    }

    if (format && round !== 0) {
      return round ? bigNumberValue.dp(round).toFormat(formatNumberParams) : "";
    }
    if (format && round === 0) {
      return bigNumberValue.dp(0, 1).toFormat(formatNumberParams);
    }
    if (!asBN) {
      return bigNumberValue.toString(10);
    }
    return bigNumberValue;
  }
}

@Pipe({ name: "bigNumberMin" })
export class BigNumberMin implements PipeTransform {
  transform(values) {
    return BigNumber.min.apply(null, values);
  }
}
@Pipe({ name: "bigNumberMax" })
export class BigNumberMax implements PipeTransform {
  transform(values) {
    return BigNumber.max.apply(null, values);
  }
}
