import {
  Component,
  Input,
  OnInit,
  Optional,
  Output,
  Self,
  EventEmitter,
  HostBinding,
} from "@angular/core";
import { ControlValueAccessor, NgControl } from "@angular/forms";
import { InputDropdownContent } from "src/app/models/data-models";
import { GlobalControllerService } from "src/app/services/global-controller.service";

@Component({
  selector: "app-rex-input",
  templateUrl: "./rex-input.component.html",
  styleUrls: ["./rex-input.component.scss"],
})
export class RexInputComponent implements OnInit, ControlValueAccessor {
  @Input() type: string = "string";

  @Input() specialType: string = "";

  @Input() placeholder: string = "";

  @Input() step: number = 0.01; // REX NUMBER DEFAULT

  @Input() pattern: string = "";

  @Input() textAlign: string = "";

  @Input() iconRight: string = "";

  @Input() textRight: string = "";

  @Input() textColor: string = "#fff"; // REX default text-main value

  @Input() disabled: boolean;

  @Input() backgroundColor: string = "#282828"; // REX default input background-color value

  @Input() initValue: any = "";

  @Input() value: any;

  @Input() tooltipContent: string;

  @Input() dropdownContent: InputDropdownContent[];

  @Input() @HostBinding("style.zIndex") zIndex: number;

  @Input() customClasses: string[] = [];

  @Input() minDate: string;

  @Output() selectValueChanged = new EventEmitter<any>();

  boxRight: boolean = false;

  isPreselected: boolean = false;

  constructor(
    @Self()
    @Optional()
    private ngControl: NgControl,
    public glbC: GlobalControllerService
  ) {
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnInit() {
    if (this.type === "number") {
      if (!this.pattern) {
        this.pattern = "([0-9]+).([0-9]{2,8})";
      }
      if (!this.placeholder) {
        this.placeholder = "0.0";
      }
      this.customClasses = ["numberFont"];
    }
    if (!this.value) {
      if (this.initValue) {
        this.value = this.initValue;
      } else {
        this.value = "";
      }
    }
    this.updateDropdownContent();
  }

  ngOnChanges() {
    this.updateDropdownContent();
  }

  updateDropdownContent() {
    if (this.dropdownContent) {
      this.boxRight = true;
      this.isPreselected = false;
      if (this.value) {
        this.dropdownContent.forEach((dContent) => {
          if (dContent.value.toString() === this.value) {
            this.isPreselected = true;
          }
        });
      }
    }
  }

  writeValue(value: any): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onSelectChange($event) {
    this.isPreselected = true;
    this.selectValueChanged.emit($event);
  }

  onChange() {
    // if (this.dropdownContent && !this.value) {
    //   this.isPreselected = false;
    // }
  }

  onTouched() {}
}
