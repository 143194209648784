/* eslint-disable no-param-reassign */
import { Component, HostListener, NgZone, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators,
} from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import BigNumber from "bignumber.js";
import { of, Subscription } from "rxjs";
import { BigNumberFormat } from "src/app/directives/bignumber/bignumber";
import { DepositInterface } from "src/app/models/data-models";
import { ContractService } from "src/app/services/contract";
import { GlobalControllerService } from "src/app/services/global-controller.service";
import { chartInstance, HttpService } from "src/app/services/http.service";
import { DatePipe } from "@angular/common";

const AVAILABLE_DAYS_AFTER_END = 14;

@Component({
  selector: "app-staking-page",
  templateUrl: "./staking-page.component.html",
  styleUrls: ["./staking-page.component.scss"],
})
export class StakingPageComponent implements OnInit {
  firstActiveDayTimestamp: number;

  withdrawDayMultipleTimestamp: number;

  withdrawDayTimestamp: number;

  allStakesConcat: any[] = [];

  allStakes: any;
  pageIsTranslated: boolean = false;
  lowestPosWithdrawTimestamp: number;

  @HostListener("document:click", ["$event"])
  clickout(event) {
    function parentHasClass(element, className) {
      do {
        if (element.classList && element.classList.contains(className)) {
          return true;
        }
        element = element.parentNode;
      } while (element);
      return false;
    }
    if (!parentHasClass(event.target, "dontHideDropdown")) {
      this.actionButtonChecked = this.actionButtonChecked.map((x) => false);
    }
  }

  initSubscription: Subscription = null;

  stakedChart: chartInstance = {
    type: "line",
    label: [],
    data: [
      {
        data: [],
        borderColor: this.httpService.colorRexBlue,
        pointBackgroundColor: this.httpService.colorRexBlue,
        pointRadius: 1,
      },
    ],
    options: {
      maintainAspectRatio: false,
      hitRadius: this.httpService.hitradiusCharts,
      elements: {
        line: {
          tension: 0.3,
        },
      },
      plugins: {
        legend: {
          display: false,
        },
      },
      scales: {
        x: {
          grid: {},
          ticks: {
            autoSkip: true,
            maxTicksLimit: 10,
            color: this.httpService.colorRexBlue,
          },
        },
        y: {
          ticks: {
            color: this.httpService.colorRexBlue,
          },
        },
      },
    },
  };

  lineChart: chartInstance = {
    type: "line",
    label: [],
    data: [],
    options: {
      maintainAspectRatio: false,
      hitRadius: this.httpService.hitradiusCharts,
      elements: {
        line: {
          tension: 0.1, // disables bezier curves
        },
      },

      plugins: {
        legend: {
          display: false,
        },
      },
      scales: {
        x: {
          grid: {},
          ticks: {
            autoSkip: true,
            maxTicksLimit: 10,
            color: this.httpService.colorRexBlue,
          },
        },
        y: {
          min: 0,
          ticks: {
            color: this.httpService.colorRexBlue,
          },
        },
      },
    },
  };

  firstActiveDay: number;

  withdrawDay: number;

  withdrawDayMultiple: number;

  mySharePrice: BigNumber;

  longerPaysBetterFactor: number = 1;

  longerPaysBetterFactorMultiple: number = 1;

  lpbFactorVariousArray: any[] = [];

  sharesToRecieve: BigNumber;

  sharesToRecieveWithTREX: BigNumber;

  sharesToRecieveMultiple: BigNumber;

  sharesToRecieveWithTREXMultiple: BigNumber;

  isTrexHolder: boolean = false;

  theoStakesShares: BigNumber;

  theoStakesSharesMultiple: BigNumber;

  theoStakesSharesTrex: BigNumber;

  theoStakesSharesTrexMultiple: BigNumber;

  chart1LabelArr: any[] = [[], []];

  allStakesChart1Objects: any[];

  revokeOfferProcess: boolean = false;

  currentDate: any;

  lowestWithdrawDate: any;

  lowestWithdrawDateMultiple: any;

  lowestPossibleWithdrawDate: any;

  stakingData: {
    active: {
      tableHeads: string[];
      stakes: DepositInterface[];
    };
    closed: {
      tableHeads: string[];
      stakes: DepositInterface[];
    };
    offered: {
      tableHeads: string[];
      stakes: DepositInterface[];
    };
    sold: {
      tableHeads: string[];
      stakes: DepositInterface[];
    };
    transferred: {
      tableHeads: string[];
      stakes: DepositInterface[];
    };
  };

  filterOptionsActive = [
    {
      checkboxType: "hasTREX",
      checkboxText: "COMPONENTS.FILTEROPTIONS.was_built_with_trex",
      displayName: "COMPONENTS.FILTEROPTIONS.trex",
    },
    {
      minCalendar: "minStartDate",
      maxCalendar: "maxStartDate",
      displayName: "COMPONENTS.FILTEROPTIONS.start_date",
    },
    {
      minCalendar: "minEndDate",
      maxCalendar: "maxEndDate",
      displayName: "COMPONENTS.FILTEROPTIONS.end_date",
    },

    {
      minType: "minDuration",
      maxType: "maxDuration",
      displayName: "COMPONENTS.FILTEROPTIONS.duration",
    },
    {
      minType: "minProgress",
      maxType: "maxProgress",
      displayName: "COMPONENTS.FILTEROPTIONS.progress",
    },
    {
      minType: "minPrincipal",
      maxType: "maxPrincipal",
      displayName: "COMPONENTS.FILTEROPTIONS.principal",
    },
    {
      minType: "minShares",
      maxType: "maxShares",
      displayName: "COMPONENTS.FILTEROPTIONS.shares",
    },
    {
      minType: "minRewardsPayable",
      maxType: "maxRewardsPayble",
      displayName: "COMPONENTS.FILTEROPTIONS.rewards_payable",
    },
  ];

  filterOptionsClosed = [
    {
      minCalendar: "minStartDate",
      maxCalendar: "maxStartDate",
      displayName: "COMPONENTS.FILTEROPTIONS.start_date",
    },
    {
      minCalendar: "minEndDate",
      maxCalendar: "maxEndDate",
      displayName: "COMPONENTS.FILTEROPTIONS.end_date",
    },
    {
      minCalendar: "minCloseDate",
      maxCalendar: "maxCloseDate",
      displayName: "COMPONENTS.FILTEROPTIONS.close_date",
    },
    {
      minType: "minDuration",
      maxType: "maxDuration",
      displayName: "COMPONENTS.FILTEROPTIONS.duration",
    },
    {
      minType: "minStakedDays",
      maxType: "maxStakedDays",
      displayName: "COMPONENTS.FILTEROPTIONS.staked_days",
    },
    {
      minType: "minPrincipal",
      maxType: "maxPrincipal",
      displayName: "COMPONENTS.FILTEROPTIONS.principal",
    },
    {
      minType: "minShares",
      maxType: "maxShares",
      displayName: "COMPONENTS.FILTEROPTIONS.shares",
    },
    {
      minType: "minPenalty",
      maxType: "maxPenalty",
      displayName: "COMPONENTS.FILTEROPTIONS.penalty",
    },
    {
      minType: "minRewards",
      maxType: "maxRewards",
      displayName: "COMPONENTS.FILTEROPTIONS.rewards",
    },
  ];

  filterOptionsOffered = [
    {
      minCalendar: "minStartDate",
      maxCalendar: "maxStartDate",
      displayName: "COMPONENTS.FILTEROPTIONS.start_date",
    },
    {
      minCalendar: "minEndDate",
      maxCalendar: "maxEndDate",
      displayName: "COMPONENTS.FILTEROPTIONS.end_date",
    },
    {
      minType: "minPrincipal",
      maxType: "maxPrincipal",
      displayName: "COMPONENTS.FILTEROPTIONS.principal",
    },
    {
      minType: "minShares",
      maxType: "maxShares",
      displayName: "COMPONENTS.FILTEROPTIONS.shares",
    },
  ];

  filterOptionsSold = [
    {
      minCalendar: "minTransferredDate",
      maxCalendar: "maxTransferredDate",
      displayName: "Transferred Date",
    },
    {
      minCalendar: "minStartDate",
      maxCalendar: "maxStartDate",
      displayName: "Start Date",
    },
    {
      minCalendar: "minEndDate",
      maxCalendar: "maxEndDate",
      displayName: "End Date",
    },
    {
      minType: "minPrincipal",
      maxType: "maxPrincipal",
      displayName: "Principal",
    },
    {
      minType: "minShares",
      maxType: "maxShares",
      displayName: "Shares",
    },
  ];

  appliedFilterDisplayNames = {
    hasTREX: "STAKING.filter_has_TREX",
    minStartDate: "STAKING.filter_min_start_date",
    maxStartDate: "STAKING.filter_max_start_date",
    minEndDate: "STAKING.filter_min_end_date",
    maxEndDate: "STAKING.filter_max_end_date",
    minDuration: "STAKING.filter_min_duration",
    maxDuration: "STAKING.filter_max_duration",
    minProgress: "STAKING.filter_min_progress",
    maxProgress: "STAKING.filter_max_progress",
    minPrincipal: "STAKING.filter_min_principal",
    maxPrincipal: "STAKING.filter_max_principal",
    minShares: "STAKING.filter_min_shares",
    maxShares: "STAKING.filter_max_shares",
    minRewardsPayable: "STAKING.filter_min_rewards_payable",
    maxRewardsPayble: "STAKING.filter_max_rewards_payable",
    minCloseDate: "STAKING.filter_min_close_date",
    maxCloseDate: "STAKING.filter_max_close_date",
    minStakedDays: "STAKING.filter_min_staked_days",
    maxStakedDays: "STAKING.filter_max_staked_days",
    minPenalty: "STAKING.filter_min_penalty",
    maxPenalty: "STAKING.filter_max_penalty",
    minRewards: "STAKING.filter_min_rewards",
    maxRewards: "STAKING.filter_max_rewards",
    minTransferredDate: "STAKING.filter_min_transferred_date",
    maxTransferredDate: "STAKING.filter_max_transferred_date",
  };

  // Extra object, so that the filter function will be applied on all offers
  filteredStakes = {
    active: [],
    closed: [],
    offered: [],
    sold: [],
    transferred: [],
  };

  amountStakesPerPage = {
    active: 10,
    closed: 10,
    offered: 10,
    sold: 10,
    transferred: 10,
  };

  currentPagination = {
    active: {
      first: 0,
      last: this.amountStakesPerPage.active,
    },
    closed: {
      first: 0,
      last: this.amountStakesPerPage.closed,
    },
    offered: {
      first: 0,
      last: this.amountStakesPerPage.offered,
    },
    sold: {
      first: 0,
      last: this.amountStakesPerPage.sold,
    },
    transferred: {
      first: 0,
      last: this.amountStakesPerPage.transferred,
    },
  };

  createStakeForm: FormGroup;

  createStakeProgress: boolean = false;

  createMultipleStakesForm: FormGroup;

  createMultipleStakesProgress: boolean = false;

  stakesAreLoaded: boolean = false;

  noActiveStakes: boolean = false;

  errorInLoadingStakes: boolean = false;

  multipleStakeVariation: string = "ladder";

  durationOfStakeDropdownContent = [];

  intervalOfStakesDropdownContent = [];

  actionButtonChecked: boolean[] = [];

  rexAmountIsLoaded: boolean = false;

  variousStakesInputs: any[] = [
    ["", "", false],
    ["", "", false],
  ];

  totalAmountOfVariousREX: number = 0;

  validationMessages = {
    stakeRexAmount: [
      {
        type: "required",
        message: "ERRORMESSAGES.stakeRexAmount_required",
      },
      {
        type: "min",
        message: "ERRORMESSAGES.stakeRexAmount_min",
      },
      {
        type: "max",
        message: "ERRORMESSAGES.stakeRexAmount_max",
      },
    ],
    stakeDuration: [
      {
        type: "required",
        message: "ERRORMESSAGES.stakeDuration_required",
      },
      {
        type: "min",
        message: "ERRORMESSAGES.stakeDuration_minimum",
      },
      {
        type: "max",
        message: "ERRORMESSAGES.stakeDuration_maximum",
      },
    ],
  };

  validationMessagesOfMultipleStakes = {
    stakeRexAmount: [
      {
        type: "required",
        message: "ERRORMESSAGES.stakeRexAmount_required",
      },
      {
        type: "min",
        message: "ERRORMESSAGES.stakeRexAmount_min",
      },
      {
        type: "max",
        message: "ERRORMESSAGES.stakeRexAmount_max",
      },
    ],
    stakeInterval: [
      {
        type: "required",
        message: "ERRORMESSAGES.stakeInterval_required",
      },
      {
        type: "min",
        message: "ERRORMESSAGES.stakeInterval_minimum",
      },
      {
        type: "max",
        message: "ERRORMESSAGES.stakeDuration_maximum",
      },
    ],
    stakesAmount: [
      {
        type: "required",
        message: "ERRORMESSAGES.stakesAmount_required",
      },
      {
        type: "min",
        message: "ERRORMESSAGES.stakesAmount_minimum",
      },
      {
        type: "max",
        message: "ERRORMESSAGES.stakesAmount_maximum",
      },
      {
        type: "exceedingMaxStakeDuration",
        message: "ERRORMESSAGES.stakesAmount_exceedingMaxStakeDuration",
      },
    ],
  };

  sortingOptions: any[] = [
    "STAKING.table_head_col1",
    "STAKING.TREX",
    "STAKING.table_head_col2_start",
    "STAKING.table_head_col2_end",
    "STAKING.duration",
    "STAKING.progress",
    "STAKING.table_head_col4_rex",
    "STAKING.table_head_col4_shares",
    "STAKING.table_head_col5_mobile",
    "STAKING.table_head_col6_mobile",
    "STAKING.close_date",
    "STAKING.staked_days",
    "STAKING.penalty",
    "STAKING.table_head_col2_transferred",
  ];

  sortingOptionsTranslated: any[] = [];

  tableHeaderMobile: any[] = [
    "STAKING.table_head_col1",
    "DEX.mobile_table_head_col2",
    "DEX.mobile_table_head_col3",
    "",
  ];

  showFilterRow = {
    active: false,
    offered: false,
    closed: false,
    sold: false,
    transferred: false,
  };

  constructor(
    private formBuilder: FormBuilder,
    public glbC: GlobalControllerService,
    public httpService: HttpService,
    private translateService: TranslateService,
    private ngZone: NgZone,
    private contractService: ContractService,
    private bigNumberFormatPipe: BigNumberFormat,
    private datePipe: DatePipe
  ) {}

  ngOnInit() {
    this.updateStakedChart(0); // this.glbC.showLoader(true);
    // translate errorMessages:
    this.translateService
      .get([
        this.validationMessages.stakeRexAmount[0].message,
        this.validationMessages.stakeRexAmount[1].message,
        this.validationMessages.stakeRexAmount[2].message,
        this.validationMessages.stakeDuration[0].message,
        this.validationMessages.stakeDuration[1].message,
        this.validationMessages.stakeDuration[2].message,
        this.validationMessagesOfMultipleStakes.stakeRexAmount[0].message,
        this.validationMessagesOfMultipleStakes.stakeRexAmount[1].message,
        this.validationMessagesOfMultipleStakes.stakeRexAmount[2].message,
        this.validationMessagesOfMultipleStakes.stakesAmount[0].message,
        this.validationMessagesOfMultipleStakes.stakesAmount[1].message,
        this.validationMessagesOfMultipleStakes.stakesAmount[2].message,
        this.validationMessagesOfMultipleStakes.stakesAmount[3].message,
        this.validationMessagesOfMultipleStakes.stakeInterval[0].message,
        this.validationMessagesOfMultipleStakes.stakeInterval[1].message,
        this.validationMessagesOfMultipleStakes.stakeInterval[2].message,
      ])
      .toPromise()
      .then((t) => {
        this.validationMessages.stakeRexAmount[0].message =
          t[this.validationMessages.stakeRexAmount[0].message];
        this.validationMessages.stakeRexAmount[1].message =
          t[this.validationMessages.stakeRexAmount[1].message];
        this.validationMessages.stakeRexAmount[2].message =
          t[this.validationMessages.stakeRexAmount[2].message];
        this.validationMessages.stakeDuration[0].message =
          t[this.validationMessages.stakeDuration[0].message];
        this.validationMessages.stakeDuration[1].message =
          t[this.validationMessages.stakeDuration[1].message];
        this.validationMessages.stakeDuration[2].message =
          t[this.validationMessages.stakeDuration[2].message];
        this.validationMessagesOfMultipleStakes.stakeRexAmount[0].message =
          t[this.validationMessagesOfMultipleStakes.stakeRexAmount[0].message];
        this.validationMessagesOfMultipleStakes.stakeRexAmount[1].message =
          t[this.validationMessagesOfMultipleStakes.stakeRexAmount[1].message];
        this.validationMessagesOfMultipleStakes.stakeRexAmount[2].message =
          t[this.validationMessagesOfMultipleStakes.stakeRexAmount[2].message];
        this.validationMessagesOfMultipleStakes.stakesAmount[0].message =
          t[this.validationMessagesOfMultipleStakes.stakesAmount[0].message];
        this.validationMessagesOfMultipleStakes.stakesAmount[1].message =
          t[this.validationMessagesOfMultipleStakes.stakesAmount[1].message];
        this.validationMessagesOfMultipleStakes.stakesAmount[2].message =
          t[this.validationMessagesOfMultipleStakes.stakesAmount[2].message];
        this.validationMessagesOfMultipleStakes.stakesAmount[3].message =
          t[this.validationMessagesOfMultipleStakes.stakesAmount[3].message];
        this.validationMessagesOfMultipleStakes.stakeInterval[0].message =
          t[this.validationMessagesOfMultipleStakes.stakeInterval[0].message];
        this.validationMessagesOfMultipleStakes.stakeInterval[1].message =
          t[this.validationMessagesOfMultipleStakes.stakeInterval[1].message];
        this.validationMessagesOfMultipleStakes.stakeInterval[2].message =
          t[this.validationMessagesOfMultipleStakes.stakeInterval[2].message];
      });
    // init status check:
    // check init status:
    if (!this.initSubscription) {
      this.initSubscription = this.glbC.initSubject.subscribe(async (data) => {
        console.log("initSubscription data: ", data);
        if (data.doInit) {
          if (this.glbC.isConnected) {
            this.initContract();
          } else if (
            !this.glbC.getHasConnectedBefore("metamask") ||
            !this.glbC.getHasConnectedBefore("walletConnect")
          ) {
            console.log("logout click");
            this.glbC.logoutClicked();
          }
        }
      });
    }

    if (!this.glbC.isConnected) {
      if (this.glbC.getHasConnectedBefore("metamask")) {
        this.loginWithMetamask();
      } else if (this.glbC.getHasConnectedBefore("walletConnect")) {
        this.loginWithWalletConnect();
      }
    } else if (
      (!this.glbC.isOnWrongNetwork && this.glbC.walletType === "metamask") ||
      this.glbC.walletType === "walletConnect"
    ) {
      this.initContract();
    }

    this.stakingData = {
      active: {
        tableHeads: [],
        stakes: [],
      },
      closed: {
        tableHeads: [],
        stakes: [],
      },
      offered: {
        tableHeads: [],
        stakes: [],
      },
      sold: {
        tableHeads: [],
        stakes: [],
      },
      transferred: {
        tableHeads: [],
        stakes: [],
      },
    };
  }

  ngOnDestroy() {
    this.initSubscription.unsubscribe();
  }

  private calculateLongerPaysBetterFactor(stakeType: string) {
    const stakeDuration: number[] = [];
    const lpbFactor: any[] = [1];
    if (stakeType === "single") {
      stakeDuration[0] = this.createStakeForm.value.stakeDuration;
    } else if (stakeType === "multiple") {
      console.log(
        "Stake Intervall:",
        this.createMultipleStakesForm.value.stakeInterval
      );
      if (
        this.createMultipleStakesForm.value.stakeInterval === "" &&
        this.multipleStakeVariation !== "various"
      ) {
        console.log("Intervall is empty");
        this.longerPaysBetterFactorMultiple = 1;
        return;
      }
      if (this.multipleStakeVariation === "ladder") {
        let offset = 0;
        if (this.createMultipleStakesForm.value.stakeIntervalOffset !== "") {
          offset = this.createMultipleStakesForm.value.stakeIntervalOffset;
        } else {
          offset = 0;
        }
        for (
          let i = 0;
          i < this.createMultipleStakesForm.value.stakesAmount;
          i++
        ) {
          stakeDuration[i] =
            this.createMultipleStakesForm.value.stakeInterval * (i + 1) +
            Number(offset);
        }
      } else if (this.multipleStakeVariation === "bulk") {
        stakeDuration[0] = this.createMultipleStakesForm.value.stakeInterval;
      } else if (this.multipleStakeVariation === "various") {
        for (let i = 0; i < this.variousStakesInputs.length; i++) {
          stakeDuration.push(this.variousStakesInputs[i][1]);
        }
        console.log(stakeDuration);
      }
    }
    for (let i = 0; i < stakeDuration.length; i++) {
      let tmpDays: number = 0;
      if (stakeDuration[i] <= 365) {
        tmpDays = stakeDuration[i];
      } else {
        for (let j = 0; j < Math.floor(stakeDuration[i] / 365); j++) {
          tmpDays = Number(tmpDays) + Number(stakeDuration[i]) - j * 365;
          console.log(`tmpDays(${j}): ${tmpDays}`);
        }
        tmpDays += stakeDuration[i] - Math.floor(stakeDuration[i] / 365) * 365;
      }
      lpbFactor[i] =
        (10000000000 + (tmpDays * 10000000000) / 7300) / 10000000000;
    }
    console.log(lpbFactor);

    if (stakeType === "single") {
      [this.longerPaysBetterFactor] = lpbFactor;
    } else if (stakeType === "multiple") {
      if (this.multipleStakeVariation === "ladder") {
        const sum = lpbFactor.reduce((a, b) => a + b, 0);
        const avg = sum / lpbFactor.length;
        this.longerPaysBetterFactorMultiple = avg;
      } else if (this.multipleStakeVariation === "bulk") {
        [this.longerPaysBetterFactorMultiple] = lpbFactor;
      } else if (this.multipleStakeVariation === "various") {
        this.lpbFactorVariousArray = lpbFactor;
        console.log(this.lpbFactorVariousArray);
        const sum = lpbFactor.reduce((a, b) => a + b, 0);
        const avg = sum / lpbFactor.length;
        this.longerPaysBetterFactorMultiple = avg;
      }
    }
  }

  private initCreateStakeForm() {
    this.firstActiveDay = 1 + Number(this.glbC.currRDay);
    console.log("Current REX Day: " + this.glbC.currRDay);
    console.log("First active day: " + this.firstActiveDay);
    this.lowestPosWithdrawTimestamp = this.glbC.rexDayToTimestamp(
      Number(this.firstActiveDay) + 7
    );
    console.log(this.lowestPosWithdrawTimestamp);
    this.currentDate = new Date();
    this.lowestWithdrawDate = new Date(this.lowestPosWithdrawTimestamp)
      .toISOString()
      .slice(0, 10);

    console.log(this.lowestWithdrawDate);
    this.lowestWithdrawDateMultiple = this.lowestWithdrawDate;
    this.lowestPossibleWithdrawDate = this.lowestWithdrawDate;
    this.withdrawDayTimestamp = this.glbC.rexDayToTimestamp(
      this.firstActiveDay + 7
    );
    this.withdrawDayMultipleTimestamp = this.glbC.rexDayToTimestamp(
      this.firstActiveDay + 7
    );
    this.durationOfStakeDropdownContent = [
      { name: "7 d", value: 7 },
      { name: "30 d", value: 30 },
      { name: "60 d", value: 60 },
      { name: "6 m", value: 182 },
      { name: "1 y", value: 365 },
      { name: "2 y", value: 730 },
      { name: "5 y", value: 1825 },
      { name: "10 y", value: 3653 },
    ];
    this.createStakeForm = this.formBuilder.group({
      stakeRexAmount: new FormControl(
        "",
        Validators.compose([
          Validators.required,
          Validators.max(this.glbC.rexAmount / 1e18),
        ])
      ),
      stakeDuration: new FormControl(
        "",
        Validators.compose([
          Validators.required,
          Validators.min(this.glbC.minStakeDuration),
          Validators.max(this.glbC.maxStakeDuration),
        ])
      ),
      stakeDescription: new FormControl(""),
      isIrrevocable: new FormControl(false),
      withdrawDate: new FormControl(""),
    });
    // this.createStakeForm.valueChanges.subscribe((val) => {
    //   console.log("createStake form value changed, val: ", val);
    //   this.withdrawDay = this.firstActiveDay + Number(val.stakeDuration);
    //   this.calculateLongerPaysBetterFactor();
    // });
    // init multiple stakes form:
    this.intervalOfStakesDropdownContent = [
      { name: `7 ${this.translateService.instant("d")}`, value: 7 },
      { name: `15 ${this.translateService.instant("d")}`, value: 15 },
      { name: `30 ${this.translateService.instant("d")}`, value: 30 },
      { name: `2 ${this.translateService.instant("m")}`, value: 60 },
      { name: `4 ${this.translateService.instant("m")}`, value: 121 },
      { name: `6 ${this.translateService.instant("m")}`, value: 182 },
    ];
    this.createMultipleStakesForm = this.formBuilder.group({
      stakeRexAmount: new FormControl(
        "",
        Validators.compose([
          Validators.required,
          /*       Validators.min(1), */
          Validators.max(this.glbC.rexAmount / 1e18),
        ])
      ),
      stakeInterval: new FormControl(
        "",
        Validators.compose([
          Validators.required,
          Validators.min(this.glbC.minStakeDuration),
          Validators.max(this.glbC.maxStakeDuration),
        ])
      ),
      stakesAmount: new FormControl(
        1,
        Validators.compose([
          Validators.required,
          Validators.min(2),
          Validators.max(100),
        ])
      ),
      stakeDescription: new FormControl(""),
      withdrawDate: new FormControl(""),
      stakeIntervalOffset: new FormControl(""),
      isIrrevocable: new FormControl(false),
    });
    console.log(
      "this.createMultipleStakesForm: ",
      this.createMultipleStakesForm
    );
  }

  private resetCreateStakeForm() {
    this.createStakeForm.patchValue({
      stakeDuration: "",
      stakeRexAmount: 0,
      stakeDescription: "",
      withdrawDate: "",
      isIrrevocable: false,
    });
  }

  private resetCreateMultipleStakesForm() {
    this.createMultipleStakesForm.patchValue({
      stakeRexAmount: "",
      withdrawDate: "",
      stakeInterval: "",
      stakeDescription: "",
      stakesAmount: "",
      stakeIntervalOffset: "",
      isIrrevocable: false,
    });
    if (this.multipleStakeVariation === "various") {
      this.variousStakesInputs = [
        ["", "", false],
        ["", "", false],
      ];
      this.totalAmountOfVariousREX = 0;
    }
  }

  resetProgresses() {
    this.createStakeProgress = false;
    this.createMultipleStakesProgress = false;
  }

  private initContract() {
    this.glbC.showLoader(true);
    console.log("staking initContract called");
    this.resetProgresses();
    this.glbC
      .subscribeAccount()
      .then(() => {
        this.loadContractData();
        this.loadUserStakes();
        this.updateStakedChart(0);
      })
      .catch((err) => {
        this.glbC.showLoader(false);
        console.error("connect error: ", err);
      });
  }

  private loadContractData() {
    console.log("Contract data gets loaded");
    this.glbC.showLoader(true);
    return new Promise((resolve: any) => {
      const promises: Promise<any>[] = [];

      /*       promises.push(this.contractService.loadAccountInfo());  */ // TODO maybe uncomment again
      promises.push(
        new Promise((res: any) => {
          this.contractService
            .getRexAmount()
            .then((rexAmount) => {
              this.rexAmountIsLoaded = true;
              this.glbC.rexAmount = rexAmount;
              console.log("rexAmount: ", rexAmount);
              res();
            })
            .catch((error) => {
              console.error("Error is get rex amount:", error);
            });
        })
      );
      promises.push(
        new Promise((res: any) => {
          this.contractService
            .getRexGlobals()
            .then((rexGlobals) => {
              console.log("getRexGlobals rexGlobals: ", rexGlobals);
              this.glbC.totalStakedRex = rexGlobals.totalStaked;
              this.glbC.totalSharesRex = rexGlobals.totalShares;
              this.glbC.totalClaimedRex = rexGlobals.totalClaimed;
              this.glbC.sharePriceRex = rexGlobals.sharePrice;
              console.log("this.glbC.currRDay: ", this.glbC.currRDay);

              res();
            })
            .catch((error) => {
              console.error("Error is get rex globals:", error);
            });
        })
      );
      promises.push(
        new Promise((res: any) => {
          this.contractService.getCurrentRexDay().then((currRxDay) => {
            console.log("getCurrentRexDay currRxDay: ", currRxDay);
            this.glbC.currRDay = currRxDay;
            res();
          });
        })
      );
      promises.push(
        new Promise((res: any) => {
          this.contractService
            .getTrex()
            .then((result) => {
              if (result > 0) {
                this.isTrexHolder = true;
              } else {
                this.isTrexHolder = false;
              }
              res();
            })
            .catch((error) => {
              console.error("Error is is trex holder:", error);
            });
        })
      );
      Promise.all(promises).then(() => {
        console.log("staking promises resolved");
        this.initCreateStakeForm();
        this.updateVariables("single");
        this.updateVariables("multiple");
        this.glbC.showLoader(false);
        resolve();
      });
    });
  }

  updateVariables(stakeType: string) {
    console.log("Update Variables: ", stakeType);
    // REFRESH vars:
    this.ngZone.run(() => {
      let tmpSharesToReceive;
      console.log("Current rex day: ", this.glbC.currRDay);
      this.firstActiveDay = 1 + Number(this.glbC.currRDay);
      this.firstActiveDayTimestamp = this.glbC.rexDayToTimestamp(
        1 + Number(this.glbC.currRDay)
      );
      console.log(
        "First active single day timestamp",
        this.firstActiveDayTimestamp
      );
      if (stakeType === "single") {
        if (Number(this.createStakeForm.value.stakeDuration) !== 0) {
          this.withdrawDay =
            this.firstActiveDay +
            Number(this.createStakeForm.value.stakeDuration);
          this.withdrawDayTimestamp = this.glbC.rexDayToTimestamp(
            this.firstActiveDay +
              Number(this.createStakeForm.value.stakeDuration)
          );
        } else {
          this.withdrawDay = this.firstActiveDay + 7;
          this.withdrawDayTimestamp = this.glbC.rexDayToTimestamp(
            this.firstActiveDay + 7
          );
        }
      } else if (
        stakeType === "multiple" &&
        this.multipleStakeVariation === "bulk"
      ) {
        if (Number(this.createMultipleStakesForm.value.stakeInterval) !== 0) {
          this.withdrawDayMultiple =
            this.firstActiveDay +
            Number(this.createMultipleStakesForm.value.stakeInterval);
          this.withdrawDayMultipleTimestamp = this.glbC.rexDayToTimestamp(
            this.firstActiveDay +
              Number(this.createMultipleStakesForm.value.stakeInterval)
          );
        } else {
          this.withdrawDayMultiple = this.firstActiveDay + 1;
          this.withdrawDayMultipleTimestamp = this.glbC.rexDayToTimestamp(
            this.firstActiveDay + 7
          );
        }
      }

      this.calculateLongerPaysBetterFactor(stakeType);
      this.determineShares(stakeType);
      if (this.isTrexHolder && stakeType === "single") {
        tmpSharesToReceive = this.theoStakesSharesTrex;
      } else if (!this.isTrexHolder && stakeType === "single") {
        tmpSharesToReceive = this.theoStakesShares;
      } else if (this.isTrexHolder && stakeType === "multiple") {
        tmpSharesToReceive = this.theoStakesSharesTrexMultiple;
      } else if (!this.isTrexHolder && stakeType === "multiple") {
        tmpSharesToReceive = this.theoStakesSharesMultiple;
      }
      if (stakeType === "single") {
        this.sharesToRecieve = new BigNumber(tmpSharesToReceive).times(
          10 ** 18
        );
        this.sharesToRecieveWithTREX = new BigNumber(
          this.theoStakesSharesTrex
        ).times(10 ** 18);
      } else if (stakeType === "multiple") {
        this.sharesToRecieveMultiple = new BigNumber(tmpSharesToReceive).times(
          10 ** 18
        );
        this.sharesToRecieveWithTREXMultiple = new BigNumber(
          this.theoStakesSharesTrexMultiple
        ).times(10 ** 18);
      }
    });
  }

  /*   determineSharesTrex() {
    const divDecimals = 10 ** 18;
    const irrVocMult = this.createStakeForm.value.isIrrevocable ? 1.25 : 1.0;
    const shareP = this.glbC.sharePriceRex * 0.75;
    this.theoStakesSharesTrex = new BigNumber(
      this.createStakeForm.value.stakeRexAmount || 0
    )
      .times(divDecimals)
      .times(irrVocMult)
      .div(shareP)
      .times(this.longerPaysBetterFactor);
  } */

  determineShares(stakeType: string) {
    console.log("Determine Shares Func, Staketype:", stakeType);
    const divDecimals = 10 ** 18;
    const shareP = this.glbC.sharePriceRex;
    let irrVocMult;
    if (stakeType === "single") {
      irrVocMult = this.createStakeForm.value.isIrrevocable ? 1.25 : 1.0;
      this.theoStakesShares = new BigNumber(
        this.createStakeForm.value.stakeRexAmount || 0
      )
        .times(divDecimals)
        .times(irrVocMult)
        .div(shareP)
        .times(this.longerPaysBetterFactor);
      this.theoStakesSharesTrex = new BigNumber(this.theoStakesShares).times(
        1.25
      );
      console.log(this.theoStakesShares);
    } else if (stakeType === "multiple") {
      console.log("Multiple Staking Type:", this.multipleStakeVariation);
      if (
        this.multipleStakeVariation === "bulk" ||
        this.multipleStakeVariation === "ladder"
      ) {
        irrVocMult = this.createMultipleStakesForm.value.isIrrevocable
          ? 1.25
          : 1.0;
        this.theoStakesSharesMultiple = new BigNumber(
          this.createMultipleStakesForm.value.stakeRexAmount || 0
        )
          .times(divDecimals)
          .times(irrVocMult)
          .div(shareP)
          .times(this.longerPaysBetterFactorMultiple);
        this.theoStakesSharesTrexMultiple = new BigNumber(
          this.theoStakesSharesMultiple
        ).times(1.25);
        console.log(this.theoStakesSharesMultiple);
      } else if (this.multipleStakeVariation === "various") {
        let sumOfShares: BigNumber = new BigNumber(0);
        let sumOfSharesTrex: BigNumber = new BigNumber(0);
        for (let i = 0; i < this.variousStakesInputs.length; i++) {
          let stakeIsIrr = this.variousStakesInputs[i][2] ? 1.25 : 1.0;
          let rexAmountToStake =
            this.variousStakesInputs[i][0] === ""
              ? 0
              : this.variousStakesInputs[i][0].replace(/,/g, "");
          let theoStakesSharesVarious = new BigNumber(rexAmountToStake)
            .times(divDecimals)
            .times(stakeIsIrr)
            .div(shareP)
            .times(this.lpbFactorVariousArray[i]);

          let theoStakesSharesTrexVarious = new BigNumber(
            theoStakesSharesVarious
          ).times(1.25);
          console.log(theoStakesSharesVarious);
          sumOfShares = sumOfShares.plus(theoStakesSharesVarious);
          sumOfSharesTrex = sumOfSharesTrex.plus(theoStakesSharesTrexVarious);
        }

        this.theoStakesSharesMultiple = sumOfShares;
        this.theoStakesSharesTrexMultiple = sumOfSharesTrex;
        console.log(
          "Sum of shares without trex: ",
          this.theoStakesSharesMultiple
        );
        console.log(
          "Sum of shares with trex: ",
          this.theoStakesSharesTrexMultiple
        );
      }
    }
  }

  private async loadUserStakes() {
    console.log("loadUserStakes called");
    this.noActiveStakes = false;

    this.stakingData.active.stakes = [];
    this.stakingData.closed.stakes = [];
    this.stakingData.offered.stakes = [];
    this.stakingData.sold.stakes = [];
    this.stakingData.transferred.stakes = [];

    this.contractService
      .getAccountStakes()
      .then((accountStakes) => {
        console.log("User stakes loaded:", accountStakes);
        this.allStakes = accountStakes;
        const tHeads = [
          "STAKING.table_head_col1",
          "STAKING.table_head_col2",
          "STAKING.table_head_col3",
          "STAKING.table_head_col4",
          "STAKING.table_head_col5",
          "STAKING.table_head_col6",
          "STAKING.table_head_col2_closed",
          "STAKING.table_head_col3_closed",
          "STAKING.table_head_col4_closed",
          "STAKING.table_head_col5_closed",
          "STAKING.table_head_col2_transferred",
          "STAKING.table_head_col3_transferred",
        ];
        this.translateService
          .get(tHeads)
          .toPromise()
          .then((t) => {
            this.ngZone.run(() => {
              if (accountStakes.opened && accountStakes.opened.length > 0) {
                this.stakingData.active = {
                  tableHeads: [
                    t[tHeads[0]],
                    "TREX",
                    t[tHeads[1]],
                    t[tHeads[2]],
                    t[tHeads[3]],
                    t[tHeads[4]],
                    "",
                  ],
                  stakes: accountStakes.opened,
                };

                console.log(this.stakingData.active);

                this.filteredStakes.active = [
                  ...this.stakingData.active.stakes,
                ];

                // Fill array to keep track which action button of the stakes is pressed
                for (
                  let i = 0;
                  i < this.stakingData.active.stakes.length;
                  i++
                ) {
                  this.actionButtonChecked[i] = false;
                }
              }

              if (accountStakes.closed && accountStakes.closed.length > 0) {
                this.stakingData.closed = {
                  tableHeads: [
                    t[tHeads[0]],
                    t[tHeads[6]],
                    t[tHeads[7]],
                    t[tHeads[8]],
                    t[tHeads[9]],
                  ],
                  stakes: accountStakes.closed,
                };
                this.addAPYtoClosedStakes();
                this.filteredStakes.closed = [
                  ...this.stakingData.closed.stakes,
                ];
              }
              if (accountStakes.offered && accountStakes.offered.length > 0) {
                this.stakingData.offered = {
                  tableHeads: [
                    t[tHeads[0]],
                    "TREX",
                    t[tHeads[1]],
                    t[tHeads[2]],
                    t[tHeads[3]],
                    t[tHeads[4]],
                    "",
                  ],
                  stakes: accountStakes.offered,
                };
                this.filteredStakes.offered = [
                  ...this.stakingData.offered.stakes,
                ];
              }
              if (accountStakes.sold && accountStakes.sold.length > 0) {
                this.stakingData.sold = {
                  tableHeads: [
                    t[tHeads[0]],
                    t[tHeads[10]],
                    t[tHeads[11]],
                    t[tHeads[3]],
                  ],
                  stakes: accountStakes.sold,
                };
                this.filteredStakes.sold = [...this.stakingData.sold.stakes];
              }
              if (
                accountStakes.transferred &&
                accountStakes.transferred.length > 0
              ) {
                this.stakingData.transferred = {
                  tableHeads: [
                    t[tHeads[0]],
                    t[tHeads[10]],
                    t[tHeads[11]],
                    t[tHeads[3]],
                  ],
                  stakes: accountStakes.transferred,
                };
                this.filteredStakes.transferred = [
                  ...this.stakingData.transferred.stakes,
                ];
              }
              this.stakesAreLoaded = true;
              this.errorInLoadingStakes = false;
              if (this.stakingData.active.stakes.length === 0) {
                console.log("trigger no active stakes");
                this.noActiveStakes = true;
              }
              this.addAPYtoActiveStakes();

              this.checkCookieForTableLength("tb_stakes_active", "active");
              this.checkCookieForTableLength("tb_stakes_closed", "closed");
              this.checkCookieForTableLength("tb_stakes_offered", "offered");
              this.checkCookieForTableLength("tb_stakes_sold", "sold");
              this.checkCookieForTableLength(
                "tb_stakes_transferred",
                "transferred"
              );

              console.log("Staking data", this.stakingData);
              this.updatePersonalStakedChart(0, this.allStakes);
            });
          });
      })
      .catch((error) => {
        console.log("Error in get stakes: ", error);
        this.errorInLoadingStakes = true;
      });
    this.translateAppliedFilterNames();
    this.translateSortingTypes();
    this.translateFilterLists("filterOptionsActive");
    this.translateFilterLists("filterOptionsClosed");
    this.translateFilterLists("filterOptionsSold");
    this.translateFilterLists("filterOptionsOffered");
  }

  increaseZindex(stakeNumber: any) {
    for (let i = 0; i < this.actionButtonChecked.length; i++) {
      if (i === stakeNumber) {
        this.actionButtonChecked[i] = !this.actionButtonChecked[i];
      } else {
        this.actionButtonChecked[i] = false;
      }
    }
  }

  getPercentActive(stake) {
    console.log(stake);
    const perc = Math.round((stake.possibleRewardsEarly / stake.amount) * 100);
    if (Number.isNaN(perc)) {
      return 0;
    }
    return perc;
  }

  formatRexForInput(amount) {
    return this.bigNumberFormatPipe.transform(amount, 18, true, false, 2);
  }

  getPercAPYActive(deposit) {
    const percOfPrincipal =
      (Number(deposit.possibleRewardsMax) / Number(deposit.amount)) * 100;
    const duration = this.glbC.currRDay - deposit.start;
    const durationNew = duration > 1 ? duration : 1;
    const percOfYear = durationNew / 365;
    const apy = percOfPrincipal / percOfYear;
    if (Number.isNaN(apy)) {
      return 0;
    }
    return apy;
  }

  addAPYtoActiveStakes() {
    console.log("add apy");
    for (let i = 0; i < this.stakingData.active.stakes.length; i++) {
      this.stakingData.active.stakes[i].apy = this.getPercAPYActive(
        this.stakingData.active.stakes[i]
      );
    }
    console.log(this.stakingData.offered);
    for (let i = 0; i < this.stakingData.offered.stakes.length; i++) {
      this.stakingData.offered.stakes[i].apy = this.getPercAPYActive(
        this.stakingData.offered.stakes[i]
      );
      console.log("Check offered stake");
    }
  }

  addAPYtoClosedStakes() {
    for (let i = 0; i < this.stakingData.closed.stakes.length; i++) {
      this.stakingData.closed.stakes[i].apy = this.getPercAPY(
        this.stakingData.closed.stakes[i]
      );
    }
  }

  public getPercAPY(deposit) {
    const percOfPrincipal = this.getPercent(deposit);
    const duration =
      deposit.close < deposit.end
        ? deposit.close - deposit.start
        : deposit.end - deposit.start;
    const durationNew = duration > 1 ? duration : 1;
    const percOfYear = durationNew / 365;
    const apy = Number((percOfPrincipal / percOfYear).toFixed(2));
    if (Number.isNaN(apy)) {
      return 0;
    }
    return apy;
  }

  public getPercAPYOnce(deposit) {
    const percOfPrincipal =
      (Number(deposit.possibleRewardsMax) / Number(deposit.amount)) * 100;
    const duration = this.glbC.currRDay - deposit.start;
    const durationNew = duration > 1 ? duration : 1;
    const percOfYear = durationNew / 365;
    const apy = Number((percOfPrincipal / percOfYear).toFixed(2));
    console.log("percPrinc", percOfPrincipal);
    console.log("Duration", durationNew);
    console.log("percOfYear", percOfYear);
    console.log("apy", apy);
    if (Number.isNaN(apy)) {
      return 0;
    }
    return apy;
  }

  public getPercent(deposit) {
    const perc =
      (deposit.rewards / deposit.amount) * 100 < 0
        ? 0
        : Math.round((deposit.rewards / deposit.amount) * 100 * 100) / 100;
    if (Number.isNaN(perc)) {
      return 0;
    }
    return perc;
  }

  getDayProgress(stake) {
    const fullAge = stake.end - stake.start;
    const backAge = this.glbC.currRDay - stake.start;
    const perc =
      (backAge / fullAge) * 100 < 0
        ? 0
        : Math.min(Math.round((backAge / fullAge) * 100 * 100) / 100, 100);
    return perc;
  }

  rexAmountSelectionChanged(e) {
    console.log(e.target.value);
    const rexAmountStake = parseFloat(e.target.value.replace(/,/g, ""));
    console.log(rexAmountStake);
    this.createStakeForm.controls.stakeRexAmount.patchValue(rexAmountStake);
    this.updateVariables("single");
  }

  stakeDurationSelectionChanged(e) {
    console.log("stake dur changed:", e.target.value);
    this.createStakeForm.controls.stakeDuration.patchValue(e.target.value);
    this.updateVariables("single");
  }

  multipleStakesRexAmountSelectionChanged(e) {
    console.log(e.target.value);
    const rexAmountStake = parseFloat(e.target.value.replace(/,/g, ""));
    console.log(rexAmountStake);
    this.createMultipleStakesForm.controls.stakeRexAmount.patchValue(
      rexAmountStake
    );
    this.updateVariables("multiple");
  }

  stakeIntervalSelectionChanged(e) {
    console.log("stake interval changed:", e.target.value);
    this.createMultipleStakesForm.controls.stakeInterval.patchValue(
      e.target.value
    );
    this.updateVariables("multiple");
  }

  stakeOffsetChanged(e) {
    console.log("stake offset changed:", e.target.value);
    this.createMultipleStakesForm.controls.stakeIntervalOffset.patchValue(
      e.target.value
    );
    this.updateVariables("multiple");
  }

  multipleStakeVariationChanged(mSVariation: string) {
    this.ngZone.run(() => {
      this.multipleStakeVariation = mSVariation;
      this.resetCreateMultipleStakesForm();
      this.updateVariables("multiple");
      if (this.multipleStakeVariation === "ladder") {
        this.intervalOfStakesDropdownContent = [
          { name: `7 ${this.translateService.instant("d")}`, value: 7 },
          { name: `15 ${this.translateService.instant("d")}`, value: 15 },
          { name: `30 ${this.translateService.instant("d")}`, value: 30 },
          { name: `2 ${this.translateService.instant("m")}`, value: 60 },
          { name: `4 ${this.translateService.instant("m")}`, value: 121 },
          { name: `6 ${this.translateService.instant("m")}`, value: 182 },
        ];
      } else if (this.multipleStakeVariation === "bulk") {
        this.intervalOfStakesDropdownContent =
          this.durationOfStakeDropdownContent;
      } else {
        console.log("various");
      }
    });
  }

  getProgress(deposit) {
    const fullAge = deposit.end - deposit.start;
    const backAge = this.glbC.currRDay - deposit.start;
    const perc =
      (backAge / fullAge) * 100 < 0
        ? 0
        : Math.min(Math.round((backAge / fullAge) * 100 * 100) / 100, 100);
    deposit.progress = perc;
    return deposit.progress;
  }

  changeTablePage(newNumbers: any, tabletype: string) {
    this.currentPagination[tabletype].first = newNumbers.lowestNumber;
    this.currentPagination[tabletype].last = newNumbers.highestNumber;

    if (newNumbers.defaultAmount) {
      console.log(
        "change default amount of table to ",
        newNumbers.defaultAmount
      );
      this.glbC.cookieService.set(
        `tb_stakes_${tabletype}`,
        newNumbers.defaultAmount,
        {
          expires: 365,
          path: "/",
        }
      );
    }
  }

  createStakeFormSubmit() {
    if (this.createStakeForm.valid) {
      this.createStake();
    } else {
      const errorMessages = [];
      Object.keys(this.createStakeForm.controls).forEach((key) => {
        const tempErrors: ValidationErrors =
          this.createStakeForm.get(key).errors;
        if (tempErrors != null) {
          Object.keys(tempErrors).forEach((errorKey) => {
            this.validationMessages[key].forEach((msg) => {
              if (msg.type === errorKey) {
                errorMessages.push(msg.message);
              }
            });
          });
        }
      });
      console.log("errorMessages: ", errorMessages);
      let formattedErrorMessage: string = "<ul>";
      errorMessages.forEach((errMsg) => {
        formattedErrorMessage += `<li>${errMsg}</li>`;
      });
      formattedErrorMessage += "</ul>";
      this.translateService
        .get(["POPUPS.form_error", "BUTTONS.ok"])
        .toPromise()
        .then((translations) => {
          this.glbC.showPopupMessage({
            title: translations["POPUPS.form_error"],
            msg: formattedErrorMessage,
            declineButtonText: translations["BUTTONS.ok"],
          });
        });
    }
  }

  createMultipleStakesFormSubmit() {
    console.log("createMultipleStakesFormSubmit");
    // check if last interval would exceed maximum stake duration
    const asLadder = this.multipleStakeVariation === "ladder";
    if (asLadder) {
      const amountOfStakes = this.createMultipleStakesForm.value.stakesAmount;
      const intervalLength = this.createMultipleStakesForm.value.stakeInterval;
      let offset = 0;
      if (this.createMultipleStakesForm.value.stakeIntervalOffset !== "") {
        offset = this.createMultipleStakesForm.value.stakeIntervalOffset;
      }

      if (
        amountOfStakes * intervalLength + offset >
        this.glbC.maxStakeDuration
      ) {
        this.createMultipleStakesForm.controls.stakesAmount.setErrors({
          exceedingMaxStakeDuration: true,
        });
      }
    } else if (this.multipleStakeVariation === "various") {
      console.log("Variation is various");
      // In order to show error messages for all stakes, the values are transferred to the angular form, even though these form values are not submitted
      const hasEmptyREXinput = this.arrayHasEmptyElement(
        "variousStakesInputs",
        0
      );
      const hasEmptyDurationInput = this.arrayHasEmptyElement(
        "variousStakesInputs",
        1
      );
      console.log("Has empty REX input", hasEmptyREXinput);
      console.log("Has empty Duration input", hasEmptyDurationInput);
      if (hasEmptyREXinput) {
        this.createMultipleStakesForm.controls.stakeRexAmount.patchValue("");
      } else {
        this.createMultipleStakesForm.controls.stakeRexAmount.patchValue(
          Number(this.totalAmountOfVariousREX)
        );
      }
      if (hasEmptyDurationInput) {
        this.createMultipleStakesForm.controls.stakeInterval.patchValue("");
      } else {
        let maxDuration: number = 0;
        for (let i = 0; i < this.variousStakesInputs.length; i++) {
          if (Number(this.variousStakesInputs[i][1]) > maxDuration) {
            maxDuration = this.variousStakesInputs[i][1];
          }
        }
        this.createMultipleStakesForm.controls.stakeInterval.patchValue(
          Number(maxDuration)
        );
      }
      this.createMultipleStakesForm.controls.stakesAmount.patchValue(
        this.variousStakesInputs.length
      );

      console.log(this.createMultipleStakesForm.controls);
    } else if (
      this.createMultipleStakesForm.controls.stakesAmount.hasError(
        "exceedingMaxStakeDuration"
      )
    ) {
      delete this.createMultipleStakesForm.controls.stakesAmount.errors
        .exceedingMaxStakeDuration;
    }
    if (this.createMultipleStakesForm.valid) {
      this.createMultipleStakes(asLadder);
    } else {
      const errorMessages = [];
      Object.keys(this.createMultipleStakesForm.controls).forEach((key) => {
        const tempErrors: ValidationErrors =
          this.createMultipleStakesForm.get(key).errors;
        if (tempErrors != null) {
          Object.keys(tempErrors).forEach((errorKey) => {
            this.validationMessagesOfMultipleStakes[key].forEach((msg) => {
              if (msg.type === errorKey) {
                errorMessages.push(msg.message);
              }
            });
          });
        }
      });
      console.log("errorMessages: ", errorMessages);
      let formattedErrorMessage: string = "<ul>";
      errorMessages.forEach((errMsg) => {
        formattedErrorMessage += `<li>${errMsg}</li>`;
      });
      formattedErrorMessage += "</ul>";
      this.translateService
        .get(["POPUPS.form_error", "BUTTONS.ok"])
        .toPromise()
        .then((translations) => {
          this.glbC.showPopupMessage({
            title: translations["POPUPS.form_error"],
            msg: formattedErrorMessage,
            declineButtonText: translations["BUTTONS.ok"],
          });
        });
    }
  }
  /**
   * Checks if the given array has an empty element in its child arrays for the given index
   * @param array Given array
   * @param index Index to check
   */
  arrayHasEmptyElement(array: any, index: number) {
    let hasEmptyElement: boolean = false;
    for (let i = 0; i < this[array].length; i++) {
      if (this[array][i][index] === "" || this[array][i][index] === 0) {
        hasEmptyElement = true;
      }
    }

    return hasEmptyElement;
  }

  createStake() {
    console.log("createStake called");
    // contract call
    this.createStakeProgress = true;
    const stakeDescription = this.createStakeForm.value.stakeDescription
      ? this.createStakeForm.value.stakeDescription
      : "no_name";
    console.log("this.createStakeForm.value: ", this.createStakeForm.value);
    // const tmpAmount = new BigNumber(this.createStakeForm.value.stakeRexAmount);
    // const rexAmountToDeposit = tmpAmount.times(10 ** 18);
    const rexAmountToDeposit = new BigNumber(
      this.createStakeForm.value.stakeRexAmount
    );
    console.warn("rexAmountToDeposit: ", rexAmountToDeposit);
    this.contractService
      .depositREX(
        rexAmountToDeposit,
        this.createStakeForm.value.stakeDuration,
        stakeDescription,
        this.createStakeForm.value.isIrrevocable
      )
      .then(() => {
        console.log("stake was created");
        this.createStakeProgress = false;
        this.noActiveStakes = false;
        this.stakesAreLoaded = false;
        this.glbC.showLoader(true);
        this.loadContractData();
        this.loadUserStakes();
        this.updateREXamount();
        this.resetCreateStakeForm();
      })
      .catch((err) => {
        console.error("contractService.depositREX error: ", err);
        this.createStakeProgress = false;
      });
  }

  createMultipleStakes(asLadder: boolean = false) {
    let rexAmountToDeposit;
    let stakesAmountToDepost;
    let stakesDuration;
    let stakeDescription;
    let isIrrevocable;
    let stakesOffset = 0;
    const asVarious = this.multipleStakeVariation === "various";
    console.log("createMultipleStakes called");
    this.createMultipleStakesProgress = true;
    stakesAmountToDepost = Number(
      this.createMultipleStakesForm.value.stakesAmount
    );
    stakeDescription = this.createMultipleStakesForm.value.stakeDescription;
    if (!asVarious) {
      rexAmountToDeposit = Number(
        this.createMultipleStakesForm.value.stakeRexAmount
      );
      stakesDuration = this.createMultipleStakesForm.value.stakeInterval;

      isIrrevocable = this.createMultipleStakesForm.value.isIrrevocable;
      if (this.createMultipleStakesForm.value.stakeIntervalOffset !== "") {
        stakesOffset = this.createMultipleStakesForm.value.stakeIntervalOffset;
      }
    } else {
      rexAmountToDeposit = [];
      stakesDuration = [];
      isIrrevocable = [];
      for (let i = 0; i < this.variousStakesInputs.length; i++) {
        rexAmountToDeposit.push(this.variousStakesInputs[i][0]);
        stakesDuration.push(this.variousStakesInputs[i][1]);
        isIrrevocable.push(this.variousStakesInputs[i][2]);
      }
    }

    console.log(rexAmountToDeposit);
    console.log(stakesAmountToDepost);
    console.log(stakesDuration);
    console.log(isIrrevocable);
    this.contractService
      .depositREXBulk(
        rexAmountToDeposit,
        stakesAmountToDepost,
        stakesDuration,
        stakesOffset,
        stakeDescription,
        isIrrevocable,
        asLadder,
        asVarious
      )
      .then(() => {
        this.createMultipleStakesProgress = false;
        this.stakesAreLoaded = false;
        this.glbC.showLoader(true);
        this.loadContractData();
        this.loadUserStakes();
        this.updateREXamount();
        this.resetCreateMultipleStakesForm();
        this.glbC.getCurrentRexDay();
      })
      .catch((err) => {
        console.error("createMultipleStakes error: ", err);
        this.createMultipleStakesProgress = false;
      });
  }

  inputChanged(type: string, data?) {
    if (type === "duration") {
      console.log("duration input changed: ", data);
      this.lowestWithdrawDate = new Date();
      if (data !== "") {
        const withdrawTimestamp = this.glbC.rexDayToTimestamp(
          Number(this.firstActiveDay) + Number(data)
        );
        this.lowestWithdrawDate = new Date(withdrawTimestamp)
          .toISOString()
          .slice(0, 10);
      } else {
        this.lowestWithdrawDate = new Date(this.lowestPosWithdrawTimestamp)
          .toISOString()
          .slice(0, 10);
      }
      console.log(this.lowestWithdrawDate);
      this.createStakeForm.controls.stakeDuration.patchValue(data);
    } else if (type === "amountRex") {
      this.createStakeForm.controls.stakeRexAmount.patchValue(data);
    } else if (type === "description") {
      this.createStakeForm.controls.stakeDescription.patchValue(data);
    } else if (type === "isIrrevocable") {
      this.createStakeForm.controls.isIrrevocable.patchValue(data);
    } else if (type === "withdraw_date") {
      console.log("withdraw input changed. New withdraw date: ", data);
      const withdrawDateSplit: any = data.split("-");
      const withdrawDateTimestamp = new Date(
        withdrawDateSplit[0],
        withdrawDateSplit[1] - 1,
        withdrawDateSplit[2],
        19,
        0,
        0
      ).getTime();
      console.log(withdrawDateTimestamp);
      const diffTimestamps =
        withdrawDateTimestamp - this.firstActiveDayTimestamp;
      console.log(diffTimestamps);
      const diffDays = Math.ceil(diffTimestamps / (1000 * 60 * 60 * 24) - 1);
      console.log(diffDays);
      /* const diffTime = Math.abs(withdrawDate - this.currentDate);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); */
      this.createStakeForm.controls.withdrawDate.patchValue(data);
      this.createStakeForm.controls.stakeDuration.patchValue(diffDays);
    }
    setTimeout(() => {
      this.updateVariables("single");
    }, 50);
  }

  inputOfMultipleChanged(type: string, data?) {
    if (type === "amountRex") {
      if (this.multipleStakeVariation !== "various") {
        this.createMultipleStakesForm.controls.stakeRexAmount.patchValue(data);
      } else {
        const inputElement = this.findAncestor(
          data.target,
          "variousStakeInputLine"
        );
        const stakeID = inputElement.attributes.stakeID.value;
        const amount = data.target.value;
        console.log(inputElement);
        console.log(stakeID);
        console.log(amount);
        this.changeVariousStakeInput(stakeID, 0, amount);
        inputElement.value = amount;
      }
    } else if (type === "stakesAmount") {
      this.createMultipleStakesForm.controls.stakesAmount.patchValue(data);
    } else if (type === "interval") {
      if (this.multipleStakeVariation !== "various") {
        console.log("duration input changed: ", data);

        this.lowestWithdrawDateMultiple = new Date();
        if (data !== "") {
          const withdrawTimestamp = this.glbC.rexDayToTimestamp(
            Number(this.firstActiveDay) + Number(data)
          );
          this.lowestWithdrawDateMultiple = new Date(withdrawTimestamp)
            .toISOString()
            .slice(0, 10);
        } else {
          this.lowestWithdrawDateMultiple = new Date(
            this.lowestPosWithdrawTimestamp
          )
            .toISOString()
            .slice(0, 10);
        }
        console.log(this.lowestWithdrawDateMultiple);
        this.createMultipleStakesForm.controls.stakeInterval.patchValue(data);
      } else {
        const inputElement = this.findAncestor(
          data.target,
          "variousStakeInputLine"
        );
        const stakeID = inputElement.attributes.stakeID.value;
        const duration = data.target.value;
        console.log(inputElement);
        console.log(stakeID);
        console.log(duration);
        this.changeVariousStakeInput(stakeID, 1, duration);
      }
    } else if (type === "description") {
      this.createMultipleStakesForm.controls.stakeDescription.patchValue(data);
    } else if (type === "isIrrevocable") {
      if (this.multipleStakeVariation !== "various") {
        this.createMultipleStakesForm.controls.isIrrevocable.patchValue(data);
      } else {
        const inputElement = this.findAncestor(
          data.target,
          "variousStakeInputLine"
        );
        const stakeID = inputElement.attributes.stakeID.value;
        const irreVoc = data.target.checked;
        console.log(inputElement);
        console.log(stakeID);
        console.log(irreVoc);
        this.changeVariousStakeInput(stakeID, 2, irreVoc);
      }
    } else if (type === "withdraw_date") {
      console.log("withdraw input multiple changed. New withdraw date: ", data);
      const withdrawDateSplit: any = data.split("-");
      const withdrawDateTimestamp = new Date(
        withdrawDateSplit[0],
        withdrawDateSplit[1] - 1,
        withdrawDateSplit[2],
        19,
        0,
        0
      ).getTime();
      console.log(withdrawDateTimestamp);
      const diffTimestamps =
        withdrawDateTimestamp - this.firstActiveDayTimestamp;
      console.log(diffTimestamps);
      const diffDays = Math.ceil(diffTimestamps / (1000 * 60 * 60 * 24) - 1);
      console.log(diffDays);

      this.createMultipleStakesForm.controls.withdrawDate.patchValue(data);
      this.createMultipleStakesForm.controls.stakeInterval.patchValue(diffDays);
    } else if (type === "offset") {
      console.log("offset changed: ", data);
      this.createMultipleStakesForm.controls.stakeIntervalOffset.patchValue(
        data
      );
    }
    setTimeout(() => {
      this.updateVariables("multiple");
    }, 50);
  }

  closeStakeClicked(stake: DepositInterface, event: any) {
    console.log("closeStakeClicked stake: ", stake);
    if (!event.target.classList.contains("disabled")) {
      this.translateService
        .get(["POPUPS.close_stake", "BUTTONS.abort"])
        .toPromise()
        .then((t) => {
          let penaltyAmount = new BigNumber(0);
          /*      const scheduledDuration = stake.end - stake.start; */
          const actualDuration =
            this.glbC.currRDay < stake.end
              ? this.glbC.currRDay - stake.start
              : stake.end - stake.start;
          console.log(actualDuration);
          console.log(Number(stake.end) - Number(this.glbC.currRDay) - 1);
          /*    const perc =
            (actualDuration / scheduledDuration) * 100 < 0
              ? 0
              : Math.min((actualDuration / scheduledDuration) * 100, 100); */
          if (
            Number(this.glbC.currRDay) >= Number(stake.start) &&
            Number(this.glbC.currRDay) < Number(stake.end)
          ) {
            penaltyAmount = stake.possiblePenalty;
            /*            (Number(stake.amount) *
                (100 +
                  (800 * (Number(stake.end) - Number(this.glbC.currRDay) - 1)) /
                    Number(Number(actualDuration) - 1))) /
              1000; */
          }

          /*  (_stake.stakedAmount *
       (100 + (800 * (_daysLeft(_stake) - 1)) / (_stake.stakingDays - 1))) /
   1000;
           */

          const totalPayoutPrincipal =
            stake.amount.toNumber() - penaltyAmount.toNumber();
          // const totalPayoutInterest = stake.possibleRewards;
          const totalPayout =
            totalPayoutPrincipal + stake.possibleRewardsEarly.toNumber();
          // const openedWarning = this.dialog.open(this.warningModalClose, {});
          const now = Number(this.glbC.currRDay);
          const { end } = stake;

          const endTwoWeeks = end + AVAILABLE_DAYS_AFTER_END;
          let late = "";
          if (now < end) {
            late = "early";
          } else if (now > endTwoWeeks) {
            late = "late";
          } else {
            late = "regular";
          }

          // this.confirmWithdrawData = {
          //   deposit,
          //   openedWarning,
          //   penalty,
          //   late,
          //   payOutAmountPrincipal: totalPayoutPrincipal,
          //   payOutAmount: totalPayout,
          // };
          const data = {
            stake,
            penaltyAmount,
            late,
            payOutAmountPrincipal: totalPayoutPrincipal,
            payOutAmount: totalPayout,
          };
          console.log("close data: ", data);
          const popupMessage = `<p class="popup-align-left">${this.translateService.instant(
            "POPUPS.close_stake_message_template",
            {
              end: stake.end,
              endDate: this.datePipe.transform(
                this.glbC.rexDayToTimestamp(stake.end),
                "MMM dd, yyyy "
              ),
              closeTime: late,
              principal: this.bigNumberFormatPipe.transform(
                stake.amount,
                18,
                true,
                false,
                0
              ),
              principalPenalty: this.bigNumberFormatPipe.transform(
                penaltyAmount,
                18,
                true,
                false,
                0
              ),
              principalPayout: this.bigNumberFormatPipe.transform(
                totalPayoutPrincipal,
                18,
                true,
                false,
                0
              ),
              rewardsEarned: this.bigNumberFormatPipe.transform(
                stake.possibleRewardsMax,
                18,
                true,
                false,
                0
              ),
              totalPayout: this.bigNumberFormatPipe.transform(
                totalPayout,
                18,
                true,
                false,
                0
              ),
            }
          )}</p>`;
          this.glbC.showPopupMessage({
            title: `${t["POPUPS.close_stake"]} "${stake.description}"`,
            msg: popupMessage, // constructedMessage,
            declineButtonText: `${t["BUTTONS.abort"]}`,
            acceptButtonText: `${t["POPUPS.close_stake"]}`,
            removeOtherPopups: true,
            extraClass: "dontHideDropdown",
            onAcceptCallback: () => {
              console.log(
                "closeStakeClicked onAcceptCallback called data: ",
                data
              );
              console.log(
                "closeStakeClicked onAcceptCallback called stake: ",
                stake
              );
              stake.withdrawProgress = true;
              this.contractService
                .unstake(stake.sessionId)
                .then(() => {
                  console.log("closed stake successfully");
                  this.stakesAreLoaded = false;
                  stake.withdrawProgress = false;
                  this.contractService.updateREXBalance(true);
                  this.contractService.updateETHBalance(true);
                  this.loadUserStakes();
                  this.updateREXamount();
                })
                .catch((error) => {
                  console.log("Error is closing stake: ", error);
                  stake.withdrawProgress = false;
                });
            },
            onCancelCallback: () => {
              this.actionButtonChecked = this.actionButtonChecked.map(
                (x) => false
              );
            },
          });
        });
    }
  }

  renameStakeClicked(stake: DepositInterface, event: any) {
    console.log("renameStakeClicked stake: ", stake);
    if (!event.target.classList.contains("disabled")) {
      this.translateService
        .get([
          "POPUPS.rename_stake",
          "POPUPS.enter_the_new_name_for_your_stake",
          "POPUPS.enter_new_name",
          "BUTTONS.abort",
        ])
        .toPromise()
        .then((t) => {
          this.glbC.showPopupMessage({
            title: `${t["POPUPS.rename_stake"]} "${stake.description}"`,
            msg: `<p>${t["POPUPS.enter_the_new_name_for_your_stake"]}</p>`,
            inputFieldPlaceholder: `${t["POPUPS.enter_new_name"]}`,
            declineButtonText: `${t["BUTTONS.abort"]}`,
            acceptButtonText: `${t["POPUPS.rename_stake"]}`,
            removeOtherPopups: true,
            extraClass: "dontHideDropdown",
            onAcceptCallback: (newName) => {
              console.log(
                `renameStakeClicked onAcceptCallback newName: ${newName}`
              );
              if (newName) {
                // TODO further name validation
                stake.renameProgress = true;
                this.contractService
                  .renameStake(stake.sessionId, newName)
                  .then(() => {
                    this.contractService.updateETHBalance(true);
                    this.loadUserStakes();

                    this.stakesAreLoaded = false;
                    stake.renameProgress = false;
                  })
                  .catch(() => {
                    stake.renameProgress = false;
                  });
              } else {
                // TODO show error invalid input
              }
            },
            onCancelCallback: () => {
              console.log("abort");
              this.actionButtonChecked = this.actionButtonChecked.map(
                (x) => false
              );
            },
          });
        });
    }

    const perc = this.getPercentActive(stake);
    const duration = this.glbC.currRDay - stake.start;
    const apy = Math.round(perc / duration);
    console.log(perc);
    console.log(duration);
    console.log(apy);
  }

  splitStakeClicked(stake: DepositInterface, event: any) {
    console.log("splitStakeClicked stake: ", stake);
    if (!event.target.classList.contains("disabled")) {
      this.translateService
        .get([
          "POPUPS.split_stake",
          "POPUPS.split_stake_popup_message",
          "BUTTONS.abort",
        ])
        .toPromise()
        .then((t) => {
          this.glbC.showPopupMessage({
            title: `${t["POPUPS.split_stake"]} "${stake.description}"`,
            msg: `<p>${t["POPUPS.split_stake_popup_message"]}</p>`,
            declineButtonText: `${t["BUTTONS.abort"]}`,
            acceptButtonText: `${t["POPUPS.split_stake"]}`,
            removeOtherPopups: true,
            extraClass: "dontHideDropdown",
            onAcceptCallback: () => {
              console.log("splitStakeClicked onAcceptCallback");
              stake.splitProgress = true;
              this.contractService
                .splitstake(stake.sessionId, stake.amount)
                .then(() => {
                  this.contractService.updateETHBalance(true);
                  this.loadUserStakes();
                  this.stakesAreLoaded = false;
                  stake.splitProgress = false;
                })
                .catch(() => {
                  stake.splitProgress = false;
                });
            },
            onCancelCallback: () => {
              this.actionButtonChecked = this.actionButtonChecked.map(
                (x) => false
              );
            },
          });
        });
    }
  }

  withdrawStakeClicked(stake: DepositInterface, event: any) {
    let currentSelectedDaysVar = 0;
    let currentRewardsVar = 0;
    let currentPenaltyVar = 0;
    console.log("withdrawStakeClicked stake: ", stake);
    if (!event.target.classList.contains("disabled")) {
      const p2Variant =
        stake.scrape > 0
          ? "POPUPS.withdraw_rewards_message_template_already_scraped"
          : "POPUPS.withdraw_rewards_message_template_not_scraped";
      this.translateService
        .get([
          "POPUPS.withdraw_rewards_from_stake",
          "POPUPS.enter_number_of_days_to_scrape",
          "POPUPS.possilbe_rewards",
          "BUTTONS.withdraw_rewards",
          "BUTTONS.abort",
          "BUTTONS.calc_values",
          "BUTTONS.ok",
        ])
        .toPromise()
        .then((t) => {
          const popupMessage = `<p class="popup-align-left">${this.translateService.instant(
            p2Variant,
            {
              end: stake.end,
              lastWithdrawDay: stake.scrape > 0 ? stake.scrape : 0,
              maxDays:
                stake.scrape > 0
                  ? this.glbC.currRDay - stake.scrape
                  : this.glbC.currRDay - stake.start,
              possibleRewards: this.bigNumberFormatPipe.transform(
                stake.possibleRewardsMax,
                18,
                true,
                false,
                0
              ),
            }
          )}</p>`;
          this.glbC.showPopupMessage({
            title: `${t["POPUPS.withdraw_rewards_from_stake"]} "${stake.description}"`,
            msg: popupMessage,
            inputFieldPlaceholder: `${t["POPUPS.enter_number_of_days_to_scrape"]}`,
            declineButtonText: `${t["BUTTONS.abort"]}`,
            acceptButtonText: `${t["BUTTONS.withdraw_rewards"]}`,
            extraButtonText: `${t["BUTTONS.calc_values"]}`,
            removeOtherPopups: true,
            extraClass: "dontHideDropdown",
            onAcceptCallback: (days) => {
              days = Number(days);
              console.log(
                `withdrawStakeClicked onAcceptCallback days: ${days}`
              );
              console.log(
                "max withdraw:",
                stake.scrape > 0
                  ? this.glbC.currRDay - stake.scrape
                  : this.glbC.currRDay - stake.start
              );
              if (
                days &&
                days > 0 &&
                days <=
                  (stake.scrape > 0
                    ? this.glbC.currRDay - stake.scrape
                    : this.glbC.currRDay - stake.start)
              ) {
                if (
                  days ===
                  (stake.scrape > 0
                    ? this.glbC.currRDay - stake.scrape
                    : this.glbC.currRDay - stake.start)
                ) {
                  console.log("scrape all days");
                } else {
                  console.log("dont scrape all days");
                }
                stake.scrapeProgress = true;
                this.contractService
                  .scrapeRewards(stake.sessionId, days)
                  .then(() => {
                    this.contractService.updateREXBalance(true);
                    this.contractService.updateETHBalance(true);
                    this.loadUserStakes();
                    this.updateREXamount();
                    this.stakesAreLoaded = false;
                    stake.scrapeProgress = false;
                  })
                  .catch(() => {
                    stake.scrapeProgress = false;
                  });
              } else {
              }
            },
            onCancelCallback: () => {
              this.actionButtonChecked = this.actionButtonChecked.map(
                (x) => false
              );
            },
            validationCheck: (days) => {
              let errormessage: string;
              let maxDaysToScrape: number;
              if (stake.scrape > 0) {
                maxDaysToScrape =
                  Number(this.glbC.currRDay) - Number(stake.scrape);
              } else {
                maxDaysToScrape =
                  Number(this.glbC.currRDay) - Number(stake.start);
              }
              console.log(days);
              console.log("max Days to scape:", maxDaysToScrape);
              if (
                Number(days) >= 1 &&
                Number(days) <= Number(maxDaysToScrape)
              ) {
                console.log("entered number is valid");
                return true;
              }
              if (Number(days) === 0) {
                errormessage =
                  "You have to enter a higher number than 0 to withdraw some interest.";
              } else if (Number(days) > Number(maxDaysToScrape)) {
                errormessage =
                  "You can't enter a higher number of days than you are allowed to withdraw. <br><br>Please adjust your input.";
              }
              this.translateService
                .get(["POPUPS.incorrect_input", "BUTTONS.ok"])
                .toPromise()
                .then((p) => {
                  this.glbC.showPopupMessage({
                    title: `${p["POPUPS.incorrect_input"]}`,
                    msg: `<p>${errormessage}</p>`,
                    acceptButtonText: `${p["BUTTONS.ok"]}`,
                    onAcceptCallback: () => {
                      console.log("accept clicked");
                    },
                  });
                  return false;
                })
                .catch((error) => {
                  console.warn("error in translation", error);
                  return false;
                });
            },
            onExtraButtonCallback: (days) => {
              let maxDaysToScrape: number;
              if (stake.scrape > 0) {
                maxDaysToScrape =
                  Number(this.glbC.currRDay) - Number(stake.scrape);
              } else {
                maxDaysToScrape =
                  Number(this.glbC.currRDay) - Number(stake.start);
              }
              console.log("in extra button click callback for days:", days);
              this.glbC.globalLoading = true;
              this.contractService
                .getRewardsandPenalty(stake.sessionId, days)
                .then((result) => {
                  this.glbC.globalLoading = false;

                  if (days > maxDaysToScrape) {
                    days = maxDaysToScrape;
                  }
                  console.log(`Result for ${days} Days: `, result);
                  currentSelectedDaysVar = days;

                  // eslint-disable-next-line prefer-destructuring
                  currentRewardsVar = Number(result[1]) / 1e18;
                  // eslint-disable-next-line prefer-destructuring
                  currentPenaltyVar = Number(result[2]) / 1e18;
                  console.log(currentRewardsVar);
                  console.log(currentPenaltyVar);
                  // Open another window to show possible rewards/penalties
                  const popupMessageText = `<p class="popup-align-left">${this.translateService.instant(
                    "POPUPS.withdraw_numbers",
                    {
                      currentSelectedDays: currentSelectedDaysVar,
                      currentRewards: this.bigNumberFormatPipe.transform(
                        currentRewardsVar,
                        0,
                        true,
                        false,
                        0
                      ),
                      currentShares: this.bigNumberFormatPipe.transform(
                        stake.shares,
                        18,
                        true,
                        false,
                        0
                      ),
                      currentPenalty: this.bigNumberFormatPipe.transform(
                        currentPenaltyVar,
                        0,
                        true,
                        false,
                        0
                      ),
                      sharesAfter: this.bigNumberFormatPipe.transform(
                        Number(stake.shares) / 1e18 - currentPenaltyVar,
                        0,
                        true,
                        false,
                        0
                      ),
                    }
                  )}</p>`;
                  this.glbC.showPopupMessage({
                    title: `${t["POPUPS.possilbe_rewards"]}  for stake "${stake.description}"`,
                    msg: popupMessageText,
                    acceptButtonText: `${t["BUTTONS.ok"]}`,
                    onAcceptCallback: () => {
                      console.log("accept clicked");
                    },
                    extraClass: "dontHideDropdown",
                  });
                })
                .catch((error) => {
                  this.glbC.globalLoading = false;
                  console.warn(
                    "Error in request of penalty and rewards",
                    error
                  );
                  this.glbC.showHelpMessage("contract_not_yet_triggered");
                });
            },
          });
        });
    }
  }

  transferStakeClicked(stake: DepositInterface, event: any) {
    if (!event.target.classList.contains("disabled")) {
      console.log("transferStakeClicked stake: ", stake);
      this.translateService
        .get([
          "POPUPS.transfer_stake",
          "POPUPS.transfer_stake_message",
          "POPUPS.enter_address",
          "BUTTONS.abort",
        ])
        .toPromise()
        .then((t) => {
          this.glbC.showPopupMessage({
            title: `${t["POPUPS.transfer_stake"]} "${stake.description}"`,
            msg: `<p>${t["POPUPS.transfer_stake_message"]}</p>`,
            inputFieldPlaceholder: `${t["POPUPS.enter_address"]}`,
            declineButtonText: `${t["BUTTONS.abort"]}`,
            acceptButtonText: `${t["POPUPS.transfer_stake"]}`,
            removeOtherPopups: true,
            extraClass: "dontHideDropdown",
            onAcceptCallback: (walletAddress) => {
              console.log(
                `transferStakeClicked onAcceptCallback walletAddress: ${walletAddress}`
              );

              // TODO further walletAddress validation
              stake.moveProgress = true;
              this.contractService
                .movestake(stake.sessionId, walletAddress)
                .then(() => {
                  this.contractService.updateREXBalance(true);
                  this.loadUserStakes();
                  this.stakesAreLoaded = false;
                  stake.moveProgress = false;
                })
                .catch(() => {
                  stake.moveProgress = false;
                });
            },
            onCancelCallback: () => {
              this.actionButtonChecked = this.actionButtonChecked.map(
                (x) => false
              );
            },
            validationCheck: (walletAddress) => {
              if (walletAddress.length === 42) {
                return true;
              }
              // Show error message if input data was not correct
              this.translateService
                .get([
                  "POPUPS.incorrect_input",
                  "POPUPS.incorrect_address_length",
                  "BUTTONS.ok",
                ])
                .toPromise()
                .then((p) => {
                  this.glbC.showPopupMessage({
                    title: `${p["POPUPS.incorrect_input"]}`,
                    msg: `<p>${p["POPUPS.incorrect_address_length"]}</p>`,
                    acceptButtonText: `${p["BUTTONS.ok"]}`,
                    onAcceptCallback: () => {
                      console.log("accept clicked");
                    },
                  });
                  return false;
                });
              return false;
            },
          });
        });
    }
  }

  offerStakeClicked(stake: DepositInterface, event: any) {
    console.log("offerStakeClicked stake: ", stake);
    if (!event.target.classList.contains("disabled")) {
      this.translateService
        .get([
          "POPUPS.offer_stake",
          "POPUPS.choose_offer_days_and_cost",
          "POPUPS.revoke_stake",
          "POPUPS.enter_offer_duration",
          "POPUPS.enter_offer_price",
          "BUTTONS.abort",
          "BUTTONS.offer_stake",
        ])
        .toPromise()
        .then((t) => {
          const valuePrincipal =
            Number(stake.amount) * this.glbC.currentREXpricePublic;
          const valueRewards =
            Number(stake.possibleRewardsMax) * this.glbC.currentREXpricePublic;
          const popupMessageText = `<p>${
            t["POPUPS.choose_offer_days_and_cost"]
          }</p><br><p>${
            t["POPUPS.revoke_stake"]
          }</p><br><p>${this.translateService.instant(
            "POPUPS.current_worth_stake_in_busd",
            {
              valuePrincipal: this.bigNumberFormatPipe.transform(
                valuePrincipal,
                18,
                true,
                false,
                2
              ),

              valueRewards: this.bigNumberFormatPipe.transform(
                valueRewards,
                18,
                true,
                false,
                2
              ),
            }
          )}</p>`;

          this.glbC.showPopupMessage({
            title: `${t["POPUPS.offer_stake"]} "${stake.description}"`,
            msg: popupMessageText,
            inputFieldPlaceholder: `${t["POPUPS.enter_offer_duration"]}`,
            inputFieldPlaceholder2: `${t["POPUPS.enter_offer_price"]}`,
            inputType: "number",
            inputType2: "number",
            declineButtonText: `${t["BUTTONS.abort"]}`,
            acceptButtonText: `${t["BUTTONS.offer_stake"]}`,
            removeOtherPopups: true,
            extraClass: "dontHideDropdown",
            onAcceptCallback: (offerDuration, offerPrice, e) => {
              console.log(
                `Offer stake confirmed. Duration:${offerDuration} Price: ${offerPrice}`
              );
              stake.offerProgress = true;
              this.contractService
                .offerStake(stake.sessionId, offerPrice, offerDuration)
                .then(() => {
                  this.contractService.updateETHBalance(true);
                  this.loadUserStakes();
                  this.stakesAreLoaded = false;
                  stake.offerProgress = false;
                })
                .catch(() => {
                  stake.offerProgress = false;
                });
            },
            onCancelCallback: () => {
              this.actionButtonChecked = this.actionButtonChecked.map(
                (x) => false
              );
            },
            validationCheck: (offerDuration, offerPrice) => {
              let errormessage: string;
              if (
                offerDuration >= 1 &&
                offerDuration <= 30 &&
                offerPrice >= 5
              ) {
                console.log(offerDuration);
                console.log(this.glbC.currRDay);
                console.log(stake.end);
                console.log(Number(offerDuration) + Number(this.glbC.currRDay));
                if (
                  Number(offerDuration) + Number(this.glbC.currRDay) >=
                  Number(stake.end)
                ) {
                  errormessage =
                    "Your entered offer duration exceeds your stake duration. <br><br> Please enter a smaller number.";
                } else {
                  console.log("offerDuration and offerPrice is correct");
                  return true;
                }
              }
              if (offerDuration >= 1 && offerDuration <= 30 && offerPrice < 5) {
                errormessage =
                  "Your entered offer price is too low.<br><br> Please enter a higher number.";
              } else if (offerDuration < 1 && offerPrice >= 5) {
                errormessage =
                  "Your entered offer duration is too low. <br><br>Please enter a higher number.";
              } else if (offerDuration > 30 && offerPrice >= 5) {
                errormessage =
                  "Your entered offer duration is too high. <br><br>Please enter a number between 1 and 30.";
              } else if (offerDuration > 30 && offerPrice < 5) {
                errormessage =
                  "Your entered offer price is too low and the offer duration is too high. <br><br>Please adjust both numbers to proceed.";
              } else if (
                typeof offerDuration === "undefined" ||
                typeof offerPrice === "undefined"
              ) {
                errormessage =
                  "Your didn't fill out the required input fields. <br><br> Please fill out both of them.";
              }
              // Show error message if input data was not correct
              this.translateService
                .get(["POPUPS.incorrect_input", "BUTTONS.ok"])
                .toPromise()
                .then((p) => {
                  this.glbC.showPopupMessage({
                    title: `${p["POPUPS.incorrect_input"]}`,
                    msg: `<p>${errormessage}</p>`,
                    acceptButtonText: `${p["BUTTONS.ok"]}`,
                    onAcceptCallback: () => {
                      console.log("accept clicked");
                    },
                  });
                  return false;
                });
            },
          });
        });
    }
  }

  sortContent(sortingType: any, tabletype: string) {
    const integerTypes: string[] = [
      "start",
      "end",
      "amount",
      "shares",
      "possibleRewardsEarly",
      "isTrex",
      "close",
      "apy",
    ];
    const stringTypes: string[] = ["description"];

    if (integerTypes.includes(sortingType)) {
      this.filteredStakes[tabletype].sort(
        (a, b) => Number(a[sortingType]) - Number(b[sortingType])
      );
    } else if (stringTypes.includes(sortingType)) {
      this.filteredStakes[tabletype].sort((a, b) =>
        a[sortingType].localeCompare(b[sortingType])
      );
    } else if (sortingType === "duration") {
      this.filteredStakes[tabletype].sort(
        (a, b) =>
          Number(Number(a.end) - Number(a.start)) -
          Number(Number(b.end) - Number(b.start))
      );
    } else if (sortingType === "stakedDays") {
      this.filteredStakes[tabletype].sort(
        (a, b) =>
          Number(Number(a.close) - Number(a.start)) -
          Number(Number(b.close) - Number(b.start))
      );
    } else if (sortingType === "progress") {
      this.filteredStakes[tabletype].sort(
        (a, b) =>
          Number(this.getDayProgress(a)) - Number(this.getDayProgress(b))
      );
    }

    console.log(JSON.parse(JSON.stringify(this.stakingData[tabletype].stakes)));
  }

  translateAppliedFilterNames() {
    const valuesArray = Object.values(this.appliedFilterDisplayNames);
    this.translateService
      .get(valuesArray)
      .toPromise()
      .then((p) => {
        this.appliedFilterDisplayNames = {
          hasTREX: `${p[valuesArray[0]]}`,
          minStartDate: `${p[valuesArray[1]]}`,
          maxStartDate: `${p[valuesArray[2]]}`,
          minEndDate: `${p[valuesArray[3]]}`,
          maxEndDate: `${p[valuesArray[4]]}`,
          minDuration: `${p[valuesArray[5]]}`,
          maxDuration: `${p[valuesArray[6]]}`,
          minProgress: `${p[valuesArray[7]]}`,
          maxProgress: `${p[valuesArray[8]]}`,
          minPrincipal: `${p[valuesArray[9]]}`,
          maxPrincipal: `${p[valuesArray[10]]}`,
          minShares: `${p[valuesArray[11]]}`,
          maxShares: `${p[valuesArray[12]]}`,
          minRewardsPayable: `${p[valuesArray[13]]}`,
          maxRewardsPayble: `${p[valuesArray[14]]}`,
          minCloseDate: `${p[valuesArray[15]]}`,
          maxCloseDate: `${p[valuesArray[16]]}`,
          minStakedDays: `${p[valuesArray[17]]}`,
          maxStakedDays: `${p[valuesArray[18]]}`,
          minPenalty: `${p[valuesArray[19]]}`,
          maxPenalty: `${p[valuesArray[20]]}`,
          minRewards: `${p[valuesArray[21]]}`,
          maxRewards: `${p[valuesArray[22]]}`,
          minTransferredDate: `${p[valuesArray[23]]}`,
          maxTransferredDate: `${p[valuesArray[24]]}`,
        };
      });
  }

  filterTable(event: any, table: string) {
    console.log("filter in front");
    this.showFilterRow[table] = false;
    console.log(event);
    this.filterAllNotNullElements(event, table);
  }

  filterAllNotNullElements(formControl: any, offerType: string) {
    let filterReturnArray: any[] = [...this.stakingData[offerType].stakes];
    if (formControl.hasTREX) {
      filterReturnArray = filterReturnArray.filter((stake) => {
        return formControl.hasTREX === stake.isTrex;
      });
    }
    if (formControl.minStartDate) {
      const inputUnix = Math.floor(
        new Date(formControl.minStartDate).getTime() / 1000
      );
      filterReturnArray = filterReturnArray.filter((stake) => {
        return (
          inputUnix <=
          Math.floor(this.glbC.rexDayToTimestamp(Number(stake.start)) / 1000)
        );
      });
    }
    if (formControl.maxStartDate) {
      const inputUnix = Math.floor(
        new Date(formControl.maxStartDate).getTime() / 1000
      );
      filterReturnArray = filterReturnArray.filter((stake) => {
        return (
          inputUnix >=
          Math.floor(this.glbC.rexDayToTimestamp(Number(stake.start)) / 1000)
        );
      });
    }
    if (formControl.minEndDate) {
      const inputUnix = Math.floor(
        new Date(formControl.minEndDate).getTime() / 1000
      );
      filterReturnArray = filterReturnArray.filter((stake) => {
        return (
          inputUnix <=
          Math.floor(this.glbC.rexDayToTimestamp(Number(stake.end)) / 1000)
        );
      });
    }
    if (formControl.maxEndDate) {
      const inputUnix = Math.floor(
        new Date(formControl.maxEndDate).getTime() / 1000
      );
      filterReturnArray = filterReturnArray.filter((stake) => {
        return (
          inputUnix >=
          Math.floor(this.glbC.rexDayToTimestamp(Number(stake.end)) / 1000)
        );
      });
    }
    if (formControl.minCloseDate) {
      const inputUnix = Math.floor(
        new Date(formControl.minCloseDate).getTime() / 1000
      );
      filterReturnArray = filterReturnArray.filter((stake) => {
        return (
          inputUnix <=
          Math.floor(this.glbC.rexDayToTimestamp(Number(stake.close)) / 1000)
        );
      });
    }
    if (formControl.maxCloseDate) {
      const inputUnix = Math.floor(
        new Date(formControl.maxCloseDate).getTime() / 1000
      );
      filterReturnArray = filterReturnArray.filter((stake) => {
        return (
          inputUnix >=
          Math.floor(this.glbC.rexDayToTimestamp(Number(stake.close)) / 1000)
        );
      });
    }
    if (formControl.minTransferredDate) {
      const inputUnix = Math.floor(
        new Date(formControl.minTransferredDate).getTime() / 1000
      );
      filterReturnArray = filterReturnArray.filter((stake) => {
        return (
          inputUnix <=
          Math.floor(this.glbC.rexDayToTimestamp(Number(stake.close)) / 1000)
        );
      });
    }
    if (formControl.maxTransferredDate) {
      const inputUnix = Math.floor(
        new Date(formControl.maxTransferredDate).getTime() / 1000
      );
      filterReturnArray = filterReturnArray.filter((stake) => {
        return (
          inputUnix >=
          Math.floor(this.glbC.rexDayToTimestamp(Number(stake.close)) / 1000)
        );
      });
    }

    if (formControl.minDuration) {
      filterReturnArray = filterReturnArray.filter((stake) => {
        return (
          Number(formControl.minDuration) <=
          Number(Number(stake.end) - Number(stake.start))
        );
      });
    }
    if (formControl.maxDuration) {
      filterReturnArray = filterReturnArray.filter((stake) => {
        return (
          Number(formControl.maxDuration) >=
          Number(Number(stake.end) - Number(stake.start))
        );
      });
    }
    if (formControl.minStakedDays) {
      filterReturnArray = filterReturnArray.filter((stake) => {
        return (
          Number(formControl.minStakedDays) <=
          Number(Number(stake.close) - Number(stake.start))
        );
      });
    }
    if (formControl.maxStakedDays) {
      filterReturnArray = filterReturnArray.filter((stake) => {
        return (
          Number(formControl.maxStakedDays) >=
          Number(Number(stake.close) - Number(stake.start))
        );
      });
    }

    if (formControl.minProgress) {
      filterReturnArray = filterReturnArray.filter((stake) => {
        return Number(formControl.minProgress) <= this.getDayProgress(stake);
      });
    }
    if (formControl.maxProgress) {
      filterReturnArray = filterReturnArray.filter((stake) => {
        return Number(formControl.maxProgress) >= this.getDayProgress(stake);
      });
    }
    if (formControl.minPrincipal) {
      filterReturnArray = filterReturnArray.filter((stake) => {
        return Number(formControl.minPrincipal) <= Number(stake.amount) / 1e18;
      });
    }
    if (formControl.maxPrincipal) {
      filterReturnArray = filterReturnArray.filter((stake) => {
        return Number(formControl.maxPrincipal) >= Number(stake.amount) / 1e18;
      });
    }
    if (formControl.minShares) {
      filterReturnArray = filterReturnArray.filter((stake) => {
        return Number(formControl.minShares) <= Number(stake.shares) / 1e18;
      });
    }
    if (formControl.maxShares) {
      filterReturnArray = filterReturnArray.filter((stake) => {
        return Number(formControl.maxShares) >= Number(stake.shares) / 1e18;
      });
    }
    if (formControl.minRewardsPayable) {
      filterReturnArray = filterReturnArray.filter((stake) => {
        return (
          Number(formControl.minRewardsPayable) <=
          Number(stake.possibleRewardsEarly) / 1e18
        );
      });
    }
    if (formControl.maxRewardsPayble) {
      filterReturnArray = filterReturnArray.filter((stake) => {
        return (
          Number(formControl.maxRewardsPayble) >=
          Number(stake.possibleRewardsEarly) / 1e18
        );
      });
    }
    if (formControl.minPenalty) {
      filterReturnArray = filterReturnArray.filter((stake) => {
        return Number(formControl.minPenalty) <= Number(stake.penalty) / 1e18;
      });
    }
    if (formControl.maxPenalty) {
      filterReturnArray = filterReturnArray.filter((stake) => {
        return Number(formControl.maxPenalty) >= Number(stake.penalty) / 1e18;
      });
    }
    if (formControl.minRewards) {
      filterReturnArray = filterReturnArray.filter((stake) => {
        return (
          Number(formControl.minRewards) <=
          Number(stake.possibleRewardsEarly) / 1e18
        );
      });
    }
    if (formControl.maxRewards) {
      filterReturnArray = filterReturnArray.filter((stake) => {
        return (
          Number(formControl.maxRewards) >=
          Number(stake.possibleRewardsEarly) / 1e18
        );
      });
    }

    console.log(filterReturnArray);
    this.filteredStakes[offerType] = filterReturnArray;
    console.log(this.filteredStakes[offerType]);
  }

  deleteAllFilters(table: string) {
    console.log("delete all filters");
    this.showFilterRow[table] = false;
    this.filteredStakes[table] = this.stakingData[table].stakes;
  }

  getPercentageRewards(stake: DepositInterface) {
    const rewardPercentage =
      (Number(stake.possibleRewardsMax) / Number(stake.amount)) * 100;
    return rewardPercentage;
  }

  updateREXamount() {
    this.contractService
      .getRexAmount()
      .then((rexAmount) => {
        this.rexAmountIsLoaded = true;
        this.glbC.rexAmount = rexAmount;
        console.log("rexAmount: ", rexAmount);
      })
      .catch((error) => {
        console.error("Error is get rex amount:", error);
      });
  }

  updateStakedChart(timeNum: number) {
    this.httpService.updateChart(
      this.httpService.DBglobals,
      "timestamp",
      "totalRexStaked",
      2,
      0,
      timeNum,
      this.stakedChart
    );
  }

  /**
   * Updates the first chart on the page "your statistics"
   * @param timeperiod Either 0 (="week"), 1 (="month"), 2 (="year") or 3(="max")
   * @param allStakes All stakes linked to the connected account
   * @param displayType Eighter "staked", "intereset" or "combined"
   */
  async updatePersonalStakedChart(timeperiod: number, allStakes) {
    console.log(allStakes);
    // Change active state of buttons
    this.httpService.activeTimeArray[4][0].forEach((value, index) => {
      this.httpService.activeTimeArray[4][0][index] = false;
    });
    this.httpService.activeTimeArray[4][0][timeperiod] = true;

    await this.calcLabelArrayChart1(timeperiod, allStakes);
    await this.calcValueArrayChart1(allStakes);

    const test = [...this.chart1LabelArr];
    console.log(test);

    [, this.lineChart.label] = this.chart1LabelArr;

    this.chart1LabelArr[0] = [];
    this.chart1LabelArr[1] = [];
  }

  /** Calculates the days depending on the selected timeperiod
   *
   * @param timeperiod Either 0 (="week"), 1 (="month"), 2 (="year") or 3(="max")
   * @param allStakes All existing stakes (closed and opened) of the linked account
   */
  calcLabelArrayChart1(timeperiod: number, allStakes: any) {
    switch (timeperiod) {
      case 0:
        for (let i = this.glbC.currRDay - 6; i <= this.glbC.currRDay; i++) {
          this.chart1LabelArr[0].push(i);
        }
        break;

      case 1:
        for (let i = this.glbC.currRDay - 30; i <= this.glbC.currRDay; i++) {
          this.chart1LabelArr[0].push(i);
        }
        break;

      case 2:
        for (let i = this.glbC.currRDay - 364; i <= this.glbC.currRDay; i++) {
          this.chart1LabelArr[0].push(i);
        }
        break;

      case 3:
        {
          const stakesStartDays = [];
          this.allStakesConcat = Array.prototype.concat.apply(
            [],
            Object.values(allStakes)
          );
          for (let i = 0; i < this.allStakesConcat.length; i++) {
            stakesStartDays[i] = Number(this.allStakesConcat[i].start);
          }
          for (
            let i = Math.min(...stakesStartDays) - 2;
            i < this.glbC.currRDay;
            i++
          ) {
            this.chart1LabelArr[0].push(i);
          }
        }
        break;

      default:
        break;
    }

    for (let i = 0; i < this.chart1LabelArr[0].length; i++) {
      this.chart1LabelArr[1][i] = this.datePipe.transform(
        this.glbC.rexDayToTimestamp(this.chart1LabelArr[0][i]),
        "MMM dd, yyyy "
      );
    }
  }

  /** Calculates the corresponding staking amount for the given input array.
   * Further, it also prints the values to the chart
   * @param allStakes All stakes linked to that account
   */
  calcValueArrayChart1(allStakes) {
    const allStakesValuesArray = [];
    this.allStakesChart1Objects = [];

    this.allStakesConcat = Array.prototype.concat.apply(
      [],
      Object.values(allStakes)
    );

    for (let i = 0; i < this.allStakesConcat.length; i++) {
      allStakesValuesArray.push([]);
    }
    // Fill array with the staked amount of each stake since REX Day 0
    for (let s = 0; s < this.allStakesConcat.length; s++) {
      for (let i = 0; i <= this.glbC.currRDay; ++i) {
        if (
          Number(this.allStakesConcat[s].start) <= i &&
          Number(this.allStakesConcat[s].end) > i
        ) {
          allStakesValuesArray[s][i] =
            this.allStakesConcat[s].amount.c[0] / 10000;
        } else {
          allStakesValuesArray[s][i] = 0;
        }
      }
    }

    // Only take as many days as are to be displayed. If there are days before REX Day 0, don't display something at that time
    if (this.chart1LabelArr[1].length <= this.glbC.currRDay) {
      for (let i = 0; i < allStakesValuesArray.length; i++) {
        allStakesValuesArray[i] = allStakesValuesArray[i].slice(
          -this.chart1LabelArr[1].length
        );
      }
    } else {
      for (
        let i = 0;
        i < this.chart1LabelArr[1].length - 1 - this.glbC.currRDay;
        i++
      ) {
        for (let j = 0; j < allStakesValuesArray.length; j++) {
          allStakesValuesArray[j].unshift(undefined);
        }
      }
    }

    // Create sum of all stakes for "combined Stakes"
    let sumREXonDay: Number = 0;
    const sumStakedREXarr = [];
    for (let i = 0; i < this.chart1LabelArr[1].length; i++) {
      sumREXonDay = 0;
      for (let j = 0; j < allStakesValuesArray.length; j++) {
        sumREXonDay += allStakesValuesArray[j][i];
      }
      sumStakedREXarr.push(sumREXonDay);
    }

    this.allStakesChart1Objects.push({
      label: "REX staked",
      data: sumStakedREXarr,
      borderColor: this.httpService.colorRexBlue,
      pointBackgroundColor: this.httpService.colorRexBlue,
      pointRadius: 1,
      fill: true,
    });

    this.lineChart.data = this.allStakesChart1Objects;
  }

  /**
   * Checks if there is a local cookie stored for the table. If yes, use it as table size
   * @param cookieName Name of the set cookie
   * @param tableName Which table will it affect
   */
  checkCookieForTableLength(cookieName: string, tableName: string) {
    // Check if there is a cookie set for table size
    if (this.glbC.cookieService.get(cookieName)) {
      const cookieTableLength = Number(this.glbC.cookieService.get(cookieName));
      this.amountStakesPerPage[tableName] = cookieTableLength;

      this.currentPagination[tableName].last =
        this.currentPagination[tableName].first + cookieTableLength;
    }
  }

  translateSortingTypes() {
    this.translateService
      .get(this.sortingOptions)
      .toPromise()
      .then((t) => {
        this.sortingOptionsTranslated = [
          t[this.sortingOptions[0]],
          t[this.sortingOptions[1]],
          t[this.sortingOptions[2]],
          t[this.sortingOptions[3]],
          t[this.sortingOptions[4]],
          t[this.sortingOptions[5]],
          t[this.sortingOptions[6]],
          t[this.sortingOptions[7]],
          t[this.sortingOptions[8]],
          t[this.sortingOptions[9]],
          t[this.sortingOptions[10]],
          t[this.sortingOptions[11]],
          t[this.sortingOptions[12]],
          t[this.sortingOptions[13]],
        ];
        this.pageIsTranslated = true;
      });
  }

  translateFilterLists(filterType: string) {
    const valuesArray = this[filterType].map(Object.values);
    const valuesArrayFlat = [].concat.apply([], valuesArray);
    this.translateService
      .get(valuesArrayFlat)
      .toPromise()
      .then((t) => {
        let counter = 0;
        for (let i = 2; i < valuesArrayFlat.length; i = i + 3) {
          this[filterType][counter].displayName = t[valuesArrayFlat[i]];
          counter++;
        }
        // Translates the checkbox texts
        for (let i = 0; i < this[filterType].length; i++) {
          if (this[filterType][i].checkboxText) {
            const indexInTranslatedArray = i * 3 + 1;
            this[filterType][i].checkboxText =
              t[valuesArrayFlat[indexInTranslatedArray]];
          }
        }
      });
  }

  /**
   * This function downloads all stakes of the logged in user in form of an csv file
   */
  downloadStakes() {
    console.log("download stakes");
    let allStakesArray = [].concat.apply([], Object.values(this.allStakes));
    let transformedStakes = [];
    let walletID = this.glbC.account.address;

    let exportArray = [
      [
        "Description",
        "State",
        "Amount (in REX)",
        "Shares",
        "Start Date",
        "End Date",
        "Close Date",
        "Duration (in Days)",
        "APY (in %)",
        "is TREX",
        "is Irrevocable",
        "is Split",
        "Stake ID",
        "Wallet ID",
      ],
      [],
    ];
    console.log(allStakesArray);

    for (let i = 0; i < allStakesArray.length; i++) {
      let name = allStakesArray[i].description;
      if (name === "🤴 AIRDROP") {
        name = "AIRDROP";
      } else if (name === "🤴 AUCTION") {
        name = "AUCTION";
      }
      let stakeID = allStakesArray[i].sessionId;
      let amount = (allStakesArray[i].amount.c[0] / 10000).toFixed(2);
      let shares = (allStakesArray[i].shares.c[0] / 10000).toFixed(2);
      let startDate = this.httpService.timestampToDate(
        this.glbC.rexDayToTimestamp(allStakesArray[i].start) / 1000,
        true
      );
      let endDate = this.httpService.timestampToDate(
        this.glbC.rexDayToTimestamp(allStakesArray[i].end) / 1000,
        true
      );
      let closeDate =
        allStakesArray[i].close !== "0"
          ? this.httpService.timestampToDate(
              this.glbC.rexDayToTimestamp(allStakesArray[i].close) / 1000,
              true
            )
          : "x";
      let duration =
        Number(allStakesArray[i].end) - Number(allStakesArray[i].start);

      let apy = allStakesArray[i].apy.toFixed(2);
      let isTrex = allStakesArray[i].isTrex ? "yes" : "no";
      let isIrr = allStakesArray[i].isIrr ? "yes" : "no";
      let isSplit = allStakesArray[i].isSplit ? "yes" : "no";
      let state;
      if (allStakesArray[i].isActive === 0) {
        state = "closed";
      } else if (allStakesArray[i].isActive === 1) {
        state = "active";
      } else if (allStakesArray[i].isActive === 2) {
        state = "offered";
      } else if (allStakesArray[i].isActive === 3) {
        state = "sold";
      } else if (allStakesArray[i].isActive === 3) {
        state = "transferred";
      }
      transformedStakes.push([
        name,
        state,
        amount,
        shares,
        startDate,
        endDate,
        closeDate,
        duration,
        apy,
        isTrex,
        isIrr,
        isSplit,
        stakeID,
        walletID,
      ]);
    }

    exportArray = exportArray.concat(transformedStakes);

    console.log(exportArray);

    this.exportToCsv("stakes_export", exportArray);
  }

  exportToCsv(filename, rows) {
    var processRow = function (row) {
      var finalVal = "";
      for (var j = 0; j < row.length; j++) {
        var innerValue = row[j] === null ? "" : row[j].toString();
        if (row[j] instanceof Date) {
          innerValue = row[j].toLocaleString();
        }
        var result = innerValue.replace(/"/g, '""');
        if (result.search(/("|,|\n)/g) >= 0) result = '"' + result + '"';
        if (j > 0) finalVal += ",";
        finalVal += result;
      }
      return finalVal + "\n";
    };

    var csvFile = "";
    for (var i = 0; i < rows.length; i++) {
      csvFile += processRow(rows[i]);
    }

    var blob = new Blob([csvFile], { type: "text/csv;charset=utf-8;" });

    var link = document.createElement("a");
    if (link.download !== undefined) {
      // feature detection
      // Browsers that support HTML5 download attribute
      var url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", filename);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  async revokeDEXoffer(stake) {
    let stakeID = stake.sessionId;
    let dexOffers: {
      closed: any;
      opened: any;
    };
    this.revokeOfferProcess = true;
    const numberDexOffers: number =
      await this.contractService.getNoOfOffersDex();
    console.log(stakeID);

    dexOffers = await this.contractService.getOffers(numberDexOffers);
    console.log(dexOffers);
    console.log("revoke offer", stake);

    const searchedOffer = dexOffers.opened.filter((obj) => {
      return obj.offerStakeID === stakeID;
    });
    console.log(searchedOffer);
    console.log("revoce offer id ", searchedOffer[0].numbr);
    this.contractService
      .revokeOffer(searchedOffer[0].numbr)
      .then(() => {
        this.contractService.updateREXBalance(true).then(() => {
          this.revokeOfferProcess = false;
          this.loadUserStakes();
        });
      })
      .catch(() => {
        this.revokeOfferProcess = false;
      });
  }

  addNewStakeLine() {
    console.log("add new stake line");
    this.variousStakesInputs.push(["", "", false]);
  }

  removeStakeLine(lineNumber: number) {
    console.log("remove stake line " + lineNumber);
    this.variousStakesInputs.splice(lineNumber, 1);
  }

  /**
   * Searches for an parent element with a certain class
   * @param el Current element
   * @param cls Class to search
   * @returns The first element with the searched class
   */
  findAncestor(el, cls) {
    while ((el = el.parentElement) && !el.classList.contains(cls));
    return el;
  }

  /**
   * Changes the array of data for various stake created
   * @param stakeID Stake number
   * @param type 0 for amount, 1 for duration and 2 for irrevocable stake
   * @param value The new value
   */
  changeVariousStakeInput(stakeID: number, type: number, value: string) {
    this.variousStakesInputs[stakeID - 1][type] = value;
    console.log(this.variousStakesInputs);
    if (type === 0) {
      let sumOfInput: number = 0;
      for (let i = 0; i < this.variousStakesInputs.length; i++) {
        let cleanNumber = this.variousStakesInputs[i][0].replace(/[,]/g, "");
        console.log(cleanNumber);
        sumOfInput += Number(cleanNumber);
      }
      console.log(sumOfInput);
      this.totalAmountOfVariousREX = sumOfInput;
    }
  }

  loginWithMetamask() {
    console.log("login metamask clicked");
    if (!this.glbC.waitingForAuthorization) {
      this.glbC.walletType = "metamask";
      this.glbC.hasConnectedBefore(true, "metamask");
      this.initContract();
    }
  }

  loginWithWalletConnect() {
    console.log("login walletConnect clicked");
    if (!this.glbC.waitingForAuthorization) {
      this.glbC.walletType = "walletConnect";
      this.glbC.hasConnectedBefore(true, "walletConnect");
      this.initContract();
    }
  }
}
