import { Component, OnInit } from "@angular/core";
import { GlobalControllerService } from "src/app/services/global-controller.service";
import { chartInstance, HttpService } from "../../../../services/http.service";

@Component({
  selector: "app-price",
  templateUrl: "./price.component.html",
  styleUrls: ["./price.component.scss"],
})
export class PriceComponent implements OnInit {
  rexChart: chartInstance = {
    type: "line",
    label: [],
    data: [
      {
        data: [],
        borderColor: this.service.colorRexBlue,
        pointBackgroundColor: this.service.colorRexBlue,
        pointRadius: 1,
      },
    ],
    options: {
      maintainAspectRatio: false,
      hitRadius: this.service.hitradiusCharts,
      elements: {
        line: {
          tension: 0.3,
        },
      },
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          callbacks: {
            label(context) {
              return `$${context.raw}`;
            },
          },
        },
      },
      scales: {
        x: {
          grid: {},
          ticks: {
            autoSkip: true,
            maxTicksLimit: 10,
            color: this.service.colorRexBlue,
          },
        },
        y: {
          ticks: {
            color: this.service.colorRexBlue,
            callback(value) {
              const callbackVal = value.toFixed(5);
              return `$${callbackVal}`;
            },
          },
        },
      },
    },
  };

  mrexChart: chartInstance = {
    type: "line",
    label: [],
    data: [
      {
        data: [],
        borderColor: this.service.colorRexBlue,
        pointBackgroundColor: this.service.colorRexBlue,
        pointRadius: 1,
      },
    ],
    options: {
      maintainAspectRatio: false,
      hitRadius: this.service.hitradiusCharts,
      elements: {
        line: {
          tension: 0.3,
        },
      },
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          callbacks: {
            label(context) {
              return `$${context.raw}`;
            },
          },
        },
      },
      scales: {
        x: {
          grid: {},
          ticks: {
            autoSkip: true,
            maxTicksLimit: 10,
            color: this.service.colorRexBlue,
          },
        },
        y: {
          ticks: {
            color: this.service.colorRexBlue,
            callback(value) {
              return `$${Number(value.toFixed(1))}`;
            },
          },
        },
      },
    },
  };

  constructor(
    public service: HttpService,
    public glbC: GlobalControllerService
  ) {}

  ngOnInit() {
    this.glbC.showLoader(true);
    this.service.isInitializing = true;
    this.service.updatePagePrice();
    this.updateREXPriceChart(0);
    this.updateMREXPriceChart(0);
    this.service.waitForInitialization().then(() => {
      this.service.isInitializing = false;
      this.service.isReady = false;
      this.glbC.showLoader(false);
    });
  }

  updateREXPriceChart(timeNum: number) {
    this.service.updateChart(
      this.service.DBprice,
      "timestamp",
      "REXtoUSD",
      3,
      0,
      timeNum,
      this.rexChart
    );
  }

  updateMREXPriceChart(timeNum: number) {
    this.service.updateChart(
      this.service.DBprice,
      "timestamp",
      "MREXtoUSD",
      3,
      1,
      timeNum,
      this.mrexChart
    );
  }
}
