import { DatePipe } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators,
} from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import BigNumber from "bignumber.js";
import { Subscription } from "rxjs";
import { ContractService } from "src/app/services/contract";
import { GlobalControllerService } from "src/app/services/global-controller.service";
import { HttpService } from "src/app/services/http.service";

@Component({
  selector: "app-liquiddery-page",
  templateUrl: "./liquiddery-page.component.html",
  styleUrls: ["./liquiddery-page.component.scss"],
})
export class LiquidderyPageComponent implements OnInit {
  buyTicketForm: FormGroup;

  blockUserForm: FormGroup;

  pageIsReady: boolean = false;

  buyTicketBlocked: boolean = false;

  buyTicketProcess: boolean = false;

  triggerNextRoundProcess: boolean = false;

  blockUserProcess: boolean = false;

  oracleIsReady: boolean = false;

  totalCostTicket: number = 0;

  ticketsOrTicket: string = "tickets";

  buyTicketSentence: string = "Buy Ticket";

  lastBlockedDay: any;

  currentDate: Date;
  initSubscription: Subscription = null;

  validationMessages = {
    numberOfTickets: [
      {
        type: "required",
        message: "ERRORMESSAGES.numberOfTickets_required",
      },
      {
        type: "min",
        message: "ERRORMESSAGES.numberOfTickets_min",
      },
      {
        type: "max",
        message: "ERRORMESSAGES.numberOfTickets_max",
      },
    ],
    numberOfDays: [
      {
        type: "required",
        message: "ERRORMESSAGES.numberOfDays_required",
      },
      {
        type: "min",
        message: "ERRORMESSAGES.numberOfDays_min",
      },
      {
        type: "max",
        message: "ERRORMESSAGES.numberOfDays_max",
      },
    ],
  };

  constructor(
    private formBuilder: FormBuilder,
    public service: HttpService,
    public glbC: GlobalControllerService,
    private translateService: TranslateService,
    public contractService: ContractService,
    private datePipe: DatePipe
  ) {}

  ngOnInit() {
    // check init status:
    this.initSubscription = this.glbC.initSubject.subscribe(async (data) => {
      console.log("initSubscription data: ", data);
      if (data.doInit) {
        if (this.glbC.isConnected) {
          this.initContract();
        } else if (
          !this.glbC.getHasConnectedBefore("metamask") ||
          !this.glbC.getHasConnectedBefore("walletConnect")
        ) {
          this.glbC.logoutClicked();
        }
      }
    });

    if (!this.glbC.isConnected) {
      if (this.glbC.getHasConnectedBefore("metamask")) {
        this.loginWithMetamask();
      } else if (this.glbC.getHasConnectedBefore("walletConnect")) {
        this.loginWithWalletConnect();
      }
    } else if (
      (!this.glbC.isOnWrongNetwork && this.glbC.walletType === "metamask") ||
      this.glbC.walletType === "walletConnect"
    ) {
      this.glbC.showLoader(true);
      this.service.isInitializing = true;
      this.service.updatePageLotterie();
      this.service.waitForInitialization().then(() => {
        this.loadPageData();
      });
    }
  }

  async loadPageData() {
    this.pageIsReady = false;
    console.log("Load Lottier page data");
    this.currentDate = new Date();
    this.lastBlockedDay = this.datePipe.transform(
      this.currentDate,
      "MMM dd, yyyy"
    );

    this.buyTicketBlocked = !this.service.lotterieSaleOpen;
    if (this.buyTicketBlocked) {
      this.checkForOracle();
    }
    this.initForms();
    this.translateMessages();
    this.pageIsReady = true;
    this.service.isInitializing = false;
    this.service.isReady = false;
    this.glbC.showLoader(false);
  }

  translateMessages() {
    this.translateService
      .get([
        this.validationMessages.numberOfTickets[0].message,
        this.validationMessages.numberOfTickets[1].message,
        this.validationMessages.numberOfTickets[2].message,
      ])
      .toPromise()
      .then((t) => {
        this.validationMessages.numberOfTickets[0].message =
          t[this.validationMessages.numberOfTickets[0].message];
        this.validationMessages.numberOfTickets[1].message =
          t[this.validationMessages.numberOfTickets[1].message];
        this.validationMessages.numberOfTickets[2].message =
          t[this.validationMessages.numberOfTickets[2].message];
      });
  }

  /**
   * Checks if the oracle is done calculating so that the next round can be triggered
   */
  async checkForOracle() {
    console.log("Check for oracle");
    let counter = 0;
    this.oracleIsReady = await this.contractService.getOracleReady();
    // Check all 10 seconds is oracle is done
    const oracleInterval = setInterval(async () => {
      counter++;
      this.oracleIsReady = await !this.contractService.getOracleReady();
      console.log("Interval round. Oracle is ready: " + this.oracleIsReady);
      if (!this.oracleIsReady) {
        // Oracle is done with calculating and the next round can be triggered
        clearInterval(oracleInterval);
      } else if (counter === 15) {
        clearInterval(oracleInterval);
      }
    }, 10000);
  }

  inputChanged(type: string, data: any) {
    switch (type) {
      case "numberOfTickets":
        console.log("New Number input. Data: ", data);
        this.totalCostTicket = 20 * data;
        if (data > 1 && this.glbC.currentLanguage === "de") {
          this.ticketsOrTicket = "Tickets";
          this.buyTicketSentence = "Tickets kaufen";
        } else if (data > 1 && this.glbC.currentLanguage !== "de") {
          this.ticketsOrTicket = "tickets";
          this.buyTicketSentence = "Buy Tickets";
        } else if (data <= 1 && this.glbC.currentLanguage === "de") {
          this.ticketsOrTicket = "Ticket";
          this.buyTicketSentence = "Ticket kaufen";
        } else if (data <= 1 && this.glbC.currentLanguage !== "de") {
          this.ticketsOrTicket = "ticket";
          this.buyTicketSentence = "Buy Ticket";
        }
        this.buyTicketForm.controls.numberOfTickets.patchValue(data);
        break;
      case "numberOfDays":
        let newLastDate = new Date();
        console.log("New Number of days input. Data: ", data);
        this.blockUserForm.controls.numberOfDays.patchValue(data);
        this.lastBlockedDay = this.datePipe.transform(
          newLastDate.setDate(this.currentDate.getDate() + Number(data)),
          "MMM dd, yyyy"
        );
        break;
    }
  }

  private initForms() {
    if (this.glbC.currentLanguage === "de") {
      this.ticketsOrTicket = "Ticket";
      this.buyTicketSentence = "Ticket kaufen";
    } else {
      this.ticketsOrTicket = "ticket";
      this.buyTicketSentence = "Buy Ticket";
    }
    this.buyTicketForm = this.formBuilder.group({
      numberOfTickets: new FormControl(
        "",
        Validators.compose([
          Validators.required,
          Validators.min(1),
          Validators.max(20),
        ])
      ),
    });
    this.blockUserForm = this.formBuilder.group({
      numberOfDays: new FormControl(
        "",
        Validators.compose([
          Validators.required,
          Validators.min(1),
          Validators.max(365),
        ])
      ),
    });
  }

  async takePartInRoundFormSubmit() {
    const amountOfTickets: number = this.buyTicketForm.value.numberOfTickets;
    this.service.soldTickets = await this.contractService.getSoldTickets();
    this.service.lotterieSaleOpen = await this.contractService.getSaleOpen();
    console.log("take part in round clicked");
    if (this.buyTicketForm.valid) {
      if (
        Number(amountOfTickets) + Number(this.service.personalTickets) <=
        20
      ) {
        if (this.service.lotterieSaleOpen) {
          if (
            Number(this.service.soldTickets) + Number(amountOfTickets) <=
            500
          ) {
            this.sendBUSDforTicket(amountOfTickets);
          } else {
            this.buyTicketProcess = false;
            this.glbC.showHelpMessage("lotterie_max_tickets_sold");
            return;
          }
        } else {
          this.buyTicketProcess = false;
          this.glbC.showHelpMessage("lotterie_not_open");
          return;
        }
      } else {
        this.buyTicketProcess = false;
        this.glbC.showHelpMessage("lotterie_max_pers_tickets_reached");
        return;
      }
    } else {
      const errorMessages = [];
      Object.keys(this.buyTicketForm.controls).forEach((key) => {
        const tempErrors: ValidationErrors = this.buyTicketForm.get(key).errors;
        console.log(tempErrors);
        if (tempErrors != null) {
          Object.keys(tempErrors).forEach((errorKey) => {
            this.validationMessages[key].forEach((msg) => {
              if (msg.type === errorKey) {
                errorMessages.push(msg.message);
              }
            });
          });
        }
      });
      console.log("errorMessages: ", errorMessages);
      let formattedErrorMessage: string = "";
      errorMessages.forEach((errMsg) => {
        formattedErrorMessage += `<p>${errMsg}</p>`;
      });
      this.translateService
        .get(["POPUPS.form_error", "BUTTONS.ok"])
        .toPromise()
        .then((translations) => {
          this.glbC.showPopupMessage({
            title: translations["POPUPS.form_error"],
            msg: formattedErrorMessage,
            declineButtonText: translations["BUTTONS.ok"],
            removeOtherPopups: true,
          });
        });
    }
  }

  async sendBUSDforTicket(amountOfTickets: number) {
    this.buyTicketProcess = true;
    const costForTickets = new BigNumber(amountOfTickets).times(20).times(1e18);
    console.log("Form is valid, buy " + amountOfTickets + " tickets");
    console.log("Cost for tickets: " + costForTickets);
    console.log(
      "BUSD in wallet: " +
        this.glbC.account.balances.BUSD.weiBigNumber.toNumber()
    );
    if (
      costForTickets > this.glbC.account.balances.BUSD.weiBigNumber.toNumber()
    ) {
      this.buyTicketProcess = false;
      this.glbC.showHelpMessage("auction_input_insufficient_busd");
      return;
    } else {
      console.log("Enough BUSD in wallet");
      this.contractService
        .buyLotterieTicket(amountOfTickets)
        .then((transactionHash) => {
          console.log("transactionHash: ", transactionHash);
          this.contractService.updateETHBalance(true);
          this.contractService.updateBUSDBalance(true);
          this.resetBuyTicketForm();
          this.service.updatePageLotterie();
          this.buyTicketProcess = false;
        })
        .catch((error) => {
          console.log("Error in sending BUSD to lotterie ", error);
          this.buyTicketProcess = false;
        });
    }
  }

  private triggerNextRound() {
    console.log("Trigger next round");
    this.triggerNextRoundProcess = true;
    this.contractService
      .distributeLotterieRound()
      .then(() => {
        console.log("Success");
        this.triggerNextRoundProcess = false;
        this.resetBuyTicketForm();
        this.service.updatePageLotterie();
      })
      .catch((error) => {
        console.log("Error in trigger next round: ", error);
        this.triggerNextRoundProcess = false;
      });
  }

  resetBuyTicketForm() {
    this.buyTicketForm.controls.numberOfTickets.patchValue("");
    this.totalCostTicket = 0;
    if (this.glbC.currentLanguage === "de") {
      this.ticketsOrTicket = "Ticket";
      this.buyTicketSentence = "Ticket kaufen";
    } else {
      this.ticketsOrTicket = "ticket";
      this.buyTicketSentence = "Buy Ticket";
    }
  }

  blockUserFormSubmit() {
    const numberOfDays: number = this.blockUserForm.value.numberOfDays;
    if (this.blockUserForm.valid) {
      this.blockUser(numberOfDays);
    } else {
      const errorMessages = [];
      Object.keys(this.blockUserForm.controls).forEach((key) => {
        const tempErrors: ValidationErrors = this.blockUserForm.get(key).errors;
        console.log(tempErrors);
        if (tempErrors != null) {
          Object.keys(tempErrors).forEach((errorKey) => {
            this.validationMessages[key].forEach((msg) => {
              if (msg.type === errorKey) {
                errorMessages.push(msg.message);
              }
            });
          });
        }
      });
      console.log("errorMessages: ", errorMessages);
      let formattedErrorMessage: string = "";
      errorMessages.forEach((errMsg) => {
        formattedErrorMessage += `<p>${errMsg}</p>`;
      });
      this.translateService
        .get(["POPUPS.form_error", "BUTTONS.ok"])
        .toPromise()
        .then((translations) => {
          this.glbC.showPopupMessage({
            title: translations["POPUPS.form_error"],
            msg: formattedErrorMessage,
            declineButtonText: translations["BUTTONS.ok"],
            removeOtherPopups: true,
          });
        });
    }
  }

  blockUser(days: number) {
    this.blockUserProcess = true;
    console.log("block user for " + days + " days");
    this.contractService
      .blockUserForXDays(Number(days))
      .then(() => {
        console.log("Success blocking user");
        this.blockUserProcess = false;
        this.service.updatePageLotterie();
      })
      .catch((error) => {
        console.log("Error in trigger next round: ", error);
        this.blockUserProcess = false;
      });
  }

  loginWithMetamask() {
    console.log("login metamask clicked");
    if (!this.glbC.waitingForAuthorization) {
      this.glbC.walletType = "metamask";
      this.glbC.hasConnectedBefore(true, "metamask");
      this.initContract();
    }
  }

  loginWithWalletConnect() {
    console.log("login walletConnect clicked");
    if (!this.glbC.waitingForAuthorization) {
      this.glbC.walletType = "walletConnect";
      this.glbC.hasConnectedBefore(true, "walletConnect");
      this.initContract();
    }
  }

  initContract() {
    if (!this.glbC.waitingForAuthorization) {
      this.glbC.showLoader(true);
      this.glbC
        .subscribeAccount()
        .then(() => {
          this.glbC.isConnected = true;
          console.log("this.glbC.isConnected", this.glbC.isConnected);
          this.service.updatePageLotterie();
          this.service.waitForInitialization().then(() => {
            this.loadPageData();
          });
        })
        .catch((err) => {
          this.glbC.showLoader(false);
          console.warn("Error in initContract", err);
        });
    }
  }
}
