import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { HttpService } from "src/app/services/http.service";

@Component({
  selector: "app-table-filter",
  templateUrl: "./table-filter.component.html",
  styleUrls: ["./table-filter.component.scss"],
})
export class TableFilterComponent implements OnInit {
  @ViewChild("appliedFilters", { static: false }) appliedFilterRef: ElementRef;

  @Input() isVisible: boolean = false;

  @Input() tableType: string;

  @Input() filterOptions: any[] = [];

  @Input() appliedFilterNames: any;

  @Output() filterTableData = new EventEmitter<any>();

  @Output() deleteAllFilters = new EventEmitter<any>();

  filterForm: FormGroup;

  filterItemActive: boolean[] = [];

  appliedFilter: any[] = [];

  @HostBinding("class") public get hostClass(): string {
    return this.isVisible ? "isExpanded" : "";
  }

  constructor(private formBuilder: FormBuilder, private service: HttpService) {}

  ngOnInit() {
    this.initFilterComp();
  }

  sendFilterContent() {
    if (this.filterForm.valid) {
      console.log(this.filterForm.value);
    }
    this.addSingleFilter();
    this.filterItemActive = this.filterItemActive.map(() => false);
    this.filterTableData.emit(this.filterForm.value);
  }

  inputChanged(type: string, data?) {
    console.log("Type: ", type, "Data: ", data);
    this.filterForm.controls[type].setValue(data);
  }

  addSingleFilter() {
    this.appliedFilter = [];
    const filterKeys = Object.keys(this.filterForm.value).filter((key) => {
      return (
        this.filterForm.value[key] !== "" && this.filterForm.value[key] !== null
      );
    });
    for (let i = 0; i < filterKeys.length; i++) {
      this.appliedFilter.push({
        type: filterKeys[i],
        value: this.filterForm.value[filterKeys[i]],
        displayName: this.appliedFilterNames[filterKeys[i]],
      });
    }
    console.log(this.appliedFilter);
    console.log(this.appliedFilterRef);
  }

  deleteFilterItem(event: any) {
    const clickedType = event.srcElement.attributes.filtertype.nodeValue;
    this.filterForm.controls[clickedType].patchValue(null);
    for (let i = 0; i < this.appliedFilter.length; i++) {
      if (Object.values(this.appliedFilter[i]).includes(clickedType)) {
        console.log("object includes this key");
        this.appliedFilter.splice(i, 1);
      }
    }
    this.filterTableData.emit(this.filterForm.value);
  }

  showClickDetails(event: any, index: number) {
    for (let i = 0; i < this.filterItemActive.length; i++) {
      if (i === index) {
        this.filterItemActive[i] = !this.filterItemActive[i];
      } else {
        this.filterItemActive[i] = false;
      }
    }
    console.log(this.filterItemActive);
  }

  initFilterComp() {
    this.filterForm = this.formBuilder.group({});
    for (let i = 0; i < this.filterOptions.length; i++) {
      this.filterItemActive[i] = false;
      if (this.filterOptions[i].minType) {
        this.filterForm.addControl(
          this.filterOptions[i].minType,
          new FormControl("")
        );
      }
      if (this.filterOptions[i].maxType) {
        this.filterForm.addControl(
          this.filterOptions[i].maxType,
          new FormControl("")
        );
      }
      if (this.filterOptions[i].checkboxType) {
        this.filterForm.addControl(
          this.filterOptions[i].checkboxType,
          new FormControl("")
        );
      }
      if (this.filterOptions[i].minCalendar) {
        this.filterForm.addControl(
          this.filterOptions[i].minCalendar,
          new FormControl("")
        );
      }
      if (this.filterOptions[i].maxCalendar) {
        this.filterForm.addControl(
          this.filterOptions[i].maxCalendar,
          new FormControl("")
        );
      }
    }
  }

  deleteFilters(event: any) {
    event.path[3].style.height = `${event.path[3].offsetHeight}px !important`;
    for (let i = 0; i < this.filterOptions.length; i++) {
      if (this.filterOptions[i].minType) {
        this.filterForm.patchValue({ [this.filterOptions[i].minType]: null });
      }
      if (this.filterOptions[i].maxType) {
        this.filterForm.patchValue({ [this.filterOptions[i].maxType]: null });
      }
    }
    this.filterItemActive = this.filterItemActive.map(() => false);
    this.appliedFilter = [];

    this.deleteAllFilters.emit();
  }
}
