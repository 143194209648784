import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "app-table-pagination",
  templateUrl: "./table-pagination.component.html",
  styleUrls: ["./table-pagination.component.scss"],
})
export class TablePaginationComponent implements OnInit {
  @Input()
  dropdownNumbers: number[];

  @Input()
  numberOfItems: number;

  @Input()
  textContent: string;

  @Input()
  defaultNumberItems: number;

  @Output() changeTablePage = new EventEmitter<object>();

  @Output() changeNumberStakes = new EventEmitter<any>();

  dropdownContentStakesPerPage: { value: any; name: any }[] = [];

  currentLowestElement: number = 0;

  currentHighestElement: number;

  constructor() {}

  ngOnInit() {
    this.updateDropdownContent();
    if (this.numberOfItems > this.defaultNumberItems) {
      this.currentHighestElement =
        this.currentLowestElement + this.defaultNumberItems;
    } else {
      this.currentHighestElement = this.numberOfItems;
    }
  }

  ngOnChanges() {
    if (this.numberOfItems < this.currentHighestElement) {
      this.currentHighestElement = this.numberOfItems;
    } else if (this.numberOfItems > this.currentHighestElement) {
      if (this.numberOfItems <= this.defaultNumberItems) {
        this.currentHighestElement = this.numberOfItems;
      } else {
        this.currentHighestElement = this.defaultNumberItems;
      }
    }
  }

  updateDropdownContent() {
    for (let i = 0; i < this.dropdownNumbers.length; i++) {
      this.dropdownContentStakesPerPage.push({
        name: this.dropdownNumbers[i].toString(),
        value: this.dropdownNumbers[i],
      });
    }
  }

  changeNumberOfStakes(amountStakes: number) {
    if (amountStakes <= this.numberOfItems) {
      this.defaultNumberItems = Number(amountStakes);
    } else {
      this.defaultNumberItems = this.numberOfItems;
    }

    if (
      Number(this.currentLowestElement) + Number(this.defaultNumberItems) <=
      this.numberOfItems
    ) {
      this.currentHighestElement =
        Number(this.currentLowestElement) + Number(this.defaultNumberItems);
    } else {
      this.currentHighestElement = this.numberOfItems;
    }

    this.currentLowestElement =
      this.currentHighestElement - this.defaultNumberItems;

    const newNumbers = {
      defaultAmount: amountStakes,
      lowestNumber: this.currentLowestElement,
      highestNumber: this.currentHighestElement,
    };
    this.changeNumberStakes.emit(newNumbers);
  }

  browseTable(direction: number) {
    console.log("browse Table");
    if (direction === 0) {
      if (this.currentLowestElement >= this.defaultNumberItems) {
        this.currentLowestElement -= this.defaultNumberItems;
      } else {
        this.currentLowestElement = 0;
      }
      if (this.currentHighestElement >= this.defaultNumberItems * 2) {
        this.currentHighestElement -= this.defaultNumberItems;
      } else {
        this.currentHighestElement = this.defaultNumberItems;
      }
    } else if (direction === 1) {
      if (
        this.currentLowestElement <
        this.numberOfItems - 2 * this.defaultNumberItems
      ) {
        this.currentLowestElement += this.defaultNumberItems;
      } else {
        this.currentLowestElement =
          this.numberOfItems - this.defaultNumberItems;
      }
      if (
        this.currentHighestElement <=
        this.numberOfItems - this.defaultNumberItems
      ) {
        this.currentHighestElement += this.defaultNumberItems;
      } else {
        this.currentHighestElement = this.numberOfItems;
      }
    }
    const newNumbers = {
      lowestNumber: this.currentLowestElement,
      highestNumber: this.currentHighestElement,
    };
    this.changeTablePage.emit(newNumbers);
  }
}
