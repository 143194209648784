import {
  Component,
  ElementRef,
  OnInit,
  Renderer2,
  ViewChild,
} from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { filter } from "rxjs/operators";
import { TranslateService } from "@ngx-translate/core";
import { GlobalControllerService } from "../services/global-controller.service";
import { HttpService } from "../services/http.service";
import { lang } from "moment";

@Component({
  selector: "app-sidebar-menu",
  templateUrl: "./sidebar-menu.component.html",
  styleUrls: ["./sidebar-menu.component.scss"],
})
export class SidebarMenuComponent implements OnInit {
  public activeRoute: string = "";

  @ViewChild("burgerMenu", { static: false }) burgerMenu: ElementRef;

  constructor(
    private router: Router,
    public glbC: GlobalControllerService,
    private renderer: Renderer2,
    public service: HttpService,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((sEvent: NavigationEnd) => {
        const initRoute = sEvent.url.substr(1);
        if (
          initRoute === "" ||
          initRoute === "dashboard" ||
          initRoute === "auctions" ||
          initRoute === "staking" ||
          initRoute === "statistics" ||
          initRoute === "statistics" ||
          initRoute === "dex" ||
          initRoute === "liquiddery"
        ) {
          if (initRoute === "") {
            this.activeRoute = "dashboard";
          } else {
            this.activeRoute = initRoute;
          }
          if (initRoute === "statistics") {
            if (this.glbC.isConnected) {
              this.service.updatePageGlobal();
            }
          }
        }
      });
    // const cRoute = this.router.routerState.snapshot.url;
    // console.log("cRoute: ", cRoute);
  }

  /**
   * Sets the activeRoute variable
   * @param navString the new string value for the activeRoute variable
   */
  setActiveNav(navString: string) {
    this.activeRoute = navString;
    this.burgerMenuClicked(false);
  }

  burgerMenuClicked(shouldOpen?: boolean) {
    let tmpShouldOpen: boolean;
    if (shouldOpen || shouldOpen === false) {
      tmpShouldOpen = shouldOpen;
    } else {
      tmpShouldOpen = !this.glbC.burgerMenuOpen;
    }
    if (!tmpShouldOpen) {
      if (this.burgerMenu) {
        this.renderer.removeClass(this.burgerMenu.nativeElement, "is-open");
        this.renderer.addClass(this.burgerMenu.nativeElement, "is-closed");
      }
      this.glbC.burgerMenuOpen = false;
    } else {
      if (this.burgerMenu) {
        this.renderer.removeClass(this.burgerMenu.nativeElement, "is-closed");
        this.renderer.addClass(this.burgerMenu.nativeElement, "is-open");
      }
      this.glbC.burgerMenuOpen = true;
    }
  }

  showBugWindow() {
    this.translateService
      .get([
        "POPUPS.bug_report_title",
        "POPUPS.bug_report_template",
        "BUTTONS.abort",
        "BUTTONS.submitReport",
      ])
      .toPromise()
      .then((translations) => {
        this.glbC.showPopupMessage({
          title: translations["POPUPS.bug_report_title"],
          msg: translations["POPUPS.bug_report_template"],
          declineButtonText: translations["BUTTONS.abort"],
          acceptButtonText: translations["BUTTONS.submitReport"],
          onAcceptCallback: () => {
            console.log("Accept Form");
            window.open(
              "https://docs.google.com/forms/d/e/1FAIpQLScj7r4bB4YECD6pmuM4uO1hIvd1oe1ZHbsMDo5fn3Y2NX_CmA/viewform?usp=sf_link"
            );
          },
        });
      });
  }

  switchLanguage(language: string) {
    console.log("switch to lang", language);
    this.translateService.use(language + "-" + this.glbC.suffixLang);
    this.glbC.showLanguageBox = false;
    this.glbC.currentLanguage = language;
    this.glbC.cookieService.set(`lang`, language, {
      expires: 365,
      path: "/",
    });
  }
}
