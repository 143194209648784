import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
} from "@angular/core";

@Component({
  selector: "app-rex-popup-message",
  templateUrl: "./rex-popup-message.component.html",
  styleUrls: ["./rex-popup-message.component.scss"],
})
export class RexPopupMessageComponent implements OnInit {
  @Input() popupTitle: string;

  @Input() popupMessage: string;

  @Input() inputFieldPlaceholder: string;

  @Input() inputFieldPlaceholder2: string;

  @Input() inputType: string = "text";

  @Input() inputType2: string = "text";

  @Input() acceptButtonText: string;

  @Input() declineButtonText: string;

  @Input() extraButtonText: string;

  @Input() messageId: number;

  @Output() removePopup = new EventEmitter<any>();

  @Input() onAcceptCallback: any;

  @Input() onCancelCallback: any;

  @Input() onExtraButtonCallback: any;

  @Input() validationCheck: any;

  @Input() extraClass: string;

  @HostBinding("style.marginLeft.px") marginLeft = 0;

  @HostBinding("style.marginTop.px") marginTop = 0;

  /*   @HostBinding("style.top.%") offsetTop = 35; */

  inputValue: any;

  inputValue2: any;

  constructor() {}

  ngOnInit() {
    if (this.messageId) {
      this.marginLeft = this.messageId * 10;
      this.marginTop = this.messageId * 10;
    }
    /*     if (this.extraClass === "dexInfoPopup") {
      console.log("extraClass");
      this.offsetTop = 5;
    } else if (this.extraClass === "smallDexInfoPopup") {
      console.log("extraClassSmall");
      this.offsetTop = 50;
    } */
    console.log("init Popup");
  }

  closeModal() {
    console.log("exit Popup");
    if (this.onCancelCallback) {
      this.onCancelCallback();
    }
    this.removePopup.emit(this.messageId);
  }

  validationCheckfunc() {
    console.log("perform validation");
    if (this.validationCheck(this.inputValue, this.inputValue2)) {
      console.log("val success");
      return true;
    }
    return false;
  }

  extraButtonClickFunc() {
    console.log("extra button click");
    this.onExtraButtonCallback(this.inputValue);
  }

  switchLanguage(language: string) {
    console.log("switch to lang", language);
  }

  acceptClicked() {
    console.log("popup acceptClicked");
    if (typeof this.validationCheck === "function") {
      if (this.validationCheckfunc()) {
        this.onAcceptCallback(this.inputValue, this.inputValue2);
        this.removePopup.emit(this.messageId);
      } else {
        console.log("validation fail");
      }
    } else {
      console.log("No validation performed");
      this.onAcceptCallback(this.inputValue, this.inputValue2);
      this.removePopup.emit(this.messageId);
    }
  }
}
