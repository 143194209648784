import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";

@Component({
  selector: "app-rex-table",
  templateUrl: "./rex-table.component.html",
  styleUrls: ["./rex-table.component.scss"],
})
export class RexTableComponent implements OnInit {
  @Input() tableHeads: string[];

  @Input() tableMatrix: string[][];

  @Input() hasExtraData: boolean = false;

  @Input() extraClasses: string[];

  @Input() isLoading: boolean = false;

  @Output() onExtraDataRequest = new EventEmitter<any>();

  @Output() onHeadItemClick = new EventEmitter<any>();

  dataIsValid: boolean = null;

  constructor() {}

  ngOnInit() {
    this.dataIsValid = this.testDataValidity();
  }

  ngOnChanges(changes: SimpleChanges) {
    console.log("rex-table input changes: ", changes);
    if (changes.extraTableData) {
      console.log("extraTableData change detected!");
    }
  }

  /**
   * Checks the validity of the passed data, by checking the length of all rows if they match with the length of the tableHeads Array
   */
  testDataValidity() {
    if (this.tableHeads && this.tableMatrix) {
      // check lengths:
      let lenghsAreCorrect = true;
      const requiredLength = this.tableHeads.length;
      this.tableMatrix.forEach((tRow) => {
        if (tRow.length !== requiredLength) {
          lenghsAreCorrect = false;
        }
      });
      return lenghsAreCorrect;
    }
    if (!this.tableHeads && !this.tableMatrix) {
      return null;
    }
    return false;
  }

  /**
   * Sends an event to parent that extra tableData is requested.
   */
  requestExtraData() {
    this.onExtraDataRequest.emit();
  }
}
