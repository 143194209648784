import { DatePipe } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { max } from "moment";
import { ContractService } from "src/app/services/contract";
import { GlobalControllerService } from "src/app/services/global-controller.service";
import { chartInstance, HttpService } from "../../../../services/http.service";

@Component({
  selector: "app-auctions",
  templateUrl: "./auctions.component.html",
  styleUrls: ["./auctions.component.scss"],
})
export class AuctionsComponent implements OnInit {
  busdChart: chartInstance = {
    type: "line",
    label: [],
    data: [
      {
        data: [],
        borderColor: "#00ffff",
        pointBackgroundColor: "#00ffff",
        pointRadius: 1,
      },
    ],
    options: {
      maintainAspectRatio: false,
      hitRadius: this.service.hitradiusCharts,
      elements: {
        line: {
          tension: 0.3,
        },
      },
      plugins: {
        legend: {
          display: false,
        },
      },
      scales: {
        x: {
          grid: {},
          ticks: {
            autoSkip: true,
            maxTicksLimit: 10,
            color: "#00ffff",
          },
        },
        y: {
          ticks: {
            color: "#00ffff",
          },
        },
      },
    },
  };

  bpdChart: chartInstance = {
    type: "bar",
    label: [],
    data: [],
    options: {
      maintainAspectRatio: false,
      hitRadius: 100,
      plugins: {
        legend: {
          display: false,
        },
      },
      scales: {
        x: {
          grid: {},
          ticks: {
            color: this.service.colorRexBlue,
            maxTicksLimit: 15,
          },
          title: {
            display: true,
            text: "Amount of BPDs",
            font: {
              weight: "lighter",
              size: "14",
            },
            color: this.service.colorRexBlue,
          },
        },
        y: {
          title: {
            display: true,
            text: "Amount of Users",
            font: {
              weight: "lighter",
              size: "14",
            },
            color: this.service.colorRexBlue,
          },
          ticks: {
            color: this.service.colorRexBlue,
            beginAtZero: true,
          },
          /*      type: "logarithmic", */
        },
      },
    },
  };

  bpdAmountsChart: chartInstance = {
    type: "line",
    label: [],
    data: [
      {
        data: [],
        borderColor: "#00ffff",
        pointBackgroundColor: "#00ffff",
        pointRadius: 1,
      },
    ],
    options: {
      maintainAspectRatio: false,
      hitRadius: this.service.hitradiusCharts,
      elements: {
        line: {
          tension: 0.2,
        },
      },
      plugins: {
        legend: {
          display: false,
        },
      },
      scales: {
        x: {
          grid: {},
          ticks: {
            autoSkip: true,
            maxTicksLimit: 10,
            color: "#00ffff",
          },
        },
        y: {
          ticks: {
            color: "#00ffff",
          },
        },
      },
    },
  };

  rexChart: chartInstance = {
    type: "line",
    label: [],
    data: [
      {
        data: [],
        borderColor: "#00ffff",
        pointBackgroundColor: "#00ffff",
        pointRadius: 1,
      },
    ],
    options: {
      maintainAspectRatio: false,
      hitRadius: this.service.hitradiusCharts,
      elements: {
        line: {
          tension: 0.3,
        },
      },
      plugins: {
        legend: {
          display: false,
        },
      },
      scales: {
        x: {
          grid: {},
          ticks: {
            autoSkip: true,
            maxTicksLimit: 10,
            color: "#00ffff",
          },
        },
        y: {
          ticks: {
            color: "#00ffff",
          },
        },
      },
    },
  };

  currRexDay: any;

  auctionsHaveEndedString: string;

  firstAuctionData: any;

  oldAuctionData: any;

  nextAuctionEndTimestamp: number;

  personalAuctionData: any;

  bpdTableIsLoaded: boolean = false;

  auctionTableIsLoaded: boolean = false;

  bpdScaleIsLog: boolean = false;

  bpdChartLoaded: boolean = false;

  bpdTableData: {
    tableHeads: string[];
    tableMatrix: any[];
  };

  auctionTableData: {
    tableHeads: string[];
    tableMatrix: any[];
  };

  filteredBPDtable: any[] = [];

  filteredAuctionsTable: any[] = [];

  amountOfTableEntries = {
    bpd: 10,
    auctions: 20,
  };

  currentPagination = {
    bpd: {
      first: 0,
      last: this.amountOfTableEntries.bpd,
    },
    auctions: {
      first: 0,
      last: this.amountOfTableEntries.auctions,
    },
  };

  bpdTableHeads = [
    "STATISTICS.auctions.round",
    "STATISTICS.auctions.busd_won",
    "STATISTICS.auctions.receivers",
    "STATISTICS.auctions.pool_size_start",
    "STATISTICS.auctions.pool_size_end",
    "STATISTICS.auctions.creation_date",
    "STATISTICS.auctions.creation_time",
  ];

  bpdTableHeadsMobile = [
    "STATISTICS.auctions.round",
    "AUCTIONS.mobile_table_head_col2",
    "AUCTIONS.mobile_table_head_col3",
  ];

  auctionTableHeads = [
    "AUCTIONS.table_head_col1",
    "AUCTIONS.table_head_col2",
    "AUCTIONS.table_head_col3",
    "AUCTIONS.table_head_col4",
    "AUCTIONS.table_head_col5",
    "AUCTIONS.table_head_col6",
    "AUCTIONS.table_head_col7",
  ];

  auctionTableMobile = [
    "AUCTIONS.mobile_table_head_col1",
    "AUCTIONS.mobile_table_head_col2",
    "AUCTIONS.mobile_table_head_col3",
  ];

  constructor(
    public service: HttpService,
    public glbC: GlobalControllerService,
    private translateService: TranslateService,
    private contractService: ContractService,
    private datePipe: DatePipe
  ) {}

  ngOnInit() {
    this.glbC.showLoader(true);
    this.service.isInitializing = true;
    this.service.updatePageAuction();
    this.service.waitForInitialization().then(() => {
      this.loadPageData();
    });
  }

  updateBUSDChart(timeNum: number) {
    this.service.updateChart(
      this.service.DBauctions,
      "dateNo",
      "totalDonBUSD",
      1,
      0,
      timeNum,
      this.busdChart
    );
  }

  updateREXChart(timeNum: number) {
    this.service.updateChart(
      this.service.DBauctions,
      "dateNo",
      "totalGeneratedREX",
      1,
      1,
      timeNum,
      this.rexChart
    );
  }

  updateUsersWithBPDChart(timeNum: number) {
    this.service.updateChart(
      this.service.DBauctions,
      "dateNo",
      "uniqueBPDwinner",
      1,
      2,
      timeNum,
      this.bpdAmountsChart
    );
  }

  updateBPDchart() {
    let maxNumBPDs;
    /*     let numberUnhitAddresses; */
    const usersWithBPD: any[] = [];

    /*     const promUnhitAddresses = new Promise((resolve) => {
      this.service
        .getRequest(
          `${this.service.serverAddress}/readDB/auctions?action=unhitAddresses`
        )
        .subscribe((content) => {
          const result = JSON.parse(content);
          [numberUnhitAddresses] = Object.values(result[0]);
          console.log(numberUnhitAddresses);
          usersWithBPD.push(numberUnhitAddresses);

          resolve(result);
        });
    }); */

    const maxNumBPDsProm = new Promise((resolve) => {
      this.service
        .getRequest(
          `${this.service.serverAddress}/readDB/bpd?action=maxAmountBPDs`
        )
        .subscribe((content) => {
          const result = JSON.parse(content);

          resolve(result);
        });
    });
    this.contractService
      .getUnhit()
      .then((numberUnhitAddresses) => {
        console.log(numberUnhitAddresses);
        usersWithBPD.push(numberUnhitAddresses);
        maxNumBPDsProm
          .then((result) => {
            [maxNumBPDs] = Object.values(result[0]);
            const labelArrayBPD: any[] = [];
            this.service
              .getRequest(
                `${this.service.serverAddress}/readDB/bpd?action=bpdDistribution&parameter=${maxNumBPDs}`
              )
              .subscribe((content) => {
                this.bpdChartLoaded = true;
                const result2 = JSON.parse(content);
                for (let i = 0; i < result2.length; i++) {
                  labelArrayBPD.push(i);
                  usersWithBPD.push(Object.values(result2[i][0])[0]);
                }
                labelArrayBPD.push(result2.length);
                console.log(usersWithBPD);

                const dataObject = [
                  {
                    barPercentage: 0.8,
                    /*             barThickness: 10, */
                    maxBarThickness: 15,
                    minBarLength: 3,
                    data: usersWithBPD,
                    borderRadius: "4",
                    backgroundColor: this.service.colorRexBlue,
                    label: "Amount of users",
                  },
                ];
                this.bpdChart.data = dataObject;
                this.bpdChart.label = labelArrayBPD;
              });
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((err) => {
        console.log("Error in get unitaddresses: ", err);
      });
  }

  async loadOldAuctions() {
    let oldPersonalData: any;
    let lastDayToLoad;
    if (this.currRexDay <= this.glbC.lastAuctionDay) {
      lastDayToLoad = this.currRexDay - this.service.auctionDaysToPreload - 1;
    } else {
      lastDayToLoad =
        this.glbC.lastAuctionDay - this.service.auctionDaysToPreload;
    }
    const promAuctions = new Promise((resolve) => {
      this.service
        .getRequest(
          `${this.service.serverAddress}/readDB/auctions?action=getOldAuctions&parameter=${lastDayToLoad}`
        )
        .subscribe((content) => {
          const result = Object.values(JSON.parse(content));
          resolve(result);
        });
    });

    // eslint-disable-next-line prefer-const
    [this.oldAuctionData, oldPersonalData] = await Promise.all([
      promAuctions,
      this.service.contractService.getPersonalAuctionData(0, lastDayToLoad),
    ]);

    // Combine global auction data and personal referal/donation amount
    for (let i = 0; i < this.oldAuctionData.length; i++) {
      for (let j = 0; j < oldPersonalData.length; j++) {
        if (oldPersonalData[j].day === this.oldAuctionData[i].dayNo) {
          this.oldAuctionData[i].myDonAmount = oldPersonalData[j].myDonAmount;
          this.oldAuctionData[i].myRefAmount = oldPersonalData[j].myRefAmount;
        }
      }
    }

    this.oldAuctionData = this.oldAuctionData.reverse();

    this.auctionTableData.tableMatrix =
      this.auctionTableData.tableMatrix.concat(this.oldAuctionData);

    this.checkCookieForTableLength("tb_auctions", "auctions");
  }

  async loadBPDdata() {
    console.log("start load data bpds");
    this.service
      .getRequest(`${this.service.serverAddress}/readDB/bpd?action=createdBPDs`)
      .subscribe((content) => {
        const result = Object.values(JSON.parse(content)).reverse();
        console.log(result);

        this.translateService
          .get(this.bpdTableHeads)
          .toPromise()
          .then((t) => {
            this.bpdTableData = {
              tableHeads: [
                t[this.bpdTableHeads[0]],
                t[this.bpdTableHeads[1]],
                t[this.bpdTableHeads[2]],
                /*                 t[this.bpdTableHeads[3]],
                t[this.bpdTableHeads[4]], */
                t[this.bpdTableHeads[5]],
                t[this.bpdTableHeads[6]],
              ],
              tableMatrix: result,
            };
            this.bpdTableIsLoaded = true;
            console.log(this.bpdTableData);

            this.checkCookieForTableLength("tb_bpd", "bpd");
          });
      });
  }

  /**
   * Checks if there is a local cookie stored for the table. If yes, use it as table size
   * @param cookieName Name of the set cookie
   * @param tableName Which table will it affect
   */
  checkCookieForTableLength(cookieName: string, tableName: string) {
    // Check if there is a cookie set for table size
    if (this.glbC.cookieService.get(cookieName)) {
      const cookieTableLength = Number(this.glbC.cookieService.get(cookieName));
      this.amountOfTableEntries[tableName] = cookieTableLength;

      this.currentPagination[tableName].last =
        this.currentPagination[tableName].first + cookieTableLength;
    }
  }

  changeYScale() {
    if (!this.bpdScaleIsLog) {
      this.bpdChart.options = {
        maintainAspectRatio: false,
        hitRadius: 100,
        plugins: {
          legend: {
            display: false,
          },
        },
        scales: {
          x: {
            grid: {},
            ticks: {
              color: this.service.colorRexBlue,
              maxTicksLimit: 15,
            },
            title: {
              display: true,
              text: "Amount of BPDs",
              font: {
                weight: "lighter",
                size: "14",
              },
              color: this.service.colorRexBlue,
            },
          },
          y: {
            title: {
              display: true,
              text: "Amount of Users",
              font: {
                weight: "lighter",
                size: "14",
              },
              color: this.service.colorRexBlue,
            },
            ticks: {
              color: this.service.colorRexBlue,
              beginAtZero: true,
            },
            type: "logarithmic",
          },
        },
      };
    } else {
      this.bpdChart.options = {
        maintainAspectRatio: false,
        hitRadius: 100,
        plugins: {
          legend: {
            display: false,
          },
        },
        scales: {
          x: {
            grid: {},
            ticks: {
              color: this.service.colorRexBlue,
              maxTicksLimit: 15,
            },
            title: {
              display: true,
              text: "Amount of BPDs",
              font: {
                weight: "lighter",
                size: "14",
              },
              color: this.service.colorRexBlue,
            },
          },
          y: {
            title: {
              display: true,
              text: "Amount of Users",
              font: {
                weight: "lighter",
                size: "14",
              },
              color: this.service.colorRexBlue,
            },
            ticks: {
              color: this.service.colorRexBlue,
              beginAtZero: true,
            },
          },
        },
      };
    }
    this.bpdScaleIsLog = !this.bpdScaleIsLog;
  }

  async loadLatestAuctions() {
    let dayToLoad;
    let lastLoadingDay;

    if (this.currRexDay <= this.glbC.lastAuctionDay && this.currRexDay >= 10) {
      dayToLoad = this.currRexDay - this.service.auctionDaysToPreload;
    } else if (this.currRexDay < 10) {
      dayToLoad = 1;
    } else {
      dayToLoad = 213;
    }

    const promFirstAuctions = await new Promise((resolve) => {
      this.service
        .getRequest(
          `${this.service.serverAddress}/readDB/auctions?action=getNewAuctions&parameter=${dayToLoad}`
        )
        .subscribe((content) => {
          const result = Object.values(JSON.parse(content));
          resolve(result);
        });
    });

    if (this.currRexDay <= this.glbC.lastAuctionDay) {
      lastLoadingDay = this.currRexDay;
    } else {
      lastLoadingDay = this.glbC.lastAuctionDay;
    }
    [this.firstAuctionData, this.personalAuctionData] = await Promise.all([
      promFirstAuctions,
      this.contractService.getPersonalAuctionData(dayToLoad, lastLoadingDay),
    ]);

    // Combine global auction data and personal referal/donation amount
    for (let i = 0; i < this.firstAuctionData.length; i++) {
      for (let j = 0; j < this.personalAuctionData.length; j++) {
        if (
          Number(this.personalAuctionData[j].day) ===
          Number(this.firstAuctionData[i].dayNo)
        ) {
          this.firstAuctionData[i].myDonAmount =
            this.personalAuctionData[j].myDonAmount;
          this.firstAuctionData[i].myRefAmount =
            this.personalAuctionData[j].myRefAmount;
        }
      }
    }
    this.firstAuctionData = this.firstAuctionData.reverse();
    console.log([...this.firstAuctionData]);

    this.translateService
      .get(this.auctionTableHeads)
      .toPromise()
      .then((t) => {
        this.auctionTableData = {
          tableHeads: [
            t[this.auctionTableHeads[0]],
            t[this.auctionTableHeads[1]],
            t[this.auctionTableHeads[2]],
            t[this.auctionTableHeads[3]],
            t[this.auctionTableHeads[4]],
            t[this.auctionTableHeads[5]],
            t[this.auctionTableHeads[6]],
          ],
          tableMatrix: this.firstAuctionData,
        };
        this.auctionTableIsLoaded = true;
        console.log([...this.firstAuctionData]);

        this.loadOldAuctions();
      });
  }

  sortContent(sortingType: any, tableType: string) {
    if (tableType === "bpd") {
      this.bpdTableData.tableMatrix.sort(
        (a, b) => Number(a[sortingType]) - Number(b[sortingType])
      );
    } else if (tableType === "auctions") {
      this.auctionTableData.tableMatrix.sort(
        (a, b) => Number(a[sortingType]) - Number(b[sortingType])
      );
    }
  }

  changeTablePage(newNumbers: any, tableType: string) {
    this.currentPagination[tableType].first = newNumbers.lowestNumber;
    this.currentPagination[tableType].last = newNumbers.highestNumber;

    if (newNumbers.defaultAmount) {
      console.log(
        "change default amount of table to ",
        newNumbers.defaultAmount
      );
      this.glbC.cookieService.set(`tb_${tableType}`, newNumbers.defaultAmount, {
        expires: 365,
        path: "/",
      });
    }
  }

  async loadPageData() {
    this.currRexDay = this.glbC.currRDay;
    this.glbC.getAuctionTimestamp(this.currRexDay).then((timestamp) => {
      if (Number(this.currRexDay) === 0) {
        this.nextAuctionEndTimestamp =
          timestamp + this.glbC.rexDayDurationInMilliseconds;
      } else {
        this.nextAuctionEndTimestamp = timestamp;
      }
    });

    if (this.currRexDay >= 1) {
      if (this.currRexDay <= this.glbC.lastAuctionDay) {
        this.updateBUSDChart(0);
        this.updateREXChart(0);
        this.updateUsersWithBPDChart(0);
      } else {
        this.updateBUSDChart(2);
        this.updateREXChart(2);
        this.updateUsersWithBPDChart(2);
      }

      this.updateBPDchart();

      /*       this.service.updateChart(
        this.service.DBauctions,
        "dateNo",
        "sumBPDwinners",
        1,
        1,
        2,
        this.bpdChart
      ); */

      // Already display page while table data is loading
      this.service.isInitializing = false;
      this.service.isReady = false;
      this.glbC.showLoader(false);

      this.loadBPDdata();
      await this.loadLatestAuctions();

      // If auction phase has already endend, calculate ending date
      if (this.currRexDay > this.glbC.lastAuctionDay) {
        this.auctionsHaveEndedString = this.datePipe.transform(
          this.glbC.rexDayToTimestamp(this.glbC.lastAuctionDay),
          "MMM dd yyyy "
        );
      }
    } else {
      this.service.isInitializing = false;
      this.service.isReady = false;
      this.glbC.showLoader(false);
    }
  }
}
