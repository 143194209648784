import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-rex-countdown-timer",
  templateUrl: "./rex-countdown-timer.component.html",
  styleUrls: ["./rex-countdown-timer.component.scss"],
})
export class RexCountdownTimerComponent implements OnInit {
  @Input() title: string;

  @Input() timerEndTimestamp: number;

  currentHours: string = "00";

  currentMinutes: string = "00";

  currentSeconds: string = "00";

  private timerInterval: any;

  constructor() {}

  ngOnInit() {
    console.log("this.timerEndTimestamp: ", this.timerEndTimestamp);
    this.startTimerInterval();
  }

  /**
   * Starts the timer interval, which will update the countdown timer every second
   */
  startTimerInterval() {
    this.timerInterval = setInterval(() => {
      const currentTime = Date.now();
      const diffTime = (this.timerEndTimestamp - currentTime) / 1000;
      if (diffTime < 0) {
        this.currentHours = "00";
        this.currentMinutes = "00";
        this.currentSeconds = "00";
      } else {
        const hours = diffTime / 3600;
        const restOfHours = (hours - Math.floor(hours)) * 3600;
        const minutes = restOfHours / 60;
        const restOfMinutes = (minutes - Math.floor(minutes)) * 3600;
        const seconds = restOfMinutes / 60;
        this.currentHours = this.formatNumber(Math.floor(hours));
        this.currentMinutes = this.formatNumber(Math.floor(minutes));
        this.currentSeconds = this.formatNumber(Math.round(seconds));
      }
    }, 1000);
  }

  /**
   * Helperfunction to format countdown timer numbers correctly
   * @param nr The number to be formatted
   * @returns The string with the correct number representation
   */
  formatNumber(nr: number) {
    if (nr.toString().length === 1) {
      return `0${nr}`;
    }
    return nr.toString();
  }
}
