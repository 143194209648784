import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ChartTypeRegistry } from "chart.js";
import { ContractService } from "./contract/index";
import { GlobalControllerService } from "./global-controller.service";

export interface chartInstance {
  type: keyof ChartTypeRegistry;
  label: any[];
  data: any;
  options: {
    maintainAspectRatio?: boolean;
    hitRadius?: any;
    elements?: any;
    plugins: object;
    scales: {
      x?: object;
      y?: object;
    };
  };
}

@Injectable({
  providedIn: "root",
})
export class HttpService {
  isInitializing: boolean = false;

  public serverAddress: string = "https://database.rex.io/v1/api";

  isReady: boolean = false;

  colorRexBlue: string = "#00ffff";

  hitradiusCharts: number = 20;

  // Stores all the possible chart data, once one of the possible time frames was requested
  dataAllCharts = [
    {
      name: "totalSupply",
      values: [
        [[], []],
        [[], []],
        [[], []],
        [[], []],
        [[], []],
      ],
    },
    {
      name: "totalSharesRex",
      values: [
        [[], []],
        [[], []],
        [[], []],
        [[], []],
        [[], []],
      ],
    },
    {
      name: "totalDonBUSD",
      values: [
        [[], []],
        [[], []],
        [[], []],
        [[], []],
      ],
    },
    {
      name: "uniqueBPDwinner",
      values: [
        [[], []],
        [[], []],
        [[], []],
        [[], []],
      ],
    },

    {
      name: "eligibleBPDaddresses",
      values: [
        [[], []],
        [[], []],
        [[], []],
        [[], []],
      ],
    },
    {
      name: "sumBPDwinners",
      values: [
        [[], []],
        [[], []],
        [[], []],
        [[], []],
      ],
    },
    {
      name: "totalGeneratedREX",
      values: [
        [[], []],
        [[], []],
        [[], []],
        [[], []],
      ],
    },
    {
      name: "totalRexStaked",
      values: [
        [[], []],
        [[], []],
        [[], []],
        [[], []],
        [[], []],
      ],
    },
    {
      name: "expiringSharesGlobal",
      values: [
        [[], []],
        [[], []],
        [[], []],
      ],
    },
    {
      name: "REXtoUSD",
      values: [
        [[], []],
        [[], []],
        [[], []],
        [[], []],
        [[], []],
      ],
    },
    {
      name: "MREXtoUSD",
      values: [
        [[], []],
        [[], []],
        [[], []],
        [[], []],
        [[], []],
      ],
    },
  ];

  // Array who controlls which buttons for chart display duration is pressed
  public activeTimeArray: any[] = [
    [
      [true, false, false, false, false],
      [true, false, false, false, false],
    ],
    [[true, false, false], [true, false, false], [true, false, false], [true]],
    [
      [true, false, false, false, false],
      [true, false, false, false],
    ],
    [
      [true, false, false, false, false],
      [true, false, false, false, false],
    ],
    [
      [true, false, false, false],
      [true, false, false, false],
    ],
  ];

  DBglobals = "globals";

  DBauctions = "auctions";

  DBbpd = "bpd";

  DBpenalties = "penalties";

  DBprice = "price";

  DBstakes = "stakes";

  returnValues = [[], [], [], [], []];

  returnValuesDEX = [];

  returnValuesLotterie = [];

  accountAddress: any;

  todaysSupply;

  currRexDay;

  totalAuctionParticipants;

  dailyTotalDonRef;

  totalDon;

  totalRef;

  rexReserveLP;

  auctionParticipants;

  auctionGlobals: any;

  generatedDays: any;

  totalGeneratedREX: any;

  totalClaimedDonationREX: any;

  totalClaimedReferralREX: any;

  totalDonatedBUSD: any;

  treasuryEligibleAdd: any;

  personalReceivedREX: any;

  personalReferalAmount: any;

  currentTime: number;

  public rexPCSPrice: any;

  public mrexPCSPrice: any;

  usdPrice: any;

  totalGlobalStaked: any;

  totalRexInStakes: any;

  totalRexInWallet: any;

  rexSharePrice: any;

  totalBPDs: any;

  addressWasHitByBPD: any;

  tvl: any;

  allocMarketCap: any;

  liquidMarketCap: any;

  totalGlobalLiquid: any;

  personalAuctionData: any[];

  auctionDaysToPreload: number = 10;

  rewardsPerShareArr: any;

  pcsReserves: any;

  auctionREXtoBUSD: any;

  busdTreasury: any;

  personalSentBUSD: any;

  totalClaimedReferralBUSD: any;

  totalClaimedRandomBUSD: any;

  busdReserveLP: any;

  personalSentBUSDBonus: any;

  currentMREXprice: any;

  amountOfDatapoints: number = 50;

  sharesChartDataPoints: number;

  sharesLabelArray: any[] = [];

  mrexTokenAddress = "0x76837d56d1105bb493cddbefeddf136e7c34f0c4";

  currentLotterieRound: any;
  soldTickets: any;
  roundLotIsDistributed: any;
  personalLotBusd: any;
  personalLotBusdRecvd: any;
  personalLotBusdSpent: any;
  personalTickets: any;
  totalPersonalTickets: Number = 0;
  lotterieSaleOpen: boolean = true;
  oracleIsReady: boolean;
  userIsBlocked: boolean;

  private rexTimestampInSecondsDay: number = this.glbC.rexDayDurationInSeconds;

  public rexTimestampInSecondsWeek: number = this.rexTimestampInSecondsDay * 7;

  public rexTimestampInSecondsMonth: number =
    this.rexTimestampInSecondsDay * 30.5;

  public rexTimestampInSecondsYear: number =
    this.rexTimestampInSecondsDay * 365.25;

  totalEligibleAddresses: any;

  usersWithBPD: any;

  constructor(
    public http: HttpClient,
    public contractService: ContractService,
    public glbC: GlobalControllerService
  ) {
    this.isInitializing = true;
    /*     this.contractService
      .getAccount(true)
      .then(() => {})
      .catch(() => {}); */
  }

  /**
   * Makes program wait for a certain time
   * @param ms Amount of milliseconds
   * @returns
   */
  public wait(ms: number) {
    return new Promise((res: any) => {
      setTimeout(() => {
        res();
      }, ms);
    });
  }

  /**
   * Waits until initialization is done
   * @returns True | False to indicate if function is fully executed
   */
  public async waitForInitialization() {
    if (this.isReady) {
      return true;
    }
    if (this.isInitializing) {
      await this.wait(100);
      return this.waitForInitialization();
    }
    return false;
  }

  /**
   * Reduces a big number to a smaller one
   * @param value Number, which is wished to be reduced
   * @param amountZeros How many decimales to cut away
   * @param digitsAfter How many decimal places after the comma the number should have
   * @returns resulting number
   */
  formatBigNumber(value: any, amountZeros: number, digitsAfter: number) {
    if (value === undefined || value === "undefined") {
      return "";
    }
    return Number(
      (Number(value) / 10 ** amountZeros).toFixed(digitsAfter)
    ).toLocaleString();
  }

  /**
   * Sends request to DB to obtain the rex rewards per share on every day since the entered starting day
   * @param startDay Rex day from which on the data should be requested
   * @returns Array of rewards per share
   */
  requestPenalties(startDay: number) {
    return new Promise((resolve: any) => {
      this.getRequest(
        `${this.serverAddress}/readDB/penalties?action=getPenalties&parameter=${startDay}`
      ).subscribe((content) => {
        let result = JSON.parse(content);
        result = result.map((a) => Number(a.rewardPerShare));
        resolve(result);
      });
    });
  }

  /**
   * Performs a HTTP get request
   * @param url URL to send the get request to
   * @returns Get response
   */
  getRequest(url: string) {
    const options = {
      responseType: "text" as const,
    };
    return this.http.get(url, options);
  }

  /**
   * Makes a GET request to the PCS API to receive current price of the input token
   * @param token Token address ("0x...")
   * @returns Current price of the token
   */
  /*   getPCSdata(token: string) {
    return new Promise((resolve) => {
      this.getRequest(
        `https://api.pancakeswap.info/api/v2/tokens/${token}`
      ).subscribe((content) => {
        const currentTokenPrice = Number(
          JSON.parse(content).data.price
        ).toFixed(4);
        resolve(currentTokenPrice);
      });
    });
  } */

  /**
   * Converts a REX day to a normal date format
   * @param day REX day
   * @param showDay If true, the hours are not displayed, only the day
   * @returns REX day in date format (e.g. Nov 15 2021)
   */
  rexDayToDate(day: number, showDay: boolean) {
    const now = Date.now();
    const then =
      now +
      this.glbC.rexDayDurationInMilliseconds * (Number(day) - this.currRexDay);
    const dateStr = new Date(then).toDateString();
    if (showDay) return dateStr.substring(dateStr.length - 11);
    console.log(dateStr);
    return dateStr.substring(dateStr.length - 8);
  }

  timestampToDate(timestamp, fullyear: boolean = false) {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const a = new Date(Number(timestamp) * 1000);
    let year;
    if (fullyear) {
      year = a.getFullYear().toString();
    } else {
      year = a.getFullYear().toString().substring(2, 4);
    }

    const month = months[a.getMonth()];
    let date: any = a.getDate();
    if (date.toString().length === 1) {
      date = `0${date.toString()}`;
    }
    const dateCombined = `${month} ${date}, ${year}`;
    return dateCombined;
  }

  timestampToHours(timestamp) {
    const a = new Date(Number(timestamp) * 1000);
    const hour = a.getHours();
    let min: any = a.getMinutes();
    if (min.toString().length === 1) {
      min = `0${min.toString()}`;
    }
    const time = `${hour}h${min}`;
    return time;
  }

  /**
   * Formats the given timestamps into a readable format
   * @param labelArray Array which has to be transformed
   * @param timeNum between 0 and 4, corresponding to "day", "week", "month", "year" and "max"
   */
  fortmatDate(labelArray, timeNum) {
    const modLabelArray = labelArray;
    for (let i = 0; i < labelArray.length; i++) {
      modLabelArray[i] = new Date(labelArray[i] * 1000);
      let dateWithYear = modLabelArray[i].toDateString();
      dateWithYear = dateWithYear.substring(4, dateWithYear.length);
      const dateWithoutYear = dateWithYear.substring(
        0,
        dateWithYear.length - 5
      );

      let minutes = labelArray[i].getMinutes();

      if (minutes.toString().length === 1) {
        minutes = `0${minutes}`;
      }
      switch (timeNum) {
        case 0:
          modLabelArray[i] = `${labelArray[i].getHours()}:${minutes}`;
          break;
        case 1:
          modLabelArray[i] = `${dateWithoutYear} ${labelArray[
            i
          ].getHours()}:${minutes}`;
          break;
        case 2:
          modLabelArray[i] = `${dateWithYear}`;
          break;
        case 3:
          modLabelArray[i] = `${dateWithYear}`;
          break;

        case 4:
          modLabelArray[i] = `${dateWithYear}`;
          break;

        default:
          modLabelArray[i] = `${dateWithoutYear} ${labelArray[
            i
          ].getHours()}:${minutes}`;
          break;
      }
    }
  }

  /**
   * Requests the highest price of REX/MREX stored in the DB
   * @param token To which token is the highest price requested? "rex" or "mrex"
   * @returns Highest price in USD
   */
  getMaxPrice(token: string) {
    let getRequest: string;
    if (token === "rex") {
      getRequest = `${this.serverAddress}/readDB/price?action=maxPriceREX`;
    } else if (token === "mrex") {
      getRequest = `${this.serverAddress}/readDB/price?action=maxPriceMREX`;
    } else {
      throw new Error("Wrong token name in getMaxPrice");
    }
    return new Promise((resolve) => {
      this.getRequest(getRequest).subscribe((content) => {
        const maxPrice = Number(Object.values(JSON.parse(content)[0]));
        resolve(maxPrice);
      });
    });
  }

  /**
   * Requests the lowest timestamp from the "price" table
   * @param token From which token should the lowest timestamp be displayed? "rex" or "mrex"
   * @returns Lowest timestamp
   */
  getMinTime(token: string) {
    let getRequest: string;
    if (token === "rex") {
      getRequest = `${this.serverAddress}/readDB/price?action=minTimestampREX`;
    } else if (token === "mrex") {
      getRequest = `${this.serverAddress}/readDB/price?action=minTimestampMREX`;
    } else {
      throw new Error("Wrong token name in getMaxPrice");
    }
    return new Promise((resolve) => {
      this.getRequest(getRequest).subscribe((content) => {
        const minTimestamp = Number(Object.values(JSON.parse(content)[0]));
        resolve(minTimestamp);
      });
    });
  }

  public getLiquidRex() {
    return new Promise((resolve: any, reject: any) => {
      this.getRequest(
        `${this.serverAddress}/readDB/globals?action=totalSupply`
      ).subscribe(
        (content) => {
          const result =
            Number(Object.values(JSON.parse(content)[0])[0]) / 1e18;
          resolve(result);
        },
        (error) => {
          console.error(error);
          reject(error);
        }
      );
    });
  }

  /**
   * Calculates closest value in the database to given timestamp in the price table
   * @param goalTimestamp Timestamp to which the value is demanded
   * @param token From which token is the data? "rex" or "mrex"
   * @returns closestValue to timestamp
   */
  calcClosestValue(goalTimestamp: number, token: string, lowestTimestamp: any) {
    let closestValue: number;
    let closestValuePercent;
    let closestTimestamp;
    let getRequest: string;
    let currentTokenValue: number;
    let timestampArray = [];
    const timestampMin = goalTimestamp - 10000;
    const timestampMax = goalTimestamp + 10000;
    if (token === "rex") {
      getRequest = `${this.serverAddress}/readDB/price?action=rexPricesBetween&var1=${timestampMin}&var2=${timestampMax}`;
      currentTokenValue = this.rexPCSPrice;
    } else if (token === "mrex") {
      currentTokenValue = this.mrexPCSPrice;
      getRequest = `${this.serverAddress}/readDB/price?action=mrexPricesBetween&var1=${timestampMin}&var2=${timestampMax}`;
    } else {
      throw new Error("Wrong token name in calcClosestValue");
    }

    // Check if there demanded timestamp is smaller than the smallest entry in DB
    if (Number(lowestTimestamp) < goalTimestamp) {
      return new Promise((resolve) => {
        this.getRequest(getRequest).subscribe((content) => {
          const result = JSON.parse(content);

          // Get arrays of all close timestamps
          timestampArray = result.map((a) => a.timestamp);

          // Find nearest timestamp
          if (timestampArray.length > 0) {
            closestTimestamp = timestampArray.reduce((prev, curr) => {
              return Math.abs(curr - goalTimestamp) <
                Math.abs(prev - goalTimestamp)
                ? curr
                : prev;
            });

            [, closestValue] = Object.values(
              result[timestampArray.indexOf(closestTimestamp)]
            );
            // Compare closest number with current price, set "0" or "No data found" if closest number === 0
            if (Number(closestValue) === 0 && currentTokenValue === 0) {
              closestValuePercent = "0.00%";
            } else if (Number(closestValue) === 0 && currentTokenValue !== 0) {
              closestValuePercent = "No data found";
            } else if (Number(closestValue) !== 0 && currentTokenValue !== 0) {
              closestValuePercent = `${(
                (currentTokenValue / closestValue - 1) *
                100
              ).toFixed(2)}%`;
            }

            resolve(closestValuePercent);
          }
          closestValuePercent = "No data found";
          resolve(closestValuePercent);
        });
      });
    }
    return "No data found";
  }

  async updatePageGlobal(forceReload?: boolean) {
    if (this.returnValues[0].length === 0 || forceReload) {
      console.log("global page was not laoded before");
      if (this.glbC.currRDay !== undefined) {
        this.currRexDay = this.glbC.currRDay;
      } else {
        this.currRexDay = await this.contractService.getCurrentRexDay();
      }
      if (
        Number(this.currRexDay) > 0 &&
        Number(this.currRexDay) <= Number(this.glbC.lastAuctionDay)
      ) {
        console.log("is in loading contract data global page");
        [this.todaysSupply, this.totalDon, this.totalRef, this.pcsReserves] =
          await Promise.all([
            this.contractService.getTodaysSupply(this.currRexDay),
            this.contractService.getDailyTotalDonation(this.currRexDay),
            this.contractService.getDailyTotalReferral(this.currRexDay),
            this.contractService.getPairReserves(),
          ]);

        this.dailyTotalDonRef = Number(this.totalDon) + Number(this.totalRef);
        this.auctionREXtoBUSD = Number(
          this.todaysSupply / this.dailyTotalDonRef
        );
        // eslint-disable-next-line no-underscore-dangle
        this.rexReserveLP = this.pcsReserves._reserve0;
        // eslint-disable-next-line no-underscore-dangle
        this.busdReserveLP = this.pcsReserves._reserve1;
        this.rexPCSPrice = Number(
          this.busdReserveLP / this.rexReserveLP
        ).toFixed(7);
        console.log(this.rexPCSPrice);
      }

      const currentSuppy = await this.contractService.getRexTotalSupply();
      // Get current about of Shares through DB
      this.getRequest(
        `${this.serverAddress}/readDB/globals?action=totalShares`
      ).subscribe((content) => {
        console.log(content);
        const result = Number(Object.values(JSON.parse(content)[0])[0]) / 1e18;
        console.log(result);
        this.returnValues[0] = [
          1 / this.rexPCSPrice,
          this.auctionREXtoBUSD,
          currentSuppy,
          result,
        ];
      });
    }
    this.isInitializing = false;
    this.isReady = true;
    console.log("finish http function");
  }

  async updatePageAuction(forceReload?: boolean) {
    if (this.glbC.currRDay !== undefined) {
      this.currRexDay = this.glbC.currRDay;
    } else {
      this.currRexDay = await this.contractService.getCurrentRexDay();
      this.glbC.currRDay = this.currRexDay;
    }
    if (
      (this.returnValues[1].length === 0 || forceReload) &&
      this.currRexDay >= 2
    ) {
      let daysTillTreasury;
      let sumBUSDunhitAddresses;
      let treasuryRatio;
      let personalTresury;

      const promBUSDtreasury = await new Promise((resolve) => {
        this.getRequest(
          `${this.serverAddress}/readDB/auctions?action=busdTreasury`
        ).subscribe((content) => {
          let result;
          if (JSON.parse(content).length !== 0) {
            [result] = Object.values(JSON.parse(content)[0]);
          } else {
            result = 0;
          }

          resolve(result);
        });
      });
      /*       const promBPDwinners = await new Promise((resolve) => {
        this.getRequest(
          `${this.serverAddress}/readDB/bpd?action=addressesWithBPD`
        ).subscribe((content) => {
          let result;
          console.log(content);
          if (JSON.parse(content).length !== 0) {
            [result] = Object.values(JSON.parse(content)[0]);
          } else {
            result = 0;
          }
          resolve(result);
        });
      }); */
      console.log("update page auctions start");
      [
        this.totalEligibleAddresses,
        this.busdTreasury,
        this.todaysSupply,
        this.auctionParticipants,
        this.totalDon,
        this.totalRef,
      ] = await Promise.all([
        this.contractService.getIsBPDeligibleAddrAll(),
        promBUSDtreasury,
        this.contractService.getTodaysSupply(this.currRexDay),
        this.contractService.getDonatorsToday(this.currRexDay),
        this.contractService.getDailyTotalDonation(this.currRexDay),
        this.contractService.getDailyTotalReferral(this.currRexDay),
      ]);

      console.log("first part finished");
      [
        this.totalAuctionParticipants,
        this.auctionGlobals,
        this.treasuryEligibleAdd,
      ] = await Promise.all([
        this.contractService.getUniqueDonatorCount(),
        this.contractService.getGlobals(),
        this.contractService.getUnhit(),
      ]);

      console.log("second part finished");
      [this.personalSentBUSD, this.addressWasHitByBPD, sumBUSDunhitAddresses] =
        await Promise.all([
          this.contractService.getTotalSentBUSD(),
          this.contractService.getAddressHitByRandomWho(),
          this.contractService.sumOfDonationsOfUnHit(),
        ]);

      treasuryRatio = this.busdTreasury / sumBUSDunhitAddresses;

      console.log("treasury Ratio: ", treasuryRatio);

      personalTresury = this.personalSentBUSD * treasuryRatio;

      console.log(personalTresury);

      this.dailyTotalDonRef = Number(this.totalDon) + Number(this.totalRef);
      this.auctionREXtoBUSD = Number(this.todaysSupply / this.dailyTotalDonRef);

      /* eslint-disable prefer-destructuring */
      this.generatedDays = this.auctionGlobals[0];
      this.totalBPDs = Number(this.auctionGlobals[1]);
      this.totalDonatedBUSD = this.auctionGlobals[2];
      this.totalGeneratedREX = this.auctionGlobals[3];
      this.totalClaimedDonationREX = this.auctionGlobals[4];
      this.totalClaimedReferralREX = this.auctionGlobals[5];
      this.totalClaimedReferralBUSD = this.auctionGlobals[6];
      this.totalClaimedRandomBUSD = this.auctionGlobals[7];

      if (252 - this.currRexDay > 0) {
        daysTillTreasury = 252 - this.currRexDay;
      } else {
        daysTillTreasury = 0;
      }

      this.returnValues[1] = [
        this.dailyTotalDonRef,
        this.auctionREXtoBUSD,
        this.auctionParticipants,
        this.todaysSupply,
        this.totalAuctionParticipants,
        this.totalDonatedBUSD,
        this.treasuryEligibleAdd,
        this.busdTreasury,
        this.personalSentBUSD,
        Boolean(this.addressWasHitByBPD),
        Number(this.totalBPDs),
        this.totalGeneratedREX,
        this.totalClaimedDonationREX,
        this.totalClaimedReferralREX,
        this.totalClaimedReferralBUSD,
        this.totalClaimedRandomBUSD,
        daysTillTreasury,
        personalTresury,
      ];
    }

    this.isInitializing = false;
    this.isReady = true;
  }

  async updatePageStaking(forceReload?: boolean) {
    if (this.returnValues[2].length === 0 || forceReload) {
      if (this.glbC.currRDay !== undefined) {
        this.currRexDay = this.glbC.currRDay;
      } else {
        this.currRexDay = await this.contractService.getCurrentRexDay();
      }
      // Check how many stakes end today
      const stakesEndToday = new Promise((resolve) => {
        this.getRequest(
          `${this.serverAddress}/readDB/stakes?action=todayEndingStakes&parameter=${this.currRexDay}`
        ).subscribe((content) => {
          const [result] = Object.values(JSON.parse(content)[0]);
          resolve(result);
        });
      });
      // Check how many stakes end this month
      const stakeEndMonth = new Promise((resolve) => {
        this.getRequest(
          `${this.serverAddress}/readDB/stakes?action=monthEndingStakes&parameter=${this.currRexDay}`
        ).subscribe((content) => {
          const [result] = Object.values(JSON.parse(content)[0]);
          resolve(result);
        });
      });
      // Check how many shares end this month
      const stakesMonthShares = new Promise((resolve) => {
        this.getRequest(
          `${this.serverAddress}/readDB/stakes?action=endingShares&parameter=${
            this.currRexDay
          }&parameter2=${Number(this.currRexDay) + 30}`
        ).subscribe((content) => {
          let result = JSON.parse(content);
          result = result.map((a) => a.stakesShares / 1e18);
          const sumShares = result.reduce((partialSum, a) => partialSum + a, 0);
          resolve(sumShares);
        });
      });
      // Check how many active stakes there are globally
      const stakesTotalActive = new Promise((resolve) => {
        this.getRequest(
          `${this.serverAddress}/readDB/stakes?action=activeGlobalStakes`
        ).subscribe((content) => {
          const [result] = Object.values(JSON.parse(content)[0]);
          resolve(result);
        });
      });
      const [
        endingStakesDay,
        endingStakesMonth,
        endingSharesMonth,
        totalActiveStakes,
      ] = await Promise.all([
        stakesEndToday,
        stakeEndMonth,
        stakesMonthShares,
        stakesTotalActive,
      ]);
      // Get rex share price
      this.rexSharePrice = await this.contractService.getRexGlobals();
      this.rexSharePrice = this.rexSharePrice[2];

      this.returnValues[2] = [
        endingStakesDay,
        endingStakesMonth,
        endingSharesMonth,
        totalActiveStakes,
        this.rexSharePrice,
        this.currRexDay,
      ];
    }
    this.isInitializing = false;
    this.isReady = true;
  }

  async updatePagePrice(forceReload?: boolean) {
    if (this.returnValues[3].length === 0 || forceReload) {
      let rexPriceATH: string;
      let mrexPriceATH: string;
      let trexPrice;

      if (this.glbC.currentMREXpricePublic === undefined) {
        this.mrexPCSPrice = await this.glbC.getPCSdata(this.mrexTokenAddress);
      } else {
        this.mrexPCSPrice = this.glbC.currentMREXpricePublic;
      }
      console.log("MREX Price", this.mrexPCSPrice);

      if (this.glbC.currentREXpricePublic === undefined) {
        this.pcsReserves = await this.contractService.getPairReserves();
        this.rexReserveLP = this.pcsReserves[0];
        this.busdReserveLP = this.pcsReserves[1];
        if (Number(this.rexReserveLP) !== 0) {
          this.rexPCSPrice = Number(this.busdReserveLP / this.rexReserveLP);
        } else {
          this.rexPCSPrice = 0;
        }
      } else {
        this.rexPCSPrice = this.glbC.currentREXpricePublic;
      }
      console.log("REX Price", this.rexPCSPrice);

      const [maxPriceREX, maxPriceMREX, minTimestampREX, minTimestampMREX] =
        await Promise.all([
          this.getMaxPrice("rex"),
          this.getMaxPrice("mrex"),
          this.getMinTime("rex"),
          this.getMinTime("mrex"),
        ]);
      console.log(maxPriceMREX);

      const promStakedRex = new Promise((resolve, reject) => {
        this.getRequest(
          `${this.serverAddress}/readDB/globals?action=totalRexStaked`
        ).subscribe(
          (content) => {
            const result =
              Number(Object.values(JSON.parse(content)[0])[0]) / 1e18;
            resolve(result);
          },
          (error) => {
            console.error(error);
            reject(error);
          }
        );
      });

      const promLiquidRex = new Promise((resolve, reject) => {
        this.getRequest(
          `${this.serverAddress}/readDB/globals?action=totalSupply`
        ).subscribe(
          (content) => {
            const result =
              Number(Object.values(JSON.parse(content)[0])[0]) / 1e18;
            resolve(result);
          },
          (error) => {
            console.error(error);
            reject(error);
          }
        );
      });

      [this.totalGlobalStaked, this.totalGlobalLiquid, trexPrice] =
        await Promise.all([
          promStakedRex,
          promLiquidRex,
          this.contractService.getTrexPrice(),
        ]);

      this.tvl = this.rexPCSPrice * this.totalGlobalStaked;
      this.liquidMarketCap = this.rexPCSPrice * this.totalGlobalLiquid;
      this.allocMarketCap = this.tvl + this.liquidMarketCap;

      // Calculate comparison to previous moments
      this.currentTime = Math.floor(new Date().getTime() / 1000.0);
      const timestampDay = this.currentTime - this.rexTimestampInSecondsDay;
      const timestampWeek = this.currentTime - this.rexTimestampInSecondsWeek;
      const timestampMonth = this.currentTime - this.rexTimestampInSecondsMonth;
      const timestampYear = this.currentTime - this.rexTimestampInSecondsYear;
      const [
        rexPrice24h,
        rexPriceWeek,
        rexPriceMonth,
        rexPriceYear,
        mrexPrice24h,
        mrexPriceWeek,
        mrexPriceMonth,
        mrexPriceYear,
      ] = await Promise.all([
        this.calcClosestValue(timestampDay, "rex", minTimestampREX),
        this.calcClosestValue(timestampWeek, "rex", minTimestampREX),
        this.calcClosestValue(timestampMonth, "rex", minTimestampREX),
        this.calcClosestValue(timestampYear, "rex", minTimestampREX),
        this.calcClosestValue(timestampDay, "mrex", minTimestampMREX),
        this.calcClosestValue(timestampWeek, "mrex", minTimestampMREX),
        this.calcClosestValue(timestampMonth, "mrex", minTimestampMREX),
        this.calcClosestValue(timestampYear, "mrex", minTimestampMREX),
      ]);

      if (maxPriceREX !== 0) {
        rexPriceATH = `${(
          (Number(Number(this.rexPCSPrice).toFixed(7)) / Number(maxPriceREX) -
            1) *
          100
        ).toFixed(2)}%`;
      } else {
        rexPriceATH = "No Data Found";
      }

      if (maxPriceMREX !== 0) {
        mrexPriceATH = `${(
          (Number(Number(this.mrexPCSPrice).toFixed(4)) / Number(maxPriceMREX) -
            1) *
          100
        ).toFixed(2)}%`;
      } else {
        mrexPriceATH = "No Data Found";
      }

      this.returnValues[3] = [
        rexPrice24h,
        rexPriceWeek,
        rexPriceMonth,
        rexPriceYear,
        rexPriceATH,
        mrexPrice24h,
        mrexPriceWeek,
        mrexPriceMonth,
        mrexPriceYear,
        mrexPriceATH,
        this.tvl,
        this.allocMarketCap,
        this.liquidMarketCap,
        this.rexPCSPrice,
        this.mrexPCSPrice,
        trexPrice,
      ];
    }
    this.isInitializing = false;
    this.isReady = true;
  }

  async updatePageYourStat(forceReload?: boolean) {
    console.log("update your stat");
    if (this.returnValues[4].length === 0 || forceReload) {
      let totalMoneyBPDs;
      let dayToLoad;
      let totalREXreceived;
      let stakingPercentage;

      if (this.glbC.currRDay !== undefined) {
        this.currRexDay = this.glbC.currRDay;
      } else {
        this.currRexDay = await this.contractService.getCurrentRexDay();
      }

      if (this.currRexDay <= this.glbC.lastAuctionDay) {
        dayToLoad = this.currRexDay;
      } else {
        dayToLoad = 222;
      }

      const accountAddress = await this.contractService.account.address;

      const promBPDs = new Promise((resolve, reject) => {
        this.getRequest(
          `${this.serverAddress}/readDB/bpd?action=claimedBPD&parameter=${accountAddress}`
        ).subscribe(
          (content) => {
            let result = JSON.parse(content);
            if (result.length !== 0) {
              [result] = Object.values(result[0]);
            } else {
              result = 0;
            }
            this.totalBPDs = result;
            resolve(result);
          },
          (error) => {
            console.error(error);
            reject(error);
          }
        );
      });
      const promMoneyBPDs = new Promise((resolve, reject) => {
        this.getRequest(
          `${this.serverAddress}/readDB/bpd?action=totalWonBUSD&parameter=${accountAddress}`
        ).subscribe(
          (content) => {
            let result = JSON.parse(content);
            if (result.length !== 0) {
              [result] = Object.values(result[0]);
            } else {
              result = 0;
            }
            totalMoneyBPDs = result;
            resolve(result);
          },
          (error) => {
            console.error(error);
            reject(error);
          }
        );
      });

      const promises: Promise<any>[] = [];

      promises.push(
        new Promise((res: any) => {
          this.contractService
            .getTotalReceivedREX()
            .then((result) => {
              totalREXreceived = result;
              res();
            })
            .catch((error) => {
              console.error("Error:", error);
            });
        })
      );
      promises.push(
        new Promise((res: any) => {
          this.contractService
            .getTotalSentBUSD()
            .then((result) => {
              this.personalSentBUSD = result;
              res();
            })
            .catch((error) => {
              console.error("Error:", error);
            });
        })
      );
      promises.push(
        new Promise((res: any) => {
          this.contractService
            .getTotalDonBalance()
            .then((result) => {
              this.personalSentBUSDBonus = result;
              res();
            })
            .catch((error) => {
              console.error("Error:", error);
            });
        })
      );
      promises.push(
        new Promise((res: any) => {
          this.contractService
            .getTotalRefBalance()
            .then((result) => {
              this.personalReferalAmount = result;
              res();
            })
            .catch((error) => {
              console.error("Error:", error);
            });
        })
      );
      promises.push(
        new Promise((res: any) => {
          this.contractService
            .getRexAmount()
            .then((result) => {
              this.totalRexInWallet = Number(result) / 1e18;
              res();
            })
            .catch((error) => {
              console.error("Error:", error);
            });
        })
      );
      promises.push(
        new Promise((res: any) => {
          this.contractService
            .getMyTotalStakedREX()
            .then((result) => {
              this.totalRexInStakes = Number(result) / 1e18;
              res();
            })
            .catch((error) => {
              console.error("Error:", error);
            });
        })
      );
      promises.push(
        new Promise((res: any) => {
          this.contractService
            .getPersonalAuctionData(0, dayToLoad)
            .then((result) => {
              console.log(result);
              this.personalAuctionData = result;
              res();
            })
            .catch((error) => {
              console.error("Error:", error);
            });
        })
      );
      promises.push(promBPDs);
      promises.push(promMoneyBPDs);

      Promise.all(promises).then(() => {
        console.log("your stat first part promises resolved");

        console.log(this.totalBPDs);
        console.log(totalMoneyBPDs);

        const countAuctionPart = this.personalAuctionData.filter((obj) => {
          return obj.myDonAmount > 0;
        }).length;
        if (Number(this.totalRexInStakes) !== 0) {
          stakingPercentage =
            (Number(this.totalRexInStakes) /
              Number(this.totalRexInStakes + this.totalRexInWallet)) *
            100;
        } else {
          stakingPercentage = 0;
        }

        this.returnValues[4] = [
          countAuctionPart,
          totalREXreceived,
          totalMoneyBPDs,
          stakingPercentage,
        ];
        this.isInitializing = false;
        this.isReady = true;
        this.glbC.showLoader(false);
      });
    }
  }

  async updatePageDEX() {
    this.currRexDay = await this.contractService.getCurrentRexDay();
    if (
      this.returnValuesDEX.length === 0 &&
      this.currRexDay >= this.glbC.firstDEXDay
    ) {
      console.log("update DEX page");
      this.accountAddress = await this.contractService.loadCurrentAccount();
      this.accountAddress = this.accountAddress.address;
      console.log(this.accountAddress);
      this.isInitializing = false;
      this.isReady = true;
    } else {
      this.isInitializing = false;
      this.isReady = true;
    }
  }

  async updatePageLotterie() {
    console.log("update Lotterie page");
    let totalPersTicketsObject: any;
    this.totalPersonalTickets = 0;
    this.currentLotterieRound = await this.contractService.getCurrentRound();
    console.log("Current Round: " + this.currentLotterieRound);
    [
      this.soldTickets,
      this.roundLotIsDistributed,
      this.personalLotBusd,
      this.personalLotBusdRecvd,
      this.personalLotBusdSpent,
      this.personalTickets,
      totalPersTicketsObject,
      this.lotterieSaleOpen,
      this.oracleIsReady,
      this.userIsBlocked,
    ] = await Promise.all([
      this.contractService.getSoldTickets(),
      this.contractService.getRoundIsDistributed(0),
      this.contractService.getPersonalBusdClaim(),
      this.contractService.getTotalPersonalBUSDrecvd(),
      this.contractService.getTotalPersonalBUSDspent(),
      this.contractService.getTicketsOfAddr(this.currentLotterieRound),
      this.contractService.getTicketsOfAddrTotal(this.currentLotterieRound),
      this.contractService.getSaleOpen(),
      this.contractService.getOracleReady(),
      this.contractService.userIsBlocked(),
    ]);

    for (let i = 0; i < totalPersTicketsObject.length; i++) {
      let amountOfTickets: any = Number(
        totalPersTicketsObject[i].numberOfTickets
      );
      this.totalPersonalTickets += amountOfTickets;
    }

    console.log("Sold tickets: " + this.soldTickets);
    console.log("Round is distributed: " + this.roundLotIsDistributed);
    console.log("Claimable Lotterie Busd: " + this.personalLotBusd);
    console.log("Already received Lot Busd: " + this.personalLotBusdRecvd);
    console.log("Spent busd on tickets: " + this.personalLotBusdSpent);
    console.log(
      "Amount of owned tickets (this round): " + this.personalTickets
    );
    console.log("Amount of owned tickets (total): ", this.totalPersonalTickets);
    console.log("Is Sale open? " + this.lotterieSaleOpen);
    console.log("Oracle is ready?: " + this.oracleIsReady);

    /*     console.log("Amount of lot participants: " + lotterieParticipants);
    console.log("Random rumber: " + rndmNumber); */

    this.isInitializing = false;
    this.isReady = true;
  }

  /**
   * Updates the chart with data from the database
   * @param table From which table in the DB comes the data
   * @param xAxis What is on the xAxis? (either timestamp or dateNo)
   * @param yAxis What is on the yAxis?
   * @param pageNum On which statistical page is the diagram? (0-4)
   * @param chartNum On that specific page, is it the first, second or third diagram?
   * @param timeNum Which time interval was selected? 0 (=day) | 1 (=week) | 2 (=month) | 3 (=year) | 4 (=max) if no "day" button exists: 0 (=week)... (3=max)
   * @param chart Reference to the chart to modify
   */
  async updateChart(
    table: string,
    xAxis: string,
    yAxis: string,
    pageNum: number,
    chartNum: number,
    timeNum: number,
    chart: chartInstance
  ) {
    const myChart = chart;

    const numberDatapoints: number = this.amountOfDatapoints;

    // Change active state of buttons
    this.activeTimeArray[pageNum][chartNum].forEach((value, index) => {
      this.activeTimeArray[pageNum][chartNum][index] = false;
    });
    this.activeTimeArray[pageNum][chartNum][timeNum] = true;

    const requestedDBentry = this.dataAllCharts.find(
      (entry) => entry.name === yAxis
    );

    if (requestedDBentry.values[timeNum][1].length === 0) {
      if (xAxis === "timestamp") {
        // Request Data
        this.getRequest(
          `${this.serverAddress}/chartData/${table}?column=${yAxis}&timeInterval=${timeNum}&amountDatapoints=${numberDatapoints}`
        ).subscribe((content) => {
          const result: any[] = Object.values(JSON.parse(content));
          [
            requestedDBentry.values[timeNum][0],
            requestedDBentry.values[timeNum][1],
          ] = result;
          this.fortmatDate(requestedDBentry.values[timeNum][0], timeNum);

          [myChart.label, myChart.data[0].data] =
            requestedDBentry.values[timeNum];
        });
      } else if (xAxis === "dateNo") {
        let timeToLoad: number;
        if (this.currRexDay === "" || this.currRexDay === 0) {
          this.currRexDay = await this.contractService.getCurrentRexDay();
        }

        switch (timeNum) {
          case 0:
            if (this.currRexDay - 7 > 0) {
              timeToLoad = this.currRexDay - 7;
            } else {
              timeToLoad = 1;
            }
            break;
          case 1:
            if (this.currRexDay - 31 > 0) {
              timeToLoad = this.currRexDay - 31;
            } else {
              timeToLoad = 1;
            }
            break;
          case 2:
            timeToLoad = 1;
            break;

          default:
            break;
        }
        if (yAxis === "totalDonBUSD") {
          console.log("get total don");
          this.getRequest(
            `${this.serverAddress}/readDB/auctions?action=totalDonations&parameter=${timeToLoad}`
          ).subscribe((content) => {
            const result = JSON.parse(content);
            console.log(result);
            for (let i = 0; i < result.length; i++) {
              requestedDBentry.values[timeNum][0].push(result[i].dateNo);
              requestedDBentry.values[timeNum][1].push(
                Number(result[i].totalDonatedBUSD / 1e18)
              );
            }
            console.log(requestedDBentry);

            [myChart.label, myChart.data[0].data] =
              requestedDBentry.values[timeNum];
          });
        } else if (yAxis === "uniqueBPDwinner") {
          this.getRequest(
            `${this.serverAddress}/readDB/auctions?action=uniqueBPDwinner&parameter=${timeToLoad}`
          ).subscribe((content) => {
            const result = JSON.parse(content);
            for (let i = 0; i < result.length; i++) {
              requestedDBentry.values[timeNum][0].push(result[i].dateNo);
              requestedDBentry.values[timeNum][1].push(
                result[i].uniqueBPDwinner
              );
            }

            [myChart.label, myChart.data[0].data] =
              requestedDBentry.values[timeNum];

            this.usersWithBPD =
              myChart.data[0].data[myChart.data[0].data.length - 1];
            console.log(this.usersWithBPD);
          });
        } else if (yAxis === "eligibleBPDaddresses") {
          this.getRequest(
            `${this.serverAddress}/readDB/auctions?action=eligibleBPDaddresses&parameter=${timeToLoad}`
          ).subscribe((content) => {
            const result = JSON.parse(content);
            for (let i = 0; i < result.length; i++) {
              requestedDBentry.values[timeNum][0].push(result[i].dateNo);
              requestedDBentry.values[timeNum][1].push(
                result[i].eligibleBPDaddresses
              );
            }

            [myChart.label, myChart.data[0].data] =
              requestedDBentry.values[timeNum];
          });
        } else if (yAxis === "sumBPDwinners") {
          this.getRequest(
            `${this.serverAddress}/readDB/auctions?action=sumBPDwinners&parameter=${timeToLoad}`
          ).subscribe((content) => {
            const result = JSON.parse(content);
            for (let i = 0; i < result.length; i++) {
              requestedDBentry.values[timeNum][0].push(result[i].dateNo);
              requestedDBentry.values[timeNum][1].push(result[i].sumBPDwinners);
            }
            [myChart.label, myChart.data[0].data] =
              requestedDBentry.values[timeNum];
          });
        } else if (yAxis === "totalGeneratedREX") {
          this.getRequest(
            `${this.serverAddress}/readDB/auctions?action=totalGeneratedREX&parameter=${timeToLoad}`
          ).subscribe((content) => {
            const result = JSON.parse(content);
            for (let i = 0; i < result.length; i++) {
              requestedDBentry.values[timeNum][0].push(result[i].dateNo);
              requestedDBentry.values[timeNum][1].push(
                Number(result[i].totalGeneratedREX) / 1e18
              );
            }

            [myChart.label, myChart.data[0].data] =
              requestedDBentry.values[timeNum];
          });
        }
      }
    } else {
      // If data is already loaded, dont make a request to the DB
      const linkedValues = this.dataAllCharts.find(
        (entry) => entry.name === yAxis
      );
      [myChart.label, myChart.data[0].data] = linkedValues.values[timeNum];
    }
  }

  /**
   * Calculates the labels for the global chart "expiring shares"
   * @param timeperiod Either 0 (=week), 1 (=month) or 2 (=year)
   */
  calculateSharesChartLabels(timeperiod: number) {
    const requestedDBentry = this.dataAllCharts.find(
      (entry) => entry.name === "expiringSharesGlobal"
    );
    if (requestedDBentry.values[timeperiod][0].length === 0) {
      let timeDif;

      // Calculates the time difference between now and the timestamp of the final day
      if (timeperiod === 0) {
        timeDif = this.rexTimestampInSecondsWeek;
        this.sharesChartDataPoints = 7;
      } else if (timeperiod === 1) {
        timeDif = this.rexTimestampInSecondsMonth;
        this.sharesChartDataPoints = 30;
      } else if (timeperiod === 2) {
        timeDif = this.rexTimestampInSecondsYear;
        this.sharesChartDataPoints = 365;
      }
      const currentTime = Math.floor(new Date().getTime() / 1000.0); // Get current date & time

      for (let i = 0; i < this.sharesChartDataPoints; i++) {
        this.sharesLabelArray[i] = Math.trunc(
          currentTime + (timeDif / (this.sharesChartDataPoints - 1)) * i
        );
      }
      this.fortmatDate(this.sharesLabelArray, 2);
    }
  }

  /**
   * Calculates the values for the global chart "expiring shares"
   * @param timeperiod Either 0 (=week), 1 (=month) or 2 (=year)
   * @param sharesChart The chart object which is effected
   */
  async calculateSharesChartValues(
    timeperiod: number,
    sharesChart: chartInstance
  ) {
    const theSharesChart = sharesChart;
    const requestedDBentry = this.dataAllCharts.find(
      (entry) => entry.name === "expiringSharesGlobal"
    );
    if (requestedDBentry.values[timeperiod][1].length === 0) {
      const daysArray: any[] = [];
      const sharesValueArray: any[] = [];
      let lastLoadDay: number;

      // Until which day the data should be loaded
      if (timeperiod === 0) {
        lastLoadDay = Number(this.currRexDay) + 8;
      } else if (timeperiod === 1) {
        lastLoadDay = Number(this.currRexDay) + 31;
      } else if (timeperiod === 2) {
        lastLoadDay = Number(this.currRexDay) + 366;
      }

      for (let i = Number(this.currRexDay) + 1; i < lastLoadDay; i++) {
        daysArray.push(i);
      }
      for (let i = 0; i < daysArray.length; i++) {
        sharesValueArray.push(0);
      }

      // Requests all stakes, which end between now and the end day
      const expSharesRequest = new Promise((resolve) => {
        this.getRequest(
          `${this.serverAddress}/readDB/stakes?action=endingShares&parameter=${this.currRexDay}&parameter2=${lastLoadDay}`
        ).subscribe((content) => {
          const result = JSON.parse(content);
          for (let i = 0; i < result.length; i++) {
            result[i].stakesShares = Math.round(
              Number(result[i].stakesShares) / 1e18
            );
          }
          resolve(result);
        });
      });
      expSharesRequest
        .then((endingObjects: any[]) => {
          // Fill Array "sharesOnDay" with the ending shares on each day
          for (let i = 0; i < daysArray.length; i++) {
            for (let j = 0; j < endingObjects.length; j++) {
              if (daysArray[i] === Number(endingObjects[j].endDay) + 1) {
                sharesValueArray[i] += endingObjects[j].stakesShares;
              }
            }
          }
          requestedDBentry.values[timeperiod] = [
            this.sharesLabelArray,
            sharesValueArray,
          ];
          theSharesChart.data[0].data = sharesValueArray;
          theSharesChart.label = this.sharesLabelArray;

          this.sharesLabelArray = [];
        })
        .catch(() => {
          console.log("Error in Database request");
        });
    } else {
      theSharesChart.data[0].data = requestedDBentry.values[timeperiod][1];
      theSharesChart.label = requestedDBentry.values[timeperiod][0];
    }
  }
}
