import { Component, OnInit } from "@angular/core";
import { GlobalControllerService } from "src/app/services/global-controller.service";
import { chartInstance, HttpService } from "../../../../services/http.service";

@Component({
  selector: "app-global",
  templateUrl: "./global.component.html",
  styleUrls: ["./global.component.scss"],
})
export class GlobalComponent implements OnInit {
  supplyChart: chartInstance = {
    type: "line",
    label: [],
    data: [
      {
        data: [],
        borderColor: this.service.colorRexBlue,
        pointBackgroundColor: this.service.colorRexBlue,
        pointRadius: 1,
      },
    ],
    options: {
      maintainAspectRatio: false,
      hitRadius: this.service.hitradiusCharts,
      elements: {
        line: {
          tension: 0.3,
        },
      },
      plugins: {
        legend: {
          display: false,
        },
      },
      scales: {
        x: {
          ticks: {
            autoSkip: true,
            maxTicksLimit: 10,
            color: this.service.colorRexBlue,
          },
        },
        y: {
          ticks: {
            color: this.service.colorRexBlue,
          },
        },
      },
    },
  };

  sharesChart: chartInstance = {
    type: "line",
    label: [],
    data: [
      {
        data: [],
        borderColor: this.service.colorRexBlue,
        pointBackgroundColor: this.service.colorRexBlue,
        pointRadius: 1,
      },
    ],
    options: {
      maintainAspectRatio: false,
      hitRadius: this.service.hitradiusCharts,
      elements: {
        line: {
          tension: 0.3,
        },
      },
      plugins: {
        legend: {
          display: false,
        },
      },
      scales: {
        x: {
          ticks: {
            autoSkip: true,
            maxTicksLimit: 10,
            color: this.service.colorRexBlue,
          },
        },
        y: {
          ticks: {
            color: this.service.colorRexBlue,
          },
        },
      },
    },
  };

  constructor(
    public service: HttpService,
    public glbC: GlobalControllerService
  ) {}

  ngOnInit() {
    this.glbC.showLoader(true);
    this.service.isInitializing = true;
    this.updateSupplyChart(0);
    this.updateSharesChart(0);
    // If the page data was already loaded before
    if (this.service.returnValues[0].length !== 0) {
      this.service.isReady = true;
    }
    this.service.waitForInitialization().then(() => {
      console.log("page is initiazied");
      this.service.isInitializing = false;
      this.service.isReady = false;
      this.glbC.showLoader(false);
    });
  }

  updateSupplyChart(timeNum: number) {
    this.service.updateChart(
      this.service.DBglobals,
      "timestamp",
      "totalSupply",
      0,
      0,
      timeNum,
      this.supplyChart
    );
  }

  updateSharesChart(timeNum: number) {
    this.service.updateChart(
      this.service.DBglobals,
      "timestamp",
      "totalSharesRex",
      0,
      1,
      timeNum,
      this.sharesChart
    );
  }
}
