import {
  ComponentFactoryResolver,
  ComponentRef,
  Inject,
  Injectable,
  NgZone,
} from "@angular/core";
import { Subject, Subscription } from "rxjs";
import { Router } from "@angular/router";
import BigNumber from "bignumber.js";
import { CookieService } from "ngx-cookie-service";
import { TranslateService } from "@ngx-translate/core";
import { HttpClient } from "@angular/common/http";

import { AppConfig } from "./app-config.service";
import { ContractService } from "./contract";
import { RexPopupMessageComponent } from "../components/rex-popup-message/rex-popup-message.component";
import { RexPopupOptions } from "../models/data-models";

@Injectable({
  providedIn: "root",
})
export class GlobalControllerService {
  public suffixLang: string = "v2";

  public debugValues: boolean = false;

  public showPelerinBox: boolean = false;

  public globalLoading: boolean = false;

  /*   public account = {
    address: "",
    balances: {
      ETH: {
        wei: String,
        weiBigNumber: BigNumber,
        shortBigNumber: BigNumber,
        display: String,
      },
      REX: {
        wei: String,
        weiBigNumber: BigNumber,
        shortBigNumber: BigNumber,
        display: String,
      },
      BUSD: {
        wei: String,
        weiBigNumber: BigNumber,
        shortBigNumber: BigNumber,
        display: String,
      },
      REXPAIR: {
        wei: String,
        weiBigNumber: BigNumber,
        shortBigNumber: BigNumber,
        display: String,
      },
      MREX: { display: "" },
      TREX: { display: "" },
    },
  };
 */

  public currentLanguage: string = "en";

  public account: any = {};

  showLanguageBox: boolean = false;

  public waitingForAuthorization: boolean = false;

  public balancesAreLoaded: boolean = false;

  public walletType: string = "";

  // global values:
  public currRDay: number;

  public totalStakedRex;

  public totalSharesRex;

  public totalClaimedRex;

  public sharePriceRex;

  public rexAmount;

  private accountSubscribe: Subscription;

  public showConnectBox: boolean = false;

  public addToMetamask = false;

  public chainNetwork = "SmartChain";

  public bannerForClaim = false;

  public isOnWrongNetwork: boolean = false;

  public initSubject: Subject<any> = null;

  public networkOrAccountChangedSubject: Subject<{ type: string; data: any }> =
    null;

  public currentMREXpricePublic: any;

  public currentREXpricePublic: any;

  private popupRootViewContainer: any;

  private factoryResolver: ComponentFactoryResolver;

  private nextPopupId = 1;

  private mrexTokenAddress = "0x76837d56d1105bb493cddbefeddf136e7c34f0c4";

  public isConnected: boolean = false;

  private allPopupComponents: ComponentRef<RexPopupMessageComponent>[] = [];

  private globalLoadingSpinnerStartedTimestamp: number = 0;

  public rewardWindowAlreadeyOpen: boolean = false;

  public isMobile: boolean = false;

  public isBigTablet: boolean = false;

  public winWidth: number;

  public winHeight: number;

  public burgerMenuOpen: boolean = false;

  public maxStakeDuration: number = 3653;

  public minStakeDuration: number = 7;

  public lastAuctionDay: number = 222;

  public treasuryClaimDay: number = 252;

  public lastContractDay: number = 258;

  public firstDEXDay: number = 111;

  public rexDayDurationInMilliseconds: number = 86400000; // change to 86400000 for LIVE; 3600000 for TESTNET

  public rexDayDurationInSeconds: number =
    this.rexDayDurationInMilliseconds / 1000;

  public firstAirdropClaimDay: number = 1;

  public rexReserveLP: number;

  public busdReserveLP: number;

  public chainNetworkString = "Binance SmartChain"; // change to "Binance SmartChain" for LIVE

  public bigNumberConfig = {
    DECIMAL_PLACES: 18,
    ROUNDING_MODE: BigNumber.ROUND_FLOOR,
  }; // , ROUNDING_MODE: 3

  pricesAreLoaded: boolean = false;

  constructor(
    public contractService: ContractService,
    private config: AppConfig,
    private ngZone: NgZone,
    private router: Router,
    public cookieService: CookieService,
    private translate: TranslateService,
    public http: HttpClient,
    @Inject(ComponentFactoryResolver) factoryResolver
  ) {
    this.factoryResolver = factoryResolver;
    const settingsData = this.config.getConfig();
    contractService.setGlbc(this);

    // initial global BigNumber configs:
    this.resetBigNumberConfig();

    // Check for language cookie and switch language
    this.checkLanguage();

    console.log("window.ethereum: ", window.ethereum);
    this.initSubject = new Subject();
    this.networkOrAccountChangedSubject = new Subject();
    this.networkOrAccountChangedSubject.subscribe((data: any) => {
      console.log("networkOrAccountChangedSubject data:", data);
      if (data.type === "account") {
        console.log(data.data.length);
        if (data.data.length === 0) {
          // If logout was clicked directly in metamask
          console.log("Logout was clicked");
          this.hasConnectedBefore(false, "metamask");
          this.logoutClicked();
        } else {
          this.account = {};
          // TODO accountchange logic
          this.contractService
            .loadCurrentAccount()
            .then((newAccount) => {
              this.account = newAccount;
              console.log("new account loaded: ", this.contractService.account);
              setTimeout(() => {
                this.initSubject.next({ doInit: true, reInit: true });
              }, 100);
            })
            .catch((err) => {
              console.error("loadCurrentAccount error: ", err);
            });
        }
      } else if (data.type === "chain") {
        this.ngZone.run(() => {
          console.log(
            "set isonWrongNetwork:",
            this.config.getConfig().settings.net === Number(data.data)
          );
          if (this.config.getConfig().settings.net === Number(data.data)) {
            this.isOnWrongNetwork = false;
            // initsubscribe
            this.initSubject.next({ doInit: true, reInit: true });
          } else {
            console.log("put is on wrong network = true");
            this.isOnWrongNetwork = true;
            this.showLoader(false);
            // unsubscribe all
            if (this.accountSubscribe) {
              this.accountSubscribe.unsubscribe();
              setTimeout(() => {
                this.accountSubscribe = null;
                this.initSubject.next({ doInit: false });
              }, 100);
            }
          }
        });
      }
    });

    if (window.ethereum) {
      window.ethereum.on("chainChanged", (data) => {
        if (this.walletType === "metamask") {
          console.log("glbc ethereum chainChanged data: ", data);
          console.log(
            "glbc ethereum chainChanged Number(data): ",
            Number(data)
          );
          window.ethereum
            .request({
              method: "net_version",
            })
            .then((result) => {
              console.log("window.ethereum net_version result: ", result);
              this.addToMetamask =
                settingsData.settings.chainsForButtonAddToMetamask.includes(
                  Number(result)
                );
            });
          this.networkOrAccountChangedSubject.next({
            type: "chain",
            data,
          });
        } else {
          console.log("chain change in metamask, but not logged in");
        }
      });

      window.ethereum.on("accountsChanged", (data) => {
        if (this.walletType === "metamask") {
          console.log("glbc ethereum accountsChanged data: ", data);
          console.log(
            "glbc ethereum accountsChanged Number(data): ",
            Number(data)
          );
          this.networkOrAccountChangedSubject.next({
            type: "account",
            data,
          });
        } else {
          console.log("account change in metamask, but not logged in");
        }
      });

      /*       window.ethereum
        .request({
          method: "net_version",
        })
        .then((result) => {
          this.isOnWrongNetwork =
            this.contractService.settingsApp.settings.net !== Number(result);
          this.addToMetamask =
            settingsData.settings.chainsForButtonAddToMetamask.includes(
              Number(result)
            );
          console.log(this.contractService.settingsApp.settings.net);
          console.log(Number(result));
          console.log("this.isOnWrongNetwork: ", this.isOnWrongNetwork);
        })
        .catch((err) => {
          console.error("window.ethereum request net_version error: ", err);
        }); */
    }

    if (settingsData.settings) {
      this.chainNetwork = settingsData.settings.network;
      if (this.chainNetwork === "bsctestnet") {
        this.chainNetworkString = "Binance Testnet";
      } else {
        this.chainNetworkString = "Binance Smart Chain";
      }
      console.log(`Chain Network: ${this.chainNetwork}`);
      /* console.log(
        "Was connected before on MM: ",
        this.getHasConnectedBefore("metamask")
      );
      if (this.getHasConnectedBefore("metamask")) {
        this.lo;
      }
      console.log(
        "Was connected before on WC: ",
        this.getHasConnectedBefore("walletConnect")
      ); */
    }
  }

  public resetBigNumberConfig() {
    console.warn("resetBigNumberConfig called");
    BigNumber.config(this.bigNumberConfig);
  }

  public checkLanguage() {
    let languageToSet: string = "en";
    const cookieLanguage = this.cookieService.get("lang");
    console.log("Cookie Language:", cookieLanguage);
    if (cookieLanguage === "") {
      let browserLang: string = navigator.languages
        ? navigator.languages[0]
        : navigator.language;
      browserLang = browserLang.slice(0, 2);
      if (browserLang === "de" || browserLang === "en") {
        languageToSet = browserLang;
        console.log("Browser Lang is ", languageToSet);
      }
    } else {
      languageToSet = cookieLanguage;
    }
    this.translate.use(languageToSet + "-" + this.suffixLang);
    this.currentLanguage = languageToSet;
  }

  public logoutClicked() {
    this.isConnected = false;
    if (this.walletType === "walletConnect") {
      this.contractService.endWeb3Connection();
      this.hasConnectedBefore(false, "walletConnect");
    } else if (this.walletType === "metamask") {
      this.hasConnectedBefore(false, "metamask");
    }
    this.account = {};

    this.router.navigate(["dashboard"]);
  }

  public showLoader(showIt: boolean) {
    if (showIt) {
      this.globalLoadingSpinnerStartedTimestamp = Date.now();
    }
    this.ngZone.run(() => {
      this.globalLoading = showIt;
    });
    // start timer if loader got set to true
    if (showIt) {
      setTimeout(() => {
        console.warn(
          "showLoader called with this.waitingForAuthorization: ",
          this.waitingForAuthorization
        );
        let timeOutTimer = 25500;
        if (this.waitingForAuthorization) {
          timeOutTimer = 30000;
          this.waitingForAuthorization = false;
          this.showLoader(false);
          console.log("not logged in to metamask");
          this.showPopupMessage({
            title: this.translate.instant("POPUPS.not_authorized"),
            msg: `<p>${this.translate.instant(
              "POPUPS.please_login_to_metamask"
            )}</p>`,
            declineButtonText: this.translate.instant("BUTTONS.ok"),
          });
        }
        setTimeout(() => {
          if (
            this.globalLoading &&
            Date.now() - this.globalLoadingSpinnerStartedTimestamp > 500
          ) {
            if (this.waitingForAuthorization) {
              this.globalLoading = false;
              console.log("not logged in to metamask");
              // show chain waiting for authorization message
              this.showPopupMessage({
                title: this.translate.instant("POPUPS.not_authorized"),
                msg: `<p>${this.translate.instant(
                  "POPUPS.please_login_to_metamask_and_reload"
                )}</p>`,
                declineButtonText: this.translate.instant("BUTTONS.ok"),
                extraClass: "connectChoiceBox",
              });
            } else {
              this.globalLoading = false;
              // show chain is slow error; DONT show, because blockchain is normally not slow
              /*               this.showPopupMessage({
                title: this.translate.instant("POPUPS.warning"),
                msg: `<p>${this.translate.instant(
                  "POPUPS.blockchain_seems_slow"
                )}</p>`,
                declineButtonText: this.translate.instant("BUTTONS.ok"),
                removeOtherPopups: true,
              }); */
            }
          }
        }, timeOutTimer);
      }, 500);
    }
  }

  public getAuctionTimestamp(currentRexDay): Promise<number> {
    return new Promise((resolve: any) => {
      this.contractService.getLaunch().then((launchTime) => {
        const isDayZero = launchTime * 1000 - Date.now() < 0;
        let resultingTimestamp: number;
        if (Number(currentRexDay) === 0 && !isDayZero) {
          console.log("first day");
          // calculate timer until launch
          resultingTimestamp =
            Date.now() + Math.abs(Date.now() - launchTime * 1000);
        } else {
          console.log("not first day");
          // calculate until next auction day end
          const now = Date.now() / 1000;
          const nowSec = Math.floor(now);
          const distance = nowSec - launchTime;
          const mod = distance % (this.rexDayDurationInMilliseconds / 1000);
          const secondsleft = this.rexDayDurationInMilliseconds / 1000 - mod;
          resultingTimestamp = Date.now() + secondsleft * 1000;
        }
        resolve(resultingTimestamp);
      });
    });
  }

  public subscribeAccount(resubscribe?: boolean) {
    console.log("glbCtrl subscribeAccount called");
    return new Promise((resolve: any, reject: any) => {
      if (resubscribe) {
        this.account = null;
      }
      this.contractService
        .loadCurrentAccount()
        .then((sAccount: any) => {
          console.log("sAccount: ", sAccount);
          console.log("this.account: ", this.account);
          if (sAccount) {
            /*   if (!this.account || this.account.address !== sAccount.address) { */
            this.contractService
              .loadAccountInfo()
              .then(() => {
                this.account = sAccount;
                this.isConnected = true;
                console.log("account info successfully loaded");
                this.removeAllPopups();
                this.loadCurrentPrices();
                this.getCurrentRexDay();
                // if (this.accountSubscribe) {
                //   this.accountSubscribe.unsubscribe();
                // }
                resolve();
              })
              .catch((err) => {
                console.error("loadAccountInfo error: ", err);
                this.showPopupMessage({
                  title: this.translate.instant("POPUPS.warning"),
                  msg: `<p>${this.translate.instant(
                    "POPUPS.metamask_error"
                  )}</p>`,
                  declineButtonText: this.translate.instant("BUTTONS.ok"),
                  removeOtherPopups: true,
                });
                this.showLoader(false);
                reject();
              });
          } else {
            console.log("No account was found");
            reject();
          }
        })
        .catch((err) => {
          console.error("accountSubscription error: ", err);
          // this.accountSubscribe.unsubscribe();
          reject(err);
        });
      // this.accountSubscribe = this.contractService.accountSubscribe().subscribe(
      //   (sAccount: any) => {
      //     console.log("sAccount: ", sAccount);
      //     console.log("this.account: ", this.account);
      //     if (sAccount) {
      //       if (!this.account || this.account.address !== sAccount.address) {
      //         this.contractService
      //           .loadAccountInfo()
      //           .then(() => {
      //             this.account = sAccount;
      //             this.isConnected = true;
      //             if (this.accountSubscribe) {
      //               this.accountSubscribe.unsubscribe();
      //             }
      //             resolve();
      //           })
      //           .catch((err) => {
      //             console.error("loadAccountInfo error: ", err);
      //             reject();
      //           });
      //       } else {
      //         this.account = sAccount;
      //         this.isConnected = true;
      //         if (this.accountSubscribe) {
      //           this.accountSubscribe.unsubscribe();
      //         }
      //         resolve();
      //       }
      //     } else {
      //       // this.accountSubscribe.unsubscribe();
      //       reject();
      //     }
      //   },
      //   (err) => {
      //     console.error("accountSubscription error: ", err);
      //     this.accountSubscribe.unsubscribe();
      //     reject(err);
      //   }
      // );
      // this.contractService
      //   .getAccount()
      //   .then(() => {
      //     console.log("contractService.getAccount resolved");
      //   })
      //   .catch((err) => {
      //     console.error("this.contractService.getAccount() error: ", err);
      //     this.isConnected = false;
      //     reject(err);
      //   });
    });
  }

  public viewPortChanged() {
    if (window) {
      this.winWidth = window.innerWidth;
      this.winHeight = window.innerHeight;
    }
    if (this.winWidth && this.winWidth < 992) {
      this.isBigTablet = true;
    } else {
      this.isBigTablet = false;
    }
    if (this.winWidth && this.winWidth < 769) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
  }

  public async getCurrentRexDay() {
    this.currRDay = await this.contractService.getCurrentRexDay();
    console.log("Current REX Day Loaded. It is Day ", this.currRDay);
    return this.currRDay;
  }

  public getRexShareFactor() {
    return new BigNumber(10 ** 18).div(this.sharePriceRex);
  }

  public formatNumber(value: any, type: string, digits?: number) {
    console.log(
      `formatNumber called with value: ${value} type: ${type} digits: ${digits}`
    );
  }

  async loadCurrentPrices() {
    let pcsReserves: any;
    const promises: Promise<any>[] = [];
    console.log("load Current Prices called");

    promises.push(
      new Promise((res: any) => {
        this.contractService
          .getPairReserves()
          .then((reserves) => {
            pcsReserves = reserves;
            res();
          })
          .catch((error) => {
            console.error("Error is get rex price:", error);
          });
      })
    );
    promises.push(
      new Promise((res: any) => {
        this.contractService
          .getPairReservesBUSD()
          .then((reserves) => {
            const mrexPrice = reserves[1] / reserves[0] / 1e18;
            this.currentMREXpricePublic = mrexPrice;
            res();
          })
          .catch((error) => {
            console.error("Error is get mrex price:", error);
          });
      })
    );

    Promise.all(promises).then(() => {
      // eslint-disable-next-line prefer-destructuring
      this.rexReserveLP = pcsReserves[0];
      // eslint-disable-next-line prefer-destructuring
      this.busdReserveLP = pcsReserves[1];

      if (Number(this.rexReserveLP) !== 0) {
        this.currentREXpricePublic = Number(
          Number(this.busdReserveLP / this.rexReserveLP).toFixed(7)
        );
      } else {
        this.currentREXpricePublic = 0;
      }
      this.pricesAreLoaded = true;

      console.log(
        "Current REX Price:",
        this.currentREXpricePublic,
        " Current MREX Price:",
        this.currentMREXpricePublic
      );
    });
  }

  /**
   * Performs a HTTP get request
   * @param url URL to send the get request to
   * @returns Get response
   */
  getRequest(url: string) {
    const options = {
      responseType: "text" as const,
    };
    return this.http.get(url, options);
  }

  /**
   * Makes a GET request to the PCS API to receive current price of the input token
   * @param token Token address ("0x...")
   * @returns Current price of the token
   */
  getPCSdata(token: string) {
    return new Promise((resolve) => {
      this.getRequest(
        `https://api.pancakeswap.info/api/v2/tokens/${token}`
      ).subscribe((content) => {
        const currentTokenPrice = Number(
          JSON.parse(content).data.price
        ).toFixed(4);
        resolve(currentTokenPrice);
      });
    });
  }

  /**
   * Simulates a sleep function for asynchronous functions
   * @param ms the amount of milliseconds until the function resolves
   * @returns Promise which resolve after given milliseconds
   */
  public sleep(ms: number) {
    return new Promise((res: any) => {
      setTimeout(() => {
        res();
      }, ms);
    });
  }

  /**
   * This functions opens a new Tab to Pancakeswap with prefilled coin swaps
   *
   * @param fromCoin from which coin should be swapped
   * @param toCoin to which coin should be swapped
   */
  public openPCSSwap(fromCoinAddress: string, toCoinAddress: string) {
    console.log(
      `openPCSSwap called with from: ${fromCoinAddress} to: ${toCoinAddress}`
    );
    const url = `https://pancakeswap.finance/swap?inputCurrency=${fromCoinAddress}&outputCurrency=${toCoinAddress}`;
    window.open(url, "_blank");
  }

  public showHelpMessage(templateName: string) {
    const options: RexPopupOptions = {
      title: this.translate.instant(
        `POPUPS.HELPTEMPLATES.${templateName}_title`
      ),
      msg: `<p>${this.translate.instant(
        `POPUPS.HELPTEMPLATES.${templateName}_template`
      )}</p>`,
      declineButtonText: this.translate.instant("BUTTONS.ok"),
      removeOtherPopups: true,
    };
    this.showPopupMessage(options);
  }

  public showBSCscanPopup(transactionHash) {
    const options: RexPopupOptions = {
      title: this.translate.instant(`POPUPS.BSCSCAN.transaction_success`),
      msg: " <img class='checkmarkTransaction' src='assets/img/icons/claim-checkmark-icon.svg' alt='checkmark' />",
      acceptButtonText: this.translate.instant("POPUPS.BSCSCAN.view_on_bsc"),
      declineButtonText: this.translate.instant("BUTTONS.close"),
      removeOtherPopups: true,
      onAcceptCallback: () => {
        let url: string = "";
        console.log("Show transaction on bscscan");
        if (this.chainNetwork === "bsctestnet") {
          url = `https://testnet.bscscan.com/tx/${transactionHash}`;
        } else {
          url = `https://bscscan.com/tx/${transactionHash}`;
        }

        window.open(url, "_blank");
      },
    };
    this.showPopupMessage(options);
  }

  /**
   * Shows a popup message with the given title, message and buttonText
   * @param title title of the popup
   * @param msg message of the popup
   * @param buttonText text of the popup button
   */
  public showPopupMessage(options: RexPopupOptions) {
    if (options.removeOtherPopups) {
      this.removeAllPopups();
    }
    // dialog logic
    this.addPopupComponent(options);
  }

  public showPopupDialog(options: any) {
    this.addPopupComponent(options);
  }

  public setPopupRootViewContainerRef(viewContainerRef) {
    this.popupRootViewContainer = viewContainerRef;
  }

  private addPopupComponent(options: RexPopupOptions) {
    const factory = this.factoryResolver.resolveComponentFactory(
      RexPopupMessageComponent
    );
    const component = factory.create(
      this.popupRootViewContainer.parentInjector
    );
    component.instance.popupTitle = options.title;
    component.instance.popupMessage = options.msg;
    if (options.acceptButtonText) {
      component.instance.acceptButtonText = options.acceptButtonText;
    }
    if (options.declineButtonText) {
      component.instance.declineButtonText = options.declineButtonText;
    }
    if (options.extraButtonText) {
      component.instance.extraButtonText = options.extraButtonText;
    }
    if (options.inputFieldPlaceholder) {
      component.instance.inputFieldPlaceholder = options.inputFieldPlaceholder;
      if (options.inputType) {
        component.instance.inputType = options.inputType;
      }
    }
    if (options.inputFieldPlaceholder2) {
      component.instance.inputFieldPlaceholder2 =
        options.inputFieldPlaceholder2;
      if (options.inputType2) {
        component.instance.inputType2 = options.inputType2;
      }
    }

    if (options.onAcceptCallback) {
      component.instance.onAcceptCallback = options.onAcceptCallback;
    }
    if (options.onCancelCallback) {
      component.instance.onCancelCallback = options.onCancelCallback;
    }
    if (options.validationCheck) {
      component.instance.validationCheck = options.validationCheck;
    }
    if (options.extraClass) {
      component.instance.extraClass = options.extraClass;
    }
    if (options.onExtraButtonCallback) {
      component.instance.onExtraButtonCallback = options.onExtraButtonCallback;
    }
    if (!this.isMobile) {
      component.instance.messageId = this.nextPopupId;
    }
    this.nextPopupId += 1;
    component.instance.removePopup.subscribe(() => {
      this.popupRootViewContainer.remove(
        this.popupRootViewContainer.indexOf(component)
      );
      this.nextPopupId -= 1;
    });
    this.allPopupComponents.push(component);
    this.popupRootViewContainer.insert(component.hostView);
  }

  public removeAllPopups() {
    this.allPopupComponents.forEach((popupComponentRef) => {
      this.popupRootViewContainer.remove(
        this.popupRootViewContainer.indexOf(popupComponentRef)
      );
    });
    this.nextPopupId = 1;
  }

  /*   public rexDayToDate(rexDayAmount: number) {
    const now = Date.now();
    const millisAtRexDayZero =
      now - this.currRDay * this.rexDayDurationInMilliseconds;
    const millisAtTargetDate =
      millisAtRexDayZero + this.rexDayDurationInMilliseconds * rexDayAmount;
    const dateStr = new Date(millisAtTargetDate).toDateString();
    return dateStr.substring(dateStr.length - 11);
  } */

  public rexDayToTimestamp(rexDay: any) {
    const millisAtRexDayZero = 1656608400000;
    const millisAtTargetDate =
      millisAtRexDayZero + this.rexDayDurationInMilliseconds * Number(rexDay);
    return millisAtTargetDate;
  }

  /**
   * Set the referrer address cookie
   * @param refAddress address to be saved as referrer
   */
  public setReferrer(refAddress: string) {
    this.cookieService.set("rexRefAddr", refAddress, {
      expires: this.lastAuctionDay,
      path: "/",
    });
  }

  /**
   * check if referrer Cookie is set and return referrer address
   * @returns referrers Address if available
   */
  public getReferrer() {
    return this.cookieService.get("rexRefAddr") || "";
  }

  public hasConnectedBefore(set?: boolean, walletType?: string) {
    if (set === true && walletType === "metamask") {
      this.cookieService.delete("hCBWC");
      this.cookieService.set("hCBMM", "1", {
        expires: 7,
        path: "/",
      });
      return true;
    }
    if (set === true && walletType === "walletConnect") {
      this.cookieService.delete("hCBMM");
      this.cookieService.set("hCBWC", "1", {
        expires: 7,
        path: "/",
      });
    }
    this.cookieService.delete("hCBWC");
    if (set === false && walletType === "metamask") {
      this.cookieService.delete("hCBMM");
      return true;
    }
    if (set === false && walletType === "walletConnect") {
      this.cookieService.delete("hCBWC");
      return true;
    }
    return !!this.cookieService.get("hCB");
  }

  public getHasConnectedBefore(walletType: string) {
    if (walletType === "metamask") {
      return !!this.cookieService.get("hCBMM");
    }
    if (walletType === "walletConnect") {
      return !!this.cookieService.get("hCBWC");
    }
    return false;
  }
  /*
  switchLanguage(language: string) {
    console.log("switch to lang", language);
    this.translate.use(language);
    this.showLanguageBox = false;
    this.currentLanguage = language;
  }

  showLanguageSwitch() {
    this.showPopupMessage({
      title: this.translate.instant("POPUPS.language_switch"),
      msg: `<p>${this.translate.instant(
        "POPUPS.choose_language"
      )}</p><ul (click)="console.log('test')" class="languageList"><li (click)="console.log('test')">Deutsch</li>
        <li (click)="switchLanguage('en')">English</li></ul>`,
      declineButtonText: this.translate.instant("BUTTONS.abort"),
    });
  } */

  showBoxContent(content: any) {
    content.srcElement.classList.toggle("turned");
    content.srcElement.parentElement.nextElementSibling.classList.toggle(
      "showActive"
    );
  }

  public copyToClipboard(text: string) {
    const selBox = document.createElement("textarea");
    selBox.style.position = "fixed";
    selBox.style.left = "0";
    selBox.style.top = "0";
    selBox.style.opacity = "0";
    selBox.value = text;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand("copy");
    document.body.removeChild(selBox);
  }

  /**
   * Will compare the given address to a blacklist of wallet addresses that has been confirmed as scammers/abusers.
   * @param address A wallet address that has to be checked
   * @returns A boolean value if the given address is contained in the blacklist
   */
  public isOnBlacklist(address: string): boolean {
    const walletBlacklist = [
      "0x51e913b8185f3e400e9e601912ceb31493a10231",
      "0x65aa4dff0d3c688dceb16468f45d8c3a5d79bf39",
      "0x7de436e6e3b6cda87b01d1143c7702a46baaf589",
      "0xab6725f40f20459848930ece602f8c067b1107f8",
      "0x446826e42421f1515bf14007dc605e9ff1a413c8",
      "0xaefb1726ea366a59cbc721324ed987f020b363fb",
      "0x9f16508bdf7f45feced9072dfb455ed287487ba9",
      "0x2cd1e67d4cec0f5ff01a11731e4f4dcbd87e6c11",
      "0xfe8c334ce4f22383ab24e4bcbbd30d208b09c89d",
      "0x1bd951d362f4b8eee002ddbc071668fc1e5970f5",
      "0xbaf9a5d4b0052359326a6cdab54babaa3a3a9643",
      "0xa7667f3da3dc5ae27bf0449f9b508616a57f8732",
      "0x285ff8eecc33e81de6ae4bc67edaf4ba96cf861f",
      "0xbb2b46a41badbda3eba321f72bd4410b339cfbc0",
      "0x51e913b8185f3e400e9e601912ceb31493a10231",
      "0x7e93fe7df228cf3576de4278036f779599789957",
      "0xd7e8f8e85254b3247ffa3ee77769025ac81d08c3",
      "0x9abf08f91fae00994138e0ecc9affc0321e2e44f",
      "0x8864c46b1b278d69655b2c3f553702dcd29f2316",
      "0x859f21d8f4d19dfc2db98d27a620964c856863df",
      "0xb66ec5a383cfbd4feab7634b101d7d7617ede41e",
      "0x7b20848af156811ba61af0136b3d9d5a352e5cbf",
      "0x3aa548aec2549df5b67777674cb245805e62bb04",
      "0xbf5b71afaaa76bc58786e10aeee349e0cc152373",
      "0xe07e319b47868dfb291030bdb17c2b4892658559",
      "0xf9c668b09c47dfb146b03f6bbc0323763ad59b0b",
      "0x13a96af24cd5d4d407ca8ecd3a565e32cb6e8dec",
      "0x7b613887da942576536a9a8238876af7102c2074",
      "0x64ce8b9ba057e1ff678489a26ee91199ac30776d",
      "0x87e95f1365a982f05dc813a403da798e9e43f0da",
      "0x433036cbf872a2a10dacc930e2d14698c2c5be94",
      "0x4408af23e1a7b5758ebb4bbd841c0b61c6395c18",
      "0x735dbbf2152012c378a5204a421831be8e3bff55",
      "0x87af1c979d9c1527d7614cd062d985439bf1a951",
      "0x106e029c874f44eeb881a6cbca4af07472d90140",
    ];
    return walletBlacklist.includes(address);
  }
}
