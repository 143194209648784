import { DatePipe } from "@angular/common";
import { Component, NgZone, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators,
} from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import BigNumber from "bignumber.js";
import { Subscription } from "rxjs";
import { ContractService } from "src/app/services/contract";
import { GlobalControllerService } from "src/app/services/global-controller.service";
import { HttpService } from "src/app/services/http.service";

@Component({
  selector: "app-auctions-page",
  templateUrl: "./auctions-page.component.html",
  styleUrls: ["./auctions-page.component.scss"],
})
export class AuctionsPageComponent implements OnInit {
  initSubscription: Subscription = null;

  currentBUSDPool: number;

  currentREXPerBUSD: number;

  currentParticipants: number;

  currentREXPool: number;

  currentlyRecievedREX: number;

  myDonationToday: number;

  myTodayRefBalance: number;

  nextAuctionEndTimestamp: number;

  currentBUSDBalance: number = 0;

  hasAlreadyParticipated: boolean = false;

  participateInAuctionForm: FormGroup;

  participateInAuctionProgress: boolean = false;

  trexAmount: any;

  auctionsFromBlockchain: boolean = false;

  auctionsHaveEndedString: string;

  validationMessages = {
    busdAmount: [
      {
        type: "required",
        message: "ERRORMESSAGES.busdAmount_required",
      },
    ],
  };

  firstAuctionData: any;

  oldAuctionData: any;

  currRexDay: number = 0;

  personalAuctionData: any;

  totalREXreceived: any;

  personalSentBUSD: any;

  addressIsEligible: boolean;

  personalSentBusdBonus: boolean;

  auctionTableIsLoaded: boolean = false;

  auctionTableData: {
    tableHeads: string[];
    tableMatrix: any[];
  };

  filteredAuctionsTable: any[] = [];

  amountOfTableEntries = {
    auctions: 20,
  };

  currentPagination = {
    auctions: {
      first: 0,
      last: this.amountOfTableEntries.auctions,
    },
  };

  auctionTableHeads = [
    "AUCTIONS.table_head_col1",
    "AUCTIONS.table_head_col2",
    "AUCTIONS.table_head_col3",
    "AUCTIONS.table_head_col4",
    "AUCTIONS.table_head_col5",
    "AUCTIONS.table_head_col6",
    "AUCTIONS.table_head_col7",
  ];

  auctionTableMobile = [
    "AUCTIONS.mobile_table_head_col1",
    "AUCTIONS.mobile_table_head_col2",
    "AUCTIONS.mobile_table_head_col3",
  ];

  constructor(
    private formBuilder: FormBuilder,
    public glbC: GlobalControllerService,
    private translateService: TranslateService,
    private ngZone: NgZone,
    private contractService: ContractService,
    public service: HttpService,
    private datePipe: DatePipe
  ) {}

  ngOnInit() {
    console.log("auctions ngOnInit");
    this.translateService
      .get([this.validationMessages.busdAmount[0].message])
      .toPromise()
      .then((t) => {
        this.validationMessages.busdAmount[0].message =
          t[this.validationMessages.busdAmount[0].message];
      });
    // check init status:
    this.initSubscription = this.glbC.initSubject.subscribe(async (data) => {
      console.log("initSubscription data: ", data);
      if (data.doInit) {
        if (this.glbC.isConnected) {
          this.initContract();
        } else if (
          !this.glbC.getHasConnectedBefore("metamask") ||
          !this.glbC.getHasConnectedBefore("walletConnect")
        ) {
          this.glbC.logoutClicked();
        }
      }
    });
    if (!this.glbC.isConnected) {
      if (this.glbC.getHasConnectedBefore("metamask")) {
        this.loginWithMetamask();
      } else if (this.glbC.getHasConnectedBefore("walletConnect")) {
        this.loginWithWalletConnect();
      }
    } else if (
      (!this.glbC.isOnWrongNetwork && this.glbC.walletType === "metamask") ||
      this.glbC.walletType === "walletConnect"
    ) {
      this.initContract();
    }
  }

  ngOnDestroy() {
    this.initSubscription.unsubscribe();
  }

  resetProgresses() {
    this.participateInAuctionProgress = false;
  }

  initContract() {
    this.glbC.showLoader(true);
    // TODO init load page content
    this.resetProgresses();
    this.glbC
      .subscribeAccount()
      .then(() => {
        this.loadContractData()
          .then(() => {
            /*             this.loadAuctionsBlockchain(); */
            this.loadLatestAuctions();
            this.initParticipateInAuctionForm();
            this.glbC.showLoader(false);
          })
          .catch((error) => {
            console.warn("Error in loadContractData", error);
          });
      })
      .catch((err) => {
        console.error("connect error: ", err);
        this.glbC.showLoader(false);
      });
  }

  loadContractData() {
    return new Promise((resolve: any) => {
      console.log("loadContractData called");
      const promises: Promise<any>[] = [];
      this.contractService.getCurrentRexDay().then((currRxDay) => {
        console.log("getCurrentRexDay currRxDay: ", currRxDay);
        this.glbC.currRDay = currRxDay;
        if (
          this.glbC.currRDay <= this.glbC.lastAuctionDay &&
          Number(this.glbC.currRDay) !== 0
        ) {
          promises.push(
            new Promise((res: any) => {
              this.contractService
                .getTotalReceivedREX()
                .then((result) => {
                  this.totalREXreceived = result;
                  res();
                })
                .catch((error) => {
                  console.error("Error:", error);
                });
            })
          );
          promises.push(
            new Promise((res: any) => {
              this.contractService
                .getIsBPDeligibleAddr()
                .then((result) => {
                  console.log("Address eligible for BPDS: ", result);
                  this.addressIsEligible = Boolean(result);
                  res();
                })
                .catch((error) => {
                  console.error("Error:", error);
                });
            })
          );
          promises.push(
            new Promise((res: any) => {
              this.contractService
                .getTotalDonBalance()
                .then((result) => {
                  this.personalSentBusdBonus = result;
                  res();
                })
                .catch((error) => {
                  console.error("Error:", error);
                });
            })
          );
          promises.push(
            new Promise((res: any) => {
              this.contractService
                .getTotalSentBUSD()
                .then((result) => {
                  this.personalSentBUSD = result;
                  res();
                })
                .catch((error) => {
                  console.error("Error:", error);
                });
            })
          );
          promises.push(
            new Promise((res: any) => {
              this.glbC.getAuctionTimestamp(currRxDay).then((timestamp) => {
                this.nextAuctionEndTimestamp = timestamp;

                res();
              });
            })
          );
          promises.push(
            new Promise((res: any) => {
              this.contractService
                .getDonatorsToday(this.glbC.currRDay)
                .then((result) => {
                  this.currentParticipants = result;
                  console.log(
                    "this.currentParticipants: ",
                    this.currentParticipants
                  );
                  res();
                });
            })
          );
          promises.push(
            new Promise((res: any) => {
              this.contractService.getTrex().then((result) => {
                this.trexAmount = result;
                res();
              });
            })
          );
          promises.push(
            new Promise((res: any) => {
              this.contractService
                .getMyDonationOnDay(this.glbC.currRDay)
                .then((myDon) => {
                  this.myDonationToday = myDon;
                  if (myDon > 0) {
                    this.hasAlreadyParticipated = true;
                  } else {
                    this.hasAlreadyParticipated = false;
                  }
                  console.log("this.myDonationToday: ", this.myDonationToday);
                  this.contractService
                    .getMyReferralOnDay(this.glbC.currRDay)
                    .then((myRef) => {
                      this.myTodayRefBalance = myRef;
                      console.log(
                        "this.myTodayRefBalance: ",
                        this.myTodayRefBalance
                      );
                      this.contractService
                        .getDailyTotalDonation(this.glbC.currRDay)
                        .then((totalDon) => {
                          this.contractService
                            .getDailyTotalReferral(this.glbC.currRDay)
                            .then((totalRef) => {
                              const myDonRef = Number(myDon) + Number(myRef);
                              this.currentBUSDPool =
                                Number(totalDon) + Number(totalRef);
                              console.log(
                                "this.currentBUSDPool: ",
                                this.currentBUSDPool
                              );
                              this.currentREXPool =
                                this.contractService.getTodaysSupply(
                                  this.glbC.currRDay
                                );
                              console.log(
                                "this.currentREXPool: ",
                                this.currentREXPool
                              );
                              if (myDonRef > 0) {
                                this.currentlyRecievedREX =
                                  (this.currentREXPool * myDonRef) /
                                  this.currentBUSDPool;
                              }
                              if (myDonRef === 0) {
                                this.currentlyRecievedREX = 0;
                              }
                              console.log(
                                "this.currentlyRecievedREX: ",
                                this.currentlyRecievedREX
                              );
                              console.log(
                                `currentREXPool: ${this.currentREXPool}, currentBUSDPool: ${this.currentBUSDPool}`
                              );
                              this.currentREXPerBUSD =
                                this.currentREXPool / this.currentBUSDPool;
                              console.log(
                                "this.currentREXPerBUSD: ",
                                this.currentREXPerBUSD
                              );
                              res();
                            });
                        });
                    });
                });
            })
          );
          promises.push(
            new Promise((res: any) => {
              this.contractService.getBusdAmount().then((busdAmount) => {
                this.currentBUSDBalance = busdAmount;
                res();
              });
            })
          );
        } else if (Number(this.glbC.currRDay) === 0) {
          console.log("Day 0");

          this.glbC.getAuctionTimestamp(currRxDay).then((timestamp) => {
            this.nextAuctionEndTimestamp = timestamp; // + this.glbC.rexDayDurationInMilliseconds;
            console.log(this.nextAuctionEndTimestamp);
            resolve();
          });
        }
        Promise.all(promises).then(() => {
          // update vars
          this.auctionsHaveEndedString = this.datePipe.transform(
            this.glbC.rexDayToTimestamp(this.glbC.lastAuctionDay),
            "MMM dd yyyy "
          );
          resolve();
        });
      });
    });
  }

  private initParticipateInAuctionForm() {
    this.ngZone.run(() => {
      this.participateInAuctionForm = this.formBuilder.group({
        busdAmount: new FormControl(
          "",
          Validators.compose([Validators.required])
        ),
        referrerAddress: new FormControl(
          this.glbC.getReferrer() || "",
          Validators.compose([])
        ),
      });
      this.participateInAuctionForm.valueChanges.subscribe((val) => {
        console.log("participateInAuctionForm value changed, val: ", val);
      });
    });
  }

  private resetParticipateInAuctionForm() {
    // check if referrerCookie is set
    const referrerAddress = this.glbC.getReferrer() || "";
    this.participateInAuctionForm.patchValue({
      busdAmount: "",
      referrerAddress,
    });
  }

  setMyReferrerClicked(e) {
    e.preventDefault();
    const refAddress = this.glbC.getReferrer();
    if (refAddress) {
      this.participateInAuctionForm.controls.referrerAddress.patchValue(
        refAddress
      );
    }
  }

  setRandomReferrerClicked(e) {
    e.preventDefault();
    const rndAddress = this.getRandomReferrer();
    this.participateInAuctionForm.controls.referrerAddress.patchValue(
      rndAddress
    );
    console.log("randomizeReferrer: ", rndAddress);
  }

  getRandomReferrer() {
    const rndReferrals = [
      "0x52ea972F4b0F33DD7204bE7EE7bACCBe3DBbc265",
      "0xDEce5Dc5Cbc902938D4F0f74447BEaA4130808CF",
      "0x9F73Ab7DC12DfD2dBA0B18B28A809c8F4AFB6346",
      "0x917f0bA27BDD7C0E9d6BFa7286E7eFAA295384F0",
      "0x96971340edF00a9AFd1e2bba1bB3b59b0DA99CAC",
      "0x1e1A1DaDd6411657fecE2a35cC62335352D89EE0",
      "0xe70d08721193ef33ccf563f417168bc43681c107",
      "0xb3550b34130dfc6C204AED0BC15888208D4Fb246",
      "0xf367A698299D4c823751e75B5bdB189e4637f071",
      "0x3512A5D83C2631bEde6BB585B29C1D39f8B61AdE",
      "0x43143fdd3aeB9ad012778E490866B3BED476E375",
      "0x63F96c52ac513a32c707758f9484eDBB120b3fCC",
      "0x827b20B4f30D8057A8Fc585416F94C435bED1aaa",
      "0x0c3d05f560a17B128fc5f4Ef8f0F2CC014C48bb7",
      "0xAbB674d9D7f4435A0EACa8AcC302331689751e0D",
      "0xEF8071af60D650e548Ab9D1E7f2bB608D257852A",
      "0xBBf7d24DC0e447A959fD12fE622dC3c03c510518",
      "0xC4d2DbE8d51E4B0A36215755ac7c5847f1C3e9B0",
      "0x7D3A19bF9081dCBc6e5EC304749b982b88A90c37",
      "0x1e908B6D728f395514760A734f7173DCFfb7d0d8",
      "0x6252c737C7aBD7b7f9FaB61f1A5EC8D99684EDB1",
      "0x934b43610553C80850699aEb0d8F2D61B2048CC3",
    ];
    return rndReferrals[
      Math.floor(rndReferrals.length * (Math.random() - 0.00001))
    ];
  }

  async loadAuctionsBlockchain() {
    let auctionData = await this.contractService.getAuctionsData(
      this.glbC.currRDay - 1
    );
    console.log(auctionData);

    auctionData = auctionData.reverse();
    this.translateService
      .get(this.auctionTableHeads)
      .toPromise()
      .then((t) => {
        this.auctionTableData = {
          tableHeads: [
            t[this.auctionTableHeads[0]],
            t[this.auctionTableHeads[1]],
            t[this.auctionTableHeads[2]],
            t[this.auctionTableHeads[3]],
            t[this.auctionTableHeads[4]],
            t[this.auctionTableHeads[5]],
            t[this.auctionTableHeads[6]],
          ],
          tableMatrix: auctionData,
        };
        this.auctionTableIsLoaded = true;

        this.service.isInitializing = false;
        this.service.isReady = false;
        this.glbC.showLoader(false);
      });
  }

  participateInAuctionFormSubmit() {
    console.log("participateInAuctionFormSubmit clicked");
    if (this.participateInAuctionForm.valid) {
      this.sendToAuction();
    } else {
      const errorMessages = [];
      Object.keys(this.participateInAuctionForm.controls).forEach((key) => {
        const tempErrors: ValidationErrors =
          this.participateInAuctionForm.get(key).errors;
        console.log(tempErrors);
        if (tempErrors != null) {
          Object.keys(tempErrors).forEach((errorKey) => {
            this.validationMessages[key].forEach((msg) => {
              if (msg.type === errorKey) {
                errorMessages.push(msg.message);
              }
            });
          });
        }
      });
      console.log("errorMessages: ", errorMessages);
      let formattedErrorMessage: string = "<ul>";
      errorMessages.forEach((errMsg) => {
        formattedErrorMessage += `<li>${errMsg}</li>`;
      });
      formattedErrorMessage += "</ul>";
      this.translateService
        .get(["POPUPS.form_error", "BUTTONS.ok"])
        .toPromise()
        .then((translations) => {
          this.glbC.showPopupMessage({
            title: translations["POPUPS.form_error"],
            msg: formattedErrorMessage,
            declineButtonText: translations["BUTTONS.ok"],
            removeOtherPopups: true,
          });
        });
    }
  }

  sendToAuction() {
    console.log("sendToAuction called");
    this.participateInAuctionProgress = true;
    console.log(
      "this.participateInAuctionForm.value.busdAmount: ",
      this.participateInAuctionForm.value.busdAmount
    );
    console.log(
      "this.participateInAuctionForm.value.referrerAddress: ",
      this.participateInAuctionForm.value.referrerAddress
    );
    if (Number.isNaN(this.participateInAuctionForm.value.busdAmount)) {
      this.participateInAuctionProgress = false;
      this.glbC.showHelpMessage("auction_input_incorrect");
      return;
    }
    const auct = new BigNumber(this.participateInAuctionForm.value.busdAmount);
    if (
      auct.times(1e18) > this.glbC.account.balances.BUSD.weiBigNumber.toNumber()
    ) {
      this.participateInAuctionProgress = false;
      this.glbC.showHelpMessage("auction_input_insufficient_busd");
      return;
    }
    if (auct.gt(100000)) {
      this.participateInAuctionProgress = false;
      this.glbC.showHelpMessage("auction_input_incorrect");
      return;
    }
    if (auct.gt(50000) && this.trexAmount < 1) {
      this.participateInAuctionProgress = false;
      this.glbC.showHelpMessage("auction_input_incorrect");
      return;
    }
    if (auct.lt(100)) {
      this.participateInAuctionProgress = false;
      this.glbC.showHelpMessage("auction_input_incorrect");
      return;
    }
    const auctNew = auct.times(10 ** 18);
    console.log("send ETH");
    this.contractService
      .sendETHToAuction(
        auctNew,
        this.participateInAuctionForm.value.referrerAddress
      )
      .then(
        ({ transactionHash }) => {
          console.log("transactionHash: ", transactionHash);
          this.contractService.updateETHBalance(true);
          this.contractService.updateBUSDBalance(true);
          this.loadContractData().then(() => {
            this.resetParticipateInAuctionForm();
            this.participateInAuctionProgress = false;
            this.hasAlreadyParticipated = true;
          });
        },
        (err) => {
          if (err.msg) {
            // TODO show error
          }
          this.participateInAuctionProgress = false;
        }
      )
      .catch((error) => {
        console.log("Error in sending BUSD to auction ", error);
        this.participateInAuctionProgress = false;
      });
  }

  async loadOldAuctions() {
    let oldPersonalData: any;
    let lastDayToLoad;
    if (this.glbC.currRDay <= this.glbC.lastAuctionDay) {
      lastDayToLoad =
        this.glbC.currRDay - this.service.auctionDaysToPreload - 1;
    } else {
      lastDayToLoad =
        this.glbC.lastAuctionDay - this.service.auctionDaysToPreload;
    }
    const promAuctions = new Promise((resolve) => {
      this.service
        .getRequest(
          `${this.service.serverAddress}/readDB/auctions?action=getOldAuctions&parameter=${lastDayToLoad}`
        )
        .subscribe((content) => {
          const result = Object.values(JSON.parse(content));
          resolve(result);
        });
    });

    // eslint-disable-next-line prefer-const
    [this.oldAuctionData, oldPersonalData] = await Promise.all([
      promAuctions,
      this.service.contractService.getPersonalAuctionData(0, lastDayToLoad),
    ]);

    // Combine global auction data and personal referal/donation amount
    for (let i = 0; i < this.oldAuctionData.length; i++) {
      for (let j = 0; j < oldPersonalData.length; j++) {
        if (oldPersonalData[j].day === this.oldAuctionData[i].dayNo) {
          this.oldAuctionData[i].myDonAmount = oldPersonalData[j].myDonAmount;
          this.oldAuctionData[i].myRefAmount = oldPersonalData[j].myRefAmount;
        }
      }
    }

    this.oldAuctionData = this.oldAuctionData.reverse();

    this.auctionTableData.tableMatrix =
      this.auctionTableData.tableMatrix.concat(this.oldAuctionData);

    this.checkCookieForTableLength("tb_auctions", "auctions");
  }

  /**
   * Checks if there is a local cookie stored for the table. If yes, use it as table size
   * @param cookieName Name of the set cookie
   * @param tableName Which table will it affect
   */
  checkCookieForTableLength(cookieName: string, tableName: string) {
    // Check if there is a cookie set for table size
    if (this.glbC.cookieService.get(cookieName)) {
      const cookieTableLength = Number(this.glbC.cookieService.get(cookieName));
      this.amountOfTableEntries[tableName] = cookieTableLength;

      this.currentPagination[tableName].last =
        this.currentPagination[tableName].first + cookieTableLength;
    }
  }

  async loadLatestAuctions() {
    let dayToLoad;
    let lastLoadingDay;

    if (
      this.glbC.currRDay <= this.glbC.lastAuctionDay &&
      this.glbC.currRDay >= 10
    ) {
      dayToLoad = this.glbC.currRDay - this.service.auctionDaysToPreload;
    } else if (this.glbC.currRDay < 10) {
      dayToLoad = 1;
    } else {
      dayToLoad = 213;
    }

    const promFirstAuctions = await new Promise((resolve) => {
      this.service
        .getRequest(
          `${this.service.serverAddress}/readDB/auctions?action=getNewAuctions&parameter=${dayToLoad}`
        )
        .subscribe((content) => {
          const result = Object.values(JSON.parse(content));
          resolve(result);
        });
    });

    if (this.glbC.currRDay <= this.glbC.lastAuctionDay) {
      lastLoadingDay = this.glbC.currRDay;
    } else {
      lastLoadingDay = this.glbC.lastAuctionDay;
    }
    [this.firstAuctionData, this.personalAuctionData] = await Promise.all([
      promFirstAuctions,
      this.contractService.getPersonalAuctionData(dayToLoad, lastLoadingDay),
    ]);

    // Combine global auction data and personal referal/donation amount
    for (let i = 0; i < this.firstAuctionData.length; i++) {
      for (let j = 0; j < this.personalAuctionData.length; j++) {
        if (
          Number(this.personalAuctionData[j].day) ===
          Number(this.firstAuctionData[i].dayNo)
        ) {
          this.firstAuctionData[i].myDonAmount =
            this.personalAuctionData[j].myDonAmount;
          this.firstAuctionData[i].myRefAmount =
            this.personalAuctionData[j].myRefAmount;
        }
      }
    }
    this.firstAuctionData = this.firstAuctionData.reverse();

    this.translateService
      .get(this.auctionTableHeads)
      .toPromise()
      .then((t) => {
        this.auctionTableData = {
          tableHeads: [
            t[this.auctionTableHeads[0]],
            t[this.auctionTableHeads[1]],
            t[this.auctionTableHeads[2]],
            t[this.auctionTableHeads[3]],
            t[this.auctionTableHeads[4]],
            t[this.auctionTableHeads[5]],
            t[this.auctionTableHeads[6]],
          ],
          tableMatrix: this.firstAuctionData,
        };
        this.auctionTableIsLoaded = true;
        console.log([...this.firstAuctionData]);

        this.service.isInitializing = false;
        this.service.isReady = false;
        this.glbC.showLoader(false);

        this.loadOldAuctions();
      });
  }

  sortContent(sortingType: any) {
    this.auctionTableData.tableMatrix.sort(
      (a, b) => Number(a[sortingType]) - Number(b[sortingType])
    );
  }

  changeTablePage(newNumbers: any, tableType: string) {
    this.currentPagination[tableType].first = newNumbers.lowestNumber;
    this.currentPagination[tableType].last = newNumbers.highestNumber;
    if (newNumbers.defaultAmount) {
      console.log(
        "change default amount of table to ",
        newNumbers.defaultAmount
      );
      this.glbC.cookieService.set(`tb_${tableType}`, newNumbers.defaultAmount, {
        expires: 365,
        path: "/",
      });
    }
  }

  loginWithMetamask() {
    console.log("login metamask clicked");
    if (!this.glbC.waitingForAuthorization) {
      this.glbC.walletType = "metamask";
      this.glbC.hasConnectedBefore(true, "metamask");
      this.initContract();
    }
  }

  loginWithWalletConnect() {
    console.log("login walletConnect clicked");
    if (!this.glbC.waitingForAuthorization) {
      this.glbC.walletType = "walletConnect";
      this.glbC.hasConnectedBefore(true, "walletConnect");
      this.initContract();
    }
  }
}
