import {
  Component,
  ElementRef,
  HostListener,
  Inject,
  ViewChild,
  ViewContainerRef,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { GlobalControllerService } from "./services/global-controller.service";

declare global {
  interface Window {
    ethereum: any;
  }
}

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  title = "rex-app";

  @ViewChild("bgArrow1", { static: true }) bgArrow1: ElementRef;

  @ViewChild("bgArrow2", { static: true }) bgArrow2: ElementRef;

  @ViewChild("bgArrow3", { static: true }) bgArrow3: ElementRef;

  @HostListener("window:resize") onResize() {
    this.glbC.viewPortChanged();
  }

  constructor(
    public glbC: GlobalControllerService,
    private route: ActivatedRoute,
    @Inject(ViewContainerRef) viewContainerRef
  ) {
    this.glbC.setPopupRootViewContainerRef(viewContainerRef);
    this.checkQueryParams();
  }

  ngOnInit() {
    this.startBackgroundAnimation();
    this.glbC.viewPortChanged();
  }

  checkQueryParams() {
    // check if referal url:
    this.route.queryParams.subscribe((params) => {
      if (params.ref) {
        this.glbC.setReferrer(params.ref);
      }
      // check parameter to set rewardWindowAlreadeyOpen
      if (params.fAO && params.fAO === "1337") {
        this.glbC.rewardWindowAlreadeyOpen = true;
      }
    });
  }

  startBackgroundAnimation() {
    // home page mouse follow effect start
    const elements = [];
    let tmpRect = this.bgArrow1.nativeElement.getBoundingClientRect();
    elements.push({
      element: this.bgArrow1.nativeElement,
      root_x: tmpRect.left,
      root_y: tmpRect.right,
      max_move: 60,
    });
    tmpRect = this.bgArrow2.nativeElement.getBoundingClientRect();
    elements.push({
      element: this.bgArrow2.nativeElement,
      root_x: tmpRect.left,
      root_y: tmpRect.right,
      max_move: 120,
    });
    tmpRect = this.bgArrow3.nativeElement.getBoundingClientRect();
    elements.push({
      element: this.bgArrow3.nativeElement,
      root_x: tmpRect.left,
      root_y: tmpRect.right,
      max_move: 150,
    });

    const vh = window.innerHeight;
    const vw = window.innerWidth;
    const lastMovement = [Date.now(), Date.now(), Date.now()];
    for (let i = 0; i < elements.length; i++) {
      window.addEventListener("mousemove", (event) => {
        if (Date.now() - lastMovement[i] > 100) {
          lastMovement[i] = Date.now();
          const gotoX =
            (elements[i].max_move / vw) *
            -(elements[i].root_x / 2 - event.clientX / 2);
          const gotoY =
            (elements[i].max_move / vh) *
            -(elements[i].root_y / 2 - event.clientY / 2);
          elements[
            i
          ].element.style.transform = `translate(${gotoX}px, ${gotoY}px)`;
        }
      });
    }
  }
}
