import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "app-table-sorting",
  templateUrl: "./table-sorting.component.html",
  styleUrls: ["./table-sorting.component.scss"],
})
export class TableSortingComponent implements OnInit {
  @Input() dropDownOptions: object[];

  @Input() buttonText: string;

  @Output() changeSortingType = new EventEmitter<any>();

  currentValue: any;

  constructor() {}

  ngOnInit() {
    this.updateDropdownContent();
  }

  dropdownContent: any[] = [];

  updateDropdownContent() {
    for (let i = 0; i < this.dropDownOptions.length; i++) {
      this.dropdownContent.push({
        name: this.dropDownOptions[i][0].toString(),
        value: this.dropDownOptions[i][1],
      });
    }
  }

  changeSorting(sortingType: string) {
    this.currentValue = sortingType;
    console.log(sortingType);
    this.changeSortingType.emit(sortingType);
  }
}
